// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2wMOayDrwDV34k8QBqTk-l{width:1020px;margin-right:440px;margin-bottom:100px}.aj-Jdq0CvjNMbmuuTgO4A{width:400px;margin-left:1020px;z-index:100;padding:24px;box-sizing:border-box;position:fixed}._37vvBkEwE95dRPUHN16Pg1{width:400px;max-height:calc(100vh - 220px);overflow:auto;height:fit-content}@media screen and (max-width: 1480px){._2wMOayDrwDV34k8QBqTk-l{width:720px;margin-right:500px}}@media screen and (max-width: 1300px){._2wMOayDrwDV34k8QBqTk-l{margin-right:300px}}@media screen and (max-width: 1200px){._2wMOayDrwDV34k8QBqTk-l{margin-right:100px;width:520px}}", ""]);
// Exports
exports.locals = {
	"portfolioMainWrapper": "_2wMOayDrwDV34k8QBqTk-l",
	"watchlistWrapper": "aj-Jdq0CvjNMbmuuTgO4A",
	"watchlistCard": "_37vvBkEwE95dRPUHN16Pg1"
};
module.exports = exports;
