import { ResolveEnvironment } from 'phoenix/constants';
import { GetUseWhitelabel } from 'phoenix/util';

export enum Whitelabel {
    Aiva = 'Aiva',
    Galicia = 'Galicia',
    Winexco = 'Winexco',
    FolioNet = 'folionet',
    AvSecurities = 'avsecurities',
    ConosurInversiones = 'conosurinversiones'
}

export const WhitelabelFormattedNames: Record<Whitelabel, string> = {
    [Whitelabel.Aiva]: 'Aiva',
    [Whitelabel.Galicia]: 'Galicia',
    [Whitelabel.Winexco]: 'Winexco',
    [Whitelabel.FolioNet]: 'Folionet',
    [Whitelabel.AvSecurities]: 'AV Securities',
    [Whitelabel.ConosurInversiones]: 'Conosur Inversiones'
};

export const GetWhitelabel = (): Whitelabel | null => {
    const env = ResolveEnvironment();
    if (env === Whitelabel.Aiva || GetUseWhitelabel()?.toLowerCase() === Whitelabel.Aiva.toLowerCase()) return Whitelabel.Aiva;
    if (env === Whitelabel.Galicia || GetUseWhitelabel()?.toLowerCase() === Whitelabel.Galicia.toLowerCase()) return Whitelabel.Galicia;
    if (env === Whitelabel.Winexco || GetUseWhitelabel()?.toLowerCase() === Whitelabel.Winexco.toLowerCase()) return Whitelabel.Winexco;
    if (env === Whitelabel.FolioNet || GetUseWhitelabel()?.toLowerCase() === Whitelabel.FolioNet.toLowerCase()) return Whitelabel.FolioNet;
    if (env === Whitelabel.AvSecurities || GetUseWhitelabel()?.toLowerCase() === Whitelabel.AvSecurities.toLowerCase()) return Whitelabel.AvSecurities;
    if (env === Whitelabel.ConosurInversiones || GetUseWhitelabel()?.toLowerCase() === Whitelabel.ConosurInversiones.toLowerCase()) return Whitelabel.ConosurInversiones;
    return null;
};

export const IsDebugWhitelabel = (): boolean => {
    const whitelabels = Object.values(Whitelabel);
    const debugSetting = GetUseWhitelabel()?.toLowerCase();
    const whitelabelStrings = whitelabels.map((w) => w.toString().toLowerCase());
    return whitelabelStrings.includes(debugSetting);
};

const IsEnvWhitelabel = (): boolean => {
    const env = ResolveEnvironment();
    const whitelabels = Object.values(Whitelabel);
    return whitelabels.includes(env as unknown as Whitelabel);
};

export const IsWhitelabel = (): boolean => IsEnvWhitelabel() || IsDebugWhitelabel();

export function isWhitelabel(thing: unknown): thing is Whitelabel {
    return Object.values(Whitelabel).includes(thing as Whitelabel);
}

const MatchesWhitelabel = (whitelabel: Whitelabel): boolean => {
    const env = ResolveEnvironment();
    return env === whitelabel?.toLowerCase();
};

export const IsAiva = (): boolean => GetUseWhitelabel()?.toLowerCase() === Whitelabel.Aiva.toString().toLowerCase() || MatchesWhitelabel(Whitelabel.Aiva);
export const IsGalicia = (): boolean => GetUseWhitelabel()?.toLowerCase() === Whitelabel.Galicia.toString().toLowerCase() || MatchesWhitelabel(Whitelabel.Galicia);
export const IsWinexco = (): boolean => GetUseWhitelabel()?.toLowerCase() === Whitelabel.Winexco.toString().toLowerCase() || MatchesWhitelabel(Whitelabel.Winexco);
export const IsFolioNet = (): boolean => GetUseWhitelabel()?.toLowerCase() === Whitelabel.FolioNet.toString().toLowerCase() || MatchesWhitelabel(Whitelabel.FolioNet);
export const IsAvSecurities = (): boolean => GetUseWhitelabel()?.toLowerCase() === Whitelabel.AvSecurities.toString().toLowerCase() || MatchesWhitelabel(Whitelabel.AvSecurities);
export const IsConosurInversiones = (): boolean => GetUseWhitelabel()?.toLowerCase() === Whitelabel.ConosurInversiones.toString().toLowerCase() || MatchesWhitelabel(Whitelabel.ConosurInversiones);
