

import React from "react"


export const PieChartIcon = ({size}: {size?: number}) : JSX.Element => {
    return(
    <svg width={size || 25} height={size || 25} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.81128 7.43715L13.4174 12.4056C14.4263 11.1592 15.031 9.57224 15.031 7.84378C15.031 4.18825 12.3277 1.16454 8.81128 0.661469V7.43715Z" fill="url(#paint0_linear_1014_997)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.01445 8.54853C6.83676 8.35688 6.73804 8.10513 6.73804 7.84375V0.661438C3.22161 1.16451 0.518311 4.18817 0.518311 7.84375C0.518311 11.8513 3.76706 15.1001 7.77465 15.1001C9.30608 15.1001 10.7264 14.6253 11.8973 13.8154L7.01445 8.54853Z" fill="url(#paint1_linear_1014_997)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M23.5702 14.2189H18.5684C18.089 14.2189 17.7003 13.8303 17.7003 13.3508V6.01672C17.7003 5.53723 18.089 5.14856 18.5684 5.14856H23.5701C24.0496 5.14856 24.4383 5.53723 24.4383 6.01672V13.3508C24.4383 13.8303 24.0496 14.2189 23.5702 14.2189Z" fill="#D1FFDE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M19.536 24.4815H7.79626C7.31677 24.4815 6.9281 24.0928 6.9281 23.6133V19.13C6.9281 18.6505 7.31677 18.2618 7.79626 18.2618H19.536C20.0154 18.2618 20.4041 18.6505 20.4041 19.13V23.6133C20.4041 24.0928 20.0154 24.4815 19.536 24.4815Z" fill="#D1FFDE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M23.5702 4.63024H21.5876V0.518306C21.5876 0.232078 21.3555 0 21.0693 0H12.6468C12.3605 0 12.1285 0.232078 12.1285 0.518306V1.40311C11.1611 0.748578 10.0533 0.315525 8.88469 0.148339C8.73616 0.127147 8.58533 0.171385 8.47176 0.269822C8.35823 0.368259 8.293 0.511129 8.293 0.661469V7.4372C8.293 7.56786 8.34241 7.69369 8.43123 7.78959L13.0374 12.758C13.1357 12.864 13.2735 12.9239 13.4175 12.9239C13.4232 12.9239 13.4291 12.9238 13.4349 12.9236C13.5851 12.9185 13.7258 12.8485 13.8203 12.7317C14.9354 11.3542 15.5494 9.61833 15.5494 7.84378C15.5494 5.96133 14.8678 4.14499 13.6301 2.72932C13.4817 2.55965 13.3265 2.3972 13.1651 2.24202V1.03661H20.5511V4.63019H18.5685C17.804 4.63019 17.182 5.25215 17.182 6.01665V13.3507C17.182 14.1152 17.804 14.7372 18.5685 14.7372H23.5702C24.3347 14.7372 24.9566 14.1152 24.9566 13.3507V6.0167C24.9566 5.2522 24.3347 4.63024 23.5702 4.63024ZM14.5127 7.84374C14.5127 9.17991 14.1111 10.4906 13.3721 11.5943L9.32956 7.23388V1.28671C10.382 1.53617 11.3349 2.03431 12.1284 2.71101V5.75596C12.1284 6.04219 12.3604 6.27427 12.6467 6.27427C12.933 6.27427 13.165 6.04219 13.165 5.75596V3.81603C14.013 4.94913 14.5127 6.34966 14.5127 7.84374ZM23.92 13.3508C23.92 13.5437 23.7631 13.7006 23.5702 13.7006H18.5685C18.3755 13.7006 18.2186 13.5437 18.2186 13.3508V6.0167C18.2186 5.82378 18.3755 5.66685 18.5685 5.66685H23.5701C23.763 5.66685 23.92 5.82378 23.92 6.0167V13.3508Z" fill="#13B53F"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M19.5359 17.7435H7.79625C7.03175 17.7435 6.40978 18.3654 6.40978 19.1299V20.8533H4.37108V14.8327C5.39963 15.3356 6.55465 15.6184 7.77466 15.6184C9.36239 15.6184 10.89 15.1423 12.1922 14.2416C12.3158 14.1562 12.3962 14.0211 12.4126 13.8718C12.4289 13.7224 12.3796 13.5732 12.2775 13.4629L7.39459 8.19614C7.30548 8.1 7.25636 7.97475 7.25636 7.8437V0.661481C7.25636 0.511239 7.19117 0.36832 7.0776 0.269834C6.96407 0.171446 6.81334 0.12716 6.66466 0.148351C4.8302 0.4108 3.14496 1.32735 1.91929 2.72929C0.681605 4.14495 -3.05176e-05 5.96124 -3.05176e-05 7.84375C-3.05176e-05 10.4811 1.32032 12.8156 3.33442 14.222V21.3716C3.33442 21.6578 3.56644 21.8899 3.85272 21.8899H6.40968V23.6133C6.40968 24.3778 7.03165 24.9998 7.79615 24.9998H19.5359C20.3004 24.9998 20.9224 24.3778 20.9224 23.6133V19.1299C20.9224 18.3654 20.3004 17.7435 19.5359 17.7435ZM3.85277 9.50233C3.56649 9.50233 3.33446 9.73441 3.33446 10.0206V12.9073C1.92686 11.6715 1.03663 9.8597 1.03663 7.84375C1.03663 4.7154 3.22621 1.99624 6.21974 1.28672V7.84375C6.21974 8.23705 6.36701 8.61244 6.63434 8.90087L11.0923 13.7094C10.0837 14.2818 8.94829 14.5817 7.77461 14.5817C6.53385 14.5817 5.37053 14.2442 4.37103 13.6567V10.0206C4.37108 9.73446 4.13905 9.50233 3.85277 9.50233ZM19.8858 23.6133C19.8858 23.8063 19.7289 23.9632 19.5359 23.9632H7.79625C7.60333 23.9632 7.44639 23.8063 7.44639 23.6133V19.13C7.44639 18.9371 7.60333 18.7801 7.79625 18.7801H19.5359C19.7289 18.7801 19.8858 18.9371 19.8858 19.13V23.6133Z" fill="#13B53F"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.6492 20.8533H10.6081C10.8944 20.8533 11.1264 20.6212 11.1264 20.335C11.1264 20.0488 10.8944 19.8167 10.6081 19.8167H9.6492C9.36292 19.8167 9.13089 20.0488 9.13089 20.335C9.13089 20.6212 9.36292 20.8533 9.6492 20.8533Z" fill="url(#paint2_linear_1014_997)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M13.9954 19.8167H12.4899C12.2036 19.8167 11.9716 20.0488 11.9716 20.335C11.9716 20.6212 12.2036 20.8533 12.4899 20.8533H13.9954C14.2817 20.8533 14.5137 20.6212 14.5137 20.335C14.5137 20.0488 14.2817 19.8167 13.9954 19.8167Z" fill="url(#paint3_linear_1014_997)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.683 19.8167H16.7241C16.4379 19.8167 16.2058 20.0488 16.2058 20.335C16.2058 20.6212 16.4379 20.8533 16.7241 20.8533H17.683C17.9692 20.8533 18.2013 20.6212 18.2013 20.335C18.2013 20.0488 17.9692 19.8167 17.683 19.8167Z" fill="url(#paint4_linear_1014_997)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M11.3596 21.89H9.64917C9.36289 21.89 9.13086 22.122 9.13086 22.4083C9.13086 22.6945 9.36289 22.9266 9.64917 22.9266H11.3596C11.6459 22.9266 11.8779 22.6945 11.8779 22.4083C11.8779 22.122 11.6459 21.89 11.3596 21.89Z" fill="url(#paint5_linear_1014_997)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M13.9302 21.89H13.5077C13.2214 21.89 12.9893 22.122 12.9893 22.4083C12.9893 22.6945 13.2214 22.9266 13.5077 22.9266H13.9302C14.2164 22.9266 14.4485 22.6945 14.4485 22.4083C14.4485 22.122 14.2164 21.89 13.9302 21.89Z" fill="url(#paint6_linear_1014_997)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.683 21.89H15.9725C15.6863 21.89 15.4542 22.122 15.4542 22.4083C15.4542 22.6945 15.6863 22.9266 15.9725 22.9266H17.683C17.9692 22.9266 18.2013 22.6945 18.2013 22.4083C18.2013 22.122 17.9692 21.89 17.683 21.89Z" fill="url(#paint7_linear_1014_997)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M22.3651 7.09219H19.7735C19.4873 7.09219 19.2552 7.32427 19.2552 7.6105C19.2552 7.89673 19.4873 8.12881 19.7735 8.12881H22.3651C22.6513 8.12881 22.8834 7.89673 22.8834 7.6105C22.8834 7.32432 22.6513 7.09219 22.3651 7.09219Z" fill="url(#paint8_linear_1014_997)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M22.3651 9.16547H19.7735C19.4873 9.16547 19.2552 9.39754 19.2552 9.68377C19.2552 9.97 19.4873 10.2021 19.7735 10.2021H22.3651C22.6513 10.2021 22.8834 9.97 22.8834 9.68377C22.8834 9.39754 22.6513 9.16547 22.3651 9.16547Z" fill="url(#paint9_linear_1014_997)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M22.3651 11.2387H19.7735C19.4873 11.2387 19.2552 11.4708 19.2552 11.757C19.2552 12.0432 19.4873 12.2753 19.7735 12.2753H22.3651C22.6513 12.2753 22.8834 12.0432 22.8834 11.757C22.8834 11.4708 22.6513 11.2387 22.3651 11.2387Z" fill="url(#paint10_linear_1014_997)"/>
    <defs>
    <linearGradient id="paint0_linear_1014_997" x1="5.8979" y1="4.42935" x2="13.3703" y2="8.52978" gradientUnits="userSpaceOnUse">
    <stop stopColor="#80FF6C"/>
    <stop offset="0.622877" stopColor="#29E43B"/>
    <stop offset="1" stopColor="#00DB16"/>
    </linearGradient>
    <linearGradient id="paint1_linear_1014_997" x1="-4.8117" y1="5.29382" x2="5.85982" y2="14.0079" gradientUnits="userSpaceOnUse">
    <stop stopColor="#80FF6C"/>
    <stop offset="0.622877" stopColor="#29E43B"/>
    <stop offset="1" stopColor="#00DB16"/>
    </linearGradient>
    <linearGradient id="paint2_linear_1014_997" x1="8.19618" y1="20.1493" x2="8.82275" y2="21.399" gradientUnits="userSpaceOnUse">
    <stop stopColor="#80FF6C"/>
    <stop offset="0.622877" stopColor="#29E43B"/>
    <stop offset="1" stopColor="#00DB16"/>
    </linearGradient>
    <linearGradient id="paint3_linear_1014_997" x1="10.7809" y1="20.1493" x2="11.3138" y2="21.5034" gradientUnits="userSpaceOnUse">
    <stop stopColor="#80FF6C"/>
    <stop offset="0.622877" stopColor="#29E43B"/>
    <stop offset="1" stopColor="#00DB16"/>
    </linearGradient>
    <linearGradient id="paint4_linear_1014_997" x1="15.2711" y1="20.1493" x2="15.8976" y2="21.399" gradientUnits="userSpaceOnUse">
    <stop stopColor="#80FF6C"/>
    <stop offset="0.622877" stopColor="#29E43B"/>
    <stop offset="1" stopColor="#00DB16"/>
    </linearGradient>
    <linearGradient id="paint5_linear_1014_997" x1="7.84412" y1="22.2225" x2="8.34697" y2="23.6033" gradientUnits="userSpaceOnUse">
    <stop stopColor="#80FF6C"/>
    <stop offset="0.622877" stopColor="#29E43B"/>
    <stop offset="1" stopColor="#00DB16"/>
    </linearGradient>
    <linearGradient id="paint6_linear_1014_997" x1="12.3059" y1="22.2225" x2="13.0353" y2="23.2863" gradientUnits="userSpaceOnUse">
    <stop stopColor="#80FF6C"/>
    <stop offset="0.622877" stopColor="#29E43B"/>
    <stop offset="1" stopColor="#00DB16"/>
    </linearGradient>
    <linearGradient id="paint7_linear_1014_997" x1="14.1675" y1="22.2225" x2="14.6703" y2="23.6033" gradientUnits="userSpaceOnUse">
    <stop stopColor="#80FF6C"/>
    <stop offset="0.622877" stopColor="#29E43B"/>
    <stop offset="1" stopColor="#00DB16"/>
    </linearGradient>
    <linearGradient id="paint8_linear_1014_997" x1="17.5558" y1="7.42477" x2="17.9565" y2="8.87814" gradientUnits="userSpaceOnUse">
    <stop stopColor="#80FF6C"/>
    <stop offset="0.622877" stopColor="#29E43B"/>
    <stop offset="1" stopColor="#00DB16"/>
    </linearGradient>
    <linearGradient id="paint9_linear_1014_997" x1="17.5558" y1="9.49805" x2="17.9565" y2="10.9514" gradientUnits="userSpaceOnUse">
    <stop stopColor="#80FF6C"/>
    <stop offset="0.622877" stopColor="#29E43B"/>
    <stop offset="1" stopColor="#00DB16"/>
    </linearGradient>
    <linearGradient id="paint10_linear_1014_997" x1="17.5558" y1="11.5713" x2="17.9565" y2="13.0246" gradientUnits="userSpaceOnUse">
    <stop stopColor="#80FF6C"/>
    <stop offset="0.622877" stopColor="#29E43B"/>
    <stop offset="1" stopColor="#00DB16"/>
    </linearGradient>
    </defs>
    </svg>
    )
}