// @ts-strict-ignore
import { Grid } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { ExpandButton, Flex, TopDisplayCard } from '..';
import { T } from 'phoenix/assets/lang/T';
import { LiveDataNamespaces } from 'phoenix/constants/LiveDataNamespaces';
import { useAppWindowSize, WindowSizes } from 'hooks/UseWindowSize';
import { GetMultiSecurityChartsForRange, GetMultiSecurityQuotesAction, ReduxLiveUnsubscribeNamespace } from 'phoenix/redux/actions';
import { CulledCollapse } from '../CulledCollapse/CulledCollapse';
import { LoadingBlock } from '../LoadingBlock';

interface SecurityCardCollectionProps {
    symbols: string[];
    loading?: boolean;
    accountNumber?: string;
    loadingAmount?: number;
    namespace?: string;
    noDataText?: string;
    expanded?: boolean;
    onExpandToggle?: () => any;
    title?: string
}

export const SecurityCardCollection = React.memo((props: SecurityCardCollectionProps) => {

    const dispatch = useDispatch();
    const [ appWindowSize, setAppWindowSize ] = useAppWindowSize();
    const { symbols, loading, expanded } = props;
    const [ key, setKey ] = useState(1);
    const [ symbolList, setSymbolList ] = useState<string[]>([]);

    const rowCount = useMemo(() => {
        switch (appWindowSize) {
            case WindowSizes.large: return 4;
            case WindowSizes.tablet: return 2;
            default: return 3;
        }
    }, [ appWindowSize ]);

    const gridSize = useMemo(() => {
        switch (appWindowSize) {
            case WindowSizes.large: return 3;
            case WindowSizes.tablet: return 6;
            default: return 4;
        }
    }, [ appWindowSize ]);

    useEffect(() => {
        const newListIsIdentical = symbolList && symbols && (symbolList?.length === symbols?.length)
            && (symbolList.every((s, idx) => symbols[idx] === symbolList[idx]));

        if (!newListIsIdentical) {
            setKey(key + 1);
            setSymbolList(symbols);
        } else return;

        const fetchDataAsync = async () => {
            // TODO -- Get rid of this and let each card ask for quotes on its own, dedupe with Redux-level batching
            await Promise.all([
                dispatch(GetMultiSecurityQuotesAction(symbols))
                // dispatch(GetMultiSecurityChartsForRange(symbols))
            ]);
        };

        if (symbols?.length && !loading && !newListIsIdentical) fetchDataAsync();
        
    }, [ symbols ]);

    const head = symbols?.slice(0, rowCount);
    const tail = symbols?.slice(rowCount) || [];
    const anySymbols = !!symbols && !!symbols?.length;

    const commonDisplayCardProps = useMemo(() => ({ accountNumber: props.accountNumber, liveDataNamespace: props.namespace, title: props.title }), [ props.namespace, props.title, props.accountNumber ])

    if (!loading && !anySymbols) return props.noDataText ? (
        <LoadingBlock height={ 320 } noDataText={ props.noDataText } />
    ) : null;

    return (
        
        <Flex column style={{ minHeight: 320, opacity: (loading && anySymbols) ? 0.6 : 1 }}>
            <Grid container spacing={ 2 }>
                { (loading && !anySymbols) ? (
                    <>
                        { new Array(props.loadingAmount || 4).fill(0).map((_, idx) => (
                            <Grid item key={idx} xs={ gridSize }><TopDisplayCard skeleton /></Grid>
                        )) }
                    </>
                ) : (
                    head.map((c, idx) => (
                        <Grid item key={idx} xs={gridSize}>
                            <TopDisplayCard key={idx + key} symbol={ c } {...commonDisplayCardProps} />
                        </Grid>
                    ))
                )}
            </Grid>
            <CulledCollapse in={ expanded }>
                <Grid container spacing={ 2 } style={{ marginTop: 10 }}>
                    {
                        tail.map((c, idx) => (
                            <Grid item key={idx} xs={gridSize}>
                                <TopDisplayCard key={idx + key} symbol={ c } { ...commonDisplayCardProps } />
                            </Grid>
                        ))
                    }
                </Grid>
            </CulledCollapse>
            { tail?.length ? <ExpandButton expanded={ expanded } expandedLabel={ T(s => s.misc.showFewer) } symbols={ tail } onClick={ () => props.onExpandToggle && props.onExpandToggle() } /> : null }
        </Flex>
    );

});