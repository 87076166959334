// @ts-strict-ignore
import CancelIcon from '@mui/icons-material/Cancel';
import { Collapse, IconButton, Typography } from '@mui/material';
import { ExpandButton } from 'components';
import { Card } from 'components/Card';
import { Flex } from 'components/Flex';
import { AnimatedModal } from 'components/Modals/AnimatedModal';
import { ShadedTextDisplay } from 'components/ShadedTextDisplay/ShadedTextDisplay';
import { SnexButton } from 'components/SnexButton';
import { useColors } from 'hooks/UseColors';
import { OwnershipIcon } from 'phoenix/assets/images/fullyPaidLending/icons/OwnershipIcon';
import { PieChartIcon } from 'phoenix/assets/images/fullyPaidLending/icons/PieChartIcon';
import { ProfitsIcon } from 'phoenix/assets/images/fullyPaidLending/icons/ProfitsIcon';
import { T } from 'phoenix/assets/lang/T';
import { useFullyPaidLending } from 'phoenix/hooks/UseFullyPaidLending';
import { useText } from 'phoenix/hooks/UseText';
import { useBuyingPowerStore } from 'phoenix/stores/BuyingPowerStore';
import React, { useEffect, useState } from 'react';
import { Routes } from 'util/Routes';
import { FullyPaidLendingIcon } from './FullyPaidLendingIcon';
import './index.css';

const fullyPaidLendingTitle = 'Fully Paid Lending';
export const FullyPaidLendingModal = ({ showModal, onClose }: { showModal?: boolean; onClose: () => void }): JSX.Element => {
    const { grayText } = useColors();
    const { fullyPaidLendingFeatureFlag, isEquitiesAndHasMinimumBuyingPower } = useFullyPaidLending();

    const applyNow = useText((t) => t.fplBanner.applyNow);
    const depositFunds = useText((t) => t.portfolioScreen.depositFunds);
    const eligibilityText = useText((t) => t.fplModal.eligibilityText);
    const aboutText = useText((t) => t.fplModal.aboutText);
    const revenueStreamText = useText((t) => t.fplModal.revenueStreamText);
    const maintainOwnershipText = useText((t) => t.fplModal.maintainOwnershipText);
    const loansAreSecureText = useText((t) => t.fplModal.loansAreSecureText);

    const { load: loadBuyingPower } = useBuyingPowerStore();
    useEffect(() => {
        loadBuyingPower();
    }, []);

    const hasFPLFlagEnabledAndShowModal = fullyPaidLendingFeatureFlag && showModal;

    const { fplGreen } = useColors();
    const [showMore, setShowMore] = useState<boolean>(false);

    const handleDepositFunds = () => {
        window.location.href = Routes.funding();
    };

    return (
        <AnimatedModal open={hasFPLFlagEnabledAndShowModal} onClose={onClose}>
            <Card className='fpl-modal'>
                <Flex column fullWidth style={{ backgroundColor: fplGreen, borderRadius: '8px 8px 0 0', color: '#fff' }}>
                    <Flex row reverse fullWidth>
                        <IconButton title={'Close modal'} style={{ color: '#ffffff85', flex: 0 }} onClick={onClose}>
                            <CancelIcon />
                        </IconButton>
                    </Flex>
                    <Flex column fullWidth center justify='center' style={{ padding: '0 96px 32px', textAlign: 'center' }}>
                        <Typography variant='h2' style={{ fontSize: 28, marginBottom: 8, fontWeight: 600 }}>
                            {fullyPaidLendingTitle}
                        </Typography>
                        <Typography variant='body1' style={{ fontSize: 16, opacity: '.85' }}>
                            {aboutText}
                        </Typography>
                    </Flex>
                </Flex>
                <Flex row fullWidth style={{ padding: '32px', gap: 24 }}>
                    <Flex column center justify='center' style={{ width: '30%', flex: '1 1 auto' }}>
                        <FullyPaidLendingIcon height={300} width={300} style={{ flex: 0 }} />
                        <ExpandButton
                            contractedLabel={T((s) => s.misc.showMore)}
                            expanded={showMore}
                            expandedLabel={T((s) => s.misc.showLess)}
                            overhang={-32}
                            onClick={() => setShowMore(!showMore)}
                        />
                    </Flex>
                    <Flex column center align='center' style={{ height: '100%', gap: 32 }}>
                        <Flex row fullWidth align='center' style={{ gap: 32 }}>
                            <Flex column center align='center'>
                                <ProfitsIcon size={48} />
                            </Flex>
                            <Flex column center align='center' style={{ gap: 16 }}>
                                <ShadedTextDisplay segments={revenueStreamText} style={{ fontSize: 14, fontWeight: 400 }} />
                            </Flex>
                        </Flex>
                        <Flex row fullWidth align='center' style={{ gap: 32 }}>
                            <Flex column center align='center'>
                                <OwnershipIcon size={48} />
                            </Flex>
                            <Flex column center align='center' style={{ gap: 16 }}>
                                <ShadedTextDisplay segments={maintainOwnershipText} style={{ fontSize: 14, fontWeight: 400 }} />
                            </Flex>
                        </Flex>
                        <Flex row fullWidth align='center' style={{ gap: 32 }}>
                            <Flex column center align='center'>
                                <PieChartIcon size={48} />
                            </Flex>
                            <Flex column center align='center' style={{ gap: 16 }}>
                                <ShadedTextDisplay segments={loansAreSecureText} style={{ fontSize: 14, fontWeight: 400 }} />
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
                <HowItWorks isOpen={showMore} />
                <Flex column center fullWidth align='center' style={{ gap: 8, padding: '32px 32px 16px' }}>
                    {isEquitiesAndHasMinimumBuyingPower ? (
                        <SnexButton style={{ backgroundColor: `#13B53F`, color: '#fff', flex: 0, width: '350px', padding: 16 }} route={Routes.fplOnboarding()}>
                            {applyNow}
                        </SnexButton>
                    ) : (
                        <SnexButton style={{ backgroundColor: `#13B53F`, color: '#fff', flex: 0, width: '350px', padding: 16 }} onClick={handleDepositFunds}>
                            {depositFunds}
                        </SnexButton>
                    )}
                    <Typography style={{ fontSize: 14, color: grayText }}>{eligibilityText}</Typography>
                </Flex>
            </Card>
        </AnimatedModal>
    );
};

const HowItWorks = ({ isOpen }: { isOpen?: boolean }) => {
    const howItWorksTitle = useText((t) => t.fplModal.howItWorksTitle);
    const howItWorksText = useText((t) => t.fplModal.howItWorksText);
    const bulletPointsText = useText((t) => t.fplModal.bulletPointsText);
    return (
        <Collapse in={isOpen}>
            <Flex column fullWidth center align='center' style={{ padding: 32, fontSize: '16px', gap: 16 }}>
                <Typography style={{ fontSize: 16, fontWeight: 500 }}>{howItWorksTitle}</Typography>
                <Typography style={{ fontSize: 16, fontWeight: 500, opacity: '.5', textAlign: 'center' }}>{howItWorksText}</Typography>
                <ul style={{ padding: 0, marginTop: 16 }}>
                    {bulletPointsText.map((b, bIndex) => (
                        <li key={bIndex}>
                            <Typography style={{ fontSize: 14, fontWeight: 400, padding: '2px 0' }}>{b}</Typography>
                        </li>
                    ))}
                </ul>
            </Flex>
        </Collapse>
    );
};
