import { XS } from "phoenix/xstream/XS";
import { create } from "zustand";
import { useAccountValuation } from 'phoenix/hooks/UseAccountValuation';
import { ChartRange } from 'phoenix/constants';

export interface IAccountValues {
    value?: number;
    change?: number;
    changePercent?: number;
}

interface AccountValuesStore extends IAccountValues {
    valuesOverride: boolean;
}

const useAccountValuesStore = create<AccountValuesStore>((set) => ({
    value: undefined,
    change: undefined,
    changePercent: undefined,
    valuesOverride: false
}));

const getAccountValuesOverrideNonReactive = () => useAccountValuesStore.getState().valuesOverride;

const setAccountValuesOverride = (valuesOverride: boolean) => {
    const store = useAccountValuesStore;
    const prevState = store.getState();

    store.setState({
        ...prevState,
        valuesOverride
    });;
};

const getAccountValues = (accountNumber: string, range: ChartRange): IAccountValues => {
    const store = useAccountValuesStore(s => s);
    const valuation = useAccountValuation(accountNumber, range, { withoutMarketTimeRules: true });

    if (store.valuesOverride) {
        return {
            value: store.value,
            change: store.change,
            changePercent: store.changePercent,
        };
    }

    return {
        value: valuation.value,
        change: valuation.change,
        changePercent: valuation.percent100
    };
}

const setAccountValues = (values: IAccountValues) => {
    const store = useAccountValuesStore;
    const prevState = store.getState();
    store.setState({
        ...prevState,
        ...values
    });;
};

export const AccountValuesViewModel =
{
    getValues: getAccountValues,
    setValues: setAccountValues,
    getValuesOverride: getAccountValuesOverrideNonReactive,
    setValuesOverride: setAccountValuesOverride
};
