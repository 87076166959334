// @ts-strict-ignore
import { IconButton } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex } from '..';
import { T, Ts } from 'phoenix/assets/lang/T';
import { StorageKeys } from 'phoenix/constants';
import { LiveDataNamespaces } from 'phoenix/constants/LiveDataNamespaces';
import {
    GetTopGainers,
    GetTopLosers,
    GetTopMovers
} from 'phoenix/redux/actions/SecuritiesActions';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { useLocalStorage } from 'hooks/UseLocalStorage';
import { useText } from 'phoenix/hooks/UseText';
import { AnchoredMenu } from '../AnchoredMenu';
import { SectionHeader } from '../SectionHeader';
import { SecurityCardCollection } from '../SecurityCardCollection/SecurityCardCollection';
import { useAppWindowSize, WindowSizes } from 'hooks/UseWindowSize';
import { useTelemetry } from 'hooks/UseTelemetry';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import { GetFrontMonthTopMovers } from 'phoenix/redux/actions'
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { ApiData } from 'phoenix/models';
import { SecurityQuote } from 'phoenix/redux/models';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';


export const TopDisplay = React.memo((props: { accountNumber?: string }) => {

    const dispatch = useDispatch();
    const LogEvent = useTelemetry();
    const [listType, setListType] = useLocalStorage(StorageKeys.TopMoversList, 'movers');
    const [key, setKey] = useState(1);
    const [appWindowSize, setAppWindowSize] = useAppWindowSize();
    const assetClass = useMemo(() => QualifiedId.Class(props?.accountNumber), [props]);
    const allAccounts = useSelector((s: GlobalState) => s.accounts.all);
    const allFutures = allAccounts?.data?.every(a => QualifiedId.Class(a.accountNumber) === 'futures');
    const showFutures = (props.accountNumber && assetClass === 'futures') || allFutures;
    const [collapsed, setCollapsed] = useLocalStorage(StorageKeys.TOP_MOVERS_COLLAPSED, true);
    const [initialized, setInitialized] = useState(false);
    const text = useText(s => s.portfolioScreen.topMovers);
    const frontMonthTopMovers = useSnexStore(s => s.securities.top.futures.movers);

    const rowCount = useMemo(() => {
        switch (appWindowSize) {
            case WindowSizes.large: return 4;
            case WindowSizes.tablet: return 2;
            default: return 3;
        }
    }, [appWindowSize]);

    const list = useSelector((s: GlobalState) => {
        switch (listType) {
            case 'gainers': return s.securities.top.equities.gainers;
            case 'losers': return s.securities.top.equities.losers;
            case 'movers':
            default: return s.securities.top.equities.movers;
        }
    });
    const symbols = useMemo(() => list.data?.map(l => l.symbol), [list]);

    const title = useMemo(() => {
        switch (listType) {
            case 'gainers': return text.topGainers;
            case 'losers': return text.topLosers;
            case 'movers':
            default: return text.topMovers;
        }
    }, [listType]);

    useEffect(() => {
        if (!list?.loading || !!list?.error) setInitialized(true);
    }, [list]);

    useEffect(() => {
        if (showFutures) { dispatch(GetFrontMonthTopMovers()); }
        setKey(key + 1);
        switch (listType) {
            case 'gainers': dispatch(GetTopGainers()); return;
            // dispatch(StartPollingTopGainersAction()); return () => dispatch(StopPollingTopGainersAction());
            case 'losers': dispatch(GetTopLosers()); return;
            // dispatch(StartPollingTopLosersAction()); return () => dispatch(StopPollingTopLosersAction());
            case 'movers':
            default: dispatch(GetTopMovers()); 
            // dispatch(StartPollingTopMoversAction()); return () => dispatch(StopPollingTopMoversAction());
        }
    }, [listType]);

    const handleListTypeChange = useCallback((listType) => {
        LogEvent('Top section dropdown click', { listType })
        setListType(listType)
    }, [listType, key])

    return showFutures ?
        (
            <>
                <SectionHeader label={text.futuresTopMovers} />
                <SecurityCardCollection
                    expanded={!collapsed}
                    loading={frontMonthTopMovers?.loading}
                    symbols={frontMonthTopMovers?.data.map(m => m.symbol)}
                    onExpandToggle={() => setCollapsed(!collapsed)}
                />
            </>
        )
        : (

            <WithTitle title={title} onListTypeChange={handleListTypeChange}>
                <SecurityCardCollection
                    accountNumber={props.accountNumber}
                    expanded={!collapsed}
                    loading={!initialized || list.loading || list.pristine}
                    loadingAmount={!collapsed ? 10 : rowCount}
                    namespace={LiveDataNamespaces.TopSecurities}
                    noDataText={T(s => s.portfolioScreen.topMovers.unavailable)}
                    symbols={symbols}
                    title={title}
                    onExpandToggle={() => setCollapsed(!collapsed)}
                />
            </WithTitle>

        );

});

export const FuturesFrontMonth = React.memo(() => {
    const dispatch = useDispatch();
    const [collapsed, setCollapsed] = useLocalStorage(StorageKeys.TOP_MOVERS_COLLAPSED, true);
    const text = useText(s => s.portfolioScreen.topMovers);
    const frontMonthTopMovers = useSnexStore(s => s.securities.top.futures.movers);

    useEffect(() => {
    dispatch(GetFrontMonthTopMovers());
    }, [])

    return (
        <>
            <SectionHeader label={text.futuresTopMovers} />
            <ErrorBoundary>
            <SecurityCardCollection
                expanded={!collapsed}
                loading={frontMonthTopMovers?.loading}
                symbols={frontMonthTopMovers?.data.map(m => m.symbol)}
                onExpandToggle={() => setCollapsed(!collapsed)}
            />
            </ErrorBoundary>
        </>
    );
});

// Have to do this because MUI Menu is trash!!!! :D
export const WithTitle = React.memo((p: { children: any, title: string, onListTypeChange?: (type: string) => any }) => {
    const [listTypeAnchorEl, setListTypeAnchorEl] = useState(null);
    const text = useText(s => s.portfolioScreen.topMovers);

    return (
        <Flex column style={{ margin: '20px 0' }}>
            <Flex row align='center'>
                <SectionHeader infoIconMessagePath={s => s.topMoversAndSectors} label={p.title} />
                <IconButton onClick={(e) => setListTypeAnchorEl(e.target)} size="large">
                    <MoreHoriz />
                </IconButton>
            </Flex>
            <AnchoredMenu
                anchorEl={listTypeAnchorEl}
                handleClose={() => setListTypeAnchorEl(null)}
                items={[
                    {
                        label: text.topMovers, onClick: () => {
                            p.onListTypeChange('movers');
                        }
                    },
                    {
                        label: text.topGainers, onClick: () => {
                            p.onListTypeChange('gainers');
                        }
                    },
                    {
                        label: text.topLosers, onClick: () => {
                            p.onListTypeChange('losers');
                        }
                    }
                ]} />
            {p.children}
        </Flex>
    );
});
