import { Skeleton } from '@mui/material';
import React from 'react';
import { Flex } from '..';

export const PositionsListSkeletons = React.memo(() => (
    <div style={{ width: '100%', height: '78px', boxSizing: 'border-box', padding: '5px 10px' }}>
        <Flex style={{ width: '100%' }}>
            <Skeleton
                animation='wave'
                style={{ height: 20, width: 20, marginRight: 5 }}
                variant="circular" />
            <Flex
                column
                style={{ flex: 1 }}>
                <Skeleton animation='wave' />
                <Skeleton animation='wave' />
                <Skeleton
                    animation='wave'
                    height={20}
                    style={{ marginTop: 5 }} />
            </Flex>
        </Flex>
    </div>
));