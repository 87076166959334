import { MainSearchBoxProps } from 'components/MainSearchBox/MainSearchBox';
import { TelemetryCategories } from 'constants/Telemetry/TelemetryCategories';
import { useAppTheme } from 'hooks/UseAppTheme';
import { useAppWindowSize, WindowSize, WindowSizes } from 'hooks/UseWindowSize';
import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { useText } from 'phoenix/hooks/UseText';
import { GetVariant, VariantFlags } from 'phoenix/util/Variant';
import { TelemetryProvider } from 'providers/TelemetryContext';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Routes } from 'util/Routes';
import { Flex, MainSearchBox } from '../';
import { MarketHoursBadge } from '../MarketHoursBadge';
import { SnexBadge } from '../SnexBadge';
import { HeaderNavLinks } from './HeaderNavLinks';
import { HeaderUserAvatar } from './HeaderUserAvatar';
import { ResolveEnvironment } from 'phoenix/constants';
import { WhitelabelCard } from 'components/WhitelabelCard/WhitelabelCard';
import { IsWhitelabel } from 'phoenix/constants/Whitelabels';
import Logo from '../Logo';
import './Header.scss';

export const Header = TelemetryProvider(({nonFixed}: {nonFixed?: boolean}) => {
    const [appWindowSize] = useAppWindowSize();
    const [appTheme] = useAppTheme();
    const env = ResolveEnvironment();
    const text = useText((t) => t.misc);
    const variantFlags = GetVariant();

    return (
        <HeaderPresentation
            {...{
                appTheme,
                appWindowSize,
                env,
                HeaderNavLinks,
                HeaderUserAvatar,
                MainSearchBox,
                text,
                variantFlags,
                nonFixed
            }}
        />
    );
}, TelemetryCategories.header);

export type HeaderPresentationProps = {
    appWindowSize: WindowSize;
    HeaderNavLinks: React.FunctionComponent;
    HeaderUserAvatar: React.FunctionComponent;
    MainSearchBox: React.FunctionComponent<MainSearchBoxProps>;
    text: Snex1LanguagePack['misc'];
    variantFlags: VariantFlags;
    nonFixed?: boolean
};

export const HeaderPresentation = ({
    appWindowSize,
    HeaderNavLinks,
    HeaderUserAvatar,
    MainSearchBox,
    text,
    variantFlags,
    nonFixed
}: HeaderPresentationProps) : JSX.Element => {
    const { showVulcanButton } = variantFlags;
    const showWhitelabel = IsWhitelabel() === true;
    const { flexDirection, contentMargin, reverse, fullWidth } = useMemo(() => {
        switch (appWindowSize) {
            case WindowSizes.small:
            case WindowSizes.tablet:
                return { contentMargin: 0, flexDirection: 'column', reverse: true, fullWidth: true };
            case WindowSizes.regular:
                return { contentMargin: 10, flexDirection: 'row', reverse: false, fullWidth: true };
            case WindowSizes.large:
            default:
                return { contentMargin: 10, flexDirection: 'row', reverse: false };
        }
    }, [appWindowSize]);

    return (
        <>
            <Flex align='flex-start' className={`stonex-header ${nonFixed? 'non-fixed' : ''}`} column={flexDirection === 'column'} style={{ width: '100%', boxSizing: 'border-box' }}>
                <Flex align='center' justify='space-between' style={{ marginTop: 10, width: flexDirection === 'column' ? '100%' : 'auto' }}>
                    <Link to={Routes.portfolio()}>
                        <div
                            style={{ cursor: 'pointer', flexShrink: 0, width: '160px' }}
                            tabIndex={0}
                        >
                            <Logo />
                        </div>
                    </Link>

                    {showVulcanButton && (
                        <SnexBadge
                            flavor='blue'
                            onClick={() => {
                                window.location.href = 'https://client.stonex.com';
                            }}
                        >
                            {text.backToTorch}
                        </SnexBadge>
                    )}
                    {showWhitelabel && (
                        <Flex row align='center' justify='center' style={{ padding: '0 20px', width: '250px' }}>
                            <WhitelabelCard />
                        </Flex>
                    )}
                    {flexDirection === 'column' && (
                        <Flex row align='center' justify='flex-end' style={{ flex: 1 }}>
                            <HeaderNavLinks />
                            <HeaderUserAvatar />
                        </Flex>
                    )}
                </Flex>
                {flexDirection === 'column' && (
                    <Flex row style={{ width: '100%' }}>
                        <MainSearchBox resultRoutes={true} fullWidth={fullWidth} showHints showGoldenTicket autoFocus />
                    </Flex>
                )}
                {flexDirection !== 'column' && (
                    <Flex row reverse={reverse} style={{ flex: 1, marginLeft: contentMargin }}>
                        <Flex row justify='center' style={{ flex: 1, paddingTop: 5, boxSizing: 'border-box', overflow: 'hidden' }}>
                            <MainSearchBox resultRoutes={true} fullWidth={fullWidth} showHints showGoldenTicket autoFocus/>
                        </Flex>
                        <Flex row align='flex-start' justify='flex-end' style={{ flex: 1, height: 'fit-content' }}>
                            <Flex style={{ paddingTop: 15 }}>
                                <HeaderNavLinks />
                            </Flex>
                            <HeaderUserAvatar />
                        </Flex>
                    </Flex>
                )}
            </Flex>
        </>
    );
};
