// @ts-strict-ignore
import { Typography } from '@mui/material';
import { useSelectedAccountForOrderRequest } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { TradeTicketAccountDropdown } from 'components/AccountDropdown/TradeTicketAccountDropdown';
import { QuantityInputSplit } from 'components/InputSplit/QuantityInputSplit';
import { SnexButton } from 'components/SnexButton';
import useOptionsOpenClose from 'hooks/useOptionsOpenClose';
import { T } from 'phoenix/assets/lang/T';
import { Urls } from 'phoenix/constants';
import { AccountPermittedToOptionsLevel, OptionsLevel } from 'phoenix/constants/OptionsLevels';
import { TradeActions } from 'phoenix/constants/Trade';
import useOptionsOrderPermitted from 'phoenix/hooks/useOptionsOrderPermitted';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { EquitiesAssetClass } from 'phoenix/models/AssetClasses/EquitiesAssetClass';
import { FuturesAssetClass } from 'phoenix/models/AssetClasses/FuturesAssetClass';
import { getAccountAssetClass, useAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import { GetFuturesContractsAction, GetSecurityMetadataAction } from 'phoenix/redux/actions';
import { OptionSymbol } from 'phoenix/redux/models';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { floatMath, FormatNumber, SafeFormat, toMoneyOpt2 } from 'phoenix/util';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Flex } from '../..';
import { TradeCancelHeader } from '../Headers/TradeCancelHeader';
import { TradeOptionHeader } from '../Headers/TradeOptionHeader';
import { GetTradeTicketQuote } from '../Shared/helpers';
import { ImmediateExecutionAdvisory } from '../Shared/ImmediateExecutionAdvisory';
import {
    AlgoControl,
    BigTradeButton,
    BuyingPowerDisplayV2,
    OptionContractSummaryRow,
    OptionTotalCostDisplay,
    OptionUnderlyingHoldingsDisplay,
    PriceInputSplit,
    TimeInForceSplit
} from '../Shared/TradeFormComponents';
import { TradeInputValidationButton } from '../Shared/TradeInputValidationButton';
import { TradeTicketSection } from '../Shared/TradeTicketSection';
import { OptionsTradeTicketViewModel } from '../Store/TradeTicketViewModel';
import { useTradeTicketViewModel } from '../Store/useTradeTicketViewModel';

type TradeTicketVariant = 'option-individual' | 'normal';

interface OptionIndivTradeInputPageProps {
    variant?: TradeTicketVariant;
    color?: string;
}

const priceInputFields = ['limitPrice', 'stopPrice'];

export const OptionIndivTradeInputPage = React.memo((props: OptionIndivTradeInputPageProps) => {
    const dispatch = useDispatch();
    const viewModel = useTradeTicketViewModel<OptionsTradeTicketViewModel>();
    const {
        awaitingConfirmation,
        initialLimitPrice,
        initialQuantity,
        initialStopPrice,
        limitPrice,
        modifyingOrder,
        orderType,
        quantity,
        setViewModel,
        stopPrice,
        symbol = '',
        timeInForce,
        tradeAction = 'Buy',
        variant
    } = viewModel;
    // Use quantity input key to force a remount of the quantity input component
    // such as when using "Sell All" button to override user input
    const [quantityInputKey, setQuantityInputKey] = useState<number>(0);

    useEffect(() => {
        if (!variant) {
            setViewModel({ variant: props.variant });
        }
    }, [variant, props.variant, setViewModel]);

    const logo = useSnexStore((s) => s.logos.bySymbol[symbol]);
    const quote = GetTradeTicketQuote(symbol);
    const text = useText((t) => t);
    const ac = useAssetClass(symbol);
    const selectedAccountNumber = useSelectedAccountForOrderRequest(ac);
    const canTradeOptions = useMemo(() => AccountPermittedToOptionsLevel(selectedAccountNumber, OptionsLevel.CoveredCalls), [selectedAccountNumber]);
    const optSym = new OptionSymbol(symbol);
    const osiSym = optSym.toOsiSymbol();
    const meta = useSnexStore((s) => s.securities.bySymbol[symbol]?.metadata?.data);
    const { formatPrice, getPriceFormatInfo } = ac;
    const formatOptions = getPriceFormatInfo(meta);
    const { decimalPlaces, moneyFormatOptions, tickSize } = formatOptions;
    // Futures only - for enforcing tick size
    const [tickSizeError, setTickSizeError] = useState<string[]>([]);
    const isFuture = FuturesSymbol.IsFuturesSymbol(symbol);
    const fsym = useMemo(() => new FuturesSymbol(optSym.underlyingSymbol), [optSym.underlyingSymbol]);
    const base = useMemo(() => fsym.baseContract, [fsym.baseContract]);
    const userInfo = useSnexStore((s) => s.user?.myInfo.data);
    const { openClose } = useOptionsOpenClose();
    const action = text.tradeTicket.input.options.actionToOpenClose({ action: tradeAction }, openClose);
    const putCall = optSym.putCall ? T((t) => t.general.options.putCallWord(optSym.putCall)) : '';
    const showDollarSign = isFuture ? false : undefined;

    // When false, app will prompt users to upgrade options level
    const permittedForEquitiesNonadjusted = useOptionsOrderPermitted({
        accountNumber: selectedAccountNumber,
        action: tradeAction,
        quantity: quantity || initialQuantity || 0,
        symbol
    });
    // Adjusted options have separate rules regardless of options levels
    // So set this to true and let validation take care of it
    const permittedForEquities = meta?.isAdjusted ? true : permittedForEquitiesNonadjusted;

    const { assetFamily, getErrorProps, hasAdvancedRouting, headerTitle, permitted, sharesLabel, showEnableOptions, useOptionsBuyingPower } = isFuture
        ? {
              assetFamily: FuturesAssetClass.family,
              getErrorProps: (field: 'stopPrice' | 'limitPrice'): { error?: boolean; helperText?: string } =>
                  tickSizeError.includes(field)
                      ? {
                            error: true,
                            helperText: meta?.isFractional ? text.tradeTicket.input.futures.enforceFractional : text.tradeTicket.input.futures.enforceTickSize(tickSize)
                        }
                      : {},
              hasAdvancedRouting: false,
              headerTitle: fsym.noPrefix,
              permitted: true,
              sharesLabel: null,
              showEnableOptions: false,
              useOptionsBuyingPower: false
          }
        : {
              assetFamily: EquitiesAssetClass.family,
              getErrorProps: (): { error?: boolean; helperText?: string } => undefined,
              hasAdvancedRouting: userInfo?.hasAdvancedRouting,
              headerTitle: optSym.underlyingSymbol,
              permitted: permittedForEquities,
              sharesLabel: typeof meta?.deliverableCount === 'number' && text.tradeTicket.input.options.sharesPerContract(meta?.deliverableCount),
              showEnableOptions: selectedAccountNumber && !canTradeOptions,
              useOptionsBuyingPower: true
          };

    useEffect(() => {
        if (isFuture) {
            dispatch(GetFuturesContractsAction(symbol));
            dispatch(GetSecurityMetadataAction(base));
        }
    }, [base, dispatch, isFuture, symbol]);

    const handleFieldUpdate = (name: keyof OptionsTradeTicketViewModel, value: OptionsTradeTicketViewModel[keyof OptionsTradeTicketViewModel]) => {
        // Futures only: If it's a price, and the value is divisible by the tick size, it's valid
        if (isFuture && priceInputFields.includes(name)) {
            const isInputValid = !isNaN(value as number) && !floatMath(value as number, tickSize, (v, s) => v % s);
            const newTickSizeError = isInputValid ? [...tickSizeError.filter((x) => x !== name)] : [...tickSizeError, name];
            setTickSizeError(newTickSizeError);
        }

        setViewModel({ [name]: value });
    };

    const allAccounts = useSnexStore((s) => s.accounts.all);
    const selectedAccount = useMemo(() => allAccounts?.data?.find((a) => a.accountNumber === selectedAccountNumber), [allAccounts, selectedAccountNumber]);
    const hasNoEquitiesAccount = !allAccounts?.data.some((a) => getAccountAssetClass(a.accountNumber) === EquitiesAssetClass);
    const futuresAccounts = useMemo(() => allAccounts?.data?.filter((a) => getAccountAssetClass(a.accountNumber) === FuturesAssetClass), [allAccounts]);
    const hasNoFuturesAccounts = !futuresAccounts?.length;
    const isSelectedAccountPending = selectedAccount?.accountStatusDesc?.toLowerCase().trim() === 'pending';

    const formatter = (number: number) => FormatNumber.toCommas(number, true, 0);

    const handleAccountSelect = useCallback(() => setViewModel({ awaitingConfirmation: false }), [setViewModel]);

    const resolveValidationButton = () => {
        if (isFuture) {
            if (isSelectedAccountPending) {
                return (
                    <SnexButton flavor={'not-allowed'} onClick={() => undefined}>
                        {T((s) => s.tradeTicket.misc.applicationPending)}
                    </SnexButton>
                );
            }

            if (hasNoFuturesAccounts) {
                return (
                    <SnexButton eventTag='Open Futures Account' flavor={'submit'} onClick={() => (location.href = Urls.futuresOnboarding.addAccount())}>
                        {T((s) => s.futuresOnboarding.openAccount)}
                    </SnexButton>
                );
            }
        } else {
            if (hasNoEquitiesAccount) {
                return (
                    <SnexButton
                        eventTag='Open Equities Account'
                        flavor={'submit'}
                        onClick={() => (location.href = `${Urls.authentication.vulcanServer()}/l/signup/onboarding`)}
                    >
                        {'Open an Stock Trading Account'}
                    </SnexButton>
                );
            }
        }

        return <TradeInputValidationButton disabled={tickSizeError.length > 0 || undefined} />;
    };

    return (
        <Flex column>
            {awaitingConfirmation ? (
                <TradeCancelHeader
                    logo={logo}
                    text={text.tradeTicket.input}
                    showLogo={modifyingOrder}
                    symbol={symbol}
                    title={headerTitle}
                    onCancel={() => setViewModel({ awaitingConfirmation: false, validateResponse: null })}
                />
            ) : (
                <TradeOptionHeader />
            )}
            <TradeTicketSection noBorder style={{ paddingTop: 20, paddingBottom: 10 }}>
                <TradeTicketAccountDropdown
                    assetFamily={assetFamily}
                    balanceType={tradeAction}
                    defaultToFirst={'if-only-one'}
                    disabled={modifyingOrder}
                    onSelect={handleAccountSelect}
                    skipInitialSelect={modifyingOrder || !!selectedAccountNumber}
                    style={{ marginBottom: 15 }}
                    symbol={symbol}
                    useOptionsBuyingPower={useOptionsBuyingPower}
                    isByAssetClass
                />
                {['stop', 'stoplimit'].includes(orderType) && (
                    <PriceInputSplit
                        arrows={isFuture}
                        formatOptions={moneyFormatOptions}
                        formatter={(v: number) => formatPrice(v, meta)}
                        initialValue={initialStopPrice}
                        label={text.tradeTicket.input.stopPrice}
                        onValueChange={(v) => handleFieldUpdate('stopPrice', v)}
                        step={tickSize}
                        showDollarSign={showDollarSign}
                        {...getErrorProps('stopPrice')}
                    />
                )}
                {['limit', 'stoplimit'].includes(orderType) && (
                    <PriceInputSplit
                        arrows={isFuture}
                        formatOptions={moneyFormatOptions}
                        formatter={(v: number) => formatPrice(v, meta)}
                        initialValue={initialLimitPrice}
                        label={orderType === 'limit' ? text.tradeTicket.input.limitPrice : text.tradeTicket.input.stopLimitPrice}
                        onValueChange={(v) => handleFieldUpdate('limitPrice', v)}
                        step={tickSize}
                        showDollarSign={showDollarSign}
                        {...getErrorProps('limitPrice')}
                    />
                )}
                {!isFuture && (
                    <OptionContractSummaryRow
                        action={action}
                        ask={formatPrice(quote?.ask, meta)}
                        bid={formatPrice(quote?.bid, meta)}
                        expDate={SafeFormat(optSym.expDate, 'MM/dd/yyyy')}
                        last={formatPrice(quote?.last ? quote?.last : tradeAction === TradeActions.Buy ? quote?.ask : quote?.bid, meta)}
                        putCall={putCall}
                        onCancel={() => setViewModel({ symbol: null })}
                        showCloseButton={false}
                        strike={toMoneyOpt2(meta?.strikePrice, { decimalPlaces, hideCurrencySymbol: isFuture })}
                        symbol={fsym.noPrefix}
                    />
                )}
                <OptionUnderlyingHoldingsDisplay accountNumber={selectedAccountNumber} />
                <QuantityInputSplit
                    accountNumber={selectedAccountNumber}
                    arrows={true}
                    formatter={formatter}
                    initialValue={initialQuantity}
                    key={quantityInputKey}
                    label={T((t) => t.general.contracts(0))}
                    liquidateOnSellAll={false}
                    onBuySellAll={() => setQuantityInputKey(quantityInputKey + 1)}
                    onValueChange={(v) => handleFieldUpdate('quantity', v)}
                    showPosition={true}
                    showSellAll={true}
                    step={1} // Used for shares only, 1 share per tick
                    sublabel={sharesLabel}
                    symbol={symbol}
                    tradeAction={tradeAction}
                />
                {orderType !== 'market' ? (
                    <TimeInForceSplit text={text.tradeTicket.input} value={timeInForce} onValueChange={(v) => handleFieldUpdate('timeInForce', v)} />
                ) : null}
            </TradeTicketSection>
            <ImmediateExecutionAdvisory
                action={tradeAction}
                limitPrice={limitPrice}
                orderType={orderType}
                stopPrice={stopPrice}
                quote={quote}
                text={text.tradeTicket.input.advisories}
            />
            <TradeTicketSection style={{ paddingBottom: 20 }}>
                {hasAdvancedRouting && <AlgoControl />}
                <OptionTotalCostDisplay />
                {meta?.isAdjusted && <Typography variant='body1'>{text.tradeTicket.adjustedOptionsDisclaimer}</Typography>}
                {
                    [
                        {
                            rule: selectedAccountNumber && showEnableOptions,
                            value: (
                                <BigTradeButton fillColor={props.color} onClick={() => window.open(Urls.registration.enableOptions(selectedAccountNumber))}>
                                    {text.tradeTicket.input.options.enableOptionsTrading}
                                </BigTradeButton>
                            )
                        },
                        {
                            rule: selectedAccountNumber && quantity && !permitted,
                            value: (
                                <BigTradeButton fillColor={props.color} onClick={() => window.open(Urls.registration.enableOptions(selectedAccountNumber))}>
                                    {text.tradeTicket.input.options.upgradeOptionsLevel}
                                </BigTradeButton>
                            )
                        },
                        {
                            rule: true,
                            value: resolveValidationButton()
                        }
                    ].find((x) => x.rule)?.value
                }
            </TradeTicketSection>
            <TradeTicketSection noBorder>
                {/* set action to buy to force buying power to show always on equity #108615 */}
                <BuyingPowerDisplayV2 accountNumber={selectedAccountNumber} symbol={symbol} useOptionsBuyingPower={useOptionsBuyingPower} />
            </TradeTicketSection>
        </Flex>
    );
});
