import { AddAlertRounded, Loop, MoreVert } from '@mui/icons-material';
import { IconButton, Switch, Typography } from '@mui/material';
import { useColors } from 'hooks/UseColors';
import { FormatAlertCondition } from 'phoenix/constants/Alerts';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { DeleteAlertRuleAction, GetAlertRulesAction, PauseAlertRuleAction, ResumeAlertRuleAction } from 'phoenix/redux/actions/AlertsActions';
import { AlertRule } from 'phoenix/redux/models';
import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UseSwitchStyles } from 'theming';
import { AlertsPage, AnchoredMenu, Flex } from '../..';
import { SymbolLabel } from '../../SecurityCell/Shared/Labels';
import { SecurityCellLogo } from '../../SecurityCell/Shared/SecurityCellLogo';
import { SnexButton } from '../../SnexButton/SnexButton';
import { ModalViewHeader } from '../Shared';
import { PaddedSection } from '../Shared/PaddedSection';
import { IAlertViewProps } from './Shared/IAlertViewProps';
import { useAppWindowSize, WindowSizes } from 'hooks/UseWindowSize';

type AlertsListViewProps = IAlertViewProps;

const NonListViewWrapper = React.memo(({ children }: { children: any }) => (
    <Flex center column style={{ width: '100%', flex: 1 }}>
        {children}
    </Flex>
));

export const AlertsListView = React.memo((props: AlertsListViewProps) => {
    const dispatch = useDispatch();
    const alerts = useSnexStore((s) => s.alerts.rules);
    const text = useText((t) => t);

    useEffect(() => {
        // Get user's alerts via action
        dispatch(GetAlertRulesAction());
    }, []);

    const List = useCallback(() => {
        // TODO Make this an actual loading indicator
        if (alerts.loading || alerts.pristine)
            return (
                <NonListViewWrapper>
                    <Typography>{text.general.loading}</Typography>
                </NonListViewWrapper>
            );

        // TODO Make this a 'No Alerts Yet!' view
        if (!alerts.data?.length)
            return (
                <NonListViewWrapper>
                    <Typography style={{ textAlign: 'center', color: 'grey', marginBottom: 15 }} variant='h4'>
                        {text.alerts.youDontHaveAlerts}
                    </Typography>
                    <Typography style={{ paddingTop: 10 }} variant='body2'>
                        {text.alerts.createAnAlertToSeeItHere}
                    </Typography>
                </NonListViewWrapper>
            );

        return (
            <Flex column style={{ width: '100%', boxSizing: 'border-box', padding: '20px 0px', flex: 1, overflow: 'auto' }}>
                {alerts.data.map((a, key) => (
                    <AlertCell alert={a} index={key} key={key} navigate={props.navigate} />
                ))}
            </Flex>
        );
    }, [alerts]);

    return (
        <Flex column style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
            <ModalViewHeader title='Alerts' />
            <List />
            <PaddedSection>
                <SnexButton flavor='submit' style={{ margin: '20px 0px' }} onClick={() => props.navigate('create')}>
                    <span style={{ marginRight: 10 }}>{text.alerts.createAlert}</span>
                    <AddAlertRounded style={{ fontSize: 20 }} />
                </SnexButton>
            </PaddedSection>
        </Flex>
    );
});

export const AlertCell = (props: { alert: AlertRule; index: number; navigate: (view: AlertsPage, alert?: Partial<AlertRule>) => void }) => {
    const text = useText((t) => t);
    const dispatch = useDispatch();
    const colors = useColors();
    const [checked, setChecked] = useState(!props.alert.paused);
    const [focused, setFocused] = useState(false);
    const [hovered, setHovered] = useState(false);
    const toggleAlertOp = useSnexStore((s) => s.alerts.toggleRuleOperation);
    const deleteAlertOp = useSnexStore((s) => s.alerts.deleteRuleOperation);
    const [appWindowSize] = useAppWindowSize();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const onEdit = () => {
        props.navigate('edit', { ...props.alert });
    };

    const onDelete = () => {
        dispatch(DeleteAlertRuleAction(props.alert.id));
    };

    const alertActions = [
        { label: 'Edit', onClick: onEdit },
        { label: 'Delete', onClick: onDelete }
    ];

    const stylesByWindowSize = useMemo(() => {
        switch (appWindowSize) {
            case WindowSizes.tablet:
                return { dateSectionColumn: true, actionSectonWidth: 30, alwaysShowAction: true, actionSummaryFlex: 1.3, hideMarketPrice: true };
            default:
                return { dateSectionColumn: false, actionSectonWidth: 78, alwaysShowAction: false, actionSummaryFlex: 1, hideMarketPrice: false };
        }
    }, [appWindowSize]);

    const handleMenuOpen = (event: SyntheticEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const toggleAlert = useCallback(
        (toggled) => {
            if (toggled) dispatch(ResumeAlertRuleAction(props.alert.id));
            else dispatch(PauseAlertRuleAction(props.alert.id));
        },
        [dispatch, props.alert]
    );

    const isDisabled = useMemo(() => toggleAlertOp.loading || deleteAlertOp.loading, [toggleAlertOp]);

    const switchStyles = UseSwitchStyles(colors)();

    const ActionSection = useCallback(({ hover }: { hover: boolean }) => {
        return (
            <IconButton style={{ opacity: hover ? 0.4 : 0 }} onClick={handleMenuOpen}>
                <MoreVert />
            </IconButton>
        );
    }, []);

    return (
        <Flex
            style={{
                width: '100%',
                padding: '15px',
                borderBottom: 'solid 1px rgba(150,150,150,0.1)',
                opacity: isDisabled ? 0.5 : 1.0
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <Flex>
                <SecurityCellLogo symbol={props.alert.symbol} />
            </Flex>
            <Flex column align='flex-start' justify='center' style={{ flex: 1, boxSizing: 'border-box', padding: '0px 10px' }}>
                <Flex align='center'>
                    <SymbolLabel>{props.alert.name}</SymbolLabel>
                    {props.alert.repeatDaily && <Loop style={{ marginLeft: 8 }} />}
                </Flex>
                <Flex column style={{ width: '100%', marginTop: 5 }}>
                    {props.alert.conditions.map((c, key) => (
                        <Typography
                            key={key}
                            style={{
                                listStylePosition: 'inside',
                                listStyleType: 'disc',
                                display: 'list-item',
                                marginTop: 3,
                                width: '100%',
                                color: colors.grayText,
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                fontWeight: 500
                            }}
                            variant='body2'
                        >
                            {FormatAlertCondition.toLocalizedStringFromApi({ apiCondition: c, text })}
                        </Typography>
                    ))}
                </Flex>
            </Flex>
            <Flex center>
                <Switch
                    classes={switchStyles}
                    checked={checked}
                    disabled={isDisabled}
                    id={`${props.index}`}
                    onFocus={() => setFocused(true)}
                    onBlur={(e) => (e?.relatedTarget as Element)?.tagName !== 'BUTTON' && setFocused(false)}
                    size='medium'
                    onChange={(e) => {
                        setChecked(e.target.checked);
                        toggleAlert(e.target.checked);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            setChecked(!checked);
                            toggleAlert(!checked);
                        }
                    }}
                    {...((hovered || focused) && { style: { backgroundColor: colors.primaryItemBackdropColor } })}
                />
                <Flex center style={{ height: '100%', width: 50 }}>
                    {(focused || hovered) && !deleteAlertOp.loading && (
                        <Flex center row style={{ width: stylesByWindowSize.actionSectonWidth }}>
                            <ActionSection hover={stylesByWindowSize.alwaysShowAction ? true : hovered} />
                            {anchorEl && (
                                <AnchoredMenu
                                    anchorEl={anchorEl}
                                    handleClose={(e) => {
                                        e.stopPropagation();
                                        setAnchorEl(null);
                                    }}
                                    items={alertActions}
                                />
                            )}
                        </Flex>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};
