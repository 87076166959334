import { ApiData, ApiOperation } from '../../../models'
import { Watchlist } from './Watchlist'

export class WatchlistsState {
    all: ApiData<Watchlist[]>;
    create: ApiOperation;
    updatesById: { [key: string]: ApiOperation };
    deletesById: { [key: string]: ApiOperation };

    constructor () {
        this.all = new ApiData<Watchlist[]>([])
        this.create = new ApiOperation()
        this.updatesById = {}
        this.deletesById = {}
    }
}
