import { Typography } from '@mui/material';
import React from 'react';
import { Flex } from '..';

export const TourBadge = (props: { curr: number, preposition: string, tot: number }) => {

    return (
        <Flex align='center' justify='center'>
            <Typography style={{ color: 'white' }} variant='body2'>{props.curr}</Typography>
            <span style={{ color: 'rgba(255,255,255,0.7)', margin: '0 5' }}>{props.preposition}</span>
            <Typography style={{ color: 'white' }} variant='body2'>{props.tot}</Typography>
        </Flex>
    );
};