// @ts-strict-ignore
import React, { useEffect, useMemo } from 'react';
import style from './style.module.scss';
import { ListHeader, Flex } from '../';
import { useSelector } from 'react-redux';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { formattedSecurityTypeLabels, POSITION_DISPLAY, POSITION_SORT, SecurityTypes, SideBarExcludedPositionTypes, StorageKeys } from 'phoenix/constants';
import { GroupBySelect, UniqueBy } from 'phoenix/util';
import { ApiPosition } from 'phoenix/redux/models/Positions/ApiPosition';
import { QualifiedSecurityId } from 'phoenix/models/QualifiedSecurityId';
import { ExpandButton } from '../ExpandButton';
import { LazyLoadedSecurityCell } from '../SecurityCell/LazyLoadedSecurityCell';
import { CulledCollapse } from '../CulledCollapse/CulledCollapse';
import { useLocalStorage } from 'hooks/UseLocalStorage';
import { FromEnglish, T } from 'phoenix/assets/lang/T';
import { useText } from 'phoenix/hooks/UseText';
import { PositionsListSkeletons } from './PositionsListSkeletons';
import { usePositionsStore } from 'phoenix/stores/PositionsStore';

interface PositionsSubListProps {
    defaultCollapsed?: boolean;
    positions: ApiPosition[];
    positionType: string;
    hideAccountName?: boolean;
    streamingNamespace?: string;
}

interface PositionsListProps {
    accountNumber?: string;
    streamingNamespace?: string;
}

const PositionsSubList = React.memo((props: PositionsSubListProps) => {
    const { defaultCollapsed, positions, positionType } = props;
    const text = useText((s) => s.portfolioScreen.sidebar);

    const generateSubListMenuOptions = () => [
        {
            label: text.display,
            storageKey: POSITION_DISPLAY,
            options: [
                { label: text.price, value: 'price' },
                { label: text.value, value: 'value' }
            ]
        },
        {
            label: text.sortBy,
            storageKey: POSITION_SORT,
            options: [
                { label: text.price, value: 'price' },
                { label: text.value, value: 'value' }
            ],
            isSortable: true
        }
    ];

    const sortHandler = {
        movers: (dataToSort) => dataToSort.sort((a, b) => (+a.todaysGL || 0) - (+b.todaysGL || 0)),
        price: (dataToSort) => dataToSort.sort((a, b) => (a.marketPrice || a.lastPrice) - (b.marketPrice || b.lastPrice)),
        value: (dataToSort) => dataToSort.sort((a, b) => a.marketValue - b.marketValue),
        movers_desc: (dataToSort) => dataToSort.sort((a, b) => (+b.todaysGL || 0) - (+a.todaysGL || 0)),
        price_desc: (dataToSort) => dataToSort.sort((a, b) => (b.marketPrice || b.lastPrice) - (a.marketPrice || a.lastPrice)),
        value_desc: (dataToSort) => dataToSort.sort((a, b) => b.marketValue - a.marketValue)
    };

    const partialCutoff = 4;
    const [isCollapsedFull, setIsCollapsedFull] = useLocalStorage(`collapsed-${positionType}`, defaultCollapsed);
    const [isCollapsedPartial, setIsCollapsedPartial] = useLocalStorage(`collapsed-partial-${positionType}`, true);

    const [sortDir, setSortDir] = useLocalStorage('POSITION_SORT_DIR', '_desc');
    const [sortBy, setSortBy] = useLocalStorage(POSITION_SORT, 'price');

    const sortedRecords =
        sortBy && typeof sortHandler[`${sortBy}${sortDir}`] === 'function' ? sortHandler[`${sortBy}${sortDir}`](positions) : sortHandler.price(positions);

    const recordsHead = sortedRecords.slice(0, partialCutoff);
    const recordsTail = sortedRecords.slice(partialCutoff);
    const subListLabel = FromEnglish(
        (s) => s.portfolioScreen.sidebar,
        Object.entries(formattedSecurityTypeLabels).find((e) => e[0] === positionType)?.[1] || positionType
    );
    const extraSymbols = UniqueBy(positions?.map((p) => p.symbol).slice(partialCutoff), (x) => x);
    const overcount = Math.max(0, positions.length - partialCutoff);

    return (
        <div className={style.wrapper}>
            <ListHeader
                collapsable
                isCollapsed={isCollapsedFull}
                moreMenuItems={generateSubListMenuOptions()}
                title={subListLabel}
                onCollapseExpand={setIsCollapsedFull}
                horizontalPopover={'left'}
            />
            <CulledCollapse eventTag={`${subListLabel} Position List`} in={!isCollapsedFull}>
                <Flex column>
                    {/* NOTE: We do want to show positions with zero quantity, so do not filter them out here. Futures traders will be severely impacted otherwise. */}
                    {recordsHead.map((d, key) => (
                        <LazyLoadedSecurityCell
                            hideAccountName={props.hideAccountName}
                            key={`${d.symbol}-` + key + '-1'}
                            position={d}
                            streamingNamespace={props.streamingNamespace}
                            symbol={QualifiedSecurityId.FromPosition(d).toString()}
                            variant='position'
                        />
                    ))}
                    <CulledCollapse in={!isCollapsedPartial}>
                        {recordsTail.map((d, key) => (
                            <LazyLoadedSecurityCell
                                hideAccountName={props.hideAccountName}
                                key={`${d.symbol}-` + key + '-2'}
                                position={d}
                                symbol={QualifiedSecurityId.FromPosition(d).toString()}
                                streamingNamespace={props.streamingNamespace}
                                variant='position'
                            />
                        ))}
                    </CulledCollapse>
                    {extraSymbols.length ? (
                        <ExpandButton
                            expanded={!isCollapsedPartial}
                            overcount={overcount}
                            overhang={7}
                            symbols={extraSymbols}
                            onClick={() => setIsCollapsedPartial(!isCollapsedPartial)}
                        />
                    ) : null}
                </Flex>
            </CulledCollapse>
        </div>
    );
});

export const PositionsList = React.memo((props: PositionsListProps) => {
    const { load: loadPositions } = usePositionsStore();
    const positionsSummaries = usePositionsStore((s) => s.positions);
    const positionsPristine = usePositionsStore((s) => s.pristine);
    const [postionsLength, setPositionsLength] = useLocalStorage(StorageKeys.POSITIONS_PREV_LENGTH, 4);
    const [isCollapsedFull, setIsCollapsedFull] = useLocalStorage(`collapsed-${SecurityTypes.equities}`, false);
    const [isCollapsedPartial, setIsCollapsedPartial] = useLocalStorage(`collapsed-partial-${SecurityTypes.equities}`, true);
    const latestMessage = useSelector(
        (s: GlobalState) => s.messages.latest,
        (previousState, currentState) => currentState?.payloadType !== 'OrderUpdate:Filled'
    ); // only rerender for fills

    const positions = useMemo(() => {
        if (positionsSummaries?.length) {
            const rawPositionsList = positionsSummaries
                ?.filter((p: ApiPosition) => (props.accountNumber ? p.accountNumber === props.accountNumber : true))
                .filter((p: ApiPosition) => !SideBarExcludedPositionTypes.has(p.productType));

            return rawPositionsList;
        } else return [];
    }, [positionsSummaries, props.accountNumber]);

    useEffect(() => {
        setPositionsLength(positions?.length || 4);
    }, [positions?.length, setPositionsLength]);
    const allText = useText((s) => s);

    useEffect(() => {
        loadPositions();
    }, [latestMessage, loadPositions]);

    if (!positions.length && !positionsPristine) return null;

    if (!positions.length && positionsPristine) {
        return (
            <>
                <ListHeader loading title={allText.general.loading} />
                {!isCollapsedFull && Array.from({ length: isCollapsedPartial ? 4 : postionsLength }, (v, i) => i).map((num, idx) => <PositionsListSkeletons key={idx} />)}
                {postionsLength > 4 && !isCollapsedFull && (
                    <ExpandButton
                        loading
                        expanded={false}
                        expandedLabel={allText.misc.showFewer}
                        overcount={postionsLength - 4}
                        overhang={7}
                        symbols={null}
                        onClick={() => null}
                    />
                )}
            </>
        );
    }

    const positionTypeGroups = GroupBySelect(positions, (item) => item.productType);

    return (
        <>
            {Object.entries(positionTypeGroups)
                .sort((a, b) => (a[0] === SecurityTypes.equities ? -1 : 1))
                .map((group, key) => (
                    <PositionsSubList
                        // @ts-ignore
                        defaultCollapsed={group[0] !== SecurityTypes.equities}
                        hideAccountName={!!props.accountNumber}
                        key={key}
                        positions={group[1]}
                        positionType={group[0]}
                        streamingNamespace={props.streamingNamespace}
                    />
                ))}
        </>
    );
});
