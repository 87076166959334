export interface SeriesDataPoint {
    time: number;
    value?: number;
    open?: number;
    high?: number;
    low?: number;
    close?: number;
    volume?: number;
    [key: string]: any;
}

export const GetChartFrequency = (chartData: SeriesDataPoint[]) => {
    const getMinInterval = (min: number, current: number, index: number, array: number[]) => {
        if (index + 1 > array.length) {
            return min;
        }

        const next = array[index + 1];
        const interval = Math.round((next - current) / 60);

        const intervalIsValid = !isNaN(interval) && !!interval;
        return intervalIsValid && interval < min ? interval : min;
    };

    return chartData.map((spd) => spd.time).reduce(getMinInterval, 4320); // max to 3 days
};
