// @ts-strict-ignore
import { Button, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { AppearanceTypes, useToasts } from 'react-toast-notifications';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { GetSystemMessageDeliveryControlsAction } from 'phoenix/redux/actions/MessagesActions';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { SnexMessage } from 'phoenix/redux/models/Messages/SnexMessage';
import { GroupBySelectFirst } from 'phoenix/util';
import { MessageRow } from 'screens/MessagesScreen/MessageRow';
import { GoToLogin } from 'util/GoToLogin';
import { Flex } from '../Flex';
import { useColors } from 'hooks/UseColors';
import { SystemMessageDeliveryControls } from 'phoenix/redux/models/Messages/ClientMessageDeliveryControls';

export const ToastEmitter = () => {
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const text = useText((s) => s.toasts);

    // Error Handling
    const latestErrors = useSelector((s: GlobalState) => s.errors.apiErrors.latest);
    const ErrorToast = () => (
        <Flex column>
            <Typography variant='h6'>{text.error}</Typography>
            <Typography variant='body1'>
                {latestErrors?.errorMessage || '---'}
                {latestErrors?.errorId ? ` (#${latestErrors?.errorId})` : null}
            </Typography>
        </Flex>
    );

    const UnauthErrorToast = () => {
        const { primaryItemColor } = useColors();
        return (
            <Flex column>
                <Typography variant='h6'>{text.sessionExpired}</Typography>
                <Flex center column style={{ height: 70 }}>
                    <Button
                        style={{ backgroundColor: primaryItemColor, color: 'white', paddingLeft: 20, paddingRight: 20 }}
                        variant='contained'
                        onClick={() => GoToLogin()}
                    >
                        {text.logInAgain}
                    </Button>
                </Flex>
            </Flex>
        );
    };

    useEffect(() => {
        if (latestErrors?.display === 'toast') {
            if (latestErrors?.errorCode === 'UNAUTH_RELOGIN') addToast(<UnauthErrorToast />, { appearance: 'info', autoDismiss: false });
            else addToast(<ErrorToast />, { appearance: 'error' });
        }
    }, [latestErrors]);

    // Latest Messages
    const latestMessage = useSelector((s: GlobalState) => s.messages.latest);
    const messageControls: SystemMessageDeliveryControls[] = useSnexStore((s) => s.messages.systemControls.data);
    const messageControlLookup = useMemo(() => GroupBySelectFirst(messageControls, (c) => c.messageType), [messageControls]);

    const showToast = useCallback(
        (message: SnexMessage) => {
            if (!message) return false;
            const evs = messageControlLookup[message.payloadType]?.events;
            const toastMode = evs?.inAppToast?.mode;
            const effectMode = evs?.inAppEffect?.mode;
            console.log({ toastMode, effectMode, evs, message, me: 'show toast callback' });
            return (toastMode === 'enabled' || toastMode === 'is-online') && (effectMode === 'enabled' || effectMode === 'is-online');
        },
        [messageControls]
    );

    useEffect(() => {
        dispatch(GetSystemMessageDeliveryControlsAction());
    }, []);
    useEffect(() => {
        dispatch(GetSystemMessageDeliveryControlsAction());
    }, [latestMessage]);

    useEffect(() => {
        if (!showToast(latestMessage)) return;
        const appearance: AppearanceTypes = ((): AppearanceTypes => {
            switch (latestMessage.disposition) {
                case 'urgent':
                    return 'warning';
                case 'error':
                    return 'error';
                case 'normal':
                default:
                    return 'info';
            }
        })();

        const handleClick = () => {
            if (latestMessage.appLink) navigate(latestMessage.appLink);
        };

        addToast(<MessageRow dense toast message={latestMessage} onClick={handleClick} />, { appearance });
    }, [latestMessage]);

    return null;
};
