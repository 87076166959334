// @ts-strict-ignore
import { StorageKeys, Urls } from "phoenix/constants"
import { UnreadMessagesStats } from "phoenix/redux/models/Messages/UnreadMessagesStats"
import { GetPersistentStringAsync, SetPersistentStringAsync } from "phoenix/resolvers/PersistentStringStorage"
import { SnexAxios } from "phoenix/stores/AxiosHelpers"
import { create } from "zustand"

type MessagesStoreData = {

    statsLoading: boolean
    stats: UnreadMessagesStats | null

    //messagesLoading: boolean
    //messages: SnexMessage[]

}

type MessagesStoreMethods = {

    recordLastViewedTime: (dt?: Date) => Promise<void>
    clearUnreadStats: () => void
    loadUnreadStats: () => Promise<UnreadMessagesStats>
    incrementUnread: () => number
    clearAll: () => void

    //loadMessages: (page?: number) => Promise<SnexMessage[]>

}

export type MessagesStoreState = MessagesStoreData & MessagesStoreMethods

export const useMessagesStore = create<MessagesStoreState>((set, get) => ({

    statsLoading: false,
    stats: null,

    recordLastViewedTime: async (dt?: Date) => {
        const d = dt || new Date()
        console.log(`Setting last viewed time: ${d.toISOString()}`)
        await SetPersistentStringAsync(StorageKeys.MessagesLastViewedTime, d.toISOString())
    },

    clearUnreadStats: () => {
        console.log('Clearing unread stats')
        set({ stats: { unreadCount: 0 } })
    },

    loadUnreadStats: async () => {
        try {
            set({ statsLoading: true })
            let lastRead = new Date(await GetPersistentStringAsync(StorageKeys.MessagesLastViewedTime))
            if (isNaN(lastRead as any)) lastRead = new Date('2020-01-01')
            console.log(`Getting unread stats as of: ${lastRead.toISOString()}`)
            const result = await SnexAxios.ApiGet<UnreadMessagesStats>(Urls.messages.countStats(lastRead)).run();
            set({ stats: result })
            set({ statsLoading: false })
            return result
        } catch (e) {
            console.error(e)
            return null
        }
    },

    incrementUnread: () => {
        const existing = get().stats
        const newCount = (existing?.unreadCount || 0) + 1
        set({ stats: { ...existing, unreadCount: newCount } })
        return newCount;
    },

    clearAll: () => set({
        statsLoading: false,
        stats: null,
    })

}));

export const MessageStore_LoadStats = () => useMessagesStore.getState().loadUnreadStats();
export const MessageStore_IncrementUnread = () => useMessagesStore.getState().incrementUnread();