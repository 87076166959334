// @ts-strict-ignore
type PersistentStringStorageDriver = {
    GetAsync(key: string): Promise<string>
    SetAsync(key: string, value: string): Promise<void>
}

let _driver: PersistentStringStorageDriver = null

export const SetPersistentStringStorageDriver = (driver: PersistentStringStorageDriver) => { _driver = driver };
export const GetPersistentStringAsync = async (key: string): Promise<string> => {
    if (!_driver) {
        console.log(`WARNING! Attempting to get ${key} before persistent storage driver was set. Returning null`)
        return null
    }
    return await _driver?.GetAsync(key)
}
export const SetPersistentStringAsync = async (key: string, value: string): Promise<void> => {
    if (!_driver) {
        console.log(`WARNING! Attempting to set ${key} to ${value} before persistent storage driver was set. Not setting`)
    }
    await _driver?.SetAsync(key, value)
}
