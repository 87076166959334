// @ts-strict-ignore
import { Button, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Flex } from '..';
import { ProgressNameKey, ProgressNames } from 'phoenix/constants/ProgressNames';
import { Relays } from 'constants/Relays';
import { useSupportByVariant } from 'phoenix/constants/Support';
import { useRelay } from 'phoenix/hooks/UseRelay';
import { GetClientAccountsAction, HasBeenWelcomedAction } from 'phoenix/redux/actions';
import { GetVariant } from 'phoenix/util/Variant'
import { UseSecondaryButtonStyles, UseSubmitButtonStyles } from '../../theming';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { LanguageDropdown } from '../Inputs/LanguageDropdown';
import { ActionModal } from '../Modals';
import { StyledIcon } from '../StyledIcon';
import { LoadingSpinner } from '../LottieAnimations/LoadingSpinner';
import { useColors } from 'hooks/UseColors';
import { useProgress } from 'hooks/UseProgress';
import { MyProgress } from 'phoenix/redux/models/Users/MyProgress';
import { useTelemetry } from 'hooks/UseTelemetry';
const FloatingGuy = React.lazy(() => import('../LottieAnimations/FloatingGuy'));

export const WelcomeModal = React.memo(() => {
    const colors = useColors();
    const LogEvent = useTelemetry();
    const buttonStyles = UseSubmitButtonStyles(colors)();
    const exploreButtonStyles = UseSecondaryButtonStyles(colors)();
    const dispatch = useDispatch();
    const [hasManuallyClosed, setHasManuallyClosed] = useState(false);
    const [progress, setProgress] = useProgress();
    const [show, setShow] = useRelay<boolean>(Relays.WelcomeModalVisible);


    const welcomed = progress ? progress?.hasBeenWelcomed : true;
    const visitedPortfolio = progress ? progress?.portfolioTour : true;
    const visitedStocks = progress ? progress?.stockScreenTour : true;

    // Hide the modal if any of the following conditions are met.
    const userIsNew = useMemo(() => !welcomed && !visitedPortfolio && !visitedStocks, [welcomed, visitedPortfolio, visitedStocks, progress])

    const accounts = useSnexStore(s => s.accounts.all);

    useEffect(() => {
        if (userIsNew) setShow(true);
        else setShow(false);
        // Welcome modal is acting up in lower envs
        // setShow(false)
    }, [userIsNew, progress, welcomed]);

    const handleDismiss = async (outcome: 'tour' | 'dismiss' | 'none') => {
        setHasManuallyClosed(true)
        LogEvent('Welcome Dismiss', { outcome });
        setShow(false);
        if (outcome === 'dismiss') {
            setProgress(MyProgress.dismissTours(progress))
        } else {
            setProgress({ ...progress, [ProgressNameKey(ProgressNames.hasBeenWelcomed)]: true })
        }
        dispatch(HasBeenWelcomedAction());
    };

    return (

        <ActionModal closerStyle='none' height={600} isOpen={show} toggleModal={() => handleDismiss('dismiss')} width={800}>
            <Flex column align='center' justify='space-between' style={{ height: '100%', padding: 10, width: '100%', position: 'relative', paddingTop: 30 }}>
                <Flex column align='center' justify='space-between' style={{ flex: 1 }}>
                    <Flex column align='center' justify='center' >
                        <Typography style={{ marginBottom: 20, fontSize: 14, fontWeight: 300 }} variant='body2'>Pick the language you are most comfortable with</Typography>
                        <LanguageDropdown />
                    </Flex>
                    <AccountExistenceConfirmationDisplay />
                    <Flex row justify='center' style={{ width: '100%', minHeight: 50 }}>
                        {!accounts.loading && !accounts.pristine && !accounts.error
                            && <><Button classes={exploreButtonStyles} disabled={accounts.loading || accounts.pristine} style={{ padding: 10, width: 200, height: 45 }} onClick={() => handleDismiss('dismiss')}>Skip Tour</Button>
                                <Button classes={buttonStyles} disabled={accounts.loading || accounts.pristine} style={{ marginLeft: 15, padding: 10, height: 45, width: 200 }} variant='contained' onClick={() => handleDismiss('tour')}>Show Me Around!</Button></>}
                    </Flex>
                </Flex>
            </Flex>

        </ActionModal>

    );

});

const AccountExistenceConfirmationDisplay = React.memo(() => {

    const colors = useColors();
    const { isClient } = GetVariant()
    const { email } = useSupportByVariant()
    const dispatch = useDispatch();
    const accounts = useSnexStore(s => s.accounts.all);
    const exploreButtonStyles = UseSecondaryButtonStyles(colors)();

    useEffect(() => {
        dispatch(GetClientAccountsAction());
    }, []);

    const handleLookAgain = () => {
        dispatch(GetClientAccountsAction(true));
    };

    const StatusText = (props: { children: any }) => <Typography style={{ marginBottom: 15, fontSize: 25, color: colors.generalTextColor }} variant='h6'>{props.children}</Typography>;

    const InfoText = (props: { children: any }) => <Typography style={{ lineHeight: 1.2, width: 500, marginBottom: 15, fontSize: 16, color: colors.generalTextColor, fontWeight: 400 }} variant='body1'>{props.children}</Typography>;

    if (accounts.loading || accounts.pristine)
        return (
            <Flex center column>
                <Flex center style={{ height: '300px' }}>
                    <LoadingSpinner />
                </Flex>
                <StatusText>Checking the status of your account...</StatusText>
            </Flex>
        );
    else if (accounts.error) return (
        <Flex center column style={{ padding: 15, paddingBottom: 50 }}>
            <Flex style={{ minWidth: 300 }}>
                <StyledIcon IconComponent={InfoOutlined} size={30} style={{ color: 'orange', marginRight: 15 }} />
                <InfoText>
                    There was a problem locating your account. It may take a few minutes for your account to initialize.
                    If it is unavailable after 10 minutes, please reach out to our support at{' '}
                    <a href={email.mailto} style={{ padding: '0 5', color: 'orange' }}>{email.address}</a>.
                </InfoText>
            </Flex>
            <Button classes={exploreButtonStyles} style={{ padding: 10, width: 200, height: 45, marginTop: 15 }} onClick={handleLookAgain}>Try Again</Button>
        </Flex>
    ); else if (!accounts.data?.length) return (
        <Flex center column style={{ padding: 15, paddingBottom: 50 }}>
            <Flex>
                <StyledIcon IconComponent={InfoOutlined} size={30} style={{ color: 'orange', marginRight: 15 }} />
                <InfoText>
                    It looks like your account isn&apos;t quite ready yet. It may take a few minutes for your account to initialize.
                    If it is unavailable after 10 minutes, please reach out to our support at{' '}
                    <a href={email.mailto} style={{ padding: '0 5', color: 'orange' }}>{email.address}</a>.
                </InfoText>
            </Flex>
            <Button classes={exploreButtonStyles} style={{ padding: 10, width: 200, height: 45, marginTop: 15 }} onClick={handleLookAgain}>Try Again</Button>
        </Flex>
    ); else return (
        <Flex column align='flex-start'>
            <Flex center column style={{ width: '100%' }}>
                <React.Suspense fallback={<div />}>
                    <FloatingGuy size={300} />
                </React.Suspense>
                <StatusText>Your account was created successfully!</StatusText>
            </Flex>
        </Flex>
    );

});