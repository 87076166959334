// @ts-strict-ignore
import { Card } from 'components/Card';
import React, { useEffect, useMemo, useState } from 'react';
import { FullyPaidLendingIcon } from './FullyPaidLendingIcon';
import { Flex } from 'components/Flex';
import { ShadedTextDisplay } from 'components/ShadedTextDisplay/ShadedTextDisplay';
import { SnexButton } from 'components/SnexButton';
import CancelIcon from '@mui/icons-material/Cancel';
import { IconButton } from '@mui/material';
import { useLocalStorage } from 'hooks/UseLocalStorage';
import { StorageKeys } from 'phoenix/constants';
import { useText } from 'phoenix/hooks/UseText';
import { FullyPaidLendingModal } from './FullyPaidLendingModal';
import { GetAppTheme } from 'theming';
import { useFullyPaidLending } from 'phoenix/hooks/UseFullyPaidLending';
import './index.css';

export const FullyPaidLendingBanner = ({ accountNumber }: { accountNumber?: string }): JSX.Element => {
    const appTheme = GetAppTheme();
    const [showFPLBannerLocalStorage, setShowFPLBannerLocalStorage] = useLocalStorage<boolean>(StorageKeys.ShowFPLBanner, true);
    const { showFPLBanner } = useFullyPaidLending(accountNumber);

    const shadedTextList = useText((t) => t.fplBanner.bannerText(!accountNumber, appTheme));

    const learnMore = useText((t) => t.fplBanner.learnMore);
    const [showModal, setShowModal] = useState(false);

    const handleLearnMoreClick = () => {
        setShowModal(true);
    };

    const handleCloseClick = () => {
        setShowFPLBannerLocalStorage(false);
    };

    if (!showFPLBanner || !showFPLBannerLocalStorage) return null;

    return (
        <>
            <Card className='fpl-banner'>
                <Flex row fullWidth align='center' style={{ gap: 16 }}>
                    <FullyPaidLendingIcon height={75} width={75} style={{ flex: 0 }} />
                    <Flex column fullWidth style={{ flex: 1, cursor: 'pointer' }} onClick={handleLearnMoreClick}>
                        <ShadedTextDisplay segments={shadedTextList} style={{ fontSize: 14, fontWeight: 400 }} />
                    </Flex>
                    <SnexButton style={{ backgroundColor: `#13B53F`, color: '#fff', flex: 0, minWidth: '110px', padding: 8 }} onClick={handleLearnMoreClick}>
                        {learnMore}
                    </SnexButton>
                    <IconButton title={'Hide banner'} style={{ color: appTheme === 'dark' ? '#ffffff25' : '#ffffff90', flex: 0 }} onClick={handleCloseClick}>
                        <CancelIcon />
                    </IconButton>
                </Flex>
            </Card>
            <FullyPaidLendingModal showModal={showModal} onClose={() => setShowModal(false)} />
        </>
    );
};
