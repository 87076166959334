import { GetVariant, clientAccess } from 'phoenix/util/Variant';
import { Environment, ResolveEnvironment, ResolveFrontend, SnexDomain } from '.';
import { GetWhitelabel, Whitelabel, WhitelabelFormattedNames } from 'phoenix/constants/Whitelabels';

interface SupportContactInfoPerOffering {
    email: {
        address: string;
        mailto: string;
    };
    phone?: {
        number: string;
        label: string;
        href: string;
    };
}

export interface SupportContactInfoPerEnvironment extends SupportContactInfoPerOffering {
    loginSupportMailto: (userEmail: string) => string;
}

// Offering is like environment, but all of local/dev/staging/test/prod are rolled into 'one', and beta is called 'client'
type Offering = 'client' | 'one' | 'pro' | Whitelabel;

const supportByOffering: Record<Offering, SupportContactInfoPerOffering> = {
    client: {
        email: {
            mailto: 'mailto:StoneXClient@stonex.com?subject=StoneX Client - Support Inquiry',
            address: 'StoneXClient@stonex.com'
        },
        phone: {
            number: '18004054106',
            label: '1-800-405-4106',
            href: 'tel:18004054106'
        }
    },
    one: {
        email: {
            mailto: 'mailto:support@stonexone.com?subject=StoneX One - Support Inquiry',
            address: 'support@stonexone.com'
        },
        phone: {
            number: '18664435847',
            label: '1-866-443-5847',
            href: 'tel:18664435847'
        }
    },
    pro: {
        email: {
            mailto: 'mailto:prosupport@stonexone.com?subject=StoneX One Pro - Support Inquiry',
            address: 'prosupport@stonexone.com'
        },
        phone: {
            number: '18882084696',
            label: '1-888-208-4696',
            href: 'tel:18882084696'
        }
    },
    [Whitelabel.Aiva]: {
        email: {
            mailto: 'mailto:IBDsupport@stonexone.com?subject=StoneX One Aiva - Support Inquiry',
            address: 'IBDsupport@stonexone.com'
        }
    },
    [Whitelabel.Winexco]: {
        email: {
            mailto: 'mailto:IBDsupport@stonexone.com?subject=StoneX One Winexco - Support Inquiry',
            address: 'IBDsupport@stonexone.com'
        }
    },
    [Whitelabel.Galicia]: {
        email: {
            mailto: 'mailto:IBDsupport@stonexone.com?subject=StoneX One Galicia - Support Inquiry',
            address: 'IBDsupport@stonexone.com'
        }
    },
    [Whitelabel.FolioNet]: {
        email: {
            mailto: 'mailto:IBDsupport@stonexone.com?subject=StoneX One FolioNet - Support Inquiry',
            address: 'IBDsupport@stonexone.com'
        }
    },
    [Whitelabel.AvSecurities]: {
        email: {
            mailto: 'mailto:IBDsupport@stonexone.com?subject=StoneX One AvSecurities - Support Inquiry',
            address: 'IBDsupport@stonexone.com'
        }
    },
    [Whitelabel.ConosurInversiones]: {
        email: {
            mailto: 'mailto:IBDsupport@stonexone.com?subject=StoneX One Conosur Inversiones - Support Inquiry',
            address: 'IBDsupport@stonexone.com'
        }
    }
};

/**
 * @deprecated mobile uses this directly but it probably shouldn't because directly accessing this structure makes it hard to add per-environment support info
 */
export const Support = supportByOffering;

const supportByEnvBase: Record<Environment, SupportContactInfoPerOffering> = {
    beta: supportByOffering.client,

    local: supportByOffering.one,
    development: supportByOffering.one,
    test: supportByOffering.one,
    staging: supportByOffering.one,
    production: supportByOffering.one,

    pro: supportByOffering.pro,

    [Whitelabel.Aiva]: supportByOffering[Whitelabel.Aiva],
    [Whitelabel.Winexco]: supportByOffering[Whitelabel.Winexco],
    [Whitelabel.Galicia]: supportByOffering[Whitelabel.Galicia],
    [Whitelabel.FolioNet]: supportByOffering[Whitelabel.FolioNet],
    [Whitelabel.AvSecurities]: supportByOffering[Whitelabel.AvSecurities],
    [Whitelabel.ConosurInversiones]: supportByOffering[Whitelabel.ConosurInversiones]
};

const environments: Environment[] = Object.keys(supportByEnvBase) as Environment[];

const supportByEnv: Record<Environment, SupportContactInfoPerEnvironment> = environments.reduce((acc, env) => {
    acc[env] = {
        ...supportByEnvBase[env],
        loginSupportMailto: createLoginSupportMailtoFunc(env)
    };
    return acc;
}, {} as Record<Environment, SupportContactInfoPerEnvironment>);

interface LoginSupportFunc {
    (userEmail: string): string;
}

function createLoginSupportMailtoFunc(environment: Environment): LoginSupportFunc {
    const supportEmail = supportByEnvBase[environment].email.address;
    const friendlyDomain = getFriendlyDomain(environment);
    return (userEmail: string): string => `mailto:${supportEmail}?subject=User ${userEmail} unable to log in to ${friendlyDomain}`;
}

export function GetSupportInfoByEnv(): SupportContactInfoPerEnvironment {
    const envKey = getSimulatedEnvironment();
    return supportByEnv[envKey];
}

/**
 * Like `ResolveEnvironment`, but also considers debug sim of whitelabels and beta.
 */
export function getSimulatedEnvironment(): Environment {
    // ResolveEnvironment does not react to whitelabel sim options.
    // GetWhitelabel is simulatable, and so is IsBeta ("limited variant"), so prioritize these for determining support info

    // if this is non-null, we're either a whitelabel or we're simulating one
    const whitelabel = GetWhitelabel();
    if (whitelabel !== null) return whitelabel;

    if (isBeta()) return 'beta';

    return ResolveEnvironment();
}

function getFriendlyDomain(environment: Environment): string {
    const snexDomain: SnexDomain = ResolveFrontend(environment);
    const domain = snexDomain.replace(/https?:\/\//, '');
    return domain;
}

export function getFriendlyName(environment: Environment): string {
    // We see you're trying to get into {x}!
    switch (environment) {
        case 'beta':
            return 'StoneX One Beta';
        case 'local':
        case 'development':
        case 'test':
        case 'staging':
        case 'production':
            return 'StoneX One';
        case Whitelabel.Aiva:
        case Whitelabel.Galicia:
        case Whitelabel.Winexco:
        case Whitelabel.FolioNet:
        case Whitelabel.AvSecurities:
        case Whitelabel.ConosurInversiones: {
            const formattedName = WhitelabelFormattedNames[environment];
            return `${formattedName} for StoneX One`;
        }
        case 'pro':
            return 'StoneX One Pro';
        default: {
            const exhaustiveCheck: never = environment;
            console.warn(`getFriendlyName: Unhandled environment ${exhaustiveCheck}`);
            return 'StoneX One';
        }
    }
}

function isBeta(): boolean {
    return GetVariant() === clientAccess;
}

/**
 * This hook is not aware of whitelabels, and only returns the contact info for Pro, Beta, or One.
 * Use `GetSupportInfoByEnv` instead.
 * @deprecated
 */
export const useSupportByVariant = (): SupportContactInfoPerOffering => {
    const env = ResolveEnvironment();
    if (isBeta()) return supportByOffering.client;
    return env === 'pro' ? supportByOffering.pro : supportByOffering.one;
};
