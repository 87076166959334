import React from 'react';
import { Flex } from '../';
import { FormattedTextList, Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { FormattedTextDisplay } from '../FormattedTextDisplay/FormattedTextDisplay';
import './TourGuide.scss';
import { useText } from 'phoenix/hooks/UseText';

export const TourGuide = React.memo((props: { text: (lang: Snex1LanguagePack) => FormattedTextList }) => {

    return (
        <Flex>
            <FormattedTextDisplay text={ useText(t => props.text(t)) } />
        </Flex>
    );
});