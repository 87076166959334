// @ts-strict-ignore
import { MakeQueryString, ResolveFrontend, Urls } from 'phoenix/constants';
import { ProductCode } from 'phoenix/constants/PremiumProducts';

export type AccountOnboardingTypes = 'equities' | 'futures';
export type SupportSubroute = '' | 'funding' | 'funding/limits' | 'funding/voids';
export type SupportPage = '' | 'limits' | 'voids' | 'failures';
export const Routes = {
    oktaToken: (): string => '/token',
    login: (): string => '/login',
    register: (step?: string): string => `/registration/${step}`,
    onboardingSelect: (): string => '/onboarding/select',
    portfolio: (): string => '/',
    account: (accountNumber: string): string => `/account/${accountNumber}`,
    documents: (symbol?: string): string => (symbol ? `/documents?symbol=${symbol}` : '/documents'),
    positions: (): string => '/positions',
    documentPreview: (documentTypeId: string, documentId: string): string => `/documents/${documentTypeId}/${documentId}/preview`,
    banking: (): string => '/funding',
    messages: (): string => '/messages',
    message: (messageId: string): string => `/message/${messageId}`,
    funding: (): string => '/funding',
    orders: (symbol?: string, orderId?: string, accountNumber?: string): string => {
        let base = '/orders';
        const queryStrings = [];
        if (symbol) queryStrings.push(`symbol=${symbol}`);
        if (orderId) queryStrings.push(`orderId=${orderId}`);
        if (accountNumber) queryStrings.push(`accountNumber=${accountNumber}`);
        if (queryStrings.length > 0) base += `?${queryStrings.join('&')}`;
        return base;
    },
    transactions: (): string => '/transactions',
    security: (symbol: string): string => `/security/${symbol}/`,
    optionChain: (symbol: string, query?: { [key: string]: string }): string => `/security/${symbol}/options${query ? `?${MakeQueryString(query)}` : ''}`,
    optionContract: (symbol: string, query?: { [key: string]: string }): string => `/option/${symbol}/${query ? `?${MakeQueryString(query)}` : ''}`,
    position: (symbol: string, query?: { [key: string]: string }): string => `/security/${symbol}/${query ? `?${MakeQueryString(query)}` : ''}`,
    projectedIncome: (): string => '/projected-income/',
    deepAnalysis: (symbol: string): string => `/stocks/${symbol}/analysis`,
    futuresDeepAnalysis: (symbol: string): string => `/futures/${symbol}/analysis`,
    futuresSpreads: (symbol: string, frontMonthSymbol?: string): string => `/futures/${symbol}/time-spreads${frontMonthSymbol ? `/${frontMonthSymbol}` : ''}`,
    futuresTimeSpreadDeeperView: (symbol: string): string => `/futures-time-spread/${symbol}`,
    watchlists: (watchlistId: string): string => `/lists/${watchlistId}`,
    options: (symbol: string): string => `/options/${symbol}/`,
    profile: (
        subroute?: '' | 'investment-profile' | 'security' | 'accounts' | 'paperless' | ':subroute' | string,
        adminSubroute?: '' | 'authSettings' | 'positions' | string
    ): string => `/profile/${subroute || ''}${adminSubroute ? '/' + adminSubroute : ''}`,
    disclosures: (): string => '/terms',
    premium: (productCode?: ProductCode): string => `/premium/${productCode}`,
    alexland: (): string => '/alex',
    romanEmpire: (): string => '/roman',
    gillyLand: (): string => '/neil',
    mattropolis: (): string => '/matt',
    yuli: (): string => '/yuli',
    jareDebug: (): string => '/jared',
    admin: (): string => '/admin',
    style: (): string => '/style',
    sectors: (sector: string): string => `/sectors/${sector}`,
    advancedChart: (symbol: string): string => `/advanced-chart/${symbol}`,
    fplOnboarding: (step?: string): string => `/onboarding/fpl/${step || '1'}`,
    accountOnboarding: (type: AccountOnboardingTypes, step?: string | number): string => {
        switch (type) {
            case 'equities': {
                const baseUrl = ResolveFrontend();
                const vulcan = Urls.authentication.vulcanServer();
                return `${vulcan}/l/signup/onboarding?privatelabelcode=snex1&redirectUrl=${baseUrl}`;
            }
            case 'futures':
                return `/onboarding/${type}/${step || '1'}`;
        }
    },
    support: (subroute: SupportSubroute, page: SupportPage): string => `/support/${subroute}/${page}`,
    localLogin: (): string => '/local/login',
    deadLogin: () : string => '/contact/support',
    loggedOut: () : string => '/loggedOut',
    fixedPlus: () : string => 'https://fixedplus.stonex.com',
    oneProNetwork: () : string => '/oneProNetwork',
    oneProNetworkAuthor: (authorId: string): string => `/oneProNetwork/author/${authorId}`,
};

// This is used by our telemetry. Please keep it up to date as we add new routes so that we can get good analytics.
const generalRoutes = new Set('/login,/,/positions,/funding,/messages,/transactions,/orders,/documents,/projected-income,/terms,/admin'.split(','));
const cleanPaths = [
    { test: new RegExp(Routes.documentPreview('[^/]+', '[^/]+')), value: Routes.documentPreview(':type', ':id') },
    { test: new RegExp(Routes.account('.+')), value: Routes.account(':number') },
    { test: new RegExp(Routes.message('.+')), value: Routes.message(':id') },
    { test: new RegExp(Routes.security('.+')), value: Routes.security(':symbol') },
    { test: new RegExp(Routes.deepAnalysis('.+')), value: Routes.deepAnalysis(':symbol') },
    { test: new RegExp(Routes.optionChain('.+')), value: Routes.optionChain(':symbol') },
    { test: new RegExp(Routes.optionContract('.+')), value: Routes.optionContract(':symbol') },
    { test: new RegExp(Routes.advancedChart('.+')), value: Routes.advancedChart(':symbol') }
];
export const GeneralizeRoute = (route: string): string => {
    if (generalRoutes.has(route)) return route;
    if (/^\/profile/.test(route)) return route;
    else return cleanPaths.find((x) => x.test.test(route))?.value;
};
