
import React from "react"

export const ProfitsIcon = ({size}: {size?: number}) : JSX.Element => {
    return(
        <svg width={size || 27} height={size || 27} viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.08652 15.6031H7.56107V24.4705H5.08652V15.6031Z" fill="#13B53F"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.0356 12.4387H12.5102V24.4705H10.0356V12.4387Z" fill="#78D261"/>
        <rect x="14.9847" y="17.0998" width="2.47455" height="7.37072" fill="#6FFF6C"/>
        <rect x="19.9337" y="6.22413" width="2.47455" height="18.2463" fill="#5CDD5A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M26.8101 24.0409L24.748 22.1935C24.5184 21.9879 24.1712 22.0136 23.9723 22.2508C23.7734 22.488 23.7983 22.847 24.0278 23.0526L24.9759 23.902H22.9581V6.22413C22.9581 5.9102 22.712 5.65569 22.4082 5.65569H19.9337C19.63 5.65569 19.3838 5.91015 19.3838 6.22413V23.902H18.009V17.1C18.009 16.7861 17.7629 16.5316 17.4591 16.5316H14.9846C14.6809 16.5316 14.4347 16.786 14.4347 17.1V23.9021H13.0599V12.4389C13.0599 12.125 12.8138 11.8705 12.51 11.8705H10.0355C9.73178 11.8705 9.48556 12.125 9.48556 12.4389V23.9021H8.11083V15.6031C8.11083 15.2892 7.86467 15.0347 7.56092 15.0347H5.08653C4.78283 15.0347 4.53662 15.2891 4.53662 15.6031V23.9021H3.16189V2.09211L3.98359 3.07221C4.09238 3.20195 4.24542 3.2684 4.39935 3.2684C4.52697 3.2684 4.65522 3.22272 4.75926 3.12956C4.98871 2.92394 5.0136 2.56494 4.81463 2.32776L3.02747 0.196177C3.02467 0.192906 3.02209 0.190235 3.01935 0.187128C2.9615 0.121061 2.88852 0.0684581 2.80414 0.0358607C2.58914 -0.04705 2.34678 0.0168365 2.19638 0.196177L0.409217 2.32776C0.210357 2.56488 0.235194 2.92394 0.464694 3.12956C0.694246 3.33523 1.0415 3.3095 1.24036 3.07227L2.06206 2.09222V23.9021H0.549912C0.246163 23.9021 0 24.1565 0 24.4705C0 24.7844 0.246163 25.0389 0.549912 25.0389H2.06212V26.4316C2.06212 26.7455 2.30828 27 2.61203 27C2.91578 27 3.16194 26.7455 3.16194 26.4316V25.0389H24.976L24.0279 25.8883C23.7984 26.0939 23.7735 26.4529 23.9725 26.6901C24.0813 26.8198 24.2343 26.8863 24.3882 26.8863C24.5159 26.8863 24.6441 26.8406 24.7482 26.7475L26.8103 24.9001C26.8146 24.8962 26.8309 24.8804 26.8317 24.8796C26.8897 24.8219 26.9359 24.7507 26.9654 24.6692C27.0455 24.4468 26.9836 24.1964 26.8101 24.0409ZM5.63644 23.9021V16.1715H7.01117V23.9021H5.63644ZM10.5855 23.9021V13.0073H11.9602V23.9021H10.5855ZM15.5345 23.902V17.6684H16.9093V23.902H15.5345ZM20.4836 23.9021V6.79257H21.8584V23.9021H20.4836Z" fill="#13B53F"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M16.4113 2.39421L17.7004 2.67402L4.86509 11.4726C4.61196 11.6461 4.54288 11.9988 4.71074 12.2605C4.81657 12.4254 4.99133 12.5148 5.16952 12.5148C5.27388 12.5148 5.37941 12.4841 5.47296 12.42L18.286 3.63668L18.0209 4.9421C17.9585 5.24932 18.1489 5.55071 18.4461 5.61514C18.7431 5.67968 19.0349 5.48284 19.0972 5.17562L19.6379 2.51354C19.6838 2.28781 19.5932 2.05587 19.4085 1.92635C19.3582 1.89103 19.3034 1.86617 19.2469 1.84998C19.2367 1.84649 19.2257 1.84327 19.2128 1.84049L16.6375 1.28154C16.3404 1.21689 16.0487 1.41384 15.9864 1.72106C15.9238 2.02828 16.1141 2.32967 16.4113 2.39421Z" fill="#13B53F"/>
        </svg>   )
}