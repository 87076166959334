import { ohlcvBar } from 'components/SecurityChartSection';
import { format, getUnixTime } from 'date-fns';
import { BarData, isUTCTimestamp, LineData, SeriesMarker, Time } from 'lightweight-charts';
import { uniqBy } from 'lodash';
import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { ChartRange } from 'phoenix/constants';
import { SnexChartPoint, SnexChartTimezoneType, SnexSegmentScale } from 'phoenix/constants/ChartTypes';
import { AccountChartPoint } from 'phoenix/redux/models';
import { AppColorTheme } from 'phoenix/theming/ColorVariants/AppColorTheme';
import { GetTimeChunksForTimezone, QuoteAttribute } from 'phoenix/util';
import { SnexChartSeriesType } from 'store/SecurityChart';
import { SeriesConfig } from './SeriesConfig';

export const isPremarket = (hour: number, minute: number): boolean => hour < 9 || (hour < 10 && minute < 31);
export const isPostMarket = (hour: number): boolean => hour > 15;
export const isOffHours = (hour: number, minute: number): boolean => isPremarket(hour, minute) || isPostMarket(hour);

export function getLineScrubValues({ startingValue, value }: { startingValue: number; value: number }): {
    changeValue: number;
    changePercentValue: number;
    showBarLabels: boolean;
    value: number;
} {
    const changeValue = value - startingValue === 0 ? 0.0 : value - startingValue;
    const changePercentValue = ((value - startingValue) / startingValue) * 100;
    return { changeValue, changePercentValue, showBarLabels: false, value };
}

export function getCandleScrubValues({ bar, startingValue }: { bar: ohlcvBar & { price?: number }; startingValue: number }): {
    changeValue: number;
    changePercentValue: number;
    close: number;
    high: number;
    low: number;
    open: number;
    showBarLabels: boolean;
    value: number;
    volume: number;
} {
    const { close, open, high, low, volume } = bar || {};
    const value = bar?.price || close;
    const changeValue = value - startingValue === 0 ? 0.0 : value - startingValue;
    const changePercentValue = (startingValue ? (value - startingValue) / startingValue : 0) * 100;

    return { changeValue, changePercentValue, close, open, high, low, showBarLabels: true, value, volume };
}

// This function separates a single array of chart data into multiple segments
// The segments represent extended trading sessions or separations by day/month/year
export function getSegmentedData({
    data,
    segmentScale,
    timezone
}: {
    data: SnexLightweightChartPoint[];
    segmentScale?: SnexSegmentScale;
    timezone: SnexChartTimezoneType;
}): Array<Array<LineData | BarData>> {
    const uniqueData = uniqBy(data, 'time');

    const result =
        segmentScale && uniqueData.length
            ? [
                  // The first segment is the entire dataset
                  // This will be an "invisible" series that renders the high/low markers
                  // and prevents the charting library from constantly resizing the visible range when segment data changes
                  //   uniqueData,
                  ...uniqueData.reduce<Array<Array<LineData | BarData>>>((f, c) => {
                      if (!f.length) {
                          return [[c]];
                      }
                      const list = f[f.length - 1];
                      if (list.length === 1) {
                          list.push(c);
                          f[f.length - 1] = list;
                          return f;
                      }
                      const currentTimeChunks = GetTimeChunksForTimezone(timezone, (c.time as number) * 1000);
                      const previousTimeChunks = GetTimeChunksForTimezone(timezone, (list[list.length - 1].time as number) * 1000);

                      switch (segmentScale) {
                          case 'equities-day':
                              if (
                                  (isOffHours(previousTimeChunks.hour, previousTimeChunks.minute) && !isOffHours(currentTimeChunks.hour, currentTimeChunks.minute)) ||
                                  (!isOffHours(previousTimeChunks.hour, previousTimeChunks.minute) && isOffHours(currentTimeChunks.hour, currentTimeChunks.minute))
                              ) {
                                  f.push([list[list.length - 1], c]);
                                  return f;
                              }
                              break;
                          case 'day':
                              if (currentTimeChunks.dayOfMonth !== previousTimeChunks.dayOfMonth) {
                                  f.push([list[list.length - 1], c]);
                                  return f;
                              }
                              break;
                          case 'month':
                              if (currentTimeChunks.month !== previousTimeChunks.month) {
                                  f.push([list[list.length - 1], c]);
                                  return f;
                              }
                              break;
                          case 'week':
                              if (currentTimeChunks.dayOfWeek < previousTimeChunks.dayOfWeek) {
                                  f.push([list[list.length - 1], c]);
                                  return f;
                              }
                              break;
                          case 'year':
                              if (currentTimeChunks.year !== previousTimeChunks.year) {
                                  f.push([list[list.length - 1], c]);
                                  return f;
                              }
                              break;
                          default:
                              break;
                      }

                      list.push(c);
                      f[f.length - 1] = list;
                      return f;
                  }, [])
              ]
            : [];

    return result;
}

export function getLabelForTime({
    datetime,
    range,
    segmentScale,
    text,
    timezone
}: {
    datetime: Date;
    range: ChartRange;
    segmentScale: SnexSegmentScale;
    text: Snex1LanguagePack;
    timezone: SnexChartTimezoneType;
}): string {
    if (segmentScale === 'equities-day') {
        const { hour, minute } = GetTimeChunksForTimezone(timezone, datetime);
        const marketContext = isPremarket(hour, minute) ? text.securityScreen.premarket : isPostMarket(hour) ? text.securityScreen.postmarket : '';

        return `${format(datetime, 'h:mma', { locale: text.general.dateFnsLocale })}${marketContext ? ` (${marketContext})` : ''}`;
    }

    switch (range) {
        case '24h':
        case '1d':
        case '5d':
        case '1w':
        case '1m':
            return format(datetime, 'h:mma E, MMM dd', { locale: text.general.dateFnsLocale });
        default:
            return format(datetime, 'MMM do, yyyy', { locale: text.general.dateFnsLocale });
    }
}

export type GetMultiSeriesConfigProps = {
    colors?: AppColorTheme;
    data: SnexChartPoint[] | AccountChartPoint[];
    formatPrice?: (v: number) => string; // Only necessary if rendering High/Low price markers
    lineColor?: string;
    lineColorLighter?: string;
    segmentScale?: SnexSegmentScale;
    seriesType: SnexChartSeriesType;
    showVolume?: boolean;
    timezone: SnexChartTimezoneType;
    withHighLowMarkers?: boolean;
};

export interface SnexLightweightChartPoint extends BarData {
    value?: number;
}

function convertSnexDataToLightweightCharts(data: SnexChartPoint[] = []): SnexLightweightChartPoint[] {
    // Filter out points that are missing timestamp
    const filtered = data.filter((d) => isUTCTimestamp(QuoteAttribute.getTime(d)));
    // Map the "time" property that is expected by lightweight-charts
    const mapped: SnexLightweightChartPoint[] = filtered.map((d) => ({
        close: d?.close || 0,
        high: d?.high || 0,
        low: d?.low || 0,
        open: d?.open || 0,
        time: getUnixTime(new Date(QuoteAttribute.getTime(d))) as Time,
        value: QuoteAttribute.getPrice(d) || 0,
        ...d
    }));
    // sort asc
    const sorted = mapped.sort((a, b) => (a?.time as number) - (b?.time as number));

    return sorted;
}

export function getMultiSeriesConfig({
    colors,
    data = [],
    formatPrice = (v) => `${v}`,
    lineColor,
    lineColorLighter,
    segmentScale,
    seriesType,
    timezone,
    withHighLowMarkers
}: GetMultiSeriesConfigProps): SeriesConfig[] {
    const converted = convertSnexDataToLightweightCharts(data);
    const uniqueData = uniqBy(converted, 'time');
    const sortByValue = uniqueData.sort((a, b) => (a?.value || 0) - (b?.value || 0));
    const high = sortByValue?.[0];
    const low = sortByValue?.[sortByValue.length - 1];
    const lowMarker: SeriesMarker<Time> = {
        color: colors?.generalTextColor || '',
        time: low?.time as Time,
        position: 'aboveBar',
        // @ts-ignore - We do not want a marker shape but it is a required property
        shape: '',
        text: formatPrice(low?.value || 0)
    };
    const highMarker: SeriesMarker<Time> = {
        color: colors?.generalTextColor || '',
        position: 'belowBar',
        // @ts-ignore - We do not want a marker shape but it is a required property
        shape: '',
        text: formatPrice(high?.value || 0),
        time: high?.time as Time
    };

    const sortByTime = uniqueData.sort((a, b) => (a?.time as number) - (b?.time as number));

    // console.log('GET MULTI SERIES STUFF', { converted, sortByValue, high, low, sortByTime, data, uniqueData, segmentScale, seriesType, timezone, withHighLowMarkers });

    // Line charts are segmented into market segments or days; area/candle data is not segmented
    if (seriesType === 'line') {
        const segmentedData = getSegmentedData({ data: uniqueData, segmentScale, timezone });

        const seriesMap = segmentedData.map((data, i) => {
            return {
                seriesId: `TODO-${i}`,
                data: data.sort((a, b) => (a?.time as number) - (b?.time as number)),
                ...(lineColor ? { lineColor, lineColorLighter } : {}),
                markers: withHighLowMarkers
                    ? [
                          ...(lowMarker.time > data?.[0]?.time && lowMarker.time < data?.[data?.length - 1]?.time ? [lowMarker] : []),
                          ...(highMarker.time > data?.[0]?.time && highMarker.time < data?.[data?.length - 1]?.time ? [highMarker] : [])
                      ].sort((a, b) => (a?.time as number) - (b?.time as number))
                    : [],
                seriesType,
                extraOptions: {}
            };
        });

        return seriesMap;
    }

    return [
        {
            seriesId: 'TODO-CANDLE',
            data: sortByTime,
            markers: withHighLowMarkers ? [lowMarker, highMarker].sort((a, b) => (a?.time as number) - (b?.time as number)) : [],
            seriesType,
            extraOptions: {}
        }
    ];
}
