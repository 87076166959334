// @ts-strict-ignore
import { colors, Grid, GridSize } from '@mui/material';
import { Cached } from '@mui/icons-material';
import React, { useCallback, useEffect, useMemo } from 'react';
import Masonry from 'react-masonry-css';
import { useDispatch } from 'react-redux';
import { Flex } from '..';
import { T } from 'phoenix/assets/lang/T';
import { LiveDataNamespaces } from 'phoenix/constants/LiveDataNamespaces';
import { useAppWindowSizeVariable } from 'hooks/UseWindowSize';
import { GetMarketNewsAction, GetNewsForAccountAction, GetNewsForPortfolioAction, GetNewsForSymbolAction, ReduxLiveUnsubscribeNamespace, ShowStreamedNewsAction, SubscribeToLiveNewsAction } from 'phoenix/redux/actions';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { NewsCard } from '../NewsCard';
import { SectionHeader } from '../SectionHeader';
import { SnexButton } from '../SnexButton/SnexButton';
import { TelemetryProvider } from 'providers/TelemetryContext';
import { TelemetryCategories } from 'constants/Telemetry/TelemetryCategories';
import { ErrorBoundary, errorWrap, TestErrorComponent } from 'components/ErrorBoundary/ErrorBoundary';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { SetShowPremiumUpgradeModal, useShowPremiumUpgradeModal } from 'hooks/UseShowPremiumUpgradeModal';
import { ArrowLink } from 'components/ArrowLink/ArrowLink';
import { ShiningWrapper } from 'components/ShiningWrapper';
import { GetMySubscriptionInfoAction } from 'phoenix/redux/actions/SubscriptionActions';
import { PremiumProducts } from 'phoenix/constants/PremiumProducts';
import './index.module.css'

interface NewsProps {
    topic?: 'market' | 'account' | 'portfolio' | 'symbol'
    symbol?: string,
    accountNumber?: string,
    hideIfNone?: boolean
}

export const News = React.memo(TelemetryProvider((props: NewsProps) => {
    const { accountNumber, symbol, topic } = props;
    const dispatch = useDispatch();
    const rowCount = useAppWindowSizeVariable({ lg: 3, def: 2 });
    const gridSize = useAppWindowSizeVariable({ lg: 4, def: 6}) as GridSize;
    const miTier = useSnexStore(s => s.subscription?.mySubscription?.data?.tier.includes('mi'));

    const isFuture = useMemo(() => FuturesSymbol.IsFuturesSymbol(symbol), [symbol])
    const unshown = useSnexStore(props.symbol
        ? (s => s.news.newArticlesBySymbol[props.symbol])
        : (s => s.news.newMarketArticles)
    )
    const unshownCount = useMemo(() => unshown?.length || 0, [unshown]);
    const articles = useSnexStore(s => {
        switch (topic) {
            case 'market': return s.news.marketNews;
            case 'portfolio': return s.news.portfolioNews;
            case 'account': return s.news.byAccount[accountNumber];
            case 'symbol': return s.news.bySymbol[symbol];
        }
    });
    const loading = useMemo(() => !articles || articles.pristine || articles.loading, [ articles ]);

    useEffect(() => {
        if (isFuture) dispatch(GetMySubscriptionInfoAction());
        return () => ReduxLiveUnsubscribeNamespace(LiveDataNamespaces.StreamingNews);
    }, [])

    const handleShowRecent = useCallback(() => {
        dispatch(ShowStreamedNewsAction())
    }, [dispatch])

    useEffect(() => {
        switch (topic) {
            case 'market': dispatch(GetMarketNewsAction()); break;
            case 'portfolio': dispatch(GetNewsForPortfolioAction()); break;
            case 'account': dispatch(GetNewsForAccountAction(accountNumber)); break;
            case 'symbol': dispatch(GetNewsForSymbolAction(symbol)); break;
        }
        return () => ReduxLiveUnsubscribeNamespace(LiveDataNamespaces.News);
    }, [symbol, accountNumber]);

    if (articles?.pristine) return (
        <Flex column>
            <SectionHeader label='News' />
            <Grid container spacing={3} style={{ width: '100%' }}>
                <Grid item xs={gridSize}> <NewsCard /> </Grid>
                {rowCount >= 2 && <Grid item xs={gridSize}> <NewsCard /> </Grid>}
                {rowCount >= 3 && <Grid item xs={gridSize}> <NewsCard /> </Grid>}
            </Grid>
        </Flex>
    );

    if (props.hideIfNone && (!articles?.data?.length)) return null;

    return (
        <Flex column id={'news-section'}>
            <Flex row align='center' justify='space-between'>
                <SectionHeader label={T(s => s.general.news(FuturesSymbol.IsFuturesSymbol(accountNumber || symbol)))} infoIconMessagePath={s => s.news} />
                { isFuture && !miTier && (
                        <ShiningWrapper withLock >
                            <ArrowLink disabled={ loading } eventTag='Upgrade to Premium' onClick={() => SetShowPremiumUpgradeModal(PremiumProducts.mi.code)}>
                                { T(s => s.futuresContractScreen.upgradeToPremium) }
                            </ArrowLink>
                        </ShiningWrapper>
                )}
            </Flex>
            <ErrorBoundary>
                <Flex
                    row
                    justify='center'
                    className='show-recent'
                    style={{ '--showRecent': (unshownCount > 0) ? 1 : 0 } as React.CSSProperties}>
                    <SnexButton onClick={handleShowRecent}>
                        <Cached style={{ marginRight: 15 }} />
                        {unshownCount} New {unshownCount === 1 ? 'Story' : 'Stories'}
                    </SnexButton>
                </Flex>
                <Masonry breakpointCols={rowCount} className='my-masonry-grid' columnClassName='my-masonry-grid_column'>
                    {articles?.data?.map(a => (<NewsCard article={a} key={a.url} />)) || []}
                </Masonry>
            </ErrorBoundary>
        </Flex>
    );

}, TelemetryCategories.news));
