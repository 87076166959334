import { getUnixTime } from 'date-fns';
import { isUTCTimestamp, UTCTimestamp } from 'lightweight-charts';
import { ChartRange } from 'phoenix/constants';
import { ApiData } from 'phoenix/models';
import { SecurityChartData, SecurityQuote } from 'phoenix/redux/models';
import { IsMarketOpen, QuoteAttribute, UniqueBy } from 'phoenix/util';
import { SeriesConfig, SeriesDataPoint } from '../Chart/SeriesConfig';
import { SeriesList } from './SecurityChartWrapper';
import _ from 'lodash';
import { produce } from 'immer';

export type RangeArg = ChartRange | 'loRes';
export type DataArg = {
    [symbol: string]: {
        [range: string]: ApiData<SecurityChartData[]>;
    };
};
export type SymbolsArg = string[];
export type QuotesArg = {
    [symbol: string]: SecurityQuote;
};

/**
 * Makes changes to the seriesList based on business logic.
 * It does not mutate any arguments.
 * Idempotent; if no modifications apply, it returns the same seriesList object.
 *
 * @param range
 * @param data
 * @param symbols
 * @param seriesList
 * @param quotes
 * @param showOpenLine
 * @returns a clone (if necessary) of seriesList
 */
export const manipulateData = (seriesList: SeriesList, range: RangeArg, data: DataArg, symbols: SymbolsArg, quotes: QuotesArg, showOpenLine: boolean): SeriesList =>
    produce(seriesList, (draft) => {
        // compare symbols
        const oldSymbols = _.keys(draft);
        const symbolsToAdd = _.difference(symbols, oldSymbols);
        const symbolsToRemove = _.difference(oldSymbols, symbols);
        const symbolsToKeep = _.intersection(oldSymbols, symbols);

        // remove symbols from seriesList
        if (symbolsToRemove.length > 0) {
            _.each(symbolsToRemove, (s) => delete draft[s]);
        }
        // add symbols to seriesList
        if (symbolsToAdd.length > 0) {
            const newSeries = symbolsToAdd.map((s) => ({
                seriesId: s,
                data: [],
                series: 'line',
                extraOptions: { showOpenLine }
            }));
            _.merge(draft, _.zipObject(symbolsToAdd, newSeries));
        }

        _.each(symbolsToKeep, (s) => {
            const dataHere = data[s]?.[range]?.data;
            const hasData = dataHere?.length;
            // if the symbol was already in the seriesList,
            // but we don't have any data from redux,
            // clear out the data in the seriesList.
            // unsure if this is needed.
            if (seriesList[s] && !hasData) {
                draft[s].data = [];
            }
        });

        _.each(symbols, (s, idx) => {
            const dataHere = data[s]?.[range]?.data;
            if (dataHere?.length > 0) {
                const orderedData = [...dataHere]
                    .sort((a, b) => Date.parse(QuoteAttribute.getTime(b)) - Date.parse(QuoteAttribute.getTime(a)))
                    .filter((d) => isUTCTimestamp(QuoteAttribute.getTime(d)) && QuoteAttribute.getPrice(d));
                if (orderedData.length) {
                    let newChartData = orderedData.map<SeriesDataPoint>((d) => ({
                        ...d,
                        time: getUnixTime(new Date(QuoteAttribute.getTime(d))) as UTCTimestamp,
                        value: QuoteAttribute.getPrice(d) ?? undefined
                    }));

                    // if range is 1d and market is open, just show the day *so far*
                    if (range === '1d' && IsMarketOpen(newChartData[0].time as number, true)) {
                        newChartData = newChartData.filter((d) => IsMarketOpen(d.time as number, true));
                    }
                    const uniqueChartData = UniqueBy(newChartData, (item) => item.time as number);
                    if (uniqueChartData?.length) newChartData = uniqueChartData;

                    // Want to move away from changing chart values because this can be dangerous... = Roman C.
                    const lastData = newChartData[newChartData.length - 1];
                    const currentPrice = quotes[s]?.price;
                    if (newChartData?.length && lastData && currentPrice) {
                        lastData.value = currentPrice;
                    }

                    if (seriesList[s]) {
                        draft[s] = seriesList[s];
                        draft[s].data = newChartData || [];
                    } else {
                        draft[s] = {
                            seriesId: s,
                            data: newChartData || [],
                            seriesType: 'line',
                            extraOptions: { showOpenLine: idx === 0 && showOpenLine }
                        };
                    }
                }
            }
        });
    });
