// @ts-strict-ignore
import { ChartRange } from 'phoenix/constants';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import { XS } from 'phoenix/xstream/XS';
import { useEffect } from 'react';
import { create } from 'zustand';

export interface ISecurityValues {
    price?: number;
    change?: number;
    changePercent?: number;
    open?: number;
    close?: number;
    high?: number;
    low?: number;
    volume?: number;
}

interface SecurityValuesStore extends ISecurityValues {
    valuesOverride: boolean;
    showAdditionalStats: boolean;
    startingValue: number;
}

export const useSecurityValuesStore = create<SecurityValuesStore>((set) => ({
    price: null,
    change: null,
    changePercent: null,
    open: null,
    close: null,
    high: null,
    low: null,
    volume: null,
    valuesOverride: false,
    showAdditionalStats: false,
    startingValue: null
}));

export const getSecurityValuesOverrideNonReactive = () => useSecurityValuesStore.getState().valuesOverride;

export const setSecurityValuesOverride = (valuesOverride: boolean) => {
    const store = useSecurityValuesStore;
    const prevState = store.getState();

    store.setState({
        ...prevState,
        valuesOverride
    });
};

export const useShowAdditionalStats = () => useSecurityValuesStore((s) => s).showAdditionalStats;
export const getShowAdditionalStatsNonReactive = () => useSecurityValuesStore.getState().showAdditionalStats;

export const setShowAdditionalStats = (showAdditionalStats: boolean) => {
    const store = useSecurityValuesStore;
    const prevState = store.getState();

    store.setState({
        ...prevState,
        showAdditionalStats
    });
};

export const useChangePercent = () => useSecurityValuesStore((s) => s).changePercent;
export const getChangePercentNonReactive = () => useSecurityValuesStore.getState().changePercent;

export const useSecurityValues = (symbol?: string, range?: ChartRange): ISecurityValues => {
    const store = useSecurityValuesStore((s) => s);
    const assetClass = useAssetClass(symbol);
    const quote = assetClass?.derivative === 'option' ? XS.OptionQuotes.use(symbol) : XS.Quotes.use(symbol);
    const allCharts =  useSnexStore((s) => s.securityChart.bySymbol[symbol]);
    const streamingChartData = XS.SecurityCharts.use(symbol) || [];
    const historicalChartData = allCharts?.[range]?.data || [];
    const chart = streamingChartData.sort((a,b) => b.timestamp - a.timestamp).concat(historicalChartData);
    const latestPoint = chart?.[0];
    const price = latestPoint?.latestPrice || quote?.price 

    if (store.valuesOverride) {
        return {
            price: store.price,
            change: store.change,
            changePercent: store.changePercent,
            open: store.open,
            close: store.close,
            high: store.high,
            low: store.low,
            volume: store.volume
        };
    }

    const { changeValue, changePercentValue } = getChangeValues(store.startingValue, price);

    // Most of the time, the most recent streaming chart data will be the same as the quote data
    // However, there are situations (like equities pre-market) where the quote data will differ from the most recent chart point
    // In this case, prefer to use chart data over quote data or the scrub value of the most recent point
    // Will not match the value shown when not scrubbing

    const result = {
        price,
        change: store?.startingValue ? changeValue : quote?.changePercent,
        changePercent: store?.startingValue ? changePercentValue : quote?.changePercent * 100,
        open: latestPoint?.open || quote?.open,
        close: latestPoint?.close || quote?.close,
        high: latestPoint?.high || quote?.high,
        low: latestPoint?.low || quote?.low,
        volume: latestPoint?.volume || quote?.volume
    };

    return result;
};

export const setSecurityValues = (values: ISecurityValues) => {
    const store = useSecurityValuesStore;
    const prevState = store.getState();

    store.setState({
        ...prevState,
        ...values
    });
};

export const setStartingValue = (startingValue?: number) => {
    const store = useSecurityValuesStore;
    const prevState = store.getState();
    store.setState({
        ...prevState,
        startingValue
    });
};

export function getChangeValues(starting: number, current: number): { changeValue: number; changePercentValue: number } {
    const changeValue = current - starting === 0 ? 0.0 : current - starting;
    let changePercentValue = (starting ? (current - starting) / starting : 0) * 100;

    if (starting < 0) changePercentValue = changePercentValue * -1;

    return { changeValue, changePercentValue };
}
