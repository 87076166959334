// @ts-strict-ignore
import { Info } from '@mui/icons-material'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Flex } from '..'
import { StorageKeys } from 'phoenix/constants';
import { AllowedAmexCountryCodes, AmexAccountBalanceRanges } from 'phoenix/constants/Amex';
import { useShowAmexCardModal } from 'hooks/UseShowAmexCardModal';
import { GetAccountSummaryAction, GetClientAccountsAction } from 'phoenix/redux/actions';
import { useLocalStorage } from 'hooks/UseLocalStorage';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { GetVariant } from 'phoenix/util/Variant';
import { SnexBadge } from '../SnexBadge'
import { CreditCardIcon } from './CreditCardIcon';
import { createSelector } from 'reselect';
import { GlobalState } from 'phoenix/redux/GlobalState';

const makeSelectAllAcctsByNumber = () =>
    createSelector(
        (state: GlobalState) => state,
        (_: any) => null,
        (state) => Object.values(state.accountSummary.byNumber)
    );

export const AmexCardAlert = React.memo(() => {

    const { showAmexMessage } = GetVariant();
    if (!showAmexMessage) return null;

    const selectAllAccountsbyNumber = useMemo(makeSelectAllAcctsByNumber, []);

    const dispatch = useDispatch();
    const [showAmexModal, setShowAmexModal] = useShowAmexCardModal();
    const [hideAmexOfferModal, _] = useLocalStorage(StorageKeys.HideAmexOfferModal, false);
    const accountSummaries: { [symbol: string]: any } = useSnexStore(s => selectAllAccountsbyNumber(s));
    const clientAccounts = useSnexStore(s => s.accounts.all)
    const summariesLoading = accountSummaries.some(a => (a.loading || a.pristine))
    const loading = useMemo(() => (clientAccounts.loading || clientAccounts.pristine || summariesLoading), [clientAccounts.loading, clientAccounts.pristine, summariesLoading])

    useEffect(() => {
        dispatch(GetClientAccountsAction())
        dispatch(GetAccountSummaryAction())
    }, [])

    const accountNumbersInAllowedCountries = useMemo(() => {
        return !loading && !!clientAccounts.data?.length ? new Set(clientAccounts.data.filter(a => AllowedAmexCountryCodes.has(a.countryCode)).map(a => a.accountNumber)) : new Set([])
    }, [loading])

    // Had to go around using a useMemo here because I spent way too much time trying to get it to recognize the byNumber substate changes...
    const highestValueAccount = (() => {
        const summaryEntries = accountSummaries.filter(a => a?.data && accountNumbersInAllowedCountries.has(a.data.accountNumber))
        if (summaryEntries.every(a => !a.loading && !a.pristine)) return summaryEntries.sort((a, b) => b?.data?.totalAccountValueCurrent - a?.data?.totalAccountValueCurrent)
        else return [];
    })()[0] || null

    if (!showAmexModal && (hideAmexOfferModal || (!highestValueAccount?.data || highestValueAccount?.data?.totalAccountValueCurrent < AmexAccountBalanceRanges.green.min))) return null

    return (
        <Flex center onClick={(e) => { e.stopPropagation(); setShowAmexModal(true) }}>
            <SnexBadge flavor='orange' iconComponent={Info} style={{ padding: '10px' }}>
                <span style={{ marginRight: 5 }}>Try AMEX Today</span> <CreditCardIcon />
            </SnexBadge>
        </Flex>
    )
})