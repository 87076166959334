// @ts-strict-ignore

import AnimatedNumber from 'animated-number-react';
import { ChangeBar } from 'components/ChangeBar/ChangeBar';
import { Flex } from 'components/Flex';
import { InfoIcon } from 'components/InfoIcon';
import { OutageBanner } from 'components/OutageBanner';
import { AccountValuesViewModel, IAccountValues } from 'components/PortfolioChartSection/AccountValues/AccountValuesViewModel';
import { ChartRange } from 'phoenix/constants';
import { useText } from 'phoenix/hooks/UseText';
import { ChangeColor, FormatNumber, toPercent2 } from 'phoenix/util';
import React, { memo, ReactElement } from 'react';

export const AccountValues = ({ accountNumber, range }
    : { accountNumber: string, range: ChartRange }): ReactElement => {
    const values: IAccountValues = AccountValuesViewModel.getValues(accountNumber, range);
    const accountValuesInfoText = useText((s) => s.portfolioScreen.glance.portfolioValue);
    const changeColor = ChangeColor(values.changePercent || values.change);

    return <AccountValuesView value={values.value} changeValue={values.change} percentChange={values.changePercent} changeColor={changeColor} infoText={accountValuesInfoText} />
}

export const AccountValuesView = memo(({ value, changeValue, percentChange, changeColor, infoText }
    : { value: number, changeValue: number, percentChange: number, changeColor: string, infoText: string }) => {

    return <Flex row align='flex-start'>
        <div id='portfolio-value-wrapper' style={{ minHeight: 61 }}>
            <div className='portfolio-value' style={{ fontSize: 32 }}>
                <AnimatedNumber duration={300} formatValue={(v) => FormatNumber.toMoney(v)} value={value} />
            </div>
            <ChangeBar
                change={FormatNumber.toLocaleDecimal({ value: changeValue })}
                color={changeColor}
                percent={toPercent2(percentChange)}
                spun={percentChange < 0}
            />
        </div>
        <InfoIcon messagePath={(s) => s.portfolioValue} title={infoText} />
        <OutageBanner />
    </Flex>
});