// @ts-strict-ignore
import { ApiData } from '../../models';
import { ReduxAction } from '../../models/ReduxAction';
import { QuoteAttribute } from '../../util';
import { Actions, GroupNameChecker } from '../actions/Constants';
import { SecurityQuote } from '../models';
import { FuturesSymbol } from '../models/Futures/FuturesSymbol';
import { SecurityQuoteState } from '../models/SecurityQuote/SecurityQuoteState';

const permitted = GroupNameChecker([
    Actions.Options.GetChain,
    Actions.Options.GetSingleQuote,
    Actions.Securities.Quote.SpreadLiveUpdates,
    Actions.Options.StreamQuotes.Update,
    Actions.Securities.Dom.LiveUpdates.Update,
    Actions.Securities.Quote,
    Actions.Securities.GetMultiSecurityQuoteBatch,
    Actions.Securities.Chart.LiveUpdates.Update,
    Actions.Securities.GetMetadata.Success
]);
export const SecurityQuoteReducer = (state: SecurityQuoteState = new SecurityQuoteState(), action: ReduxAction): SecurityQuoteState => {
    if (!permitted(action)) return state;

    const quoteData: ApiData<SecurityQuote | null> = state.bySymbol[action.subject?.symbol] || new ApiData(null);

    const multiSymbolQuoteData: { [key: string]: ApiData<SecurityQuote | null> } = action.subject?.symbols
        ? action.subject?.symbols.reduce((f, c) => ({ ...f, [c]: state.bySymbol[c] || new ApiData(null) }), {})
        : null;

    switch (action.type) {
        case Actions.Options.GetSingleQuote.Loading:
        case Actions.Securities.Quote.Get.Loading:
            if (typeof action.subject !== 'string') return state;
            return { ...state, bySymbol: { ...state.bySymbol, [action.subject]: quoteData.startLoading(quoteData.data) } };

        case Actions.Options.GetSingleQuote.Success:
        case Actions.Securities.Quote.Get.Success:
            if (Array.isArray(action.data)) {
                const reqIds = Array.isArray(action.subject) ? action.subject : [];
                const resIds = new Set(
                    Object.values(action.data as { symbol: string; quote: any }[])
                        .filter((x) => !!x?.quote)
                        .map((x) => x.symbol)
                );
                const missing = reqIds.filter((s) => !resIds.has(s));
                let newBySymbol = action.data.reduce((lookup, next: { symbol: string; quote: SecurityQuote }) => {
                    return { ...lookup, [next.symbol]: new ApiData(null).succeeded(next.quote) };
                }, {});
                newBySymbol = {
                    ...newBySymbol,
                    ...missing.reduce(
                        (lookup, next: string) => ({
                            ...lookup,
                            [next]: new ApiData(null).failed({})
                        }),
                        {}
                    )
                };
                return { ...state, bySymbol: { ...state.bySymbol, ...newBySymbol } };
            } else {
                if (!QuoteAttribute.getTime(action.data)) action.data = { ...action.data, timestamp: new Date().getTime() };
                return { ...state, bySymbol: { ...state.bySymbol, [action.subject]: new ApiData(null).succeeded(action.data) } };
            }

        // TODO -- We need multi-symbol failure handling
        case Actions.Options.GetSingleQuote.Failure:
        case Actions.Securities.Quote.Get.Failure:
            return { ...state, bySymbol: { ...state.bySymbol, [action.subject]: quoteData.failed(action.error, true) } };

        case Actions.Securities.GetMultiSecurityQuoteBatch.Loading:
            return {
                ...state,
                bySymbol: { ...state.bySymbol, ...Object.entries(multiSymbolQuoteData).reduce((f, c) => ({ ...f, [c[0]]: c[1].startLoading(c[1].data) }), {}) }
            };

        case Actions.Securities.GetMultiSecurityQuoteBatch.Success:
            return {
                ...state,
                bySymbol: {
                    ...state.bySymbol,
                    ...Object.entries(multiSymbolQuoteData).reduce(
                        (f, c) => ({ ...f, [c[0]]: c[1].succeeded(action.data.find((q) => q.symbol === c[0])?.quote || null) }),
                        {}
                    )
                }
            };
        case Actions.Securities.Dom.LiveUpdates.Update: {
            const { symbol: domSymbol }: { symbol: string } = action.subject;
            return {
                ...state,
                domBySymbol: { ...state.domBySymbol, [domSymbol]: (state.domBySymbol[domSymbol] || new ApiData()).succeeded(action.data) }
            };
        }
        
        case Actions.Securities.Quote.SpreadLiveUpdates.Update:
        case Actions.Options.StreamQuotes.Update:
        case Actions.Securities.Chart.LiveUpdates.Update: {
            const { symbol }: { symbol: string } = action.subject;

            // if future remove close/open/low/high, we don't want the quote fields to be replace with the gain bar fields
            if (FuturesSymbol.IsFuturesSymbol(symbol)) {
                delete action.data.close;
                delete action.data.open;

                if (state.bySymbol[symbol]?.data?.high > action.data.high) {
                    delete action.data.high;
                }

                if (state.bySymbol[symbol]?.data?.low < action.data.low) {
                    delete action.data.low;
                }
            }

            const nonNaNData = Object.entries(action.data).reduce((f, c) => {
                if (isNaN(c[1] as number)) return f;
                return { ...f, ...{ [c[0]]: c[1] } };
            }, {});
            return {
                ...state,
                bySymbol: { ...state.bySymbol, [symbol]: state.bySymbol[symbol]?.succeeded({ ...state.bySymbol[symbol]?.data, ...nonNaNData }) }
            };
        }

        default:
            return state;
    }
};
