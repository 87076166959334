// @ts-strict-ignore
import React, { useEffect, useState, useMemo } from 'react';
import { Flex, Watchlists, AccountList, News, PortfolioGlance, PortfolioChartSection, PositionsList, OpenOrders, TopDisplay, SectorCardSection } from 'components';
import style from './style.module.scss';
import { GetClientAccountsAction } from 'phoenix/redux/actions';
import { GetHidePositionSticker } from 'phoenix/util';
import { Prestage } from 'components/Prestage/Prestage';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { WithSidebarLayout } from 'components/Layouts/WithSidebarLayout';
import { SnexTour, SnexTours } from 'components/SnexTour/SnexTour';
import { ProgressNames } from 'phoenix/constants/ProgressNames';
import { PostFundedPortfolioTour, PreFundedPortfolioTour, TicketBarTour } from 'constants/Tours';
import { AccountSummaryAndDonut } from 'components/AccountSummaryAndDonut/AccountSummaryAndDonut';
import { DisclosureFooter } from 'components/DisclosureFooter/DisclosureFooter';
import { LiveDataNamespaces } from 'phoenix/constants/LiveDataNamespaces';
import { useTelemetry } from 'hooks/UseTelemetry';
import { TelemetryProvider } from 'providers/TelemetryContext';
import { TelemetryCategories } from 'constants/Telemetry/TelemetryCategories';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { XS } from 'phoenix/xstream/XS';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import { BuyingPowerV2 } from 'components/BuyingPower/BuyingPowerV2';
import { FullyPaidLendingBanner } from 'components/FullyPaidLending/FullyPaidLendingBanner';
import { OneProNetworkSidebar } from 'components/OneProNetwork/components/sidebar/OneProNetworkSidebar';
import { FeatureFlags } from 'phoenix/constants/FeatureFlags';
import { useFeatureFlag } from 'phoenix/hooks/UseFeatureFlag';


/**
 * Also referred to as "Summary Screen", this is the primary screen the authenticated user sees when wanting a holistic view of their accounts and market information.
 *
 * To review other routes, you can {@link Routes | go to /src/constants/Routes.tsx}.
 *
 * ```
 * <PortfolioScreen />
 * ```
 */
const PortfolioScreen = React.memo(() => {
    const oneProFlagEnabled = useFeatureFlag(FeatureFlags.OneProNetwork);
    const [skipToWatchlist, setSkipToWatchlist] = useState<HTMLButtonElement>(null);
    const allAccounts = useSelector((s: GlobalState) => s.accounts.all);
    const allFutures = useMemo(() => allAccounts?.data?.every((a) => QualifiedId.Class(a.accountNumber) === 'futures'), [allAccounts.data]);

    const singleEquityAccountNumber = useMemo(() => allAccounts.data.filter((account) => account.source === 'Vulcan')[0]?.accountNumber, [allAccounts.data]);

    const watchlists = useSnexStore((s) => s.watchlists.all);

    const dispatch = useDispatch();
    const LogEvent = useTelemetry();
    const myInfo = useSelector((s: GlobalState) => s.user.myInfo);
    const { data } = myInfo;
    const text = useText((t) => t.portfolioScreen);

    useEffect(() => {
        dispatch(GetClientAccountsAction());

        return () => {
            XS.stopNs(LiveDataNamespaces.SecurityCellPortfolio);
        };
    }, [dispatch]);

    const handleAccountSelect = () => LogEvent('Existing Account (Summary Screen) select');

    useEffect(() => {
        const firstWatchlist = document.querySelectorAll('[data-watchlist]')?.[0] as HTMLButtonElement;
        if (firstWatchlist) setSkipToWatchlist(firstWatchlist);
    }, [watchlists]);

    const SkipLink = () => (
        <div className='skip-link'>
            <button onClick={() => skipToWatchlist?.focus()} type='button'>
                {text.skipToWatchlists}
            </button>
        </div>
    );

    if (!data?.hasFunded) {
        return (
            <WithSidebarLayout
                sidebar={
                    <div id='tour-02-watchlist' tabIndex={0}>
                        <Prestage delayShowMs={800}>
                            <Watchlists streamingNamespace={LiveDataNamespaces.SecurityCellPortfolio} />
                        </Prestage>
                    </div>
                }
                sidebarPadding={0}
            >
                <Flex column className={style.portfolioMainWrapper}>
                    <SkipLink />
                    <PortfolioChartSection />
                    <TopDisplay />
                    <SectorCardSection />
                    {allFutures ? <News topic='account' accountNumber={allAccounts.data[0]?.accountNumber} /> : <News topic='market' />}
                    <DisclosureFooter featuresAndBalances securityPricing clientExtras />
                </Flex>
                <SnexTour progressName={ProgressNames.tours.portfolioScreen} steps={PreFundedPortfolioTour()} />
            </WithSidebarLayout>
        );
    }

   

    return (
        <WithSidebarLayout
            sidebarId='tour-02-watchlist'
            noBottomGutter
            sidebar={
                <div>
                    {oneProFlagEnabled && <OneProNetworkSidebar /> }
                    {!GetHidePositionSticker() && (
                        <Prestage delayShowMs={700}>
                            <PositionsList streamingNamespace={LiveDataNamespaces.SecurityCellPortfolio} />
                        </Prestage>
                    )}
                    {!GetHidePositionSticker() && (
                        <Prestage delayShowMs={800}>
                            <Watchlists streamingNamespace={LiveDataNamespaces.SecurityCellPortfolio} />
                        </Prestage>
                    )}
                </div>
            }
            sidebarBottomSpacing={110}
            sidebarPadding={0}
        >
            <Flex column style={{ marginBottom: 100 }}>
                {!GetHidePositionSticker() && <SkipLink />}
                <PortfolioChartSection />
                <FullyPaidLendingBanner />
                <AccountList autoload useExpand range='1d' onAccountSelect={handleAccountSelect} />
                <OpenOrders status='Open' accountNumber='all' />
                <BuyingPowerV2 accountNumber={singleEquityAccountNumber} />
                <AccountSummaryAndDonut showProjectedIncomeLink />
                <TopDisplay />
                <SectorCardSection />
                <News topic='market' />
                <DisclosureFooter featuresAndBalances miScreen securityPricing clientExtras />
            </Flex>
            <PortfolioGlance />
            <SnexTours
                tours={[
                    {
                        progressName: ProgressNames.tours.portfolioScreen,
                        steps: PostFundedPortfolioTour()
                    },
                    {
                        progressName: ProgressNames.tours.tickerBarTour,
                        steps: TicketBarTour()
                    }
                ]}
            />
        </WithSidebarLayout>
    );
});

export default TelemetryProvider(PortfolioScreen, TelemetryCategories.summary);
