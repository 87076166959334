import { Link, Typography } from "@mui/material";
import classNames from "classnames";
import React, { useEffect, useMemo } from "react";
import { Flex } from "..";
import { FormattedText, FormattedTextList, Snex1LanguagePack } from "phoenix/assets/lang/Snex1LanguagePack";
import './FormattedTextDisplay.scss';

export const FormattedTextDisplay = React.memo(({ text }: { text: FormattedText | FormattedTextList } ) => {
    const src = !Array.isArray(text) ? [ [ (text as FormattedText) ] ] : text as FormattedTextList;

    return (
        <Flex column>
            { src.map((paragraph, i) => (
                <div className='formatted-text-paragraph' key={i}>
                    { paragraph.map(phrase => (
                        <FormattedTextPhrase key={phrase?.text} t={ phrase } />
                    )) }
                </div>
            )) }
        </Flex>
    )
})

const FormattedTextPhrase = React.memo(({ t }: { t: FormattedText }) => {
    const classes = useMemo(() => classNames({
        'formatted-text': true,
        'formatted-text-title': t?.size === 'title',
        'formatted-text-large': t?.size === 'large',
        'formatted-text-small': t?.size === 'small',
        'formatted-text-bold': t?.weight === 'bold'
    }), [ t ]);

    if (!t?.text) return null;

    if (t.url) return (
        <Link
            href={ t.url }
            style={{ color: t.color }}
            className={ classes }
            underline="hover">
            { t.text }
        </Link>
    );

    return (
        <Typography style={{ color: t.color }} className={ classes }>
            { t.text }
        </Typography>
    )

})