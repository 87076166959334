import { Flex } from 'components'
import { VideoPlayer } from 'components/OneProNetwork/components/videoPlayer/videoPlayer'
import { useModalVideoPlayerStore } from 'components/OneProNetwork/components/videoPlayer/videoPlayerHelpers'
import { SnexButton } from 'components/SnexButton'
import React, { useEffect } from 'react'
import PortfolioScreen from './PortfolioScreen/PortfolioScreen'


export const NeilDebugScreen = (): JSX.Element => {



  return (
      <PortfolioScreen />

    
  )
}









