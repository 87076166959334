// @ts-strict-ignore
import algoliasearch from 'algoliasearch';
import Axios from 'axios';
import { IsProduction, ResolveEnvironment, resolveGainLsAppId, Urls } from 'phoenix/constants';
import { GetConfig, SetConfig } from 'phoenix/constants/Config';
import { DEBUG_JWT_KEY, StorageKeys } from 'phoenix/constants/StorageKeys';
import { SetChaosCoefficient } from 'phoenix/redux/Chaos';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { ConfigureDefaultSecurityType, GetEnableDebugLogging, GetJwtEmail, Jwt } from 'phoenix/util';
import { ErrorLogger } from 'phoenix/util/ErrorLogger';
import { Lightstreamer } from 'phoenix/util/LightstreamerClientManager';
import TagManager from 'react-gtm-module';
import { Store } from 'redux';
import { GetQueryParam } from 'util/UrlHelpers';
import { SetMemo } from 'phoenix/util/Memo';
import uuid from 'uuid-random';
import { WebLsBackEnd } from './WebLsBackEnd';
import { Whitelabel } from 'phoenix/constants/Whitelabels';
import * as Sentry from '@sentry/react';
import algoliainsights from 'search-insights';
import { SnexAxios } from 'phoenix/stores/AxiosHelpers';
import { FeatureFlag } from 'phoenix/redux/models/FeatureFlags/FeatureFlag';
import { FeatureFlags } from 'phoenix/constants/FeatureFlags';
import { BrowserTracing } from '@sentry/react';


export const Initialize = async (store: Store<GlobalState>): Promise<void> => {

    // Figure out our environment
    const environment = ResolveEnvironment() || 'production';
    ErrorLogger.Init(Sentry, 'web', { environment, integrations: [
        new BrowserTracing(),
    ]});
    
    if (GetEnableDebugLogging()) console.log('environment: ', environment);
    const authScheme = (() => {
        const schemeOverride = GetQueryParam('auth-scheme')?.replace(/\?$/, ''); // Sometimes they put an extra question mark on the end
        if (!!schemeOverride && !IsProduction()) {
            console.log(`Accepted Scheme Override: ${schemeOverride}`);
            return schemeOverride;
        }
        return environment === 'development' || environment === 'local' ? 'jwt' : 'cookies';
    })();

    const sessionId = uuid();
    SetMemo('SESSION_ID', sessionId);

    if (window.location.search.indexOf('chaos') !== -1) SetChaosCoefficient(0.5);

    // Init Google Tag Manager
    TagManager.initialize({ gtmId: 'GTM-N8ZJNGM' });

    const plaidEnv = (() => {
        switch (environment) {
            case 'beta':
            case 'staging':
            case 'production':
            case Whitelabel.Aiva:
            case Whitelabel.Galicia:
            case Whitelabel.Winexco:
            case Whitelabel.FolioNet:
            case Whitelabel.AvSecurities:
            case Whitelabel.ConosurInversiones:
            case 'pro':
                return 'production';
            case 'local':
            case 'development':
            case 'test':
            default:
                return 'sandbox';
        }
    })();

    // Initialize our static config
    const savedUserJwt = localStorage.getItem(DEBUG_JWT_KEY);

    SetMemo(StorageKeys.JwtMemo, savedUserJwt);

    SetConfig({
        ApiEnvironment: environment,
        TokenRetrievalMethod: 'api',
        AuthenticationScheme: authScheme as any,
        UserIdToken: savedUserJwt,
        Lightstreamer: {
            snex: {
                AdapterSet: 'PROXY_SOCKET_ROBUST',
                Host: (() => {
                    switch (environment) {
                        case 'pro':
                        case 'production':
                        case Whitelabel.Aiva:
                        case Whitelabel.Galicia:
                        case Whitelabel.Winexco:
                        case Whitelabel.AvSecurities:
                        case Whitelabel.FolioNet:
                        case Whitelabel.ConosurInversiones:
                            return 'https://one.stonex.com';
                        case 'beta':
                        case 'staging':
                            return 'https://one.staging.stonex.com';
                        case 'test':
                        case 'development':
                        case 'local':
                            return 'https://one.test.stonex.com';
                        default: {
                            const exhaustiveCheck: never = environment;
                            console.warn(`Unhandled environment: ${exhaustiveCheck}`);
                        }
                    }
                })()
            },
            gain: {
                AdapterSet: (() => {
                    switch (environment) {
                        case 'pro':
                        case 'production':
                        case 'beta':
                        case 'staging':
                        case Whitelabel.Aiva:
                        case Whitelabel.Galicia:
                        case Whitelabel.Winexco:
                        case Whitelabel.AvSecurities:
                        case Whitelabel.FolioNet:
                        case Whitelabel.ConosurInversiones:
                        case 'test':
                        case 'development':
                        case 'local':
                            return 'Futures';
                        default: {
                            const exhaustiveCheck: never = environment;
                            console.warn(`Unhandled environment: ${exhaustiveCheck}`);
                        }
                    }
                })(),
                Host: (() => {
                    switch (environment) {
                        case 'pro':
                        case 'production':
                        case 'beta':
                        case 'staging':
                        case Whitelabel.Aiva:
                        case Whitelabel.Galicia:
                        case Whitelabel.Winexco:
                        case Whitelabel.AvSecurities:
                        case Whitelabel.FolioNet:
                        case Whitelabel.ConosurInversiones:
                            return 'https://futures-lightstreamer-prod.stonex.com/';
                        case 'development':
                        case 'test':
                        case 'local':
                            // return 'https://dev-ls.gainfutures.com'; // Gain dev (legacy)
                            // return 'https://futures-lightstreamer-dev.stonex.com/'; // Gain dev env
                            return 'https://futures-lightstreamer-api.stonex.com/'; // Gain test env
                        default: {
                            const exhaustiveCheck: never = environment;
                            console.warn(`Unhandled environment: ${exhaustiveCheck}`);
                        }
                    }
                })()
            }
        },
        Plaid: {
            Handler: (window as any).Plaid,
            PublicKey: 'ce0394b8afd21cdd30bb5785ceb36f',
            Environment: plaidEnv,
            Products: ['auth', 'identity', 'balance']
        },
        Store: store,
        // @ts-ignore
        ZenDesk: window.zE
    });

    Lightstreamer.SetBackEnd(new WebLsBackEnd());

    // Set up Lightstreamer if debug credentials are there...
    if (savedUserJwt) {
        const gainLsAppId = resolveGainLsAppId();
        SetConfig({ UserIdToken: savedUserJwt });
        Lightstreamer.SetUser('snex', savedUserJwt, sessionId);
        Lightstreamer.SetUser('gain', `${Jwt.TryGetSecureAuthCdsId(savedUserJwt)}:${gainLsAppId}`, savedUserJwt);
    }

    // Install our Axios interceptors
    Axios.interceptors.request.use(async (config) => {
        const c = GetConfig();

        // I think this (.withCredentials) makes sure that Axios sends the auth cookie, I wouldn't touch it if I were you.
        if (c.AuthenticationScheme === 'cookies') config.withCredentials = true;

        // We fetch and attach JWTs in redux/actions/helpers.ts, but if we didn't attach one there
        // then do it here with the stored one.
        if (!config.headers.Authorization) config.headers.Authorization = `Bearer ${c.UserIdToken}`;

        // Attach a sessionId along with every request
        config.headers['X-Debug-Session-Id'] = sessionId;

        // Done! Continue processing the request
        return config;
    });

    Axios.interceptors.response.use(
        (c) => c,
        (error) => {
            throw error?.response?.data && error?.response?.data?.errorCode ? error?.response?.data : error;
        }
    );

    try {
        const url = Urls.algolia.generateKey();
        const algoliaConfigResponse: AlgoliaKeyResType = await SnexAxios.ApiGet<AlgoliaKeyResType>(url).run();
        const flags: FeatureFlag[] = await SnexAxios.ApiGet<FeatureFlag[]>(Urls.featureFlags.getAll()).run();
        const useAi = flags.some(f => f.id === FeatureFlags.algoliaAi && f.enabled)
        const { appId, securedApiKey } = algoliaConfigResponse;
        const algolia = algoliasearch(appId, securedApiKey);

        algoliainsights('init', { appId, apiKey: securedApiKey });

        SetConfig({
            Algolia: {
                Client: algolia,
                SendInsight: algoliainsights,
                index: (() => {
                    switch (environment) {
                        case 'pro':
                        case 'production':
                        case 'beta':
                        case 'staging':
                        case Whitelabel.Aiva:
                        case Whitelabel.Galicia:
                        case Whitelabel.Winexco:
                        case Whitelabel.AvSecurities:
                        case Whitelabel.FolioNet:
                        case Whitelabel.ConosurInversiones:
                            return 'p_securities';
                        case 'development':
                        case 'test':
                        case 'local':
                            return useAi ? 'np_securities_ai' : 'np_securities';
                        default: {
                            const exhaustiveCheck: never = environment;
                            console.warn(`Unhandled environment: ${exhaustiveCheck}`);
                        }
                    }
                })()
            },
        });
    } catch (error) {
        console.error('Error fetching Algolia config:', error);
    }

    // Other miscellaneous configs
    ConfigureDefaultSecurityType('equity');
};

interface AlgoliaKeyResType {
    appId: string;
    securedApiKey: string;
}
