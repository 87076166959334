import { useColors } from 'hooks/UseColors'
import React from 'react'

export const CreditCardIcon = () => {
    const colors = useColors();

    return (
        <svg height='15px' style={{ paddingTop: 1 }} version='1.1' viewBox='0 0 19 15' width='19px' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
            <title>ic_payment</title>
            <g fill='none' fillRule='evenodd' id='Main-(Client)' stroke='none' strokeWidth='1'>
                <g id='Portfolio---WIth-Funds---Amex-Alert' transform='translate(-717.000000, -772.000000)'>
                    <g id='Portfolio-Content' transform='translate(55.000000, 115.000000)'>
                        <g id='AmexAlert' transform='translate(627.000000, 650.000000)'>
                            <g id='Shape-2' transform='translate(35.000000, 7.071115)'>
                                <path d='M15.3907079,1.61244993 L4.19746579,1.61244993 C3.42093462,1.61244993 2.80530631,2.25952833 2.80530631,3.06655869 L2.79831053,11.7912112 C2.79831053,12.5982416 3.42093462,13.24532 4.19746579,13.24532 L15.3907079,13.24532 C16.1672391,13.24532 16.7898632,12.5982416 16.7898632,11.7912112 L16.7898632,3.06655869 C16.7898632,2.25952833 16.1672391,1.61244993 15.3907079,1.61244993 L15.3907079,1.61244993 Z M15.3907079,11.7912112 L4.19746579,11.7912112 L4.19746579,7.42888495 L15.3907079,7.42888495 L15.3907079,11.7912112 L15.3907079,11.7912112 Z M15.3907079,4.52066744 L4.19746579,4.52066744 L4.19746579,3.06655869 L15.3907079,3.06655869 L15.3907079,4.52066744 L15.3907079,4.52066744 Z' fill={colors.goldenTicket} id='Shape' transform='translate(9.794087, 7.428885) rotate(-15.000000) translate(-9.794087, -7.428885) '></path>
                                <rect fill={colors.goldenTicketLight} height='11.63287' id='Rectangle' rx='2' width='13.9915526' x='0.189018444' y='2.83710246'></rect>
                                <path d='M12.5923974,3.06655869 L1.39915526,3.06655869 C0.622624093,3.06655869 0.00699577632,3.71363708 0.00699577632,4.52066744 L0,13.24532 C0,14.0523503 0.622624093,14.6994287 1.39915526,14.6994287 L12.5923974,14.6994287 C13.3689286,14.6994287 13.9915526,14.0523503 13.9915526,13.24532 L13.9915526,4.52066744 C13.9915526,3.71363708 13.3689286,3.06655869 12.5923974,3.06655869 L12.5923974,3.06655869 Z M12.5923974,13.24532 L1.39915526,13.24532 L1.39915526,8.88299371 L12.5923974,8.88299371 L12.5923974,13.24532 L12.5923974,13.24532 Z M12.5923974,5.9747762 L1.39915526,5.9747762 L1.39915526,4.52066744 L12.5923974,4.52066744 L12.5923974,5.9747762 L12.5923974,5.9747762 Z' fill={colors.goldenTicket} id='Shape'></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}