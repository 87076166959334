// @ts-strict-ignore
import { Error, Warning, FlashOn } from '@mui/icons-material';
import { Typography, Skeleton, Button, Tooltip } from '@mui/material';
import { AmexCardAlert } from 'components/AmexCardAlert';
import { ArrowLink } from 'components/ArrowLink';
import { CulledCollapse } from 'components/CulledCollapse/CulledCollapse';
import { Flex } from 'components/Flex';
import { InfoIcon } from 'components/InfoIcon';
import { SnexBadge } from 'components/SnexBadge';
import { Spinny } from 'components/Spinny';
import { TelemetryCategories } from 'constants/Telemetry/TelemetryCategories';
import { useTelemetry } from 'hooks/UseTelemetry';
import { useAppWindowSize, WindowSizes } from 'hooks/UseWindowSize';
import { T } from 'phoenix/assets/lang/T';
import { Urls } from 'phoenix/constants';
import { AccountRestrictionCodes } from 'phoenix/constants/AccountRestrictionCodes';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { GetClientAccountsAction, GetUserTokenAction } from 'phoenix/redux/actions';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { FormatNumber, GetMarginCall, GetNinetyDayRestriction, GoldenTicket } from 'phoenix/util';
import { TelemetryProvider } from 'providers/TelemetryContext';
import React, { useState, useEffect, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { useColors } from 'hooks/UseColors';
import { Link } from 'react-router-dom';
import { Routes } from 'util/Routes';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { FullyPaidLendingIcon } from 'components/FullyPaidLending/FullyPaidLendingIcon';
import { BuyingPowerStore_Load, useBuyingPowerStore } from 'phoenix/stores/BuyingPowerStore';
import './BuyingPower.scss';
import { MediantSSOButton } from 'components/MediantSSOButton/MediantSSOButton';
interface BuyingPowerProps {
    accountNumber?: string;
}

export const BuyingPowerV2 = TelemetryProvider((props: BuyingPowerProps) => {
    const { accountNumber } = props;
    const [expanded, setExpanded] = useState(false);
    const buyingPowerStore = useBuyingPowerStore();
    const aggregateBuyingPower = useMemo(() => buyingPowerStore?.summary, [buyingPowerStore?.summary]);
    const selectedAccountBuyingPower = useMemo(() => buyingPowerStore?.findByAccount(accountNumber), [buyingPowerStore, accountNumber]);
    const isLoading = useMemo(
        () => buyingPowerStore?.isLoading === true || (!aggregateBuyingPower && !selectedAccountBuyingPower),
        [aggregateBuyingPower, buyingPowerStore?.isLoading, selectedAccountBuyingPower]
    );
    const buyingPower = useMemo(
        () => (accountNumber ? selectedAccountBuyingPower : aggregateBuyingPower),
        [accountNumber, aggregateBuyingPower, selectedAccountBuyingPower]
    );

    const dispatch = useDispatch();

    useEffect(() => {
        GetUserTokenAction();
        BuyingPowerStore_Load();
        dispatch(GetClientAccountsAction());
    }, [dispatch]);

    const text = useText((s) => s);
    const { greenLight } = useColors();
    const deposit = T((s) => s.portfolioScreen.depositFunds);

    const isFutures = useMemo(() => FuturesSymbol.IsFuturesSymbol(accountNumber), [accountNumber]);
    const account = useSnexStore((s) => (accountNumber ? s.accounts.all.data?.find((a) => a.accountNumber === accountNumber) : null));
    const summary = useSnexStore((s) => s.accountSummary.byNumber[accountNumber]);
    const maintenanceCallAmount = useSnexStore((s) => (accountNumber ? s.accountSummary.byNumber[accountNumber]?.data?.maintenanceCallAmount : 0));
    const hasMaintenanceCall = useMemo(() => !!maintenanceCallAmount || (accountNumber && GetMarginCall()), [accountNumber, maintenanceCallAmount]);
    const hasNinetyDayRestriction = useMemo(
        () => summary?.data?.bopInfo?.dayNinetyRestriction || (accountNumber && GetNinetyDayRestriction()),
        [accountNumber, summary?.data?.bopInfo?.dayNinetyRestriction]
    );
    const expiration = useMemo(() => hasNinetyDayRestriction && summary?.data?.bopInfo?.dayNinetyExpirationDate, [hasNinetyDayRestriction, summary?.data?.bopInfo?.dayNinetyExpirationDate])
    const hasTraderRestriction = useMemo(() => account && account.restrictionCode === AccountRestrictionCodes.stonexTraderRestriction, [account]);
    const hasFullyPaidLending = account?.fplStatus === 'Approved';
    const hasDayTrader = useMemo(() => summary?.data?.bopInfo?.dayTrader, [summary]);
    const futuresInitialMargin = useMemo(() => buyingPower?.futuresInitialMargin, [buyingPower?.futuresInitialMargin]);
    const nonMarginAccounts = useSnexStore((s) => s.accounts.all.data?.filter((a) => a.marginAgreementOnFile !== 'YES'));
    const accountToMargin = useMemo(() => {
        if (accountNumber) return nonMarginAccounts.some((a) => a.accountNumber === accountNumber) ? accountNumber : null;
        else if (buyingPowerStore?.allAccounts?.length === 1) return nonMarginAccounts[0]?.accountNumber;
        else return null;
    }, [accountNumber, buyingPowerStore?.allAccounts?.length, nonMarginAccounts]);

    return (
        <Flex column id={`buying-power-${accountNumber}-section`}>
            <Flex row className='account-summary-header' justify='space-between'>
                <Flex row align='center' style={{ minHeight: 40 }}>
                    <Typography className='account-summary-header-text' style={{ cursor: 'pointer' }} variant='h5' onClick={() => setExpanded(!expanded)}>
                        {text.portfolioScreen.buyingPower.buyingPower}
                    </Typography>
                    <InfoIcon messagePath={(s) => s.buyingPower} title={text.portfolioScreen.buyingPower.buyingPower} />
                    <FundingButton visible={!expanded} margin={'0 1rem 0 0'}>
                        {deposit}
                    </FundingButton>
                    {hasTraderRestriction && (
                        <SnexBadge flavor='orange' iconComponent={Warning} whiteSpace='normal'>
                            Pending Approval
                        </SnexBadge>
                    )}
                    {!!hasMaintenanceCall && !expanded && (
                        <SnexBadge flavor='orange' iconComponent={Warning} whiteSpace='normal'>
                            {text.general.maintenanceCall}: {FormatNumber.toMoney(maintenanceCallAmount)}
                        </SnexBadge>
                    )}
                    {hasNinetyDayRestriction && (
                        <SnexBadge flavor='red' iconComponent={Error} whiteSpace='normal'>
                            {text.general.ninetyDayRestriction.withDate(expiration)}
                        </SnexBadge>
                    )}
                    {!hasTraderRestriction && !!hasDayTrader && (
                        <Tooltip title={text.general.dayTradingFlag}>
                            <span>
                                <SnexBadge flavor='blue' iconComponent={FlashOn} whiteSpace='normal'></SnexBadge>
                            </span>
                        </Tooltip>
                    )}
                    {/* Created a "pseudo-badge" instead of using badge component since using FullyPaidLendingIcon would require refactoring the badge component */}
                    {hasFullyPaidLending && (
                        <Tooltip title={text.general.fplEnabled}>
                            <div
                                style={{
                                    backgroundColor: greenLight,
                                    height: 28,
                                    width: 45,
                                    borderRadius: 7,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    margin: '5px 10px'
                                }}
                            >
                                <FullyPaidLendingIcon height={25} width={25} loop={false} />
                            </div>
                        </Tooltip>
                    )}
                    <MediantSSOButton selectedAccount={accountNumber} />
                </Flex>
                <Flex row align='center' justify='flex-end' style={{ minHeight: 40 }} onClick={() => setExpanded(!expanded)}>
                    {!accountNumber && <AmexCardAlert />}
                    {!isLoading ? (
                        <Typography className='account-summary-header-text' style={{ marginRight: 20 }} variant='h5'>
                            {FormatNumber.toMoney(buyingPower?.buyingPower)}
                        </Typography>
                    ) : (
                        <Skeleton animation='wave' style={{ width: 200 }} />
                    )}
                    <Spinny spun={expanded}>
                        <KeyboardArrowUpIcon />
                    </Spinny>
                </Flex>
            </Flex>

            <CulledCollapse eventTag='Buying Power' in={expanded}>
                <Row className='account-summary-row' label={text.portfolioScreen.buyingPower.cashAvailable} loading={isLoading} value={buyingPower?.cashAvailable} />
                {isFutures && (
                    <Row
                        className='account-summary-row'
                        label={text.accountScreen.buyingPower.initialMargin}
                        loading={isLoading}
                        value={summary?.data?.initialMargin}
                    ></Row>
                )}
                {!accountNumber && (
                    <Row className='account-summary-row' label={text.accountScreen.buyingPower.initialMargin} loading={isLoading} value={futuresInitialMargin} />
                )}
                {isFutures && (
                    <Row
                        className='account-summary-row'
                        label={text.accountScreen.buyingPower.netLiquidity}
                        loading={isLoading}
                        value={summary?.data?.totalAccountValueCurrent}
                    ></Row>
                )}
                {!isFutures && (
                    <Row
                        className='account-summary-row-margin'
                        label={text.portfolioScreen.buyingPower.marginAvailable}
                        loading={isLoading}
                        value={buyingPower?.marginCash}
                    >
                        {accountToMargin && (
                            <ArrowLink
                                eventTag='Start Using Margin'
                                textStyle={{ color: GoldenTicket, fontSize: 15, fontWeight: 400 }}
                                onClick={() => window.open(Urls.registration.enableMargin(accountToMargin))}
                            >
                                {text.portfolioScreen.buyingPower.startUsingMargin}
                            </ArrowLink>
                        )}
                    </Row>
                )}
                {!isFutures && summary && !accountToMargin && (
                    <Row className='account-summary-row nyse-row' label='NYSE Excess' loading={isLoading} value={buyingPower?.nyseExcess || 0} />
                )}
                {hasMaintenanceCall && (
                    <Row className='account-summary-row maintenance-row' label={text.general.maintenanceCall} loading={isLoading} value={maintenanceCallAmount} />
                )}
                <Row
                    className='account-summary-total-row'
                    label={text.portfolioScreen.buyingPower.totalAvailableForTrading}
                    loading={isLoading}
                    value={buyingPower?.buyingPower}
                >
                    <FundingButton delayed visible={expanded}>
                        {deposit}
                    </FundingButton>
                </Row>
            </CulledCollapse>
        </Flex>
    );
}, TelemetryCategories.buyingPower);

const FundingButton = React.memo((props: { children: React.ReactChild; visible?: boolean; delayed?: boolean; margin?: number | string }) => {
    const { visible = true, delayed, margin } = props;
    const LogEvent = useTelemetry();

    const notVisibleStyles: React.CSSProperties = { opacity: 0, transform: 'translateY(1em)' };

    return (
        <Link to={Routes.funding()}>
            <Button
                className='green-btn'
                style={{ margin, transition: delayed ? '600ms' : '250ms', ...(!visible ? notVisibleStyles : {}) }}
                variant='contained'
                onClick={() => LogEvent('Deposit Funds button click')}
            >
                {props.children}
            </Button>
        </Link>
    );
});

const Row = React.memo((props: { label: string; value: number; className: string; children?: React.ReactChild; loading?: boolean }) => {
    const [appWindowSize] = useAppWindowSize();

    const summarySectionFlexValue = useMemo(() => {
        switch (appWindowSize) {
            case WindowSizes.tablet:
                return 1.5;
            default:
                return 1;
        }
    }, [appWindowSize]);

    return (
        <Flex row align='center'>
            <Flex row className={props.className} justify='space-between' style={{ flex: summarySectionFlexValue, paddingRight: 10 }}>
                <Typography className='account-summary-row-text' color='textSecondary'>
                    {props.label}
                </Typography>
                {!props.loading ? (
                    <Typography className='account-summary-row-figure'>{FormatNumber.toMoney(props.value)}</Typography>
                ) : (
                    <Skeleton animation='wave' style={{ width: 120 }} />
                )}
            </Flex>
            <Flex row justify='flex-start' style={{ flex: 1, marginLeft: 20 }}>
                {props.children}
            </Flex>
        </Flex>
    );
});
