// @ts-strict-ignore
import { T } from "phoenix/assets/lang/T"
import { OnboardingInputError, ValidateRequired } from "phoenix/onboarding/OnboardingTypes"
import { registrationEmailRegex, registrationPhoneRegex } from "screens/OnboardingScreens/Registration/Screens/UserInfo/Validation"
import { useFuturesOnboardingStore } from "../../Store"

export const ContactInfoIsValid = () => getContactInfoErrors()?.length < 1
export const getContactInfoErrors = () => {
    const keys = ['data.contactInfo.firstName', 'data.contactInfo.lastName', 'data.contactInfo.email', 'data.contactInfo.phone', 
        'data.contactInfo.street', 'data.contactInfo.street2', 'data.contactInfo.city', 'data.contactInfo.state', 'data.contactInfo.zip',
        'data.contactInfo.country']
    const store = useFuturesOnboardingStore.getState()
    const _errors = []  as OnboardingInputError[]
    keys.forEach(key => {
        switch(key) {
            case 'data.contactInfo.firstName':
            case 'data.contactInfo.lastName':
            case 'data.contactInfo.street':
            case 'data.contactInfo.city':
            case 'data.contactInfo.state':
            case 'data.contactInfo.zip':
            case 'data.contactInfo.country':
                _errors.push(ValidateRequired(store, key))
                break
            case'data.contactInfo.email':
                _errors.push(ValidateRequired(store, key))
                if(!registrationEmailRegex.test(store?.data?.contactInfo?.email)) _errors.push({key, error: T(t => t.webRegistration.errors.userInfo.invalidEmail)})
                break
            case 'data.contactInfo.phone':
                _errors.push(ValidateRequired(store, key))
                if(!registrationPhoneRegex.test(store.data?.contactInfo?.phone)) _errors.push({key, error: T(t => t.webRegistration.errors.userInfo.invalidPhone)})
                break


    }})
    return _errors.filter(e => !!e);
}