// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3ZT3IW2_p9fZzQHw3i6YQB{position:relative;background-position:center;background-size:cover;max-width:100%;border-radius:8px;overflow:hidden}._3ZT3IW2_p9fZzQHw3i6YQB ._2YgzJ-WpbRyy9JULpJb2ji{height:100%;width:100%;border-radius:8px;overflow:hidden;background:linear-gradient(to bottom, transparent 30%, #000);visibility:visible;position:absolute;top:0;left:0}._3ZT3IW2_p9fZzQHw3i6YQB .Pm28pSp8K5PxUR2OLbHiT{height:100%;width:100%;background-color:rgba(0,0,0,0);position:absolute;top:0;left:0;visibility:hidden;transition:300ms all ease;z-index:9999}._3ZT3IW2_p9fZzQHw3i6YQB:hover .Pm28pSp8K5PxUR2OLbHiT{background:linear-gradient(to bottom, transparent 30%, #000);visibility:visible}", ""]);
// Exports
exports.locals = {
	"in-frame-wrapper": "_3ZT3IW2_p9fZzQHw3i6YQB",
	"in-frame-video-overlay": "_2YgzJ-WpbRyy9JULpJb2ji",
	"in-frame-video-overlay-hidden": "Pm28pSp8K5PxUR2OLbHiT"
};
module.exports = exports;
