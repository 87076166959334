// @ts-strict-ignore
import { AppAuthorizationState } from 'AppRoot';
import { LoadingSpinner, ToastEmitter } from 'components';
import { SecurityChartsForMarketTimeManager } from 'components/Charting/MarketTimeChartManagers';
import { DebugModal } from 'components/DebugModal/DebugModal';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { Flex } from 'components/Flex';
import { Header } from 'components/Header';
import { NewMiniplayer } from 'components/Miniplayer/NewMiniplayer';
import { AmexCardModal } from 'components/Modals/AmexCardModal';
import { PremiumUpgradeModal } from 'components/Modals/PremiumUpgradeModal';
import SidePanel from 'components/Modals/SidePanel';
import { TradingViewMarquee } from 'components/TradingViewMarquee/TradingViewMarquee';
import 'components/TradingViewMarquee/TradingViewMarquee.css';
import { WebToken } from 'components/WebToken/WebToken';
import { WelcomeModal } from 'components/WelcomeModal/WelcomeModal';
import { useAppWindowSize, WindowSizes } from 'hooks/UseWindowSize';
import { FeatureFlags } from 'phoenix/constants/FeatureFlags';
import { ProductCode } from 'phoenix/constants/PremiumProducts';
import { useFeatureFlag } from 'phoenix/hooks/UseFeatureFlag';
import { useTier } from 'phoenix/hooks/UseTier';
import { CheckLoginStatusAction } from 'phoenix/redux/actions';
import { DebugDumpManager } from 'phoenix/util/DebugDumpManager';
import { ScrollPageToTop } from 'phoenix/util/Scroll';
import { GetVariant } from 'phoenix/util/Variant';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes as Switch, useLocation, useNavigate } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { Routes, SupportPage, SupportSubroute } from 'util/Routes';
import { AlexDebugScreen } from './AlexDebugScreen';
import DeniedScreen from './DeniedScreen/DeniedScreen';
import FuturesTimeSpreadDeeperView from './FuturesTimeSpreadDeeperView';
import { LocalStartScreen } from './LocalStartScreen/LocalStartScreen';
import LoggedOutScreen from './LoggedOutScreen/LoggedOutScreen';
import { MattDebugScreen } from './MattDebugScreen';
import { NeilDebugScreen } from './NeilDebugScreen';
import { AccountOnboardingSelectionScreen } from './OnboardingScreens/AccountOnboardingSelectScreen';
import { FullyPaidLendingOnboarding } from './OnboardingScreens/FullyPaidLending/Base';
import { FuturesOnboarding } from './OnboardingScreens/Futures/Base';
import { RegistrationOnboarding } from './OnboardingScreens/Registration/Base';
import { OptionContractScreen } from './OptionContractScreen';
import { RomanDebugScreen } from './RomanDebugScreen';
import { SectorScreen } from './SectorScreen/SectorScreen';
import { StyleGuideScreen } from './StyleGuideScreen';
import { YuliDebugScreen } from './YuliDebugScreen';
import { UseTickerTape } from 'phoenix/hooks/UseTickerTape';
import { JaredDebugScreen } from './JaredDebugScreen';
import { VideoPlayer } from 'components/OneProNetwork/components/videoPlayer/videoPlayer';
import { OneProNetworkScreen } from './OneProNetworkScreens/oneProNetworkScreen';
import { OneProAuthorScreen } from './OneProNetworkScreens/oneProAuthorScreen';

const WatchlistScreen = React.lazy(() => import('./WatchlistScreen'));
const DocumentsScreen = React.lazy(() => import('./DocumentsScreen/DocumentsScreen'));
const OptionsScreen = React.lazy(() => import('./OptionsScreen/OptionsScreen'));
const OrdersScreen = React.lazy(() => import('./OrdersScreen/OrdersScreen'));
const PortfolioScreen = React.lazy(() => import('./PortfolioScreen/PortfolioScreen'));
const ProfileScreen = React.lazy(() => import('./ProfileScreen/ProfileScreen'));
const RoutedSecurityScreen = React.lazy(() => import('./RoutedSecurityScreen/RoutedSecurityScreen'));
const TransactionsScreen = React.lazy(() => import('./TransactionsScreen/TransactionsScreen'));
const MessagesScreen = React.lazy(() => import('./MessagesScreen/MessagesScreen'));
const ViewMessageScreen = React.lazy(() => import('./ViewMessageScreen/ViewMessageScreen'));
const ProjectedIncomeScreen = React.lazy(() => import('./ProjectedIncomeScreen/ProjectedIncomeScreen'));
const NotFoundScreen = React.lazy(() => import('./NotFoundScreen/NotFoundScreen'));
const FundingScreen = React.lazy(() => import('./FundingScreen/Transfers/FundingScreen'));
const PremiumScreen = React.lazy(() => import('./PremiumScreen/PremiumScreen'));
const DeepAnalysisScreen = React.lazy(() => import('./DeepAnalysisScreen/DeepAnalysisScreen'));
const FuturesDeepAnalysisScreen = React.lazy(() => import('./DeepAnalysisScreen/FuturesDeepAnalysisScreen'));
const DisclosuresScreen = React.lazy(() => import('./DisclosuresScreen/DisclosuresScreen'));
const AdvancedChartScreen = React.lazy(() => import('./AdvancedChartScreen/AdvancedChartScreen'));
const AccountScreen = React.lazy(() => import('./AccountScreen/AccountScreen'));
const PositionsScreen = React.lazy(() => import('./PositionsScreen/PositionsScreen'));
const SupportScreen = React.lazy(() => import('./ProfileScreen/Support/SupportScreen'));
const FuturesSpreadsScreen = React.lazy(() => import('./FuturesSpreadsScreen'));

function RoutingComponent({ appAuthorizationState }: { appAuthorizationState: AppAuthorizationState }) {
    const [appWindowSize, setAppWindowSize] = useAppWindowSize();
    const location = useLocation();

    const dispatch = useDispatch();
    const resizeListener = useCallback(() => {
        if (window.innerWidth <= 1200 && appWindowSize !== WindowSizes.tablet) setAppWindowSize(WindowSizes.tablet);
        else if (window.innerWidth <= 1300 && appWindowSize !== WindowSizes.small) setAppWindowSize(WindowSizes.small);
        else if (window.innerWidth <= 1480 && appWindowSize !== WindowSizes.regular) setAppWindowSize(WindowSizes.regular);
        else if (window.innerWidth > 1480 && appWindowSize !== WindowSizes.large) setAppWindowSize(WindowSizes.large);
    }, [appWindowSize, setAppWindowSize]);

    useEffect(() => {
        resizeListener();
        window.onresize = resizeListener;
    }, []);

    useEffect(() => {
        dispatch(CheckLoginStatusAction());
    }, [dispatch, location]);

    const marginTop = useMemo(() => {
        switch (appWindowSize) {
            case WindowSizes.small:
            case WindowSizes.tablet:
            case WindowSizes.regular:
                return 132;
            case WindowSizes.large:
            default:
                return 72;
        }
    }, [appWindowSize]);

    const { permittedForDeepAnalysis } = useTier();
    const { showAdvancedChart, showOptions } = GetVariant();
    const showFuturesDeepAnalysis = true;

    const isFullScreenRoute = useMemo(() => {
        const fullScreenRoutes = [
            Routes.positions(),
            Routes.fplOnboarding(),
            '/onboarding',
            Routes.onboardingSelect(),
            '/neil',
            '/registration',
            Routes.deadLogin(),
            Routes.localLogin(),
            Routes.loggedOut()
        ];
        return fullScreenRoutes.some((x) => location?.pathname?.toLowerCase().includes(x.toString().toLowerCase()));
    }, [location.pathname]);

    const showSidePanel = useMemo(() => {
        const dontShowRoutes = [Routes.portfolio(), Routes.account('')];
        const fullScreenRouteExceptions = [Routes.positions()];
        const pathnameFilter = (x: string) => {
            // Every route is going to include a /, so check if the route is exactly the portfolio route if it's included
            if (x === Routes.portfolio()) {
                return location.pathname === x;
            } else return location.pathname.toLowerCase().includes(x.toString().toLowerCase());
        };
        return (!isFullScreenRoute && !dontShowRoutes.some((x) => pathnameFilter(x))) || fullScreenRouteExceptions.some((x) => pathnameFilter(x));
    }, [isFullScreenRoute, location?.pathname]);

    const tickerTapeEnabled = UseTickerTape()
    const showTickerTape = useMemo(() => {
        const fullScreenRouteExceptions = [Routes.positions()];
        return (
            tickerTapeEnabled && (!isFullScreenRoute || fullScreenRouteExceptions.some((x) => location?.pathname?.toLowerCase().includes(x.toString().toLowerCase())))
        );
    }, [isFullScreenRoute, location?.pathname, tickerTapeEnabled]);

    const showOnePro = useFeatureFlag(FeatureFlags.OneProNetwork);


    return (
        <>
            <ErrorBoundary>{!isFullScreenRoute && <Header />}</ErrorBoundary>
            <ErrorBoundary>
                <ScrollResetOnNavListener />
            </ErrorBoundary>
            <ErrorBoundary>
                <SecurityChartsForMarketTimeManager />
            </ErrorBoundary>
            <ErrorBoundary>
                <Flex column style={{ marginTop: !isFullScreenRoute ? marginTop : 0, flex: 1 }}>
                    <React.Suspense
                        fallback={
                            <Flex center style={{ width: '100%', height: '100%' }}>
                                <LoadingSpinner />
                            </Flex>
                        }
                    >
                        <Switch>
                            <Route element={<DeniedScreen />} path={Routes.deadLogin()} />
                            <Route element={<LoggedOutScreen />} path={Routes.loggedOut()} />
                            <Route element={<RegistrationOnboarding />} path={Routes.register(':step')} />
                            <Route element={<FullyPaidLendingOnboarding />} path={Routes.fplOnboarding(':step')} />
                            <Route element={<AccountOnboardingSelectionScreen />} path={Routes.onboardingSelect()} />
                            <Route element={<FuturesOnboarding />} path={Routes.accountOnboarding('futures', ':step')} />
                            <Route element={<PortfolioScreen />} path={Routes.portfolio()} />
                            <Route element={<FundingScreen />} path={Routes.funding()} />
                            <Route element={<AccountScreen />} path={Routes.account(':accountNumber')} />
                            <Route element={<RoutedSecurityScreen />} path={Routes.security(':securityId')} />
                            <Route element={<OptionContractScreen />} path={Routes.optionContract(':osiSymbol')} />
                            <Route element={permittedForDeepAnalysis ? <DeepAnalysisScreen /> : null} path={Routes.deepAnalysis(':symbol')} />
                            <Route element={showFuturesDeepAnalysis ? <FuturesDeepAnalysisScreen /> : <NotFoundScreen />} path={Routes.futuresDeepAnalysis(':symbol')} />
                            <Route element={showOptions ? <OptionsScreen /> : null} path={Routes.optionChain(':symbol')} />
                            <Route element={<FuturesSpreadsScreen />} path={Routes.futuresSpreads(':symbol', ':frontMonthSymbol?')} />
                            <Route element={<FuturesTimeSpreadDeeperView />} path={Routes.futuresTimeSpreadDeeperView(':symbol')} />
                            <Route element={<ProfileScreen />} path={Routes.profile()} />
                            <Route element={<ProfileScreen />} path={Routes.profile(':subroute')} />
                            <Route element={<ProfileScreen />} path={Routes.profile(':subroute/:adminSubroute')} />
                            <Route element={<DocumentsScreen />} path={Routes.documents()} />
                            <Route element={<DocumentsScreen />} path={Routes.documentPreview(':documentTypeId', ':documentId')} />
                            <Route element={<PositionsScreen />} path={Routes.positions()} />
                            <Route element={<WatchlistScreen />} path={Routes.watchlists(':watchlistId')} />
                            <Route element={<OrdersScreen />} path={Routes.orders()} />
                            <Route element={<TransactionsScreen />} path={Routes.transactions()} />
                            <Route element={<MessagesScreen />} path={Routes.messages()} />
                            <Route element={<ViewMessageScreen />} path={Routes.message(':messageId')} />
                            <Route element={<DisclosuresScreen />} path={Routes.disclosures()} />
                            <Route element={<ProjectedIncomeScreen />} path={Routes.projectedIncome()} />
                            <Route element={<PremiumScreen />} path={Routes.premium(':productCode' as ProductCode)} />
                            <Route element={<WebToken />} path={Routes.oktaToken()} />
                            <Route element={showOnePro? <OneProAuthorScreen /> : null} path={Routes.oneProNetworkAuthor(':authorId')} />
                            <Route element={showOnePro? <OneProNetworkScreen /> : null} path={Routes.oneProNetwork()} />
                            <Route element={<AlexDebugScreen />} path={Routes.alexland()} />
                            <Route element={<RomanDebugScreen />} path={Routes.romanEmpire()} />
                            <Route element={<NeilDebugScreen />} path={Routes.gillyLand()} />
                            <Route element={<MattDebugScreen />} path={Routes.mattropolis()} />
                            <Route element={<YuliDebugScreen />} path={Routes.yuli()} />
                            <Route element={<JaredDebugScreen />} path={Routes.jareDebug()} />
                            <Route element={<SectorScreen />} path={Routes.sectors(':sector')} />
                            <Route element={showAdvancedChart ? <AdvancedChartScreen /> : null} path={Routes.advancedChart(':symbol')} />
                            <Route element={<SupportScreen />} path={Routes.support(':subroute' as SupportSubroute, ':page' as SupportPage)} />
                            <Route element={<LocalStartScreen />} path={Routes.localLogin()} />
                            <Route element={<NotFoundScreen />} path='*' />
                        </Switch>
                    </React.Suspense>
                    <NewMiniplayer />
                    {!isFullScreenRoute && appAuthorizationState === AppAuthorizationState.Allowed ? <WelcomeModal /> : null}
                    {/* <TermsAndConditionsModal /> */}
                    {!permittedForDeepAnalysis && <PremiumUpgradeModal />}
                    <AmexCardModal />
                    <ToastEmitter />
                    {showSidePanel ? <SidePanel /> : null}
                    {!isFullScreenRoute ? <DebugModal /> : null}
                </Flex>
            </ErrorBoundary>
            {showTickerTape && appAuthorizationState === AppAuthorizationState.Allowed ? (
                <ErrorBoundary>
                    <TradingViewMarquee />
                </ErrorBoundary>
            ) : null}
            {showOnePro && <VideoPlayer /> }
        </>
    );
}

export const BaseScreen = React.memo(({ appAuthorizationState }: { appAuthorizationState: AppAuthorizationState }) => {
    return (
        <Flex column style={{ height: '100vh', width: '100%', position: 'relative' }}>
            <BrowserRouter>
                <ToastProvider autoDismiss autoDismissTimeout={5000} placement='bottom-left'>
                    <RoutingComponent appAuthorizationState={appAuthorizationState} />
                </ToastProvider>
            </BrowserRouter>
        </Flex>
    );
});

const ScrollResetOnNavListener = React.memo(() => {
    const location = useLocation();
    useEffect(() => {
        ScrollPageToTop('instant');
        DebugDumpManager.RecordEvent({ item: location.pathname, event: 'Navigation', other: JSON.stringify(location) });
    }, [location]);

    return null;
});
