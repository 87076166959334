// @ts-strict-ignore
import { Skeleton, Typography } from '@mui/material';
import { useAppWindowSize } from 'hooks/UseWindowSize';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { GetAccountSummaryAction, GetAggregateSummaryAction } from 'phoenix/redux/actions/AccountSummaryActions';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { FormatNumber } from 'phoenix/util';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import { GetVariant } from 'phoenix/util/Variant';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Routes } from 'util/Routes';
import { ExpandButton, Flex } from '..';
import { CulledCollapse } from '../CulledCollapse/CulledCollapse';
import DonutChart from '../DonutChart/DonutChart';
import { SectionHeader } from '../SectionHeader';
import { SnexButton } from '../SnexButton/SnexButton';
import './AccountSummaryAndDonut.scss';

interface AccountSummaryAndDonutProps {
    accountNumber?: string;
    showProjectedIncomeLink?: boolean;
}

export const AccountSummaryAndDonut = React.memo((props: AccountSummaryAndDonutProps) => {
    const dispatch = useDispatch();
    const portfolioText = useText((t) => t.portfolioScreen);
    const accountText = useText((t) => t.accountScreen);
    const [appWindowSize] = useAppWindowSize();

    const flexDirectionColumn = useMemo(() => {
        if (appWindowSize === 'large-display') return false;
        return true;
    }, [appWindowSize]);

    const summary = useSnexStore((s) => (props.accountNumber ? s.accountSummary.byNumber[props.accountNumber] : s.accountSummary.aggregate));

    useEffect(() => {
        if (props.accountNumber) dispatch(GetAccountSummaryAction(props.accountNumber));
        else dispatch(GetAggregateSummaryAction());
    }, [dispatch, props.accountNumber]);

    const chartData = useMemo(() => summary?.data?.positionSummary?.filter((p) => p.value !== 0).map((p) => ({ name: p.name, y: p.value })), [summary]);
    const showDonut = useMemo(() => !!chartData?.length, [chartData]);

    const sectionLabel = useMemo(() => {
        if (!props.accountNumber) return portfolioText.portfolioBreakdown.portfolioBreakdown;
        const assetClass = QualifiedId.Class(props.accountNumber);

        switch (assetClass) {
            case 'futures':
                return portfolioText.portfolioBreakdown.contractsBreakdown;
            case 'equities':
            default:
                return accountText.accountBreakdown;
        }
    }, [props.accountNumber, accountText, portfolioText]);

    return (
        <Flex column={flexDirectionColumn} key={`${flexDirectionColumn}`}>
            <div style={{ flex: 1, paddingRight: showDonut && !flexDirectionColumn ? 100 : 0 }}>
                <AccountSummaryTable
                    accountNumber={props.accountNumber}
                    showProjectedIncomeLink={props.showProjectedIncomeLink}
                    subtitle={props.accountNumber ? null : portfolioText.summary.allAccounts}
                />
            </div>
            <div style={{ boxSizing: 'border-box', flex: 1 }}>
                {showDonut && (
                    <Flex column style={{ boxSizing: 'border-box', flex: 1 }}>
                        <SectionHeader label={sectionLabel} />
                        <DonutChart allowFormatToggle data={chartData} size={120} />
                    </Flex>
                )}
            </div>
        </Flex>
    );
});

type AccountSummaryTemplateProps = {
    header: {
        label: string;
        value: string;
    };
    rows: AccountSummaryRowProps[];
    expandedRows: AccountSummaryRowProps[];
};

type AccountSummaryRowProps = {
    label: string;
    value: number | string;
    bold?: boolean;
};

const AccountSummaryTable = React.memo((props: { accountNumber?: string; showProjectedIncomeLink?: boolean; subtitle?: string }) => {
    const [expanded, setExpanded] = useState(false);
    const summary = useSnexStore((s) => (props.accountNumber ? s.accountSummary.byNumber[props.accountNumber] : s.accountSummary.aggregate));
    const loading = useMemo(() => summary?.pristine, [summary]);
    const isFutures = FuturesSymbol.IsFuturesSymbol(props?.accountNumber);
    const { isClient } = GetVariant();

    const text = useText((s) => s.portfolioScreen.summary);

    const template = useMemo(() => {
        const nonFutures: AccountSummaryTemplateProps = {
            header: {
                label: text.summary,
                value: FormatNumber.toMoneyOpt2(summary?.data?.totalAccountValueCurrent)
            },
            rows: [
                { label: text.cashAndEquivalents, value: summary?.data?.positionSummary?.find((p) => /^Cash/.test(p.name))?.value || 0 },
                { label: text.longMarketValue, value: summary?.data?.totalLongMarketCurrent },
                { label: text.shortMarketValue, value: summary?.data?.totalShortMarketCurrent }
            ],
            expandedRows: null
        };

        const client: AccountSummaryTemplateProps = {
            header: nonFutures.header,
            rows: [...nonFutures.rows, { label: text.assetsHeldAway, value: summary?.data?.totalAssetsHeldAwayCurrent }],
            expandedRows: null
        };

        const futures: AccountSummaryTemplateProps = {
            header: {
                label: text.summary,
                value: null
            },
            rows: [
                { label: text.unrealizedPnL, value: summary?.data?.unrealizedPnLCurrent },
                { label: text.realizedPnL, value: summary?.data?.futuresClosedPnLTopDay },
                { label: text.totalPnL, value: summary?.data?.futuresTotalPnL },
                { label: text.cash, value: summary?.data?.totalCashCurrent },
                { label: text.netLiq, value: summary?.data?.totalAccountValueCurrent, bold: true }
            ],
            expandedRows: [
                { label: text.buyingPower, value: summary?.data?.futuresReferenceBuyingPower },
                { label: text.initialMargin, value: summary?.data?.futuresInitialMargin },
                { label: text.longOptionValue, value: summary?.data?.futuresLongOptionValue },
                { label: text.shortOptionValue, value: summary?.data?.futuresShortOptionValue },
                { label: text.netOptionValue, value: summary?.data?.futuresNetOptionValue }
            ]
        };

        const logic = [
            { rule: isClient, value: client },
            { rule: isFutures, value: futures },
            { rule: true, value: nonFutures }
        ];

        const correctTemplate = logic.find((x) => x.rule)?.value;

        if (props.accountNumber && !isFutures) correctTemplate.rows.push({ label: text.totalAccountValue, value: correctTemplate.header.value, bold: true });
        return correctTemplate;
    }, [summary?.data, text]);

    return (
        <Flex column>
            <SectionHeader
                headerValue={!props.accountNumber ? template.header.value.toString() : undefined}
                label={template.header.label}
                loading={loading}
                subLabel={props.subtitle}
            />
            <Flex column style={{ marginTop: 5 }}>
                {template.rows.map((r: AccountSummaryRowProps, i: number) => (
                    <AccountSummaryRow key={i} label={r.label} loading={loading} value={r.value} isBold={r.bold} />
                ))}
                {isFutures && (
                    <>
                        <CulledCollapse in={expanded}>
                            {template.expandedRows.map((r: AccountSummaryRowProps, i: number) => (
                                <AccountSummaryRow key={i} label={r.label} loading={loading} value={r.value} />
                            ))}
                        </CulledCollapse>
                        <ExpandButton style={{ margin: '-10px auto 8px' }} expanded={expanded} onClick={() => setExpanded(!expanded)} />
                    </>
                )}

                {props.showProjectedIncomeLink ? (
                    <SnexButton eventTag='View Projected Income' flavor='secondary' style={{ marginTop: 10 }} route={Routes.projectedIncome()}>
                        {text.viewProjectedIncome}
                    </SnexButton>
                ) : null}
            </Flex>
        </Flex>
    );
});

const AccountSummaryRow = ({ label, loading, value, isBold }: { label: string; loading: boolean; value: string | number; isBold?: boolean }) => (
    <Flex row className='account-summary-row' justify='space-between'>
        <Typography className={`account-summary-row-text${isBold ? ' bold' : ''}`} color='textSecondary'>
            {label}
        </Typography>
        {loading ? (
            <Skeleton animation='wave' style={{ width: 200 }} />
        ) : (
            <Typography className={`account-summary-row-figure${isBold ? ' bold' : ''}`}>
                {typeof value === 'number' ? FormatNumber.toMoneyOpt2(value) : value}
            </Typography>
        )}
    </Flex>
);
