// @ts-strict-ignore
import { ApiData, ReduxAction } from '../../models'
import { ObjectFilter, ObjectMap } from '../../util'
import { Actions, GroupNameChecker } from '../actions'
import { MessagesState } from '../models/Messages/MessagesState'
import { SnexMessage } from '../models/Messages/SnexMessage'

const permitted = GroupNameChecker([Actions.Messages, Actions.Trading.Submit])
export const MessagesReducer = (state: MessagesState = new MessagesState(), action: ReduxAction): MessagesState => {
    if (!permitted(action)) return state

    const withUpdatedMessageById = (update: (data: ApiData<SnexMessage>) => ApiData<SnexMessage>) => ({
        ...state,
        byId: {
            ...state.byId,
            [action.subject]: update(state.byId[action.subject] || new ApiData<SnexMessage>())
        }
    })

    switch (action.type) {
        case Actions.Messages.Search.Loading: return { ...state, all: state.all.startLoading(state.all.data) }
        case Actions.Messages.Search.Success: {
            const messages = action.data
            return {
                ...state,
                all: state.all.succeeded(
                    action.passthrough.append ? [...(state.all.data || []), ...messages] : messages
                )
            }
        }
        case Actions.Messages.Search.Failure: return { ...state, all: state.all.failed(action.error) }

        case Actions.Messages.GetOne.Loading: return withUpdatedMessageById(d => d.startLoading())
        case Actions.Messages.GetOne.Success: return withUpdatedMessageById(d => d.succeeded(action.data))
        case Actions.Messages.GetOne.Failure: return withUpdatedMessageById(d => d.failed(action.error))

        case Actions.Messages.CountUnread.Loading: return { ...state, unreadCount: state.unreadCount.startLoading(state.unreadCount.data) }
        case Actions.Messages.CountUnread.Success: return { ...state, unreadCount: state.unreadCount.succeeded(action.data) }
        case Actions.Messages.CountUnread.Failure: return { ...state, unreadCount: state.unreadCount.failed(action.error) }

        case Actions.Messages.GetUnreadStats.Loading: return { ...state, unreadStats: state.unreadStats.startLoading(state.unreadStats.data) }
        case Actions.Messages.GetUnreadStats.Success: return { ...state, unreadStats: state.unreadStats.succeeded(action.data) }
        case Actions.Messages.GetUnreadStats.Failure: return { ...state, unreadStats: state.unreadStats.failed(action.error) }

        case Actions.Messages.GetSystemControls.Loading: return { ...state, systemControls: state.systemControls.startLoading(state.systemControls.data) }
        case Actions.Messages.GetSystemControls.Success: return { ...state, systemControls: state.systemControls.succeeded(action.data) }
        case Actions.Messages.GetSystemControls.Failure: return { ...state, systemControls: state.systemControls.failed(action.error) }

        case Actions.Messages.GetClientControls.Loading: return { ...state, clientControls: state.clientControls.startLoading(state.clientControls.data) }
        case Actions.Messages.GetClientControls.Success: return { ...state, clientControls: state.clientControls.succeeded(action.data) }
        case Actions.Messages.GetClientControls.Failure: return { ...state, clientControls: state.clientControls.failed(action.error) }

        case Actions.Messages.GetAdminControls.Loading: return { ...state, adminControls: state.adminControls.startLoading(state.adminControls.data) }
        case Actions.Messages.GetAdminControls.Success: return { ...state, adminControls: state.adminControls.succeeded(action.data) }
        case Actions.Messages.GetAdminControls.Failure: return { ...state, adminControls: state.adminControls.failed(action.error) }

        case Actions.Messages.UpdateAdminControls.Loading: return { ...state, updateAdminControls: state.updateAdminControls.startLoading(), adminControls: state.adminControls.startLoading(state.adminControls.data) }
        case Actions.Messages.UpdateAdminControls.Success: return { ...state, updateAdminControls: state.updateAdminControls.succeeded(), adminControls: state.adminControls.succeeded(action.data) }
        case Actions.Messages.UpdateAdminControls.Failure: return { ...state, updateAdminControls: state.updateAdminControls.failed(action.error) }

        case Actions.Messages.MarkRead.Success: {
            const updatedIds = new Set(action.subject)
            const updatedMessages = state.all.data?.map(m => (updatedIds.has(m.id) ? { ...m, readDate: (new Date()).toString() } : m))
            const nActuallyMarked = state.all.data?.length ? state.all.data?.filter(m => updatedIds.has(m.id) && m.readDate === null).length : action.subject.length
            const updatedById = action.subject.filter(id => !!state.byId[id]).reduce((lookup, id) => ({ ...lookup, [id]: state.byId[id].succeededSpread({ readDate: (new Date()).toString() }) }), {})
            return {
                ...state,
                all: state.all.succeeded(updatedMessages),
                byId: { ...state.byId, ...updatedById },
                unreadCount: state.unreadCount.succeeded(Math.max(0, state.unreadCount.data - nActuallyMarked))
            }
        }

        case Actions.Messages.LiveStream.Update:
            if (action.data?.type === 'silent') return state
            return {
                ...state,
                latest: action.data,
                all: state.all.succeededConcat([action.data], 'prepend'),
                byId: { ...state.byId, [action.data.id]: new ApiData(action.data) },
                unreadCount: state.unreadCount.succeeded((state.unreadCount.data || 0) + 1)
            }

        case Actions.Messages.Broadcast.Loading: return { ...state, broadcast: state.broadcast.startLoading() }
        case Actions.Messages.Broadcast.Success: return { ...state, broadcast: state.broadcast.succeeded() }
        case Actions.Messages.Broadcast.Failure: return { ...state, broadcast: state.broadcast.failed(action.error) }

        case Actions.Messages.Delete.Loading: {
            const idSetL = new Set((action.subject as string[][]).flat())
            const newAllL = state.all.succeeded((state.all.data || []).map(m => idSetL.has(m.id) ? ({ ...m, deleting: true }) : m))
            const newByIdL = ObjectMap(state.byId || {}, e => e[1].succeeded({ ...e[1].data, deleting: true }))
            return { ...state, all: newAllL, byId: newByIdL }
        }

        case Actions.Messages.Delete.Success: {
            const idSetS = new Set((action.subject as string[][]).flat())
            const newAllS = state.all.succeeded((state.all.data || []).filter(m => !idSetS.has(m.id)))
            const newByIdS = ObjectFilter(state.byId, e => !idSetS.has(e[0]))
            return { ...state, all: newAllS, byId: { ...state.byId, ...newByIdS } }
        }

        case Actions.Messages.Delete.Failure:
            return {
                ...state,
                all: state.all.succeeded((state.all.data || []).map(m => ({ ...m, deleting: false }))),
                byId: ObjectMap(state.byId, e => e[1].succeeded({ ...e[1].data, deleting: false }))
            }

        case Actions.Messages.emitToast:
            return {
                ...state,
                latest: { ...action.data }
            }

        default: return state
    }
}
