// @ts-strict-ignore
import { T } from "phoenix/assets/lang/T";
import { EquitiesOnboardingActionsV2 } from "phoenix/onboarding/equities/actions/EquitiesOnboardingActions";
import { EquityOnboSignupModel } from "phoenix/onboarding/equities/actions/EquitiesOnboardingModels";
import React from "react";
import { OnboardingButton, OnboardingScreen, OnboardingStep } from "../../../../../phoenix/onboarding/OnboardingTypes";
import { useRegistrationStore } from "../../Store";
import { getUserInfoErrors, UserInfoIsValid } from "../UserInfo/Validation";
import { Registration_Password_Form } from "./Form";
import { getPasswordErrors, PasswordIsValid } from "./Validation";
import * as Sentry from '@sentry/react'
import { PersonalIsValid } from "../Personal/Validation";


export const registration_password_step: OnboardingStep = {
    label: "Setup Password",
    isComplete: () => PasswordIsValid(),
    isEnabled: () =>  PersonalIsValid() && !!useRegistrationStore.getState().emailVerified && UserInfoIsValid() && !!useRegistrationStore.getState().isWelcomed && !!useRegistrationStore.getState().emailVerified,
    isCurrent: () => ['password'].includes(useRegistrationStore?.getState()?.selectedScreenId),
    onClickGoToScreenId: 'password'
}

export const registration_password_nextButton: OnboardingButton = {
    label: "Next",
    onClick: () => {
        useRegistrationStore.getState().setValue('loading', true)
        const data = useRegistrationStore.getState()?.data
        const signupModel: EquityOnboSignupModel = {
            companyName: 'stonex',
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            password: data.password,
            phoneNumber: { countryCode: data.countryCode || 1, phone: data.phone }
        };
        const doAsync = async() => {
            try{
                const success = await EquitiesOnboardingActionsV2.signup(signupModel);
                useRegistrationStore.getState().setValue('selectedScreenId', 'verifyEmail')
            }
            catch(e) {
                Sentry.captureException(e)
            }
            finally
            {
                useRegistrationStore.getState().setValue('loading', false)
            }
        }
        doAsync();
    },
    isPrimary: true,
    isLoading: () => useRegistrationStore.getState().loading,
    isEnabled: () => PersonalIsValid() && !useRegistrationStore.getState().loading && PasswordIsValid() &&  UserInfoIsValid() && !!useRegistrationStore.getState().isWelcomed 
}

export const registration_password_prevButton: OnboardingButton = {
    label: "Go Back",
    onClick: () => useRegistrationStore.getState().setValue('selectedScreenId', 'personal'),
    isPrimary: false,
    isEnabled: () => !useRegistrationStore.getState().loading
}

export const registration_password_screen: OnboardingScreen = {
    id: "password",
    validate: getUserInfoErrors,
    buttons: [registration_password_prevButton, registration_password_nextButton],
    title: T(t => t.webRegistration.password),
    subtitles: [ T(t => t.webRegistration.createPassword)],
    body: <Registration_Password_Form />
}
