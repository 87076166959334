// @ts-strict-ignore
import { ApiTimeInForce, OrderType, TradeableSecurityType } from '../redux/models';
import { IsWeekend, MarketTimeSegment } from '../util/DateFormatting';
import { GetMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';

export type AlgoStrat = 'Care' | 'VWAP' | 'TWAP' | 'POV' | 'SOR';
export type AlwaysNeverGtx = 'always' | 'never' | 'gtx-only';
export type MarketTimeSegmentDefinition = {
    timesInForce?: ApiTimeInForce[];
    strategies?: AlgoStrat[];
    orderTypes?: { orderType: OrderType; timesInForce?: ApiTimeInForce[] }[];
    disabled?: boolean;
};
export type EditableTradeField = 'quantity' | 'action' | 'time-in-force' | 'order-type' | 'prices' | 'algo-strat';
const StandardEditableFields = new Set<EditableTradeField>(['quantity', 'time-in-force', 'order-type', 'prices', 'algo-strat']);

export interface AssetTradeabilityProfile {
    enabled: boolean;
    requiresOptionPermission: boolean;
    requiresFuturesAccount?: boolean;
    requiresEquitiesAccount?: boolean;
    nbboRequired: boolean;
    accountMustBeUnrestricted: boolean;
    hasCustomSessionWindow: boolean;
    permittedOrderTypes: OrderType[];
    hasExtendedHours: boolean;
    /**
     * @deprecated please use the new `quantityQualifiersByAction` property that specifies the allowed qualifiers for each individual action
     */
    quantityQualifiers?: ('Shares' | 'EvenDollar')[];

    quantityQualifiersByAction?: {
        buy: ('Shares' | 'EvenDollar')[];
        sell: ('Shares' | 'EvenDollar')[];
    }

    permitsAdvancedRouting?: boolean;
    timesInForce: ApiTimeInForce[];
    canTradeOptions?: boolean;
    permittedAlgos?: AlgoStrat[];
    optionsRequireOptionLevel?: boolean;
    canPartialFill?: boolean;
    canTradeOnClose?: boolean;
    editableFields: Set<EditableTradeField>;
    editRule?: 'allowed' | 'not-allowed' | 'contact';
    cancelRule?: 'allowed' | 'not-allowed' | 'contact';

    schedule: Partial<{
        betaMaintenance: MarketTimeSegmentDefinition;
        premarket: MarketTimeSegmentDefinition;
        marketOpen: MarketTimeSegmentDefinition;
        postmarket: MarketTimeSegmentDefinition;
        marketClosed: MarketTimeSegmentDefinition;
        holidays: MarketTimeSegmentDefinition;
        weekends: MarketTimeSegmentDefinition;
    }>;
}

const equityProfile: AssetTradeabilityProfile = {
    enabled: true,
    requiresOptionPermission: false,
    requiresEquitiesAccount: true,
    nbboRequired: true,
    accountMustBeUnrestricted: true,
    hasExtendedHours: true,
    permittedOrderTypes: ['market', 'limit', 'stop', 'stoplimit'],
    hasCustomSessionWindow: false,
    quantityQualifiers: ['Shares'],
    quantityQualifiersByAction: {
        buy: ['Shares'],
        sell: ['Shares']
    },
    canTradeOptions: true,
    optionsRequireOptionLevel: true,
    canPartialFill: true,
    canTradeOnClose: true,
    editableFields: StandardEditableFields,
    editRule: 'allowed',
    cancelRule: 'allowed',

    permitsAdvancedRouting: true,
    permittedAlgos: ['Care', 'TWAP', 'VWAP', 'SOR', 'POV'],

    timesInForce: ['Day', 'GTC', 'GTXPost', 'NTE', 'GTXPre'],
    schedule: {
        betaMaintenance: { disabled: true },
        premarket: {
            timesInForce: ['Day', 'GTC', 'GTXPre'],
            strategies: ['Care', 'TWAP', 'VWAP', 'SOR'],
            orderTypes: [{ orderType: 'limit' }, { orderType: 'stoplimit', timesInForce: ['Day', 'GTC'] }, { orderType: 'stop', timesInForce: ['Day', 'GTC'] }]
        },
        marketOpen: {
            strategies: ['Care', 'TWAP', 'VWAP', 'SOR', 'POV'],
            timesInForce: ['Day', 'GTC']
        },
        postmarket: {
            timesInForce: ['GTXPost', 'NTE'],
            strategies: ['Care', 'TWAP', 'VWAP', 'SOR'],
            orderTypes: [{ orderType: 'limit' }, { orderType: 'stoplimit', timesInForce: ['Day', 'GTC'] }, { orderType: 'stop', timesInForce: ['Day', 'GTC'] }]
        },
        marketClosed: { disabled: true },
        weekends: { disabled: true },
        holidays: { disabled: true }
    }
};

const neverTradeable: AssetTradeabilityProfile = {
    enabled: false,
    requiresOptionPermission: false,
    requiresEquitiesAccount: false,
    nbboRequired: false,
    accountMustBeUnrestricted: true,
    hasCustomSessionWindow: false,
    permittedOrderTypes: [],
    timesInForce: [],
    quantityQualifiers: [],
    quantityQualifiersByAction: {
        buy: [],
        sell: []
    },
    hasExtendedHours: false,
    editableFields: new Set([]),
    editRule: 'not-allowed',
    cancelRule: 'not-allowed',

    schedule: {
        betaMaintenance: { disabled: true },
        premarket: { disabled: true },
        marketOpen: { disabled: true },
        postmarket: { disabled: true },
        marketClosed: { disabled: true },
        weekends: { disabled: true },
        holidays: { disabled: true }
    }
};

const futuresProfile: AssetTradeabilityProfile = {
    accountMustBeUnrestricted: true,
    canTradeOptions: true,
    editableFields: new Set<EditableTradeField>(['quantity', 'prices']),
    enabled: true,
    editRule: 'allowed',
    cancelRule: 'allowed',
    hasCustomSessionWindow: false,
    hasExtendedHours: false,
    nbboRequired: true,
    permittedOrderTypes: ['market', 'limit', 'stop', 'stoplimit'],
    quantityQualifiers: ['Shares'],
    quantityQualifiersByAction: {
        buy: ['Shares'],
        sell: ['Shares']
    },
    requiresFuturesAccount: true,
    requiresOptionPermission: false,
    schedule: {}, // Always tradeable
    timesInForce: ['Day', 'GTC']
};

export const AssetTradeability: Record<TradeableSecurityType | 'loading', AssetTradeabilityProfile> = {
    equity: equityProfile,
    etf: equityProfile,
    adr: equityProfile,
    option: {
        // Equity Options
        ...equityProfile,
        canTradeOnClose: false,
        hasExtendedHours: false,
        requiresOptionPermission: true,
        permitsAdvancedRouting: true,
        canTradeOptions: false,
        permittedAlgos: ['Care', 'SOR'],
        permittedOrderTypes: ['market', 'limit', 'stop', 'stoplimit'],
        schedule: {
            ...equityProfile.schedule,
            premarket: { disabled: true },
            postmarket: { disabled: true },
        }
    },

    future: futuresProfile,
    'futures-option': {
        enabled: true,
        accountMustBeUnrestricted: true,
        hasCustomSessionWindow: false,
        nbboRequired: true,
        schedule: {},
        timesInForce: ['Day', 'GTC'],
        requiresFuturesAccount: true,
        hasExtendedHours: false,
        requiresOptionPermission: false,
        permitsAdvancedRouting: false,
        canTradeOptions: false,
        permittedAlgos: undefined,
        permittedOrderTypes: ['market', 'limit', 'stoplimit'],
        editableFields: new Set<EditableTradeField>(['quantity', 'prices']),
        editRule: 'allowed',
        cancelRule: 'allowed',
    },
    'futures-time-spread': futuresProfile,
    crypto: {
        enabled: false,
        requiresOptionPermission: false,
        nbboRequired: true,
        accountMustBeUnrestricted: true,
        hasCustomSessionWindow: false,
        permittedOrderTypes: ['market', 'limit', 'stoplimit'],
        quantityQualifiers: ['Shares', 'EvenDollar'],
        quantityQualifiersByAction: {
            buy: ['Shares', 'EvenDollar'],
            sell: ['Shares', 'EvenDollar']
        },
        hasExtendedHours: false,
        canTradeOptions: false,
        editableFields: StandardEditableFields,
        editRule: 'allowed',
        cancelRule: 'allowed',

        timesInForce: ['FOK', 'GTC'],
        schedule: {} // Always tradeable
    },

    'mutual-fund': {
        enabled: true,
        requiresOptionPermission: false,
        nbboRequired: false,
        accountMustBeUnrestricted: false,
        hasCustomSessionWindow: false,
        permittedOrderTypes: ['market'],
        quantityQualifiers: ['Shares', 'EvenDollar'],
        quantityQualifiersByAction: {
            buy: ['Shares', 'EvenDollar'],
            sell: ['Shares', 'EvenDollar']
        },
        hasExtendedHours: false,
        canTradeOptions: false,
        editableFields: new Set([]),
        editRule: 'allowed',
        cancelRule: 'allowed',
        timesInForce: ['Day'],
        schedule: {} // Always tradeable
    },

    'offshore-mutual-fund': {
        enabled: true,
        requiresOptionPermission: false,
        nbboRequired: false,
        accountMustBeUnrestricted: false,
        hasCustomSessionWindow: false,
        permittedOrderTypes: ['market'],
        quantityQualifiers: ['Shares', 'EvenDollar'],
        quantityQualifiersByAction: {
            buy: ['EvenDollar'],
            sell: ['Shares', 'EvenDollar']
        },
        hasExtendedHours: false,
        canTradeOptions: false,
        editableFields: new Set([]),
        editRule: 'contact',
        cancelRule: 'contact',
        timesInForce: ['Day'],
        schedule: {} // Always tradeable
    },

    unknown: neverTradeable,
    loading: neverTradeable
};

export const CanTradeOrderTypeNow = (profile: AssetTradeabilityProfile, marketSegment: MarketTimeSegment, orderType: OrderType): boolean => {
    const { schedule } = profile;

    if (!schedule || !Object.entries(schedule)?.length) return true;
    let currentSchedule: MarketTimeSegmentDefinition = {};
    switch (marketSegment) {
        case 'closed':
            currentSchedule = schedule.marketClosed;
            break;
        case 'holiday':
            currentSchedule = schedule.holidays;
            break;
        case 'open':
            currentSchedule = schedule.marketOpen;
            break;
        case 'postmarket':
            currentSchedule = schedule.postmarket;
            break;
        case 'premarket':
            currentSchedule = schedule.premarket;
            break;
        default:
            currentSchedule = {};
            break;
    }

    if (!currentSchedule?.orderTypes?.length && !currentSchedule?.disabled) return true;

    const isOrderTypeAllowedNow = currentSchedule?.orderTypes?.find((o) => o.orderType === orderType);

    if (isOrderTypeAllowedNow && !currentSchedule?.disabled) return true;

    return false;
};

export const GetTradeabilityTimeSegmentDefinition = (profile: AssetTradeabilityProfile): MarketTimeSegmentDefinition => {
    if (IsWeekend()) return profile.schedule.weekends;
    const time = GetMarketTimeSegmentV2()
    switch (time) {
        case 'closed': return profile.schedule.marketClosed
        case 'premarket': return profile.schedule.premarket;
        case 'open': return profile.schedule.marketOpen;
        case 'postmarket': return profile.schedule.postmarket;
        case 'holiday': return profile.schedule.holidays;
        case 'loading': return null;
    }
}

export const TifEnabledAtTime = (tif: ApiTimeInForce, time: MarketTimeSegment, orderType: OrderType, tProfile: AssetTradeabilityProfile): boolean => {

    const def = GetTradeabilityTimeSegmentDefinition(tProfile);
    if (!def?.timesInForce) return true; // Lack of schedule / TIF list implies that there are no restrictions
    const allowedByTifWhitelist = def?.timesInForce?.includes(tif) || false;
    const allowedForSelectedOrderType = (() => {
        if (!def?.orderTypes?.length) return true;
        const relatedOrderType = def?.orderTypes.find((o) => o.orderType === orderType);
        if (!relatedOrderType) return false;
        if (!relatedOrderType?.timesInForce?.length || relatedOrderType?.timesInForce.includes(tif)) return true;
        return false;
    })();
    return (allowedByTifWhitelist && allowedForSelectedOrderType) || false;

};
