// @ts-strict-ignore

import { Flex } from "components";
import { OnboardingInputRow } from "components/Onboarding";
import { useText } from "phoenix/hooks/UseText";
import { EquitiesOnboardingActionsV2 } from "phoenix/onboarding/equities/actions/EquitiesOnboardingActions";
import React from "react";
import { OnboardingTextField } from "../../../Base/OnboardingControls";
import { useRegistrationStore } from "../../Store";
import { allowedPhoneNumberCharsRegex, registrationPhoneRegex, getUserInfoErrors } from "./Validation";
import * as Sentry from '@sentry/react'

export const VerifyUserDoesNotExist = async () => {

    try {
        useRegistrationStore.getState().setValue('verifying', true)
        useRegistrationStore.getState().setValue('emailVerified', false)
        const result = (await EquitiesOnboardingActionsV2.userExists(useRegistrationStore.getState().data.email)).result;
        if(!result.email) {
            useRegistrationStore.getState().setValue('emailVerified', true)
            useRegistrationStore.getState().setValue('selectedScreenId', 'personal')
        } else {
            useRegistrationStore.getState().setValue('emailVerified', false)
            const _existingEmails = [...(useRegistrationStore.getState().existingEmails || []), useRegistrationStore.getState().data.email]
            useRegistrationStore.getState().setValue('existingEmails', _existingEmails) 
            useRegistrationStore.getState().setValue('selectedScreenId', 'userExists')
        }
    }
    catch(e)
    {
        Sentry.captureException({userInfo: useRegistrationStore.getState().data, e})
    }
    finally{
        useRegistrationStore.getState().setValue('verifying', false)
    }
}

export const Registration_UserInfo_Form = () => {
    const text = useText(t => t.webRegistration)
    const {data, setValue, phoneNumberRaw} = useRegistrationStore()

    const handleChange = (key: string, value?: any) => {
        if(key === 'phoneNumberRaw') 
        {
            if(!value.match(allowedPhoneNumberCharsRegex) && !!value) return;
            
            if (registrationPhoneRegex.test(value)) {
                const array = [...value?.match(registrationPhoneRegex)];
                setValue('data.countryCode', Number(array[1].trim() !== ''? array[1].trim().replace('-', '') : 1));
                setValue('data.areaCode', array[2].trim().replace('-', ''));
                setValue('data.phone', array[3].trimStart() + array[4].trimEnd());
            }
        }
        setValue(key, value)
    }

    const getErrors = (key: string) => {
        return getUserInfoErrors()?.filter(e => e?.key?.toLowerCase() === key?.toLowerCase())
    }

    return(
        <Flex column fullWidth center>
            <OnboardingInputRow noPadding>
                <OnboardingTextField name='data.firstName' label={text.firstName} value={data.firstName} handleChange={handleChange} errors={getErrors('data.firstName')}/>
                <OnboardingTextField name='data.lastName' label={text.lastName} value={data.lastName} handleChange={handleChange} errors={getErrors('data.lastName')}/>
            </OnboardingInputRow>
            <OnboardingInputRow>
                <OnboardingTextField name='phoneNumberRaw' label={text.phoneNumber} value={phoneNumberRaw} handleChange={handleChange} errors={getErrors('phoneNumberRaw')} />
            </OnboardingInputRow>
            <OnboardingInputRow>
                <OnboardingTextField name='data.email' label={text.email} value={data.email} handleChange={handleChange} errors={getErrors('data.email')} />
            </OnboardingInputRow>
            <OnboardingInputRow>
                <OnboardingTextField name='data.confirmEmail' label={text.confirmEmail} value={data.confirmEmail} handleChange={handleChange} errors={getErrors('data.confirmEmail')} />
            </OnboardingInputRow>
        </Flex>
    )
}