// @ts-strict-ignore
import { Typography } from '@mui/material';
import { GetSelectedAccountByAssetClass } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { SnexButton } from 'components/SnexButton';
import { useColors } from 'hooks/UseColors';
import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { AssetClass } from 'phoenix/models/AssetClasses/AssetClass';
import { CryptosAssetClass } from 'phoenix/models/AssetClasses/CryptoAssetClass';
import { EquitiesAssetClass } from 'phoenix/models/AssetClasses/EquitiesAssetClass';
import { EquityOptionsAssetClass } from 'phoenix/models/AssetClasses/EquityOptionsAssetClass';
import { EtfAssetClass } from 'phoenix/models/AssetClasses/EtfAssetClass';
import { FuturesAssetClass } from 'phoenix/models/AssetClasses/FuturesAssetClass';
import { FuturesOptionsAssetClass } from 'phoenix/models/AssetClasses/FuturesOptionsAssetClass';
import FuturesTimeSpreadAssetClass from 'phoenix/models/AssetClasses/FuturesTimeSpreadsAssetClass';
import { useAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import { OptionSymbol, TradeRequest } from 'phoenix/redux/models';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { GetSecurityTypeFromStore } from 'phoenix/util';
import { getMultiLegNetAskBid } from 'phoenix/util/OptionsHelpers';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import React, { useMemo, useState } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import { Flex } from '../../Flex';
import { TradeCancelHeader } from '../Headers/TradeCancelHeader';
import { TradeConfirmationPresentation } from '../Shared/TradeConfirmation';
import { ShortAcceptance } from '../Shorts/ShortAcceptance';
import { TradeTicketViewModel } from '../Store/TradeTicketViewModel';
import { convertViewModelToOrderRequest, useTradeTicketViewModel } from '../Store/useTradeTicketViewModel';
import { GetTradeTicketQuote } from './helpers';
import './index.scss';
import { BidAskDisplay, BuyingPowerDisplayV2 } from './TradeFormComponents';
import { TradeTicketSection } from './TradeTicketSection';

interface TradeReviewPageProps {
    title?: string;
}

export const TradeReviewPage = React.memo((props: TradeReviewPageProps) => {
    const { title } = props;
    const [viewMore, setViewMore] = useState(false);
    const [viewMoreOffshore, setViewMoreOffshore] = useState(false);
    const viewModel = useTradeTicketViewModel<TradeTicketViewModel>();
    const {
        isGtx,
        initialLimitPrice,
        initialStopPrice,
        leg2Quantity,
        leg2Symbol,
        leg2TradeAction,
        limitPrice,
        liquidate,
        modifyingOrder,
        orderType,
        quantity,
        initialQuantity,
        quantityQualifier,
        setViewModel,
        stopPrice,
        submitTrade,
        symbol,
        tradeAction,
        validateResponse
    } = viewModel;

    const stockLoanRate = validateResponse?.data?.stockLoanRate;
    const { type } = useAssetClass(symbol);
    const showLogo = type !== 'mutual-fund' && modifyingOrder;
    const secType = GetSecurityTypeFromStore(symbol);
    const quote = GetTradeTicketQuote(symbol);
    const text = useText((t) => t);
    const assetClass: AssetClass = useAssetClass(symbol);
    const effectiveSymbol = useMemo(() => {
        if (assetClass.type === FuturesOptionsAssetClass.type) {
            return new FuturesSymbol(new OptionSymbol(symbol).underlyingSymbol).baseContract;
        }
        if (assetClass.type === FuturesAssetClass.type) {
            return new FuturesSymbol(symbol).baseContract;
        }
        if (assetClass.type === EquityOptionsAssetClass.type) {
            return new OptionSymbol(symbol).underlyingSymbol;
        }

        return symbol;
    }, [assetClass.type, symbol]);
    const logo = useSnexStore((s) => s.logos.bySymbol[effectiveSymbol]);
    const meta = useSnexStore((s) => s.securities.bySymbol[symbol]?.metadata);

    const actualLimitPrice = limitPrice || initialLimitPrice;
    const actualStopPrice = stopPrice || initialStopPrice;
    const [marketTimeSegment] = useMarketTimeSegmentV2();
    const { selectListSelectedItemColor } = useColors();
    const action = leg2Quantity ? null : tradeAction;
    const selectedAccountNumber = GetSelectedAccountByAssetClass(assetClass);
    const showGtxDisclaimer = isGtx && orderType === 'limit';
    const showOffshoreDisclaimer = assetClass.type === 'mutual-fund' && effectiveSymbol.startsWith('I:');

    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const { formatPrice } = useAssetClass(symbol);

    const purchasePrice = useMemo(() => {
        if (orderType === 'market' || orderType === 'stop' || !meta) return NaN;
        const trueQuantity = quantity || initialQuantity;
        const price = trueQuantity * (actualLimitPrice || 0) * meta?.data?.unitFactor;
        return price;
    }, [orderType, meta, quantity, initialQuantity, actualLimitPrice]);

    const leg1Quote = useSnexStore((s) => new OptionSymbol(symbol).selectQuote(s));
    const leg2Quote = useSnexStore((s) => new OptionSymbol(leg2Symbol).selectQuote(s));

    const isMultiLeg = leg2Symbol && leg2Quantity;
    const multiLegAskBid = useMemo(
        () => getMultiLegNetAskBid({ leg1Quote, leg2Quote, quantity, tradeAction, leg2Quantity, leg2TradeAction }),
        [leg1Quote, leg2Quote, quantity, tradeAction, leg2Quantity, leg2TradeAction]
    );

    const sharePrice = useMemo(() => quote?.price * meta?.data?.unitFactor || NaN, [meta?.data?.unitFactor, quote]);

    // construct a pseudo trade object because phoenix expects it
    const tempOrder = {
        ...convertViewModelToOrderRequest({ marketTimeSegment, meta: meta?.data, selectedAccountNumber, viewModel }),
        liquidate: viewModel?.liquidate // Except this UI-only field is also expected this way for some reason
    };

    const isShares = secType === 'mutual-fund' ? quantityQualifier === 'Shares' || liquidate : true;
    const defaultSegments = isShares
        ? text.tradeTicket.review.sharesV2(assetClass, tempOrder as TradeRequest, meta?.data, !action ? actualLimitPrice : purchasePrice, sharePrice)
        : text.tradeTicket.review.dollarsV2(assetClass, tempOrder as TradeRequest, meta?.data, sharePrice);

    const segments = (() => {
        switch (type) {
            case EtfAssetClass.type:
            case EquitiesAssetClass.type:
                return text.tradeTicket.review.equitiesV2(
                    assetClass,
                    tempOrder as TradeRequest,
                    meta?.data,
                    sharePrice,
                    purchasePrice,
                    actualLimitPrice,
                    actualStopPrice
                );
            case FuturesAssetClass.type:
            case FuturesOptionsAssetClass.type:
                return text.tradeTicket.review.futuresV2(assetClass, tempOrder as TradeRequest, meta?.data);
            case FuturesTimeSpreadAssetClass.type:
                return text.tradeTicket.review.futuresSpreads(assetClass, tempOrder as TradeRequest, meta?.data);
            case CryptosAssetClass.type:
                return text.tradeTicket.review.cryptos(assetClass, tempOrder as TradeRequest, meta?.data, tempOrder?.orderPrice, sharePrice);
            case EquityOptionsAssetClass.type:
                return isMultiLeg
                    ? defaultSegments // equitiesV2 does not handle multi-leg
                    : text.tradeTicket.review.equitiesV2(
                          assetClass,
                          tempOrder as TradeRequest,
                          meta?.data,
                          tempOrder?.action === 'Buy' ? quote?.ask : quote?.bid,
                          purchasePrice,
                          actualLimitPrice,
                          actualStopPrice
                      );
            default:
                return defaultSegments;
        }
    })();

    const headerTitle =
        title || leg2Symbol
            ? '' // TODO: Establish desired formatting for multi-leg order title
            : QualifiedId.Format(symbol);

    return (
        <Flex column>
            <TradeCancelHeader
                action={action}
                logo={logo}
                onCancel={() => setViewModel({ liquidate: false, state: 'input' })}
                showLogo={showLogo}
                symbol={symbol}
                text={text.tradeTicket.input}
                title={headerTitle}
            />
            <TradeTicketSection padBottom>
                <Flex center column style={{ minHeight: 200 }}>
                    {stockLoanRate && <ShortAcceptance shares={quantity} rate={stockLoanRate} />}
                    <TradeConfirmationPresentation
                        meta={meta?.data}
                        trade={tempOrder as TradeRequest}
                        tradeableSecurityType={assetClass.type}
                        {...{ tradeableSecurityType: assetClass.type, segments, text }}
                    />
                </Flex>
                {secType !== 'mutual-fund' ? (
                    isMultiLeg ? (
                        <BidAskDisplay
                            ask={formatPrice(multiLegAskBid.netAsk, meta?.data)}
                            bid={formatPrice(multiLegAskBid.netBid, meta?.data)}
                            text={text.tradeTicket.input}
                            showNet
                        />
                    ) : (
                        <BidAskDisplay ask={formatPrice(quote?.ask, meta?.data)} bid={formatPrice(quote?.bid, meta?.data)} text={text.tradeTicket.input} />
                    )
                ) : null}
                <SnexButton className='submit-trade-button' flavor='submit' onClick={submitTrade}>
                    {stockLoanRate ? text.general.acceptRateAndSubmit : text.general.submit}
                </SnexButton>
                {showOffshoreDisclaimer && (
                    <>
                        {viewMoreOffshore ? (
                            <Flex column center className='offshore-disclaimer'>
                                {text.tradeTicket.review.offshoreDisclaimer}
                                <button onClick={() => setViewMoreOffshore(false)}>&#91; {text.securityScreen.viewLess} &#93;</button>
                            </Flex>
                        ) : (
                            <Flex column center className='offshore-disclaimer'>
                                <LinesEllipsis basedOn='letters' maxLine='2' text={text.tradeTicket.review.offshoreDisclaimer} />
                                <button onClick={() => setViewMoreOffshore(true)}>&#91; {text.securityScreen.viewMore} &#93;</button>
                            </Flex>
                        )}
                    </>
                )}

                {stockLoanRate && (
                    <div style={{ marginTop: '18px', fontWeight: 400, fontSize: '13px', textAlign: 'center' }}>
                        <Typography variant='h6' style={{ fontSize: '13px' }} onClick={() => (isExpanded ? setIsExpanded(false) : null)}>
                            {text.tradeTicket.shortSales.disclosure(isExpanded)}
                            {!isExpanded && (
                                <Typography
                                    onClick={() => setIsExpanded(!isExpanded)}
                                    variant='body1'
                                    style={{ fontSize: '13px', cursor: 'pointer', color: selectListSelectedItemColor }}
                                >
                                    [{isExpanded ? text.tradeTicket.shortSales.viewLess : text.tradeTicket.shortSales.viewMore}]
                                </Typography>
                            )}
                        </Typography>
                    </div>
                )}
                {showGtxDisclaimer && (
                    <>
                        {viewMore ? (
                            <Flex column center className='gtx-disclaimer'>
                                {text.tradeTicket.review.gtxDisclaimer}
                                <button onClick={() => setViewMore(false)}>&#91; {text.securityScreen.viewLess} &#93;</button>
                            </Flex>
                        ) : (
                            <Flex column center className='gtx-disclaimer'>
                                <LinesEllipsis basedOn='letters' maxLine='2' text={text.tradeTicket.review.gtxDisclaimer} />
                                <button onClick={() => setViewMore(true)}>&#91; {text.securityScreen.viewMore} &#93;</button>
                            </Flex>
                        )}
                    </>
                )}
            </TradeTicketSection>
            <TradeTicketSection noBorder>
                <BuyingPowerDisplayV2 accountNumber={selectedAccountNumber} meta={meta?.data} symbol={symbol} />
            </TradeTicketSection>
        </Flex>
    );
});
