// @ts-strict-ignore
import { ItemUpdate } from 'lightstreamer-client-web'
import { Lightstreamer, LowercaseObjectKeys } from '../../../util'

const TryParseNormalizeJson = (jsonSrc: string) => {
    try {
        return LowercaseObjectKeys(JSON.parse(jsonSrc))
    } catch (e) {
        return null
    }
}

export type MessagePayloadType =
    | 'OrderUpdate:New'
    | 'OrderUpdate:Filled'
    | 'OrderUpdate:Updated'
    | 'OrderUpdate:Rejected'
    | 'OrderUpdate:Canceled'
    | 'OrderUpdate:Rebook'
    | 'OrderUpdate:PartialFilled'
    | 'OrderUpdate:Other'
    | 'CustomAlert:Default'
    | 'System:AdminBroadcast'
    | 'System:AdminDirect'
    | 'System:Welcome'
    | 'System:FundingUpdate'

export class SnexMessage {
    id?: string
    recipId?: string
    title: string
    message?: string
    senderId?: string
    sendDate: string
    type?: 'md-message' | 'notification' | 'silent' | string
    payloadType?: MessagePayloadType
    disposition?: 'normal' | 'urgent' | 'error' | string
    subjectUserId?: string
    subjectSymbol?: string
    appPermalink?: string
    appLink?: string
    externalLink?: string
    imageUrl?: string
    readDate?: string
    deleting?: boolean
    data?: any

    static FromLiveData = (update: ItemUpdate): SnexMessage => {

        const getValue = (key: string) => Lightstreamer.Helpers.getValueSafe(update, key)

        return {
            id: getValue('id'),
            title: getValue('title'),
            message: getValue('message'),
            senderId: getValue('senderId'),
            sendDate: new Date().toString(),
            type: getValue('type'),
            payloadType: getValue('payloadType') as MessagePayloadType,
            disposition: getValue('disposition'),
            subjectUserId: getValue('subjectUserId'),
            subjectSymbol: getValue('subjectSymbol'),
            appPermalink: getValue('appPermalink'),
            externalLink: getValue('externalLink'),
            imageUrl: getValue('imageUrl'),
            data: TryParseNormalizeJson(getValue('data'))
        }
    }

    static FromRestData = (message: SnexMessage): SnexMessage => {
        message.data = LowercaseObjectKeys(message.data || {})
        return message
    }

}
