// @ts-strict-ignore
import { FilterListRounded } from "@mui/icons-material";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useColors } from "hooks/UseColors";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Card, Flex, LoadingSpinner, SnexTableHeaderCell } from "..";
import { useAppWindowSize, WindowSizes } from "../../hooks/UseWindowSize";
import FilesAnimationData from '../../phoenix/assets/animations/Files.json';
import { GetConfig } from "../../phoenix/constants";
import { useText } from "../../phoenix/hooks/UseText";
import { GetCompanyKeyStatsAction, GetSecurityQuoteAction } from "../../phoenix/redux/actions";
import { UseDescriptionTextStyles } from "../../theming/Styles/TextStyles";
import { BlankView } from "../BlankView";
import { CulledCollapse } from "../CulledCollapse/CulledCollapse";
import { InfoIcon } from "../InfoIcon";
import { SelectListItem } from "../SelectList";
import { SnexButton } from "../SnexButton";
import { ActionButtonGroup, ActionItem } from "../SnexIconButtonGroup";
import { SortableSnexTableHeaderCell } from "../TableComponents/SortableSnexTableHeaderCell";
import { FilterableSecurity } from "./FilterableSecurity";
import { FilterableSecurityChangeSelector } from "./FilterableSecurityChangeSelector";
import { FilterableSecurityMarketCapSelector } from "./FilterableSecurityMarketCapSelector";
import { FilterableSecurityPriceRangeSelector } from "./FilterableSecurityPriceRangeSelector";
import { FilterableSecuritiesFilter, SortData } from "./FilterableSecurityProps";
import { LazyLoadedFilterableSecurityRow } from "./FilterableSecurityRow";
import { FilterHeader } from "./FilterHeader";
import { SortSecurities } from "./SortSecurities";
import style from './style.module.scss';

export interface FilterableSecurityTableProps {
    loading?: boolean,
    securities?:FilterableSecurity[],
    defaultSort:  SortData,
    primaryTitle: string,
    secondaryTitle?: string,
    headerActions? : ActionItem[],
    eventTag?: string,
    isAddToWatchlist?: boolean,
    isDeleteFromWatchlist?: boolean,
    onPrimaryTitleClick?: (event: React.MouseEvent) => any,
    onSecondaryTitleClick?: (event: React.MouseEvent) => any
}

export const MarketCapFilters = {
    Large: 0,
    Mid: 1,
    Small: 2,
    Micro: 3,
    Nano: 4
}

export const ChangeFilters = {
    Falling: 0,
    Rising: 1
}

export const FilterByChange = (change: number, selectedChangeItems: SelectListItem[]) => {
    if(selectedChangeItems.length < 1) return true; // handle no selected
    let result = false;
    selectedChangeItems.forEach(s => {
        switch(s.value) {
            case ChangeFilters.Rising: if (!result) { result = change > 0; } break;
            case ChangeFilters.Falling: if (!result) { result = change < 0; } break;
        }
    })
    return result;
}

export const FilterByMarketCap = ( marketCap: number, selectedMarketCaps: SelectListItem[]) => {
    if(selectedMarketCaps.length < 1) return true; // handle no selected
    let result = false;
    selectedMarketCaps.forEach(s => {
        if(!marketCap) return false
        switch(s.value){
            case MarketCapFilters.Large: if (!result) { result = (marketCap > 10000000000); } break;
            case MarketCapFilters.Mid: if (!result) { result = (marketCap > 2000000000 && marketCap <= 10000000000); } break;
            case MarketCapFilters.Small : if (!result) { result = (marketCap > 300000000 && marketCap <= 2000000000); } break;
            case MarketCapFilters.Micro : if (!result) { result = (marketCap > 50000000 && marketCap <= 300000000); } break;
            case MarketCapFilters.Nano  : if (!result) { result = (marketCap <= 50000000 && marketCap > 0 ); } break;
        }
    })
    return result;
}

export const FilterCalculations = {
    minPrice: (value: number, minPrice?: number) => (value >= minPrice),
    maxPrice: (value: number, maxPrice?: number) => (value <= maxPrice),
    marketCap: (value: number, selectedMarketCaps?:SelectListItem[]) => FilterByMarketCap(value, selectedMarketCaps),
    change: (change: number, selectedChangeItems?: SelectListItem[]) => FilterByChange(change,selectedChangeItems)
}

interface InitalizedFilterableSecurity
{
    security: FilterableSecurity,
    dispatch: any[]
}

const sortAndFilterSecurities = (securities: FilterableSecurity[], sort: SortData, filters: FilterableSecuritiesFilter) => {
    console.log('Reappling sort and filters ...')

    const quotedSecurities = securities?.map<FilterableSecurity>(s => {
        const quote = GetConfig().Store.getState().securityQuote.bySymbol[s.symbol]?.data;
        const marketCap = quote?.marketCap || GetConfig().Store.getState().securities.bySymbol[s.symbol]?.companyKeyStats?.data?.marketCap
        return {...s, marketCap, price: quote?.latestPrice, priceChange: quote?.change, percentChange: quote?.changePercent}
    });

    const s =  SortSecurities(quotedSecurities, sort)

    if (!s?.length) return [];
    const result =  s.filter((s) => {
        if (
            (filters.maxPrice ? FilterCalculations.maxPrice(s.price, filters.maxPrice) : true)
            && (filters.minPrice ? FilterCalculations.minPrice(s.price, filters.minPrice) : true)
            && (filters.marketCap  ? FilterCalculations.marketCap(s.marketCap, filters.marketCap): true)
            && (filters.change ? FilterCalculations.change(s.priceChange, filters.change) : true)
        ) return true
        else return false;
    })
    return result

}

export const FilterableSecurityTable = (props: FilterableSecurityTableProps) => {
    const { loading, defaultSort, primaryTitle, secondaryTitle, onSecondaryTitleClick, onPrimaryTitleClick} = props
    const [ filters, setFilters ] = useState<FilterableSecuritiesFilter>({});
    const [sort, setSort] = useState<SortData>(defaultSort)
    const [ isFilterOpen, setFilterOpen ] = useState(false)
    const [ appWindowSize ] = useAppWindowSize();
    const [ displayedSecurities, setDisplayedSecurities] = useState<FilterableSecurity[]>()
    const [ isDispatching, setIsDispatching] = useState(true)
    const dispatch = useDispatch()
    const colors = useColors()

    const refreshSort = () => {
        const s = sortAndFilterSecurities(props.securities, sort, filters)
        setDisplayedSecurities(s)
    }

    useEffect(() => {
        if(loading || !props?.securities) return
        console.log('Initalizing filterable securities ...')
        setIsDispatching(true)
        const dispatches = props.securities.map(s=> {
            const d1 = dispatch(GetSecurityQuoteAction(s.symbol, undefined, true))
            if(!s.marketCap) {
                const d2 = dispatch(GetCompanyKeyStatsAction(s.symbol))
                return [d1,d2]
            }
            return d1
        }).flat()

        Promise.all(dispatches).then(x => {
            refreshSort()
            setIsDispatching(false)
        })

        const interval = setInterval(() => refreshSort, 30000)
         return () => clearInterval(interval)

    }, [loading, props.securities])

    useEffect(() => {
        refreshSort()
    }, [sort, filters])

    const showName = useMemo(() => {
        switch (appWindowSize) {
            case WindowSizes.large: return true;
            default: return false;
        }
    }, [ appWindowSize ])


    const handleTableHeaderClick = (field: string) => {
        if(sort.field !== field) {
            setSort({field, direction: 'asc'})
        } else {
            setSort({field, direction: sort.direction === 'desc'? 'asc' : 'desc'})
        }
    }

    const handleClearAllFiltersClick = () => setFilters({})

    const handleFilterUpdate = (newFilters: FilterableSecuritiesFilter) => setFilters({ ...filters, ...newFilters })



    const filterCount = useMemo<number>(() => {
        let _filterCount = 0;
        if(filters?.maxPrice || filters?.minPrice) _filterCount +=1;
        if(filters?.marketCap) _filterCount += filters?.marketCap?.length;
        if(filters?.change) _filterCount += filters?.change?.length
        return _filterCount;
    }, [filters])

    const headerActions = useMemo<ActionItem[]>(() => {
        const base: ActionItem[] = [
            { onClick: () => setFilterOpen(!isFilterOpen),
                iconComponent: FilterListRounded,
                toolTipText: 'Filter the list',
                showZeroBadge: false,
                badgeContent: filterCount,
                badgeColor: 'primary' }
        ]
        if(props.headerActions) base.push(...props.headerActions)
        return base;

    }, [ isFilterOpen, filterCount, props.headerActions ])

    const text = useText(t => t)
    const classes = UseDescriptionTextStyles(colors)();

    const ColumnWrapper = useCallback((props: { children: any, flex?: number, noPadding?: boolean }) => (
        <Flex column style={{ flex: props.flex || 1, overflow: 'hidden', position: 'relative', boxSizing: 'border-box', paddingRight: props.noPadding ? 0 : 20 }}>{props.children}</Flex>
    ), [])

    const Header = useMemo(() =>
    <>
    <Flex column style={{ width: '100%' }}>
        <Flex row align='center' style={{ width: '100%' }}>
                    <Flex role='button' row align='center' justify='flex-start' style={{ flex: 1 }} onClick={(e) => onPrimaryTitleClick ? onPrimaryTitleClick(e) : setFilterOpen(!isFilterOpen)} >
                        <Typography style={{ paddingRight: 10, cursor: 'pointer' }} variant='h3' >{primaryTitle}</Typography>
                        {!loading
                            && displayedSecurities?.length > 0
                            && <Typography
                                style={{ borderLeft: `1px solid ${colors.grayText}`, color: colors.grayText, paddingLeft: 10 }}
                                variant='h6'>{displayedSecurities.length} {displayedSecurities.length > 1 ? text.sectorScreen.items : text.sectorScreen.item}
                            </Typography>}
                    </Flex>
            { filterCount > 0 &&
            <SnexButton flavor='action-link' onClick={handleClearAllFiltersClick}><span style={{marginTop:'8px'}}>Clear All</span></SnexButton>
             }
            <ActionButtonGroup actionItems={headerActions} />
        </Flex>
        <Flex align='flex-start'>
            <Flex row align='center' className={style.descriptionContainer}>
                <Typography variant='h6' onClick={(e) => onSecondaryTitleClick? onSecondaryTitleClick(e) : setFilterOpen(!isFilterOpen)}
                    classes={classes}
                    className={secondaryTitle === text.watchlistScreen.defaultDescription ? style.defaultWatchlistDescription :
                        style.watchlistDescription}>{ secondaryTitle }</Typography>
            </Flex>
        </Flex>
        <CulledCollapse eventTag={props.eventTag} in={ isFilterOpen }>
                <Card className={style.card}>
                    <Flex row style={{ width: '100%' }}>
                        <ColumnWrapper flex={1.3}>
                            <FilterHeader>{text.sectorScreen.price}</FilterHeader>
                            <FilterableSecurityPriceRangeSelector filters={filters} securities={displayedSecurities} onFilterUpdate={handleFilterUpdate} />
                        </ColumnWrapper>
                        <ColumnWrapper>
                            <FilterHeader>{text.sectorScreen.change}</FilterHeader>
                            <FilterableSecurityChangeSelector text={text.sectorScreen.securityChangeSelector} filters={filters} onFilterUpdate={handleFilterUpdate} />
                        </ColumnWrapper>
                        <ColumnWrapper noPadding>
                            <FilterHeader variant='element'>
                                <Typography variant='h6' className={style.label}>{text.sectorScreen.marketCap}</Typography>
                                <InfoIcon messagePath={ s => s.marketCap } title={ text.watchlistScreen.marketCap.header } />
                            </FilterHeader>
                            <FilterableSecurityMarketCapSelector text={text.sectorScreen.marketCapSelector} filters={filters} onFilterUpdate={handleFilterUpdate} />
                        </ColumnWrapper>
                    </Flex>
                </Card>
            </CulledCollapse>
    </Flex>
    </>
    , [displayedSecurities, primaryTitle, secondaryTitle, sort, filters, isFilterOpen, onPrimaryTitleClick, onSecondaryTitleClick])

    const cols = useMemo(() => {
        let result = 8;
        if(!showName) result -= 1;
        if(!props.isAddToWatchlist && !props.isDeleteFromWatchlist) result -= 1
        return result
    }, [props.isAddToWatchlist, props.isDeleteFromWatchlist, showName])

    return(
        <Flex column style={{ width: '100%', boxSizing: 'border-box', height: '100%' }}>
            {Header}
           {loading || isDispatching?
           <LoadingSpinner />
           :
            <Flex column style={{ width: '100%', marginTop: 40, flex: 1, overflowX: 'scroll' }}>
                <Table stickyHeader style={{ tableLayout: 'auto', zIndex: 1, position: 'relative' }}>
                    <TableHead>
                        <TableRow>
                            <SnexTableHeaderCell></SnexTableHeaderCell>
                            <SortableSnexTableHeaderCell field='symbol' currentSort={sort} onClick={() => handleTableHeaderClick('symbol')}>{text.sectorScreen.symbol}</SortableSnexTableHeaderCell>
                            { showName && <SortableSnexTableHeaderCell  width={200} field='name' currentSort={sort} onClick={() => handleTableHeaderClick('name')}>{text.sectorScreen.name}</SortableSnexTableHeaderCell> }
                            <SortableSnexTableHeaderCell align='right' field='price' currentSort={sort} onClick={() => handleTableHeaderClick('price')}>{text.sectorScreen.price}</SortableSnexTableHeaderCell>
                            <SortableSnexTableHeaderCell align='right' field='priceChange' currentSort={sort} onClick={() => handleTableHeaderClick('priceChange')}>{text.sectorScreen.change}</SortableSnexTableHeaderCell>
                            <SortableSnexTableHeaderCell align='right' field='percentChange' currentSort={sort} onClick={() => handleTableHeaderClick('percentChange')}>{text.sectorScreen.changePercent}</SortableSnexTableHeaderCell>
                            <SortableSnexTableHeaderCell align='right' field='marketCap' currentSort={sort} onClick={() => handleTableHeaderClick('marketCap')}>{text.sectorScreen.marketCap}</SortableSnexTableHeaderCell>
                            { (props.isAddToWatchlist || props.isDeleteFromWatchlist) && <SnexTableHeaderCell align='right'></SnexTableHeaderCell> }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        !displayedSecurities || displayedSecurities.length < 1 ?
                        <TableRow>
                            <TableCell colSpan={cols}>
                                <BlankView animationData={FilesAnimationData} text='Looks like this list is empty' />
                            </TableCell>
                        </TableRow>:
                        displayedSecurities?.map(w =>
                            <LazyLoadedFilterableSecurityRow item={w} key={`lazyRow_${w.symbol}`}
                                isAddToWatchlist={props.isAddToWatchlist} isDeleteFromWatchlist={props.isDeleteFromWatchlist}/>
                        )
                    }

                    </TableBody>
                </Table>
            </Flex>
            }
        </Flex>

    )
}

