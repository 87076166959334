// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._5-R1OqqF2TctiwfTh54s4{display:flex;flex-direction:column;width:100%;box-sizing:border-box;padding:0 0}", ""]);
// Exports
exports.locals = {
	"wrapper": "_5-R1OqqF2TctiwfTh54s4"
};
module.exports = exports;
