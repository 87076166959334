// @ts-strict-ignore
import { Actions } from './Constants'
import { ReduxApiGet } from './Helpers'
import { Urls } from '../../constants/Urls'
import { News } from '../models'
import { ReduxLiveSubscribe } from './StreamingHelpers'
import { LiveDataGroups } from '../../constants'
import { ItemUpdate } from 'lightstreamer-client-web'
import { GetVariant } from '../../util/Variant'

const dedupeApostrophes = (src: string) => src?.replace(/'{2}/g, '\'') || null

const processNews = (res: News[]) => res.map(n => ({
    ...n,
    title: dedupeApostrophes(n.title) || 'No Title',
    description: dedupeApostrophes(n.description) || '(Summary unavailable)',
    related: n.related?.toUpperCase()
}))

export const GetMarketNewsAction = () => ReduxApiGet(Urls.news.forMarket(), Actions.News.ForMarket)
    .useStored(s => s.news?.marketNews?.data.length ? s.news?.marketNews?.data : null, 'market-news', 1000 * 60)
    .onSuccess(processNews)
    .run()

export const GetNewsForSymbolAction = (symbol: string) => ReduxApiGet(Urls.news.forSymbol(symbol), Actions.News.ForSymbol)
    .useStored(s => s.news?.bySymbol[symbol]?.data || null, `news-sym${symbol}`, 1000 * 60)
    .withSubject(symbol)
    .onSuccess(processNews)
    .run()

export const GetNewsForAccountAction = (accountNumber: string) => ReduxApiGet(Urls.news.forAccount(accountNumber), Actions.News.ForAccount)
    .useStored(s => s.news?.byAccount[accountNumber]?.data || null, `news-acct${accountNumber}`, 1000 * 60)
    .withSubject(accountNumber)
    .onSuccess(processNews)
    .run()

export const GetNewsForPortfolioAction = () => ReduxApiGet(Urls.news.forPortfolio(), Actions.News.ForPortfolio)
    .useStored(s => s.news?.portfolioNews?.data || null, 'news-folio', 1000 * 60)
    .onSuccess(processNews)
    .run()

export const ShowStreamedNewsAction = () => ({ type: Actions.News.ShowStreamed })

export const SubscribeToLiveNewsAction = (namespace: string) => {
    if (!GetVariant().canStreamNews) return { type: Actions.NoAction }
}
