// @ts-strict-ignore
import { ReduxAction } from '../../models/ReduxAction'
import { Actions } from '../actions/Constants'
import { LoginState } from '../models/Login'

export const LoginReducer = (state: LoginState = new LoginState(), action: ReduxAction): LoginState => {
    switch (action.type) {
        case Actions.Login.Loading: case Actions.GetTokenFromCode.Loading: case Actions.RefreshToken.Loading:
            return { ...state, loggedIn: state.loggedIn.startLoading(false) }
        case Actions.Login.Success: case Actions.GetTokenFromCode.Success: case Actions.RefreshToken.Success:
            return { ...state, loggedIn: state.loggedIn.succeeded(true) }
        case Actions.Login.Failure: case Actions.GetTokenFromCode.Failure: case Actions.RefreshToken.Failure:
            return { ...state, loggedIn: state.loggedIn.failed(action.error) }

        case Actions.JwtLogin: return { ...state, loggedIn: action.data === null ? state.loggedIn.failed({ authenticated: false }) : state.loggedIn.succeeded(true) }

        case Actions.CheckLogin.Loading: return { ...state, loggedIn: state.loggedIn.startLoading(false) }
        case Actions.CheckLogin.Success: return { ...state, loggedIn: state.loggedIn.succeeded(true) }
        case Actions.CheckLogin.Failure: return { ...state, loggedIn: state.loggedIn.failed(action.error) }

        default: return state
    }
}
