import React from 'react';
import { Flex } from 'components';

export function JaredDebugScreen(): JSX.Element {
    return (
        <DebugScreen>
            <div style={{ width: 1020 }}></div>
        </DebugScreen>
    );
}

type DebugScreenProps = React.PropsWithChildren<unknown>;

function DebugScreen(props: DebugScreenProps): JSX.Element {
    return (
        <Flex column justify='flex-start' align='center' style={{ marginTop: 100, flex: 1, width: '100vw', overflow: 'scroll' }}>
            {props.children}
        </Flex>
    );
}
