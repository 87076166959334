// @ts-strict-ignore
import { SnexApiResponse } from 'phoenix/redux/models/SnexApiResponse';
import { ApiError } from './ApiError'

export class ApiData<T> {
    pristine: boolean;
    loading: boolean;
    data: T;
    /** @deprecated Please use errors */ error: ApiError | null;
    errors: ApiError[] | null;
    private initialValue: T;
    private loadingValue: T;

    constructor (initialValue: T = null, loadingValue: T = initialValue) {
        this.pristine = true
        this.loading = false
        this.data = initialValue
        this.error = null
        this.errors = null
        this.initialValue = initialValue
        this.loadingValue = loadingValue
    }

    // For below methods: cannot spread because it leaves off the methods

    startLoading? (loadingValue: T | null = null): ApiData<T> {
        const out = new ApiData<T>(this.initialValue, this.loadingValue)
        out.pristine = this.pristine
        out.loading = true
        out.errors = null
        out.data = loadingValue || this.loadingValue
        return out
    }

    succeeded? (data: T, errors?: ApiError[]): ApiData<T> {
        const out = new ApiData<T>(this.initialValue, this.loadingValue)
        out.pristine = false
        out.data = data
        if(errors) out.errors = errors
        return out
    }

    succeededSpread? (data: Partial<T>): ApiData<T> {
        const out = new ApiData<T>(this.initialValue, this.loadingValue)
        out.pristine = false
        // @ts-ignore
        out.data = { ...(this.data || {}), ...(data || {}) }
        return out
    }

    succeededConcat? (data: Partial<T>, end: 'prepend' | 'append', max = 0): ApiData<T> {
        const out = new ApiData<T>(this.initialValue, this.loadingValue)
        out.pristine = false
        // @ts-ignore
        if (end === 'append') out.data = [...(this.data || []), ...data]
        // @ts-ignore
        else out.data = [...data, ...(this.data || [])]
        // @ts-ignore
        if (max) out.data = out.data.slice(0, max)
        return out
    }

    succeededReplace? (mutate: (exiting: T) => T): ApiData<T> {
        const out = new ApiData<T>(this.initialValue, this.loadingValue)
        out.pristine = false
        out.data = mutate(this.data)
        return out
    }

    succeededMutateOne? (filter: (item: any) => boolean, mutate: (exiting: any) => any): ApiData<T> {
        const out = new ApiData<T>(this.initialValue, this.loadingValue)
        out.pristine = false
        // @ts-ignore
        if (Array.isArray(this.data)) out.data = this.data.map(i => { if (filter(i)) { mutate(i) } return i })
        else {
            console.info({ me: 'mutate one', warning: 'Did not mutate because the data wasnt a list', data: this.data })
            out.data = this.data
        }
        return out
    }

    failed? (error: any, keepData?: boolean): ApiData<T> {
        // If the request has been cancelled, just keep the data object the way it is
        if (error && error.isCancel) {
            return this
        }
        const out = new ApiData<T>(this.initialValue, this.loadingValue)
        out.data = keepData ? this.data : this.initialValue
        out.pristine = false
        out.error = error
        out.errors = [error as ApiError]
        return out
    }

    reset? (): ApiData<T> {
        return new ApiData<T>(this.initialValue, this.loadingValue)
    }
}
