// @ts-strict-ignore
import { AppAuthorizationState, AppDispatch } from "AppRoot";
import { GetConfig } from "phoenix/constants";
import { CountUnreadAction, GetClientAccountsAction, GetCurrentUserPrefsAction, GetMyUserInfoAction, GetUserTokenAction, StartPollingUnreadCountAction, StartTokenPollingAction, SubscribeToLiveMessagesWithJwt } from "phoenix/redux/actions";
import { GetAllFeatureFlagsAction } from "phoenix/redux/actions/FeatureFlagActions";
import { GetMarketHolidaysAction, GetMarketOpenStatusAction, StartPollingMarketStatusAction } from "phoenix/redux/actions/MarketActions";
import { GetStartupAuthorizationAction } from "phoenix/redux/actions/StartupActions";
import { GetSystemAvailabilityAction } from "phoenix/redux/actions/SystemActions";
import { UserAuthorization } from "phoenix/redux/models/Login/UserAuthorization";
import { SetJwtResolver } from "phoenix/resolvers/JwtStorage";
import { Jwt } from "phoenix/util";
import { ErrorLogger } from "phoenix/util/ErrorLogger";
import InitSalesforce from 'util/Salesforce';
import { Routes } from "./Routes";

export const GetAppAuthorizationState = async (dispatch: AppDispatch, isAnonymousRoute: boolean): Promise<AppAuthorizationState> => {


    if(isAnonymousRoute) return AppAuthorizationState.Anonymous

    const authorize = await dispatch(GetStartupAuthorizationAction());

    switch(authorize.userAuthorization)
    {
        case 'allow':
        case UserAuthorization.Allowed: return AppAuthorizationState.Allowed
        case UserAuthorization.Pending: return AppAuthorizationState.Pending
        case UserAuthorization.GoToLogin: return AppAuthorizationState.GoToLogin
        case UserAuthorization.Denied:
        default: return AppAuthorizationState.Denied
    }
}

export const AuthorizeApp = async (dispatch: AppDispatch, appAuthorizationState: AppAuthorizationState): Promise<void> => {
  

    switch (appAuthorizationState) {

        case AppAuthorizationState.Pending: 
        case AppAuthorizationState.Allowed: {
            // Await cosmos/torch verification
            await dispatch(GetMyUserInfoAction());
            // Await user's language and configured feature flags before letting the app start
            const prefsAction = await dispatch(GetCurrentUserPrefsAction());
            const holidays = dispatch(GetMarketHolidaysAction());
            const timing = dispatch(GetMarketOpenStatusAction()); // get initial timing before polling starts
            const accounts = dispatch(GetClientAccountsAction());
            const flags = dispatch(GetAllFeatureFlagsAction());

            await Promise.allSettled([holidays, accounts, timing, flags]);

            // Set document lang
            const langFromPrefs: string | undefined = prefsAction?.preference?.language?.code;
            document.documentElement.lang = langFromPrefs;
            const sfLang = langFromPrefs || navigator?.language || 'en-US';
            InitSalesforce({ locale: sfLang });

            // The rest of these can happen while the app starts up
            dispatch(GetSystemAvailabilityAction());

            const token = await dispatch(GetUserTokenAction());

            // add user id to sentry
            const userInfo = GetConfig().Store.getState().user.myInfo?.data
            ErrorLogger.SetUser(token?.accessToken, userInfo)
            dispatch(SubscribeToLiveMessagesWithJwt(token?.accessToken, { fetchOpenOrdersOnRebook: false }));
            dispatch(CountUnreadAction());
            dispatch(StartPollingUnreadCountAction());
            dispatch(StartTokenPollingAction());
            dispatch(StartPollingMarketStatusAction());
            
            SetJwtResolver(() => GetConfig()?.Store?.getState()?.user?.myToken?.data?.accessToken);
            break;
        }

        case AppAuthorizationState.Denied:
        case AppAuthorizationState.Anonymous:
        default:
        {
            const lang = navigator?.language || 'en-US';
            InitSalesforce({ locale: lang });
            break;
        }
 
    }
    
}


export const AuthorizedUrlsForAnonymousState = [
    '/registration', 
    Routes.localLogin(),
    Routes.loggedOut(),
    Routes.deadLogin()
]

export const AuthorizedUrlsForPendingState = AuthorizedUrlsForAnonymousState.concat([
    '/onboarding',
    Routes.loggedOut(),
    '/contact/support',
    '/support'
])

export interface AuthorizeAppProps
{
    state: AppAuthorizationState,
    children: JSX.Element
}
export const AuthorizedApp = (props: AuthorizeAppProps) : JSX.Element => {
    const location = window.location.href.toLowerCase()
    
    const checkRoutesForRedirect =  (allowedUrls: string[], redirectUrl: string): JSX.Element => {
        if(allowedUrls.some(u => location.includes(u.toLowerCase()))) return props.children; /* url is allowed */
        window.location.href = redirectUrl;
    }

    switch (props.state)
    {
        case AppAuthorizationState.Anonymous: return checkRoutesForRedirect(AuthorizedUrlsForAnonymousState, Routes.register("1"))
        case AppAuthorizationState.Pending: return checkRoutesForRedirect(AuthorizedUrlsForPendingState, Routes.onboardingSelect())
        
        case AppAuthorizationState.Loading:  return props.children
        case AppAuthorizationState.Allowed: {
            if(location.includes(Routes.onboardingSelect().toLowerCase())) {
                /* if allowed and url is onboarding select then redirect to portfolio screen */
                window.location.href = Routes.portfolio()
            } else {
                return props.children;
            }
            break;
        }
           


        case AppAuthorizationState.Denied: 
        default: return checkRoutesForRedirect([Routes.deadLogin()], Routes.deadLogin())   
    }

}