// @ts-strict-ignore
import React, { useMemo} from "react";
import { useColors } from "hooks/UseColors";
import { ShadedTextList } from "phoenix/assets/lang/Snex1LanguagePack";
import { useSnexStore } from "phoenix/hooks/UseSnexStore";
import { useText } from "phoenix/hooks/UseText";
import { SafeFormatToNow } from "phoenix/util";
import { Flex } from "components/Flex";
import { Error } from "@mui/icons-material";
import './index.scss'
import { Typography } from "@mui/material";

const systemNames = {
    'booksandrecords': 'books and records',
    'marketdata': 'market data',
}

export const OutageBanner = React.memo(() => {
    const status = useSnexStore(s => s?.system?.status)
    const colors = useColors();
    const translatedText = useText(s=>s.portfolioScreen.warnings)
    // @ts-ignore
    const { text, detailedText }: { text: ShadedTextList, detailedText: ShadedTextList } = useMemo(() => {

        const nonAvailableObjects = status?.data?.filter(x => x.status !== 'available') || [];
        const nonAvailable = nonAvailableObjects
            .map(x => x.id.replace(/^.*\//, '').replace(/-/g, ''))
            .map(x => systemNames[x] || x)

        if (!nonAvailable?.length) return { text: [], detailedText: [], earliestIssue: null }

        const earliestIssue = nonAvailableObjects.sort((a, b) => (a.lastAvailable?.getTime?.() || 0) - (b.lastAvailable?.getTime?.() || 0))[0]
        const generalSystemError = nonAvailableObjects.findIndex(a => a.id === 'functionality/system-general') !== -1;

        // TODO -- For i18n, consider replacing this with a new helper similar to SpokenJoin in ArrayMutations
        let brief: ShadedTextList = []

        if (generalSystemError) {

            brief = [ { text: translatedText.weAreResolvingIssues } ]

        } else {

            let tokens: ShadedTextList = nonAvailable?.flatMap((a, idx) => [
                { text: a, shade: 'dark' },
                { text: ((idx === nonAvailable.length - 2) ? ', and' :  ', '), shade: 'light' }
            ]) || []
            if(nonAvailable.length === 2) tokens[1].text = ' and ';
            tokens = tokens.slice(0, -1)

            brief = [
                { text: translatedText.resolvingIssues },
                ...tokens,
                { text: ' ' + (nonAvailable.length > 1 ? translatedText.systems : translatedText.system) + '.' }
            ]

        }


        const relevantIssueFound = !!earliestIssue?.lastAvailable?.getTime
        let extras: ShadedTextList = [];
        if (!relevantIssueFound) {
            extras = [
                { text: translatedText.pleaseCheckLater, shade: 'light' },
            ]
        } else {
            const theseSystems = nonAvailable.length > 1 ? translatedText.theseSystems : translatedText.theSystem;
            const firstReported: ShadedTextList = [
                { text: translatedText.incidentFirstReported, shade: 'light' },
                { text: SafeFormatToNow(new Date(earliestIssue.lastAvailable)), shade: 'dark' },
                { text: '.\n', shade: 'light' },
            ]
            const lastEvaluated: ShadedTextList = [
                { text: translatedText.statusEvaluated(theseSystems), shade: 'light' },
                { text: SafeFormatToNow(new Date(earliestIssue.asOf)), shade: 'dark' },
                { text: '.', shade: 'light' },
            ]

            if (earliestIssue.lastAvailable) extras = [ ...extras, ...firstReported ]
            if (earliestIssue.asOf) extras = [ ...extras, ...lastEvaluated ]
        }
        const out = { text: brief, detailedText: [ ...brief, ...extras ], earliestIssue }
        return out;

    }, [ status ,translatedText])

    const showBanner = !!text?.length || !!detailedText?.length

    if (!showBanner) return null;
    
    return (
        <Flex row align="center"  className='outage-banner'>
            <Error style={{ fill: colors.primaryItemColor, fontSize: 15 }} />
            <Typography className='outage-text' variant='body2'>
                { detailedText.map((s, idx) => 
                    <span key={`system-outage-${idx}`} className={s.shade}>{s.text}</span>
                )}
            </Typography>
        </Flex>
    )

})