// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".chart-wrapper{position:relative}.portfolio-value{font-size:32px !important}.range-list{box-sizing:border-box;padding-top:10px;padding-bottom:10px}.range-list button{border:none}.range-label{display:inline-block;margin-right:15px;font-size:15px;font-weight:500;color:#484e64;cursor:pointer;overflow:hidden;border-radius:3px}.range-label-active{padding:1px 10px 1px 10px;color:var(--white);border-radius:2px}.range-label-positive{background-color:var(--positiveValueColor)}.range-label-negative{background-color:var(--negativeValueColor)}.range-label-neutral{background-color:var(--grayText)}.range-label-non-active{background:none;padding:1px 10px 1px 10px;color:var(--fadedTextColor);transition:200ms}.range-label-non-active:hover{background:var(--greyBackgroundColor)}.reset-chart-scale{align-items:center;background:var(--grayDark);border-radius:5px;border:none;color:var(--white);cursor:pointer;display:flex;font-weight:500;padding:10px;position:absolute;top:0;z-index:1000}#custom-marker{display:block;position:absolute;z-index:20;border-radius:50%;height:10px;width:10px;transform:scale(1);box-shadow:0 0 0 0 var(--grayText);animation:pulse 2s infinite}.green{background:var(--green)}.red{background:var(--red)}@keyframes pulse{0%{box-shadow:0 0 0 0 var(--grayText);opacity:.7;transform:scale(0.95)}70%{box-shadow:0 0 0 10px var(--grayText);opacity:0;transform:scale(1)}100%{box-shadow:0 0 0 0 var(--grayText);opacity:0;transform:scale(0.95)}}.amount-change{font-size:16px;font-weight:400}.amount-change-icon{vertical-align:middle;margin-left:-8px}.tooltip{background-color:var(--cardBackgroundColor);border-radius:2px;box-sizing:border-box;display:none;font-size:11px;height:25px;padding:6px 0;pointer-events:none;position:absolute;text-align:center;width:auto;z-index:1000;color:var(--fadedTextColor);left:10px;top:0px}.multi-series-chart{position:relative}", ""]);
// Exports
exports.locals = {
	"mainBackgroundColor": "#fff",
	"cardBackgroundColor": "#fff",
	"cardSecondaryBackgroundColor": "#fff",
	"blurredBackgroundColor": "rgba(255,255,255,.9176470588)",
	"fadedTextColor": "#596269",
	"blurredAdornmentColor": "#a9a9a9",
	"focusedAdornmentColor": "#000",
	"primaryItemColor": "#346094",
	"primaryItemBackdropColor": "rgba(50,103,168,.24)",
	"primaryItemLightColor": "rgba(52,95,148,.1921568627)",
	"dividerColor": "#f5f5f5",
	"generalTextColor": "#000",
	"areaChartNegativeColor": "#f7e0e3",
	"areaChartPositiveColor": "#e0f6e6",
	"secondaryButtonBackgroundColor": "#f7fafc",
	"secondaryButtonBackgroundHoverColor": "#d2e3ee",
	"colorOverlay": "rgba(255,255,255,.7)",
	"greyBackgroundColor": "#f8f8f8",
	"inputBorderColor": "rgba(0,0,0,.15)",
	"inputBorderActiveColor": "rgba(213,213,213,.438)",
	"disabledButtonText": "#fff",
	"scrollbarColor": "#e9e9e9",
	"segmentedControlBackgroundColor": "#f0f7ff",
	"segmentedControlActiveColor": "#336094",
	"positiveValueColor": "#02b334",
	"positiveValueLighterColor": "rgba(2,179,52,.16)",
	"negativeValueColor": "#b2292e",
	"negativeValueLighterColor": "rgba(178,41,46,.16)",
	"futuresColor": "#28bbd6",
	"fplGreen": "\"#13B53F\"",
	"futuresBackdropColor": "rgba(40,188,214,.2196078431)",
	"stickyCard": "#ffc",
	"selectListItemColor": "rgba(51,96,148,.1)",
	"selectListSelectedItemColor": "#346094"
};
module.exports = exports;
