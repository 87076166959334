import { CallMade } from '@mui/icons-material';
import { CardContent, Skeleton, Typography } from '@mui/material';
import { TelemetryCategories } from 'constants/Telemetry/TelemetryCategories';
import { useColors } from 'hooks/UseColors';
import { useTelemetry } from 'hooks/UseTelemetry';
import StoneXIcon from 'phoenix/assets/images/stoneXOneMenuIcon.png';
import { LiveDataNamespaces } from 'phoenix/constants/LiveDataNamespaces';
import { useText } from 'phoenix/hooks/UseText';
import { GetSecurityQuoteAction } from 'phoenix/redux/actions';
import { News } from 'phoenix/redux/models';
import { ChangeColor, FormatNumber, QuoteAttribute, SafeFormatToNow } from 'phoenix/util';
import AlgoliaHelper from 'phoenix/util/AlgoliaHelper';
import { XS } from 'phoenix/xstream/XS';
import { useXstreamDispatch } from 'phoenix/xstream/XstreamProvider';
import { TelemetryProvider } from 'providers/TelemetryContext';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Routes } from 'util/Routes';
import { Flex } from 'components/Flex';
import { Card } from '../Card';
import { LinesEllipsis } from '../LinesEllipsis/LinesEllipsis';
import { Spinny } from '../Spinny';
import './NewsCard.scss';

interface NewsCardLinkPropsInner {
    url: string;
}
type NewsCardLinkProps = React.PropsWithChildren<NewsCardLinkPropsInner>;

function NewsCardLink(props: NewsCardLinkProps): JSX.Element {
    return (
        <a href={props.url} rel='noreferrer' target='_blank'>
            {props.children}
        </a>
    );
}

interface NewsCardProps {
    article?: News;
    subjectSymbol?: string;
}

const validSymbolPattern = /^[A-Za-z]+$/;

const NewsCardUntelemetry = React.memo(function NewsCard(props: NewsCardProps) {
    const { article, subjectSymbol } = props;
    const [couldNotLoadImage, setCouldNotLoadImage] = useState(false);
    const LogEvent = useTelemetry();
    const { blurredAdornmentColor } = useColors();
    const text = useText((s) => s.misc);

    const related = useMemo(() => {
        if (article?.related === undefined) return [];
        return article.related
            .split(',')
            .filter((s) => {
                return subjectSymbol !== s && validSymbolPattern.test(s || '');
            })
            .map((s) => s.trim());
    }, [article?.related, subjectSymbol]);

    const handleClick = useCallback(() => {
        if (article !== undefined) {
            LogEvent('Article click (News)', { 'article title': article.title, source: article.source });
        }
    }, [LogEvent, article]);

    const handleImageError = useCallback(() => {
        setCouldNotLoadImage(true);
    }, []);

    if (article === undefined) return <NewsPlaceholderCard />;

    // extract url here so we can use TS narrowing
    const imageUrl = article.imageUrl;
    // do not show image when none is available.
    // additionally, do not show image when we attempted to load one, but it failed due to CORS, 404, etc.
    const shouldShowImage = !!imageUrl && !couldNotLoadImage;

    return (
        <Card className='news-card'>
            <div style={{ cursor: 'pointer' }} onClick={handleClick}>
                {shouldShowImage && (
                    <NewsCardLink url={article.url}>
                        <img alt={article.title} className='news-image' src={imageUrl} onError={handleImageError} />
                    </NewsCardLink>
                )}

                <CardContent className='news-card-content' sx={{ '&:last-child': { paddingBottom: 0 } }}>
                    <NewsCardLink url={article.url}>
                        <Flex row justify='space-between' style={{ padding: '31px 16px 0' }}>
                            <Typography variant='h6'>
                                <LinesEllipsis component='span' lines={2}>
                                    {article.title || 'No Title'}
                                </LinesEllipsis>
                            </Typography>
                            {article.provider === 'StoneX' && (
                                <div>
                                    <img alt='StoneX' src={StoneXIcon} style={{ height: 15, marginTop: 4 }} />
                                </div>
                            )}
                        </Flex>
                        <Flex row justify='space-between' style={{ padding: '15px 16px 0' }}>
                            <Typography style={{ color: blurredAdornmentColor }} variant='body1'>
                                {article.source}
                            </Typography>
                            <Typography style={{ color: blurredAdornmentColor }} variant='body1'>
                                {SafeFormatToNow(article.publishTime)}
                            </Typography>
                        </Flex>
                    </NewsCardLink>
                    <Flex row wrap style={{ margin: '5px 16px 15px' }}>
                        {related.slice(0, 4).map((r, key) => (
                            <ChangingSymbol key={key} symbol={r} />
                        ))}
                    </Flex>
                    {article.description ? (
                        <NewsCardLink url={article.url}>
                            <Typography variant='body2' style={{ padding: '0 16px 24px' }}>
                                <LinesEllipsis component='span' lines={4}>
                                    {article.description || text.news.noSummaryAvailable}
                                </LinesEllipsis>
                            </Typography>
                        </NewsCardLink>
                    ) : null}
                </CardContent>
            </div>
        </Card>
    );
});

// TelemetryProvider HOC replaces our carefully-defined proptypes with `any`, so hack around it
export const NewsCard = (props: NewsCardProps): JSX.Element => TelemetryProvider(NewsCardUntelemetry, TelemetryCategories.news)(props);

const ChangingSymbol = React.memo(function ChangingSymbol(props: { symbol: string }) {
    const LogEvent = useTelemetry();
    const xdispatch = useXstreamDispatch();
    const dispatch = useDispatch();
    const quote = XS.Quotes.use(props.symbol);
    const changePercent = useMemo(() => QuoteAttribute.getChangePercent(quote, true) ?? 0, [quote]);
    const [hover, setHover] = useState(false);

    const { ref, inView } = useInView({ trackVisibility: true, threshold: 0, delay: 400 });
    const [subscription, setSubscription] = useState<string | null>(null);

    useEffect(() => {
        dispatch(GetSecurityQuoteAction(props.symbol));
    }, [dispatch, props.symbol]);

    useEffect(() => {
        const run = async () => {
            if (inView && !subscription) {
                const ticket: string = await xdispatch(XS.Quotes.start(props.symbol, LiveDataNamespaces.News));
                setSubscription(ticket);
            } else if (!inView && subscription) {
                XS.stop(subscription);
                setSubscription(null);
            }
        };

        run();
        return () => {
            if (subscription) {
                XS.stop(subscription);
            }
        };
    }, [inView, props.symbol, subscription, xdispatch]);

    const handleSecurityClick = useCallback<React.MouseEventHandler<HTMLAnchorElement>>(
        (e) => {
            e.stopPropagation();
            LogEvent('Security click (News)', { symbol: props.symbol });
            AlgoliaHelper.Report.ClickedSecurity(props.symbol)
        },
        [LogEvent, props.symbol]
    );

    return (
        <div ref={ref}>
            <Link onClick={handleSecurityClick} to={Routes.security(props.symbol)}>
                <Flex
                    row
                    style={{
                        marginRight: 10,
                        background: `rgba(0,0,0,${hover ? 0.05 : 0})`,
                        transition: 'background-color 200ms',
                        padding: '3px 7px',
                        marginLeft: -7,
                        borderRadius: 8
                    }}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    <Typography style={{ marginRight: 3, fontWeight: 700 }} variant='body2'>
                        {props.symbol}
                    </Typography>
                    {quote?.error ? null : (
                        <>
                            <Spinny spun={changePercent < 0} targetDegrees={90}>
                                <CallMade style={{ fontSize: 12, color: ChangeColor(changePercent), margin: '0 2px', paddingTop: 3 }} />
                            </Spinny>
                            <Typography style={{ color: ChangeColor(changePercent) }} variant='body1'>
                                {FormatNumber.toPercent(changePercent, 'never')}
                            </Typography>
                        </>
                    )}
                </Flex>
            </Link>
        </div>
    );
});

const NewsPlaceholderCard = React.memo(function NewsPlaceholderCard() {
    return (
        <Card className='news-card'>
            <CardContent className='news-card-content'>
                <Skeleton animation='wave' />
                <Skeleton animation='wave' />
                <Skeleton animation='wave' />
            </CardContent>
        </Card>
    );
});
