// @ts-strict-ignore
import { CardActionArea, Skeleton, Typography } from '@mui/material';
import { useColors } from 'hooks/UseColors';
import { useTelemetry } from 'hooks/UseTelemetry';
import { T } from 'phoenix/assets/lang/T';
import { LiveDataNamespaces } from 'phoenix/constants/LiveDataNamespaces';
import { StandardQuote } from 'phoenix/constants/ReduxSelectors';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import { GetSecurityMetadataAction, GetSecurityQuoteAction } from 'phoenix/redux/actions';
import { SecurityQuote } from 'phoenix/redux/models';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { usePositionsStore } from 'phoenix/stores/PositionsStore';
import { ChangeColor, FormatNumber, Sum } from 'phoenix/util';
import { XS } from 'phoenix/xstream/XS';
import { useXstreamDispatch } from 'phoenix/xstream/XstreamProvider';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import LinesEllipsis from 'react-lines-ellipsis';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Routes } from 'util/Routes';
import uuid from 'uuid-random';
import { Flex } from '../..';
import { Card } from '../../Card';
import { SecurityChartWrapper } from '../../Charting/SecurityChartWrapper';
import { CircularLogo } from '../../CircularLogo/CircularLogo';
import './style.module.css';
import AlgoliaHelper from 'phoenix/util/AlgoliaHelper';

export interface TopDisplayCardProps {
    getCached?: boolean;
    symbol?: string;
    accountNumber?: string;
    initialQuote?: SecurityQuote;
    skeleton?: boolean;
    live?: boolean;
    liveDataNamespace?: string;
    title?: string;
}

const TopDisplayCardFunction = (props: TopDisplayCardProps) => {
    const xdispatch = useXstreamDispatch();
    const dispatch = useDispatch();
    const LogEvent = useTelemetry();
    const { symbol, initialQuote, skeleton, getCached = false, live = true } = props;
    const { load: loadPositions, positions } = usePositionsStore();

    useEffect(() => {
        return () => XS.stopNs(props.liveDataNamespace || LiveDataNamespaces.TopSecurities);
    }, [props.liveDataNamespace]);

    const xStreamQuote: StandardQuote = XS.Quotes.use(symbol);
    const relevantPositions = positions?.filter((p) => (props.accountNumber ? p.symbol === symbol && p.accountNumber === props.accountNumber : p.symbol === symbol));
    const contracts = Sum(relevantPositions?.filter((p) => p.productType === 'Options').map((p) => p.quantity || 0));
    const shares = Sum(relevantPositions?.filter((p) => p.productType === 'Equities').map((p) => p.quantity || 0));

    const [key, setKey] = useState<number | string>(1);
    const [latestChartPercChange, setLatestChartPercChange] = useState(null);
    const [latestChartPrice, setLatestChartPrice] = useState(null);

    const isFuture = FuturesSymbol.IsFuturesSymbol(symbol);
    const parsedSymbol = isFuture ? new FuturesSymbol(symbol).noPrefix : symbol;
    const frontMonthTopMovers = useSnexStore((s) => s.securities.top.futures.movers);
    const { holdingsGold } = useColors();
    const meta = useSnexStore((s) => s.securities.bySymbol[symbol]?.metadata?.data);
    const { formatPrice } = useAssetClass(symbol);

    const { ref, inView } = useInView({ trackVisibility: true, threshold: 0, delay: 400 });
    const [subscription, setSubscription] = useState<string>(null);

    useEffect(() => {
        dispatch(GetSecurityMetadataAction(symbol));
        dispatch(GetSecurityQuoteAction(symbol));
    }, [symbol, inView, dispatch]);

    useEffect(() => {
        const run = async () => {
            if (inView && !subscription) {
                const ticket: string = await xdispatch(XS.Quotes.start(symbol, props.liveDataNamespace || LiveDataNamespaces.TopSecurities));
                setSubscription(ticket);
            } else if (!inView) {
                XS.stop(subscription);
                setSubscription(null);
            }
        };

        run();
        return () => {
            XS.stop(subscription);
        };
    }, [inView, props.liveDataNamespace, subscription, symbol, xdispatch]);

    useEffect(() => {
        loadPositions();
    }, [loadPositions]);

    useEffect(() => {
        setKey(uuid());
    }, [symbol]);

    useEffect(() => {
        if (!symbol || !live) return;
        const run = async () => {
            !getCached && (await dispatch(GetSecurityQuoteAction(parsedSymbol)));
        };
        run();
    }, [symbol, live, getCached, dispatch, parsedSymbol]);

    const Loading = (p: { width?: number }) => {
        return <Skeleton animation='wave' style={{ width: p.width || 100 }} />;
    };

    const handleClick = () => {
        AlgoliaHelper.Report.ClickedSecurity(symbol);

        LogEvent(`${props.title} click`, { symbol, route: Routes.security(symbol) });
    };

    const onCrosshairUpdate = (value?: any) => {
        if (!value?.[symbol]) return;
        setLatestChartPercChange(value?.[symbol]?.chartPercChange);
        setLatestChartPrice(typeof value?.[symbol] === 'number' ? value[symbol] : value[symbol]?.value);
    };

    const dataLoading = xStreamQuote?.loading || xStreamQuote?.pristine;
    const name = xStreamQuote?.companyName || initialQuote?.companyName;
    let price = xStreamQuote?.price || latestChartPrice;
    let changePercent = xStreamQuote?.changePercent || latestChartPercChange;

    // The front movers endpoint returns last price and change %
    // Fall back to these if more up-to-date values are unavailable
    if (isFuture && (!price || !changePercent)) {
        const match = frontMonthTopMovers?.data?.find((x) => x.symbol === symbol);
        price = match?.latestPrice;
        changePercent = match?.changePercent;
    }

    return (
        <Card id={`top-movers-${props.accountNumber}-section`} className='animate-press'>
            <Link to={Routes.security(symbol)}>
                <CardActionArea disableRipple onClick={handleClick}>
                    <Flex ref={ref} column justify='space-between' style={{ padding: '20px 30px', height: 300 }}>
                        <Flex column>
                            <Flex row align='center' justify='space-between' style={{ height: 25 }}>
                                <Flex row align='center'>
                                    <CircularLogo size={20} style={{ marginRight: 10 }} symbol={parsedSymbol} />
                                    {skeleton ? <Loading /> : <Typography variant='h6'>{parsedSymbol}</Typography>}
                                </Flex>
                            </Flex>
                            <Flex row style={{ marginTop: 10 }}>
                                {name ? (
                                    <Typography variant='h4'>
                                        <LinesEllipsis basedOn='letters' maxLine='2' text={name} />
                                    </Typography>
                                ) : dataLoading ? (
                                    <Loading width={200} />
                                ) : null}
                            </Flex>
                        </Flex>
                        <Flex align='center' justify='center' style={{ width: '100%' }}>
                            {live && (
                                <SecurityChartWrapper
                                    chartLineWidth={1}
                                    chartResolution='lo'
                                    containerId={'container-' + key}
                                    crosshairType='none'
                                    height={80}
                                    key={key}
                                    percentChangeOverride={changePercent}
                                    range='1d'
                                    symbols={[symbol]}
                                    width={170}
                                    onCrosshairUpdate={onCrosshairUpdate}
                                />
                            )}
                        </Flex>
                        <Flex column>
                            {!dataLoading ? (
                                <Typography style={{ color: ChangeColor(changePercent), opacity: dataLoading ? 0.8 : 1 }} variant='h3'>
                                    {formatPrice(price, meta)}
                                </Typography>
                            ) : (
                                <Loading />
                            )}
                            <Flex row align='flex-end' justify='space-between'>
                                {!dataLoading ? (
                                    <Typography style={{ color: ChangeColor(changePercent), opacity: dataLoading ? 0.8 : 1 }} variant='h6'>
                                        {FormatNumber.toPercent(changePercent * 100)}
                                    </Typography>
                                ) : (
                                    <Loading />
                                )}
                                <div className='holdings' style={{ color: holdingsGold }}>
                                    {!!contracts && (
                                        <>
                                            <Typography component='label' variant='body2'>
                                                {contracts}
                                            </Typography>
                                            <Typography variant='body2'>{T((s) => s.general.contracts(contracts))}</Typography>
                                        </>
                                    )}
                                    {!!shares && (
                                        <>
                                            <Typography component='label' variant='body2'>
                                                {shares}
                                            </Typography>
                                            <Typography variant='body2'>{T((s) => s.general.shares(shares))}</Typography>
                                        </>
                                    )}
                                </div>
                            </Flex>
                        </Flex>
                    </Flex>
                </CardActionArea>
            </Link>
        </Card>
    );
};

export const TopDisplayCard = React.memo(TopDisplayCardFunction);
