// @ts-strict-ignore
import { GetSelectedAccountForOrderRequest } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { Snex1English } from 'phoenix/assets/lang/Snex1English';
import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { Snex1Portuguese } from 'phoenix/assets/lang/Snex1Portuguese';
import { Snex1Spanish } from 'phoenix/assets/lang/Snex1Spanish';
import { GetConfig } from 'phoenix/constants';
import { StandardQuote } from 'phoenix/constants/ReduxSelectors';
import { OptionsOpenClose, TradeActions } from 'phoenix/constants/Trade';
import { GetMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { ApiOrderType, ApiTimeInForce, ApiTradeAction, ApiTradeRequest } from 'phoenix/models/ApiTradeRequest';
import { AssetClass, AssetFamily } from 'phoenix/models/AssetClasses/AssetClass';
import { EquitiesAssetClass } from 'phoenix/models/AssetClasses/EquitiesAssetClass';
import { GetAssetClassForSecurity, GetAssetClassFromMetadata } from 'phoenix/models/AssetClasses/useAssetClass';
import { TradeResult } from 'phoenix/models/TradeResult';
import { ApiTimeInForceOptions, InputTimeInForce, Order, TradeAction } from 'phoenix/redux/models';
import { SecurityMetadata } from 'phoenix/redux/models/Securities/SecurityMetadata';
import { MarketTimeSegment } from 'phoenix/util';
import { DetermineOrderType } from 'phoenix/util/DetermineOrderType';
import { SubmitTrade, UpdateTrade } from 'phoenix/util/Trading/TradeSubmission';
import { ValidateSubmitTrade, ValidateUpdateTrade } from 'phoenix/util/Trading/TradeValidation';
import { noteAndNotHeldToAlgoStrategy } from 'util/Utils';
import { create } from 'zustand';
import { algoStrategyToNoteAndNotHeld } from '../Shared/helpers';
import { TradeTicketViewModel } from './TradeTicketViewModel';

const useStoreBase = create<TradeTicketViewModel>((set: (store: TradeTicketViewModel) => void, get: () => TradeTicketViewModel) => ({
    getViewModel: () => get(),
    setViewModel: (update: Partial<TradeTicketViewModel>) => {
        const viewModel = get();

        const result = { ...updateViewModel({ viewModel, update }) };
        set(result);
    },
    state: 'input',
    submitTrade: async () => {
        const marketTimeSegment = GetMarketTimeSegmentV2();
        const viewModel = get();
        const meta = GetConfig().Store.getState().securities.bySymbol[viewModel?.symbol]?.metadata?.data;
        const assetClass = GetAssetClassFromMetadata(meta);
        const selectedAccountNumber = GetSelectedAccountForOrderRequest(assetClass);
        const langCode = GetConfig()?.Store?.getState().user.myPrefs.data?.preference?.language?.code;
        // TODO: Abstract a non-reactive language getter
        const lang: Snex1LanguagePack =
            {
                'es-US': { ...Snex1Spanish, ...Snex1English },
                'pt-BR': { ...Snex1Portuguese, ...Snex1English },
                'en-US': Snex1English
            }[langCode] || Snex1English;
        const orderRequest = convertViewModelToOrderRequest({ lang, marketTimeSegment, meta, selectedAccountNumber, viewModel });

        set({ ...viewModel, submitResponse: { loading: true }, state: 'done' });
        let result: TradeResult;
        if (viewModel?.modifyingOrder) {
            result = await UpdateTrade(orderRequest);
        } else {
            result = await SubmitTrade(orderRequest);
        }
        set({ ...viewModel, submitResponse: { loading: false, data: result?.order, error: result?.error }, state: 'done' });
    },
    validateTrade: async () => {
        const marketTimeSegment = GetMarketTimeSegmentV2();
        const viewModel = get();
        const isDvp = GetConfig().Store.getState().user.myInfo?.data?.isDvp;
        const meta = GetConfig().Store.getState().securities.bySymbol[viewModel?.symbol]?.metadata?.data;
        const assetClass = GetAssetClassFromMetadata(meta);
        const selectedAccountNumber = GetSelectedAccountForOrderRequest(assetClass);
        const orderRequest = convertViewModelToOrderRequest({ marketTimeSegment, meta, selectedAccountNumber, viewModel });

        set({ ...viewModel, validateResponse: { loading: true } });
        let result: TradeResult;
        if (viewModel?.modifyingOrder) {
            result = await ValidateUpdateTrade(orderRequest, true);
        } else {
            result = await ValidateSubmitTrade(orderRequest, true);
        }

        const nonReinvestState = result.success && (!result.warnings.length || isDvp) ? 'review' : viewModel?.state;

        const newState: Partial<TradeTicketViewModel> = {
            state:
                result.success && result.warnings.length === 0 && assetClass.type === 'mutual-fund' && !viewModel?.liquidate && !(viewModel?.state === 'reinvest')
                    ? 'reinvest'
                    : nonReinvestState
        };

        // If moving to the review state, set the current price/qty values as the initial values to retain them if the user navigates backwards
        if (newState?.state === 'review') {
            newState.initialLimitPrice = viewModel?.limitPrice ? viewModel?.limitPrice : viewModel?.initialLimitPrice;
            newState.initialStopPrice = viewModel?.stopPrice ? viewModel?.stopPrice : viewModel?.initialStopPrice;
            newState.initialQuantity = viewModel?.quantity ? viewModel?.quantity : viewModel?.initialQuantity;
        }

        set({
            ...viewModel,
            ...(assetClass?.type === 'future' ? { showDom: false } : {}),
            ...newState,
            validateResponse: { loading: false, data: result?.order, error: result?.error, warnings: result?.warnings }
        });
    },
    checkTradeFieldChanged: () => {
        const viewModel = get();
        const initialViewModel = viewModel?.initialViewModel;
        set({ ...viewModel, hasModifyingOrderChanged: hasAnyTradeFieldChanged(initialViewModel, viewModel) });
    }
}));

// Pass a trade ticket type/interface to the store when using the hook
export const useTradeTicketViewModel = useStoreBase as {
    <T>(): T;
    <T, U>(selector: (s: T) => U): U;
};

export const GetTradeTicketViewModel = (): TradeTicketViewModel => useStoreBase.getState().getViewModel();

export const getDefaultPrice = ({
    assetClass,
    marketTimeSegment,
    orderType,
    quote,
    tradeAction
}: {
    assetClass: AssetClass;
    marketTimeSegment?: MarketTimeSegment;
    orderType?: ApiOrderType;
    quote?: StandardQuote;
    tradeAction?: ApiTradeAction;
}): number => {
    const effectiveOrderType = orderType || (assetClass?.defaultOrderType && assetClass?.defaultOrderType(marketTimeSegment)) || undefined;
    if (assetClass.defaultTradePrice?.[effectiveOrderType]) {
        if (assetClass.defaultTradePrice?.[effectiveOrderType] === 'last' && quote?.last) return quote?.last;
        else if (assetClass.defaultTradePrice?.[effectiveOrderType] === 'bid-ask') return !tradeAction || tradeAction === TradeActions.Buy ? quote?.ask : quote?.bid;
        else return quote?.price;
    }
    return null;
};

export const getDefaultOrderProps = ({
    assetClass = EquitiesAssetClass,
    marketTimeSegment,
    quote,
    tradeAction
}: {
    assetClass: AssetClass;
    marketTimeSegment: MarketTimeSegment;
    quote?: StandardQuote;
    tradeAction?: ApiTradeAction;
}): Partial<TradeTicketViewModel> => {
    const orderType = (assetClass?.defaultOrderType && assetClass?.defaultOrderType(marketTimeSegment)) || undefined;
    const defaultPrice: number = getDefaultPrice({ marketTimeSegment, assetClass, quote, tradeAction });
    const initialQuantity = assetClass?.defaultOrderQuantity;

    const result: Partial<TradeTicketViewModel> = {
        displayOrderType: orderType,
        initialLimitPrice: defaultPrice,
        initialQuantity,
        initialStopPrice: defaultPrice,
        initialViewModel: null,
        isGtx: assetClass?.family === 'equities' && assetClass?.derivative === 'base' && marketTimeSegment === 'postmarket',
        leg2Quantity: null,
        leg2Symbol: null,
        leg2TradeAction: null,
        limitPrice: null,
        liquidate: false,
        orderType,
        quantity: assetClass?.defaultOrderQuantity || NaN,
        stopPrice: null,
        timeInForce: 'Day',
        tradeAction: TradeActions.Buy,
        validateResponse: null,
        submitResponse: null,
        quantityQualifier: 'Shares'
    };
    return result;
};

export const hasAnyTradeFieldChanged = (initialViewModel: Partial<TradeTicketViewModel>, updatedViewModel: Partial<TradeTicketViewModel>): boolean => {
    // This only matters for order modifications
    if (!updatedViewModel.modifyingOrder || updatedViewModel.state !== 'input') return false;

    const map = {
        limitPrice: 'initialLimitPrice',
        quantity: 'initialQuantity',
        quantityQualifier: 'initialQuantityQualifier',
        stopPrice: 'initialStopPrice',
        timeInForce: 'initialTimeInForce',
        orderType: 'orderType'
    };

    const keys = Object.keys(map);

    for (const k of keys) {
        const newValue = updatedViewModel[k];
        const initialValue = initialViewModel[map[k]];

        if (hasFieldChanged(initialValue, newValue)) {
            return true;
        }
    }

    return false;
};

export const hasFieldChanged = <T,>(initialValue: T, newValue: T): boolean => {
    if (!initialValue && !newValue) return false;
    if (typeof initialValue === 'string' && typeof newValue === 'string') {
        if (initialValue.toUpperCase() !== newValue.toUpperCase()) {
            return true;
        }
    }
    return initialValue !== newValue;
};

export const updateViewModel = ({ viewModel, update }: { viewModel: TradeTicketViewModel; update: Partial<TradeTicketViewModel> }): TradeTicketViewModel => {
    const newState: Partial<TradeTicketViewModel> = {
        ...update
    };

    // Transformations below are mostly conveniences for setting initial/default props in certain cases
    // Order modifications provide default props based on the order and are excluded from these transformations
    if (
        !update?.modifyingOrder &&
        // When an order modification is complete, bypass this so the input values reset
        !(viewModel.modifyingOrder && !update.modifyingOrder)
    ) {
        // This will be most cases: a user changing values on the trade ticket for a single symbol
        if (!update?.symbol || update.symbol === viewModel?.symbol) {
            if (
                // If the user is changing the order type,
                (update?.orderType && viewModel?.orderType && !update?.initialLimitPrice && viewModel?.orderType !== update.orderType) ||
                // Or the user has clicked "Submit another order" and is resetting the ticket to the input state
                (viewModel?.state === 'done' && update?.state === 'input') ||
                // Or the user is navigating back from the review or reinvest states
                (['reinvest', 'review'].includes(viewModel?.state) && update?.state === 'input')
            ) {
                // set the current price(s), quantity as initial values for the next render
                newState.initialLimitPrice = viewModel?.limitPrice || viewModel?.initialLimitPrice;
                newState.initialStopPrice = viewModel?.stopPrice || viewModel?.initialStopPrice;
                newState.initialQuantity = viewModel?.quantity || viewModel?.initialQuantity;
            }

            // displayOrderType is only relevant/differentiated for MOC/LOC orders; otherwise it's the same as the order type
            if (update?.orderType && !update?.displayOrderType) newState.displayOrderType = update.orderType;
        }
    }

    // If the user updates one of these values to something falsy, also set the initial value null so there is no fallback
    if (Object.keys(update).includes('quantity') && !update.quantity && !update.initialQuantity) newState.initialQuantity = null;
    if (Object.keys(update).includes('limitPrice') && !update.limitPrice && !update.initialLimitPrice) newState.initialLimitPrice = null;
    if (Object.keys(update).includes('stopPrice') && !update.stopPrice && !update.initialStopPrice) newState.initialStopPrice = null;

    return { ...viewModel, ...newState };
};

export const setTradeAction = (tradeAction: TradeAction, quote: StandardQuote): void => {
    const store = useStoreBase;
    const prevState = store.getState();
    const marketTimeSegment = GetMarketTimeSegmentV2();
    const assetClass = GetAssetClassForSecurity(prevState.symbol);
    const defaultPrice = getDefaultPrice({ marketTimeSegment, assetClass, quote, tradeAction });

    store.setState({
        ...prevState,
        tradeAction,
        ...(prevState.limitPrice ? {} : { initialLimitPrice: defaultPrice }),
        ...(prevState.stopPrice ? {} : { initialStopPrice: defaultPrice })
    });
};

export const makeReinvestValue = (value: 'Reinvest' | 'Cash'): boolean => value === 'Reinvest';

export const convertOrderToViewModel = ({ lang, meta, order }: { lang: Snex1LanguagePack; meta: SecurityMetadata; order: Order }): TradeTicketViewModel => {
    if (!order) return null;
    const assetClass = GetAssetClassFromMetadata(meta);
    const isPartialFill = order && order?.leavesQuantity && order?.leavesQuantity !== order?.orderQuantity;

    const isGtx = ['GTXPre', 'GTXPost', 'NTE'].includes(order?.timeInForce);
    const convertedTif = order?.timeInForce === 'GoodTillCancel' ? 'GTC' : order?.timeInForce || 'Day';
    const timeInForce = isGtx ? 'Day' : (convertedTif as InputTimeInForce);
    // TODO: Make trade actions Title Case enums coming from the API/messaging
    // Orders from color palette have actions in lowercase
    const titleCaseAction = order.action && order.action.charAt(0).toUpperCase() + order.action.slice(1);

    const { tradeAction, openClose } = {
        BuyToCover: { tradeAction: TradeActions.Buy, openClose: OptionsOpenClose.Close },
        BuyToOpen: { tradeAction: TradeActions.Buy, openClose: OptionsOpenClose.Open },
        BuyToClose: { tradeAction: TradeActions.Buy, openClose: OptionsOpenClose.Close },
        SellToOpen: { tradeAction: TradeActions.Sell, openClose: OptionsOpenClose.Open },
        SellToClose: { tradeAction: TradeActions.Sell, openClose: OptionsOpenClose.Close }
    }[order?.action] || { tradeAction: titleCaseAction as TradeAction };

    const orderType = DetermineOrderType(order);
    const displayOrderType = order.onClose
        ? {
              limit: 'loc',
              market: 'moc'
          }[orderType]
        : orderType;

    // TODO: onClose is unit tested, test the other conditions for modifyOrderType
    const isEquity = assetClass.family === 'equities';
    const isEditable = assetClass.type !== 'mutual-fund' && order && order.complexOrders?.length === 0;
    const orderExecuted = order?.orderStatus === 'Filled';
    const modifyOrderType = isEquity && isEditable && !orderExecuted && !order.onClose;

    const result = {
        algoNotes: order?.floorNote,
        algoStrategy: noteAndNotHeldToAlgoStrategy(lang, order?.floorNote, order?.notHeld),
        disableQuantityInput: order && order?.leavesQuantity && order?.leavesQuantity !== order?.orderQuantity, // partial fill,
        displayOrderType,
        initialLimitPrice: order?.limitPrice,
        initialQuantity: order?.orderQuantity,
        initialQuantityQualifier: order?.quantityQualifier as 'Shares' | 'EvenDollar',
        initialStopPrice: order?.stopPrice,
        initialTimeInForce: timeInForce,
        isGtx,
        limitPrice: order?.limitPrice,
        modifyOrderType,
        openClose,
        orderId: order?.orderId,
        orderType,
        quantity: isPartialFill ? order?.leavesQuantity : order?.orderQuantity || NaN,
        quantityQualifier: order?.quantityQualifier as 'Shares' | 'EvenDollar',
        reinvestDividends: makeReinvestValue(order?.dividendOption),
        reinvestLongTermGains: makeReinvestValue(order?.longTermGainOption),
        reinvestShortTermGains: makeReinvestValue(order?.shortTermGainOption),
        stopPrice: order?.stopPrice,
        symbol: order?.symbol,
        timeInForce,
        tradeAction
    };

    return result;
};

export const getApiTimeInForce = ({
    assetFamily,
    isGtx,
    marketTimeSegment,
    orderType,
    timeInForce
}: {
    assetFamily: AssetFamily;
    isGtx: boolean;
    marketTimeSegment: MarketTimeSegment;
    orderType: ApiOrderType;
    timeInForce: InputTimeInForce;
}): ApiTimeInForce => {
    const logic: { rule: boolean; value: ApiTimeInForce }[] = [
        { rule: assetFamily === 'cryptos' && orderType === 'market', value: ApiTimeInForceOptions.fillOrKill as ApiTimeInForce },
        { rule: assetFamily === 'cryptos', value: ApiTimeInForceOptions.goodTillCanceled as ApiTimeInForce },
        { rule: isGtx && marketTimeSegment === 'premarket', value: ApiTimeInForceOptions.gtxPre as ApiTimeInForce },
        { rule: isGtx && marketTimeSegment === 'postmarket', value: ApiTimeInForceOptions.gtxPost as ApiTimeInForce },
        { rule: true, value: (timeInForce || ApiTimeInForceOptions.day) as ApiTimeInForce }
    ];

    return logic.find((x) => x.rule)?.value;
};

export const convertViewModelToOrderRequest = ({
    lang = Snex1English,
    marketTimeSegment,
    meta,
    selectedAccountNumber,
    viewModel
}: {
    lang?: Snex1LanguagePack;
    marketTimeSegment: MarketTimeSegment;
    meta: SecurityMetadata;
    selectedAccountNumber: string;
    viewModel: TradeTicketViewModel;
}): ApiTradeRequest => {
    const { floorNote, notHeld } = algoStrategyToNoteAndNotHeld(lang, viewModel?.algoStrategy);
    const assetClass = GetAssetClassFromMetadata(meta);
    const timeInForce = getApiTimeInForce({
        assetFamily: assetClass?.family,
        isGtx: viewModel?.isGtx,
        marketTimeSegment,
        orderType: viewModel?.orderType,
        timeInForce: viewModel?.timeInForce
    });
    const actualLimitPrice = viewModel?.limitPrice || viewModel?.initialLimitPrice;
    const actualStopPrice = viewModel?.stopPrice || viewModel?.initialStopPrice;
    const actualQuantity = viewModel?.quantity || viewModel?.initialQuantity;

    const request: ApiTradeRequest = {
        action: viewModel?.liquidate ? 'Liquidate' : viewModel?.tradeAction,
        accountNumber: selectedAccountNumber,
        floorNote,
        leg2Action: viewModel.leg2TradeAction,
        leg2DebitCredit: viewModel?.debitCredit === 'Even' ? 'Credit' : viewModel?.debitCredit,
        leg2Quantity: viewModel.leg2Symbol ? viewModel.leg2Quantity : null,
        leg2SecurityId: viewModel.leg2Symbol,
        notHeld,
        onClose: ['loc', 'moc'].includes(viewModel.displayOrderType),
        orderId: viewModel?.orderId,
        orderPrice: /^stop/.test(viewModel?.orderType) ? actualStopPrice : actualLimitPrice,
        orderType: viewModel?.orderType,
        quantity: actualQuantity,
        quantityQualifier: viewModel?.quantityQualifier,
        reinvestDividends: viewModel?.reinvestDividends,
        reinvestShortTermGains: viewModel?.reinvestShortTermGains,
        reinvestLongTermGains: viewModel?.reinvestLongTermGains,
        securityId: viewModel?.symbol,
        stopLimitPrice: viewModel?.orderType === 'stoplimit' ? actualLimitPrice : undefined,
        timeInForce
    };

    return request;
};
