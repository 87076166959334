// @ts-strict-ignore
import { Checkbox, IconButton, Popover, Table, TableBody, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { DropdownComponent, Flex, LoadingSpinner } from 'components';
import { useColors } from 'hooks/UseColors';
import React, { useMemo, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import './index.module.css';
import { VisibilityOff, Visibility, CheckBoxOutlineBlank, CheckBoxOutlined } from '@mui/icons-material';
import Logo from 'components/Logo';
import { GetAppTheme } from 'theming';
import { AppliedAppThemes } from 'phoenix/constants/AppliedAppTheme';
import { OnboardingInputError, OnboardingStep } from '../../../phoenix/onboarding/OnboardingTypes';
import { SnexButton } from 'components/SnexButton';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { MouseEventHandler } from 'react-select';
import { T } from 'phoenix/assets/lang/T';
import Lottie from 'lottie-react';
import { DropdownOption } from 'phoenix/models/DropdownOptions';
import useWindow from 'hooks/UseWindow';
import { useFuturesOnboardingStore } from '../Futures/Store';
import _ from 'lodash';
import { ChatWithSupportButton } from 'components/Support/ChatWithSuportButton';
import { ShadedTextList } from 'phoenix/assets/lang/Snex1LanguagePack';
import { ShadedTextDisplay } from 'components/ShadedTextDisplay/ShadedTextDisplay';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

export const OnboardingLoading = () => {
    return (
        <Flex column center style={{ height: '100%', width: '100%' }}>
            <LoadingSpinner />
        </Flex>
    );
};

export const OnboardingStepButton = ({
    label,
    isCurrent,
    isComplete,
    isEnabled,
    screenId,
    setScreenId
}: {
    label: string;
    isCurrent: boolean;
    isEnabled?: boolean;
    isComplete?: boolean;
    screenId: string;
    setScreenId: (value?: any) => void;
}) => {
    const colors = useColors();
    const theme = GetAppTheme();

    return (
        <Flex
            row
            align='center'
            style={{ flex: '1 1 auto', padding: '6px 12px', cursor: isEnabled ? 'pointer' : 'default' }}
            onClick={() => (isEnabled ? setScreenId(screenId) : null)}
        >
            <Typography
                variant='h6'
                color={!isEnabled && !isCurrent ? colors.disabledButtonText : theme === AppliedAppThemes.light ? 'primary' : 'textPrimary'}
                style={{ fontWeight: isCurrent ? 600 : 300, flex: 1, textAlign: 'left' }}
            >
                {label}
            </Typography>
            <Typography
                variant='h6'
                color={!isEnabled && !isCurrent ? colors.disabledButtonText : theme === AppliedAppThemes.light ? 'primary' : 'textPrimary'}
                style={{
                    width: '50px',
                    flex: 0,
                    opacity: isCurrent || isEnabled ? 0.75 : 0.45
                }}
            >
                {isComplete ? <CheckBoxOutlined style={{ color: colors.positiveValueColor }} /> : <CheckBoxOutlineBlank />}
            </Typography>
        </Flex>
    );
};

export const OnboardingSteps = ({
    steps,
    setScreenId,
    disabled,
    handleLogoClick
}: {
    steps: OnboardingStep[];
    setScreenId: (value?: any) => void;
    disabled?: boolean;
    handleLogoClick?: () => void;
}) => {
    const colors = useColors();

    return (
        <Flex column style={{ width: '250px', height: '100%' }}>
            <div role='button' onClick={handleLogoClick} style={{ cursor: handleLogoClick ? 'pointer' : 'default' }}>
                <Logo />
            </div>
            <Flex column fullWidth style={{ paddingTop: '64px' }}>
                {steps.map((s, skey) => (
                    <OnboardingStepButton
                        key={`step_${skey}`}
                        isEnabled={s.isEnabled() && !disabled}
                        isComplete={s.isComplete()}
                        setScreenId={setScreenId}
                        isCurrent={s.isCurrent()}
                        label={s.label}
                        screenId={s.onClickGoToScreenId}
                    />
                ))}
            </Flex>
            <ChatWithSupportButton style={{ minWidth: 150 }} />
        </Flex>
    );
};

export const OnboardingLottieForm = ({ position, data, loop, children }: { position?: 'left' | 'right'; data: any; loop?: boolean; children: JSX.Element }) => {
    const defaultOptions = {
        loop: !!loop,
        autoplay: true,
        animationData: data,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    const { viewportWidth } = useWindow();
    const showLottie = useMemo(() => viewportWidth >= 1100, [viewportWidth]);

    return (
        <Flex row fullWidth style={{ flexFlow: position === 'right' ? 'row-reverse' : 'row', gap: '1rem' }}>
            {showLottie ? (
                <Flex column center style={{ width: '40%' }}>
                    <Lottie
                        {...defaultOptions}
                        width={'100%'}
                        height={'auto'}
                        style={{
                            opacity: 1,
                            transition: '1s'
                        }}
                    />
                </Flex>
            ) : null}
            <Flex column center style={{ width: showLottie ? '60%' : '100%' }}>
                {' '}
                {children}
            </Flex>
        </Flex>
    );
};

export const OnboardingForm = ({
    title,
    children,
    steps,
    setScreenId,
    stepsDisabled,
    handleLogoClick
}: {
    title?: string;
    children?: JSX.Element | JSX.Element[];
    steps: OnboardingStep[];
    setScreenId: (value: any) => void;
    stepsDisabled?: boolean;
    handleLogoClick?: () => void;
}) => {
    const colors = useColors();

    const canvasStyle: React.CSSProperties = {
        width: '100%',
        height: '100%',
        position: 'relative',
        padding: 16
    };
    const stepsStyle: React.CSSProperties = {
        maxWidth: '300px',
        minWidth: '200px',
        height: '100%'
    };
    const formWrapperStyle: React.CSSProperties = {
        height: '100%'
    };

    const formStyle: React.CSSProperties = {
        minWidth: '532px',
        maxWidth: '800px',
        maxHeight: '96%',
        overflowY: 'scroll',
        borderRadius: '8px',
        backgroundColor: colors.cardBackgroundColor,
        margin: '0 auto',
        boxShadow: `0px 0px 11px 3px var(${colors.boxShadow})`
    };

    const formTitleStyle: React.CSSProperties = {
        color: colors.disclosureTextColor,
        padding: 8,
        borderRadius: '8px 8px 0 0',
        backgroundColor: colors.cardTitleBackgroundColor
    };

    const titleRow = (
        <Flex row fullWidth center style={formTitleStyle}>
            <Typography variant='h6' style={{ fontWeight: 300, fontSize: 14 }}>
                {title}
            </Typography>
        </Flex>
    );

    return (
        <Flex row fullWidth id='onboarding-form-canvas' align='center' style={canvasStyle}>
            <Flex row id='onboarding-steps' style={stepsStyle}>
                <OnboardingSteps setScreenId={setScreenId} steps={steps} disabled={stepsDisabled} handleLogoClick={handleLogoClick} />
            </Flex>
            <Flex center fullWidth column id='onboarding-form-wrapper' style={formWrapperStyle}>
                <Flex column id='onboarding-form' style={formStyle}>
                    {title ? titleRow : null}
                    <Flex column center style={{ padding: '0 64px 32px', gap: '16px' }}>
                        {children}
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export const OnboardingPageTitle = ({ title, subtitles }: { title: string; subtitles?: string[] }) => {
    const colors = useColors();

    return (
        <Flex column fullWidth align={'center'} style={{ marginBottom: '1rem' }}>
            <Typography
                variant='h2'
                style={{
                    padding: '2rem  0 0',
                    color: colors.selectListSelectedItemColor
                }}
            >
                {title}
            </Typography>
            {subtitles?.map((s, key) => (
                <Typography
                    variant='h4'
                    color={'textSecondary'}
                    key={`subtitle_${key}`}
                    style={{
                        opacity: '.85',
                        textAlign: 'center'
                    }}
                >
                    {s}
                </Typography>
            ))}
        </Flex>
    );
};

export const OnboardingInputRow = ({
    center,
    align,
    gap,
    noPadding,
    children
}: {
    center?: boolean;
    align?: 'flex-start' | 'center' | 'flex-end';
    gap?: string;
    noPadding?: boolean;
    children?: JSX.Element | JSX.Element[];
}) => {
    return (
        <Flex
            row
            center={!align && center !== false}
            fullWidth
            style={{
                gap: gap || '1rem',
                paddingTop: noPadding ? 0 : '1.5rem',
                position: 'relative',
                alignItems: align || undefined
            }}
        >
            {children}
        </Flex>
    );
};

export const OnboardingErrorPopover = ({ errors }: { errors: OnboardingInputError[] }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div>
            <IconButton className={'onboarding-textfield-error-icon'} onClick={handleClick}>
                <WarningAmberIcon />
            </IconButton>
            <Popover
                open={open}
                className={'onboarding-textfield-error-popover'}
                anchorEl={anchorEl}
                onClose={handleClose}
                onClick={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                {errors.map((e, eKey) => (
                    <Typography key={eKey}>{e?.error}</Typography>
                ))}
            </Popover>
        </div>
    );
};

export const OnboardingDropdown = ({
    options,
    label,
    readonly,
    name,
    value,
    errors,
    handleChange,
    button,
    maxMenuHeight,
    isLoading
}: {
    options: DropdownOption[];
    label?: string;
    name: string;
    handleChange: (key: string, value?: string | number) => void;
    value?: string | number;
    readonly?: boolean;
    errors: OnboardingInputError[];
    button?: JSX.Element;
    maxMenuHeight?: number;
    isLoading?: boolean;
}) => {
    const _errors = errors?.filter((e) => e?.key?.toLowerCase() === name.toLowerCase());
    const hasErrors = !!_errors.length;

    return (
        <Flex column fullWidth>
            <Typography variant='body1'>{label}</Typography>
            <Flex row fullWidth style={{ gap: '8px' }}>
                <Flex row fullWidth align={'center'} className={`onboarding-textfield${hasErrors ? ' error' : ''}`} style={{ position: 'relative' }}>
                    {isLoading ? (
                        <LoadingSpinner size={32} />
                    ) : (
                        <DropdownComponent readonly={true} options={options} onChange={(e) => handleChange(name, e.value)} value={value} noBorder maxMenuHeight={maxMenuHeight} />
                    )}
                    {hasErrors ? <OnboardingErrorPopover errors={_errors} /> : null}
                </Flex>
                {button}
            </Flex>
        </Flex>
    );
};

export const OnboardingTextField = ({
    label,
    secret,
    readonly,
    name,
    value,
    errors,
    handleChange,
    button,
    placeholder,
    maxLength
}: {
    label?: string;
    name: string;
    handleChange: (key: string, value?: string) => void;
    value?: string;
    secret?: boolean;
    readonly?: boolean;
    errors: OnboardingInputError[];
    button?: JSX.Element;
    placeholder?: string;
    maxLength?: number;
}) => {
    const _errors = errors?.filter((e) => e?.key?.toLowerCase() === name.toLowerCase());
    const [showSecret, setShowSecret] = useState<boolean>(false);
    const hasErrors = !!_errors.length;

    return (
        <Flex column fullWidth>
            <Typography variant='body1'>{label}</Typography>
            <Flex row fullWidth style={{ gap: '8px' }}>
                <Flex row fullWidth align={'center'} className={`onboarding-textfield${hasErrors ? ' error' : ''}`}>
                    {secret ? (
                        <IconButton onClick={() => setShowSecret(!showSecret)}>
                            {showSecret && placeholder && !value ? (
                                <VisibilityOff fontSize='small' style={{ cursor: 'pointer' }} />
                            ) : (
                                <Visibility fontSize='small' style={{ cursor: 'pointer' }} />
                            )}
                        </IconButton>
                    ) : null}
                    <input
                        maxLength={maxLength}
                        placeholder={placeholder}
                        type={!secret || showSecret ? 'text' : 'password'}
                        disabled={readonly}
                        className={'onboarding-textfield-input'}
                        id={name}
                        value={value}
                        onChange={(e) => {
                            e.preventDefault();
                            handleChange(name, e.target.value);
                        }}
                    />
                    {hasErrors ? <OnboardingErrorPopover errors={_errors} /> : <div style={{ width: '24px' }}></div>}
                </Flex>
                {button}
            </Flex>
        </Flex>
    );
};

export const OnboardingButton = ({
    label,
    sublabel,
    required,
    selected,
    handleClick,
    flavor,
    icon,
    width,
    disabled
}: {
    label?: string;
    sublabel?: string;
    required?: boolean;
    selected?: boolean;
    handleClick?: MouseEventHandler;
    flavor?: 'default' | 'yes' | 'no';
    icon?: any;
    width?: string;
    disabled?: boolean;
}) => {
    const colors = useColors();
    return (
        <SnexButton
            size='large'
            disabled={disabled}
            flavor={selected ? 'onboarding-selected' : 'onboarding'}
            style={{
                minWidth: width || '300px',
                padding: '15px',
                border: `1px solid ${required && !selected ? colors.red : colors.inputBorderColor}`
            }}
            onClick={handleClick}
        >
            <Flex row style={{ width: '100%' }}>
                {(icon || flavor === 'yes' || flavor === 'no') && (
                    <Flex column center style={{ marginRight: '1rem' }}>
                        {flavor === 'yes' ? <ThumbUpIcon /> : flavor === 'no' ? <ThumbDownIcon /> : icon}
                    </Flex>
                )}
                <Flex column style={{ flex: '1 !important' }}>
                    {label && (
                        <Typography variant='h5' align='left' style={{ fontWeight: 500 }}>
                            {label}
                        </Typography>
                    )}
                    {sublabel && (
                        <Typography variant='h6' align='left' style={{ fontWeight: 300 }}>
                            {sublabel}
                        </Typography>
                    )}
                </Flex>
            </Flex>
        </SnexButton>
    );
};

export const OnboardingErrorRow = ({ errors }: { errors?: OnboardingInputError[] }) => {
    const hasErrors = !!errors.length;
    const errorRow = hasErrors ? (
        <Flex row align='center' fullWidth style={{ gap: '8px', backgroundColor: `#B2292D65`, borderRadius: '4px', color: '#fff', padding: '8px 15px' }}>
            <WarningAmberIcon />
            {errors.map((e, eKey) => (
                <Typography key={eKey}>{e?.error}</Typography>
            ))}
        </Flex>
    ) : null;
    return errorRow;
};

export const OnboardingYesNo = ({
    value,
    question,
    yesSublabel,
    noSublabel,
    column,
    handleChange,
    errors,
    buttonWidth,
    disabled
}: {
    value?: boolean;
    question?: string;
    yesSublabel?: string;
    noSublabel?: string;
    column?: boolean;
    buttonWidth?: string;
    handleChange?: (val?: boolean) => void;
    errors?: OnboardingInputError[];
    disabled?: boolean
}) => {
    const body = (
        <>
            <OnboardingButton
                selected={value === true}
                label={'Yes'}
                sublabel={yesSublabel}
                flavor={'yes'}
                disabled={disabled}
                handleClick={() => (value === true ? handleChange(undefined) : handleChange(true))}
                width={buttonWidth}
            />
            <OnboardingButton
                selected={value === false}
                label={'No'}
                sublabel={noSublabel}
                disabled={disabled}
                flavor={'no'}
                handleClick={() => (value === false ? handleChange(undefined) : handleChange(false))}
                width={buttonWidth}
            />
        </>
    );
    return (
        <Flex column={true} fullWidth>
            {question ? (
                <Flex row align={'center'} fullWidth style={{ gap: '1rem' }}>
                    {question ? <Typography variant='body1'>{question}</Typography> : null}
                    {errors?.length ? <OnboardingErrorPopover errors={errors} /> : null}
                </Flex>
            ) : errors?.length ? (
                <Flex row align={'center'} fullWidth style={{ gap: '1rem' }}>
                    <IconButton className={'onboarding-textfield-error-icon'}>
                        <WarningAmberIcon />
                    </IconButton>
                    {errors.map((e, eKey) => (
                        <Typography className={'onboarding-textfield-error-text'} key={eKey}>
                            {e?.error}
                        </Typography>
                    ))}
                </Flex>
            ) : null}
            {!column ? (
                <Flex row fullWidth style={{ gap: '8px' }}>
                    {body}
                </Flex>
            ) : (
                body
            )}
        </Flex>
    );
};

export const OnboardingToast = ({ title, message, isError }: { title?: string; message?: string; isError?: boolean }) => {
    return (
        <Flex column>
            {isError || !!title ? <Typography variant='h6'>{isError ? T((t) => t.toasts.error) : title}</Typography> : null}
            <Typography variant='body1'>{message || '---'}</Typography>
        </Flex>
    );
};

export type OnboardingReviewItemProp = { data: any; screenId: string; secrets?: string[] };
export type OnboardingReviewItem = { key: string; value: any; isSecret: boolean; screenId: string };

export const OnboardingReviewSection = ({
    title,
    goto,
    items,
    errors
}: {
    title: string;
    items: OnboardingReviewItem[];
    goto: string;
    errors: OnboardingInputError[];
}) => {
    const handleClick = (screenId: string) => useFuturesOnboardingStore.getState().setValue('selectedScreenId', screenId);
    const [show, setShow] = useState<boolean[]>(items.map((x) => !x.isSecret));
    const colors = useColors();
    const toggleSecret = (index, value) => {
        const newShow = [...show];
        newShow[index] = value;
        setShow(newShow);
    };

    return (
        <Flex column fullWidth>
            <Flex row align='center' style={{ gap: '1rem', color: colors.selectListSelectedItemColor }} fullWidth onClick={() => handleClick(goto)}>
                <Typography variant='h4'>{title}</Typography>
                <EditIcon />
            </Flex>
            <Table>
                <TableBody>
                    {items.map((i, ikey) => (
                        <TableRow key={`review_${goto}_${ikey}`}>
                            <TableCell width={220}>{i.key.charAt(0).toUpperCase() + i.key.slice(1)}</TableCell>
                            <TableCell>
                                {show[ikey] ? (
                                    <Flex row align='center' fullWidth style={{ gap: '1rem' }}>
                                        {i.isSecret ? <Visibility fontSize='small' style={{ cursor: 'pointer' }} onClick={() => toggleSecret(ikey, false)} /> : null}
                                        <Typography
                                            variant='h6'
                                            onClick={() => {
                                                i.screenId && useFuturesOnboardingStore.getState().setValue('selectedScreenId', i.screenId);
                                            }}
                                        >
                                            {i.value?.toString()}
                                        </Typography>
                                    </Flex>
                                ) : (
                                    <VisibilityOff fontSize='small' style={{ cursor: 'pointer' }} onClick={() => toggleSecret(ikey, true)} />
                                )}
                            </TableCell>
                            <TableCell>
                                <Flex column fullWidth>
                                    {errors
                                        .filter((e) => e.key.toLowerCase().includes(i.key.toLowerCase()))
                                        ?.map((ee) => (
                                            <Flex row align={'center'} style={{ gap: '1rem', color: colors.red }}>
                                                <WarningAmberIcon />
                                                <Typography variant='h6' style={{ color: colors.red }}>
                                                    {ee.error}
                                                </Typography>
                                            </Flex>
                                        ))}
                                </Flex>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Flex>
    );
};

export type AgreementTextList = AgreementText[];
export interface AgreementText {
    url: string;
    text: ShadedTextList | string;
    subText?: ShadedTextList;
}
export interface AgreementRow {
    label: string;
    field: string;
    docs: AgreementTextList;
}

export const OnboardingAgreementsSections = ({
    agreementRows,
    getter,
    setter
}: {
    agreementRows: AgreementRow[];
    getter: (key: string) => boolean;
    setter: (key: string, value: any) => void;
}): JSX.Element => {
    const colors = useColors();
    const isEnabled = (key: string) => getter(`${key}.viewed`);
    const isChecked = (key: string) => getter(`${key}.accepted`);
    const toggle = (key: string) => setter(`${key}.accepted`, !isChecked(key));
    const setViewed = (key: string) => setter(`${key}.viewed`, true);

    const handleView = (url: string, key: string) => {
        window.open(url, '_blank');
        setViewed(key);
    };

    return (
        <Table>
            <TableBody>
                {agreementRows.map((r, index) => (
                    <TableRow key={index}>
                        <TableCell style={{ borderBottom: 'none', verticalAlign: 'top' }}>
                            <Flex row center style={{ gap: 2 }}>
                                {!isEnabled(r.field) ? (
                                    <Tooltip title={'Click the blue link to view the agreement and enable this field'}>
                                        <Checkbox
                                            checked={isChecked(r.field)}
                                            style={{
                                                cursor: 'pointer',
                                                color: isEnabled(r.field) ? (isChecked(r.field) ? colors.green : colors.red) : colors.grayDark,
                                                padding: 0
                                            }}
                                        />
                                    </Tooltip>
                                ) : (
                                    <Tooltip title={'Click here to accept the agreement'}>
                                        <IconButton disabled={!isEnabled(r.field)} onClick={() => toggle(r.field)} title={'Click here to accept the agreement'}>
                                            <Checkbox
                                                checked={isChecked(r.field)}
                                                style={{
                                                    cursor: 'pointer',
                                                    color: isEnabled(r.field) ? (isChecked(r.field) ? colors.green : colors.red) : colors.grayDark,
                                                    padding: 0
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Flex>
                        </TableCell>
                        <TableCell>
                            {r.docs.length ? (
                                r.docs.map((d, dIndex) => (
                                    <Flex
                                        key={`docs_${dIndex}`}
                                        column
                                        onClick={() => handleView(d.url, r.field)}
                                        style={{
                                            gap: '16px',
                                            marginBottom: '.5rem',
                                            cursor: 'pointer',
                                            color: colors.selectListSelectedItemColor
                                        }}
                                    >
                                        {Array.isArray(d.text) ? (
                                            <ShadedTextDisplay segments={d.text} style={{ fontSize: 14 }} />
                                        ) : (
                                            <Typography key={`${r.field}_${dIndex}`} variant='h6'>
                                                {d.text}
                                            </Typography>
                                        )}
                                        <ShadedTextDisplay segments={d.subText} style={{ fontWeight: 300, marginLeft: '1rem' }} />
                                        <Typography variant='h6' style={{ color: colors.selectListSelectedItemColor }}>
                                            {r.label}
                                        </Typography>
                                    </Flex>
                                ))
                            ) : (
                                <Typography variant='h6'>{r.label}</Typography>
                            )}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};
