// @ts-strict-ignore
import { usePositionsStore } from 'phoenix/stores/PositionsStore';
import { ApiData } from '../models';
import { QualifiedSecurityId } from '../models/QualifiedSecurityId';
import { GlobalState } from '../redux/GlobalState';
import { ApiPosition, OptionSymbol, Order } from '../redux/models';
import { QuoteAttribute } from '../util';

export type StandardQuote = Partial<{
    symbol?: string;
    companyName?: string;

    price: number;
    bid: number;
    ask: number;
    change: number;
    changePercent: number;
    previousClose: number;
    open: number;
    close: number;
    high: number;
    low: number;
    volume: number;
    lastTradeTime: number;

    // sizing
    unitFactor?: number;

    // extended hours
    extendedPrice?: number;
    extendedChange?: number;
    extendedChangePercent?: number;
    extendedPriceTime?: number;

    // futures
    pricePerPoint?: number;
    tickSize?: number;
    dayTradeMargin?: number;
    maintenanceMargin?: number;
    initialMargin?: number;

    // options
    openInterest?: number;
    percentChange?: number;
    last?: number;

    // crypto
    marketCap?: number;

    // control
    pristine: boolean;
    loading: boolean;
    error: any;
}>;

/** @deprecated Please use XS.Quotes.use or XS.Options.use */
export const QuoteSelector = (symbol: string): ((state: GlobalState) => StandardQuote) => {
    if (!symbol) return (_) => null;
    if (OptionSymbol.IsOptionSymbol(symbol)) {
        const optSym = new OptionSymbol(symbol);
        return (s: GlobalState) => {
            const qd = s.options.quotesByOsi[optSym.originalFormat === 'Gain/CQG' ? optSym.originalSymbol : optSym.toOsiSymbol()];
            const q = qd?.data;
            return {
                ...q,
                price: q?.last,
                bid: q?.bid,
                ask: q?.ask,
                change: q?.change,
                changePercent: q?.percentChange ? q.percentChange : q?.percentChange,
                previousClose: q?.previousClose,
                open: q?.open,
                close: q?.close,
                high: q?.high,
                low: q?.low,
                volume: q?.volume,
                lastTradeTime: q?.timestamp,
                companyName: undefined,
                unitFactor: q?.unitFactor,
                pristine: !qd || qd.pristine,
                loading: qd?.loading || false,
                error: qd?.error || null
            };
        };
    } else {
        return (s: GlobalState) => {
            const q = s.securityQuote.bySymbol[symbol];
            return {
                ...q,
                price: QuoteAttribute.getPrice(q?.data),
                bid: q?.data?.bid || q?.data?.bidPrice,
                ask: q?.data?.ask || q?.data?.askPrice,
                change: QuoteAttribute.getChange(q?.data, true),
                changePercent: QuoteAttribute.getChangePercent(q?.data),
                previousClose: q?.data?.previousClose,
                open: q?.data?.open,
                close: q?.data?.close,
                high: q?.data?.high,
                low: q?.data?.low,
                volume: q?.data?.volume,
                lastTradeTime: QuoteAttribute.getTime(q?.data),
                companyName: q?.data?.companyName,
                unitFactor: q?.data?.unitFactor,
                pristine: !q || q?.pristine,
                loading: q?.loading || false,
                error: q?.error || null,
                pricePerPoint: q?.data?.pricePerPoint,
                tickSize: q?.data?.tickSize
            };
        };
    }
};

// TODO: This file is called "ReduxSelectors" but is using a non-redux store as its primary source of data
/** @deprecated Please use PositionsStore's find/findOne method located in /stores/PositionsStore */
export const SinglePositionSelector = (
    symbol: string,
    accountNumber?: string,
    filter: (p: ApiPosition) => boolean = (_) => true
): ((state: GlobalState) => ApiPosition[]) => {
    const secId = QualifiedSecurityId.Parse(symbol);
    return (s) => {
        const meta = s.securities.bySymbol[secId?.id]?.metadata?.data;
        const secIdByNumber = QualifiedSecurityId.Parse(`B:${meta?.securityNumber}`);
        return usePositionsStore
            .getState()
            .positions?.filter((p) => (secId.MatchesPosition(p) || secIdByNumber.MatchesPosition(p)) && p.accountNumber === accountNumber && filter(p));
    };
};

export const OptionSizeSelector = (symbol: string): ((state: GlobalState) => ApiData<number>) => {
    if (!OptionSymbol.IsOptionSymbol(symbol)) return (s) => null;
    const osi = new OptionSymbol(symbol).toOsiSymbol();

    return (s) => {
        const result = s.options.sizesByOsi[osi];

        // #111992 Use minimum of 100 multiplier, relevant to adjusted options
        return result?.data && result?.data < 100 ? ({ data: 100 } as ApiData<number>) : result;
    };
};

export const OptionVolumeSelector = (symbol: string): ((state: GlobalState) => number) => {
    if (!OptionSymbol.IsOptionSymbol(symbol)) return (s) => null;
    const osi = new OptionSymbol(symbol).toOsiSymbol();
    return (s) => s.options.quotesByOsi[osi].data.volume;
};

export const UserFeatureSelector = (featureKey: string): ((state: GlobalState) => boolean | null) => {
    return (s) => {
        // If features are not yet loaded, return null
        if (s.user.myFeatures.pristine || s.user.myFeatures.loading) return null;

        // Otherwise, return true if the user has the requested feature
        return s.user.myFeatures.data.some((f) => f.key === featureKey);
    };
};

export const OrderSelector = (orderId: string): ((state: GlobalState) => Order) => {
    return (s: GlobalState) => {
        const open = s.orders.openOrders.data?.find((o) => o.orderId === orderId);
        if (open) return open;
        return s.orders.searchResults.data?.find((o) => o.orderId === orderId);
    };
};

// export const UserProgressSelector = (progressName: string): ((state: GlobalState) => boolean | null) => {
//     return s => {
//         if (s.user.myInfo?.loading || s.user.myInfo?.pristine) return null;
//         return s.user.myInfo.data?.progressInformation?.[ProgressNameKey(progressName)];
//     };
// };
