import React, { ReactNode } from 'react';
import { Menu, Typography, ListItemIcon } from '@mui/material';
import { MenuItem } from './MenuItem';
import { useColors } from 'hooks/UseColors';
import { useTelemetry } from 'hooks/UseTelemetry';
import { Link } from 'react-router-dom';

export interface IAnchoredMenuItem {
    disabled?: boolean;
    IconComponent?: string;
    eventTag?: string;
    icon?: ReactNode;
    label: string;
    onClick?: (e?: any) => void;
    textStyle?: any;
    route?: string;
}

interface AnchoredMenuProps {
    anchorEl: any;
    handleClose: (e?: any) => void;
    items?: IAnchoredMenuItem[];
    headerSection?: ReactNode;
    children?: any;
    topOffset?: number;
    showBackdropOverlay?: boolean;
}

const SeparatorLabel = '__SEP';
export const AnchoredMenuSeparator = { label: '__SEP', onClick: () => undefined, icon: null };

export const AnchoredMenu = (props: AnchoredMenuProps) => {
    const { anchorEl, handleClose, items, headerSection, children, topOffset, showBackdropOverlay } = props;

    const colors = useColors();
    const LogEvent = useTelemetry();

    const Separator = () => <hr style={{ border: `solid 1px ${colors.dividerColor}`, color: 'transparent', margin: 0 }} />;

    const AnchoredMenuItem = (item: IAnchoredMenuItem, key: number) =>
        item.label === SeparatorLabel ? (
            <Separator key={key} />
        ) : (
            <MenuItem
                disabled={item?.disabled}
                key={key}
                onClick={(e) => {
                    handleClose(e);
                    item?.onClick && item.onClick(e);
                    if (item.eventTag) LogEvent(`${item.eventTag} clicked`);
                }}
            >
                <>
                    {item.icon && <ListItemIcon style={item.textStyle}>{item.icon} </ListItemIcon>}
                    <Typography style={item.textStyle} variant='button'>
                        {item.label}
                    </Typography>
                </>
            </MenuItem>
        );

    return (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            keepMounted={false}
            MenuListProps={{ style: { padding: 0, backgroundColor: colors.cardBackgroundColor } }}
            open={!!anchorEl}
            style={{ padding: 0, zIndex: 9999999, marginTop: topOffset || 0, backgroundColor: showBackdropOverlay ? colors.colorOverlay : 'transparent' }}
            onClose={handleClose}
        >
            {headerSection}
            {headerSection && <Separator />}
            {children ||
                items?.map((item, key) =>
                    item.route ? (
                        <Link to={item.route} key={key}>
                            {AnchoredMenuItem(item, key)}
                        </Link>
                    ) : (
                        AnchoredMenuItem(item, key)
                    )
                )}
        </Menu>
    );
};
