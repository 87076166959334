// @ts-strict-ignore

import _ from 'lodash';
import { GetConfig } from '../constants/Config';
import { MarketStatus } from '../redux/models/Market/MarketStatus';
import { MarketTimeSegment } from '../util';
import { useSnexStore } from './UseSnexStore';
import { useMemo } from 'react';
import { MarketTimeSegments } from 'phoenix/constants/MarketTimeSegments';

// const TEST_SEGMENT: MarketTimeSegment = 'postmarket'
const TEST_SEGMENT: MarketTimeSegment = undefined


export const useMarketTimeSegmentV2: () => [MarketTimeSegment, () => any] = () => {
    const status = useSnexStore(s => s?.market?.status?.data);
    return useMemo(() => [ TEST_SEGMENT || GetSegmentFromStatus(status), _.noop], [status]);
}

export function GetMarketTimeSegmentV2(): MarketTimeSegment {
    return TEST_SEGMENT || GetSegmentFromStatus(GetConfig()?.Store.getState().market.status?.data);
}

function GetSegmentFromStatus(status: MarketStatus | undefined): MarketTimeSegment {
    if (!status) return 'loading';

    // TODO -- account for half days
    if (status?.currentHoliday) return MarketTimeSegments.holiday;
    else {
        switch (status?.segment) {
            case 'PRE_MARKET':
                return MarketTimeSegments.premarket;
            case 'POST_MARKET':
                return MarketTimeSegments.postmarket;
            case 'MAIN_MARKET':
                return MarketTimeSegments.open;
            case 'CLOSED':
                return MarketTimeSegments.closed;
            default:
                console.warn('WARNING: Market status is unknown: ', status?.segment);
                return 'loading';
        }
    }
}
