import { Button, Typography } from "@mui/material";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { Flex } from "components/Flex";
import { SnexButton } from "components/SnexButton";
import { useColors } from "hooks/UseColors";
import { T } from "phoenix/assets/lang/T";
import { useSnexStore } from "phoenix/hooks/UseSnexStore";
import { useText } from "phoenix/hooks/UseText";
import { GetMediantSSOUrl } from "phoenix/redux/actions/AccountsActions";
import { Account } from "phoenix/redux/models";
import { GoldenTicket } from "phoenix/util";
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

export const MediantSSOButton = (props:{selectedAccount: string}) => {

    const {selectedAccount} = props
    const dispatch = useDispatch()
    const colors = useColors()
    const mediantSSO = useSnexStore(s=> s.accounts.byNumber[selectedAccount]?.mediantSSO)
    const url = mediantSSO?.data?.url


    useEffect(() => {
        if(selectedAccount) dispatch(GetMediantSSOUrl(selectedAccount))
    }, [selectedAccount])

    const handleClick = (e:React.MouseEvent<any>) => {
        window.open(url, '_blank')
    }

    const button = useMemo(() => {
        if(!url) return null;
        return (
        <SnexButton  flavor='action-link' style={{ color: GoldenTicket }} onClick={handleClick} >
           {T(s => s.mediantSSO.buttonText)}
        </SnexButton>
       
        )
    }, [url])

    return button
}