// @ts-strict-ignore
import React, { ReactChild } from 'react';
import { Flex } from '..';
import style from './style.module.scss';

interface MenuItemProps {
    disabled?: boolean;
    onClick?: (e?: any) => void;
    children: ReactChild;
}

export const MenuItem = ({ children, disabled, onClick }: MenuItemProps) => {
    return (
        <Flex className={`${style.menuItemWrapper}${disabled ? ` ${style.disabled}` : ''}`} onClick={!disabled ? (e) => onClick(e) : null} tabIndex={!disabled && 0}>
            {children}
        </Flex>
    );
};
