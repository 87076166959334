// @ts-strict-ignore
import { ApiData, ApiOperation, ReduxAction } from '../../models';
import { ActionNames, Actions, GroupNameChecker } from '../actions/Constants';
import { Watchlist, WatchlistsState } from '../models';

const permitted = GroupNameChecker([Actions.Watchlists]);

export const WatchlistsReducer = (state: WatchlistsState = new WatchlistsState(), action: ReduxAction): WatchlistsState => {
    if (!permitted(action)) return state;

    const withNewState = (modify: (s: WatchlistsState) => void): WatchlistsState => {
        const newState = { ...state };
        modify(newState);
        return newState;
    };

    const optimisticDeleteSymbolFromWatchlist = (action: ReduxAction) =>
        withNewState((s) => {
            if (ActionNames.IsSuccess(action.type))
                s.all = s.all.succeededReplace?.((w) =>
                    w.map((l) =>
                        l.id === action.subject.listId
                            ? {
                                  ...l,
                                  securities: l.securities?.filter((security) => security.symbol !== action.subject.symbol)
                              }
                            : l
                    )
                );
            s.updatesById[action.subject.listId] = new ApiOperation().respond(action);
        });

    const addSymbolToWatchlist = (action: ReduxAction) => {
        return withNewState((s) => {
            if (ActionNames.IsSuccess(action.type))
                s.all = s.all.succeededReplace?.((w) =>
                    w.map((l) =>
                        l.id === action.subject.listId
                            ? {
                                  ...l,
                                  securities: action?.data?.securities || [
                                      ...l.securities,
                                      ...(Array.isArray(action.subject.symbol) ? action.subject.symbol.map((s) => ({ symbol: s })) : [{ symbol: action.subject.symbol }])
                                  ]
                              }
                            : l
                    )
                );
            s.updatesById[action.subject.listId] = new ApiOperation().respond(action);
        });
    };

    const updateWatchlist = (action) =>
        withNewState((s) => {
            if (ActionNames.IsSuccess(action.type)) s.all = s.all.succeededReplace?.((w) => w.map((l) => (l.id === action.subject.listId ? action.data : l)));
            s.updatesById[action.subject.listId] = new ApiOperation().respond(action);
        });

    if (Actions.Watchlists.Delete.matches(action.type)) {
        return withNewState((s) => {
            if (ActionNames.IsSuccess(action.type)) s.all = s.all.succeededReplace((w) => w.filter((l) => l.id !== action.subject.listId));
            s.deletesById[action.subject.listId] = new ApiOperation().respond(action);
        });
    }

    if (Actions.Watchlists.Create.matches(action.type)) {
        return withNewState((s) => {
            if (ActionNames.IsSuccess(action.type)) s.all = s.all.succeededConcat([action.data], 'append');
            s.create = new ApiOperation().respond(action);
        });
    }

    switch (action.type) {
        case Actions.Watchlists.GetAll.Loading:
            return { ...state, all: state.all.startLoading(state.all.data) };
        case Actions.Watchlists.GetAll.Success:
            return { ...state, all: state.all.succeeded(action.data) };
        case Actions.Watchlists.GetAll.Failure:
            return { ...state, all: state.all.failed(action.error) };

        case Actions.Watchlists.ReorderSymbolsBatch:
            return {
                ...state,
                all: state.all.succeededMutateOne(
                    (i: Watchlist) => i.id === action.subject.listId,
                    (list: Watchlist) => {
                        list.securities = action.subject.securities;
                    }
                )
            };

        case Actions.Watchlists.ReorderWatchlistsBatch: {
            return {
                ...state,
                all: state.all.succeededReplace((w) => w.map((l) => ({ ...l, sequence: action.subject.watchlists.filter((x) => x.id === l.id)[0].sequence })))
            };
        }

        case Actions.Watchlists.MoveItemBetweenLists: {
            const { fromListId, toListId, fromIdx, toIdx, item } = action.data;
            const withoutItem = state.all.succeededMutateOne(
                (i: Watchlist) => i.id === fromListId,
                (list: Watchlist) => list.securities.splice(fromIdx, 1)
            );
            const withItem = withoutItem.succeededMutateOne(
                (i: Watchlist) => i.id === toListId,
                (list: Watchlist) => list.securities.splice(toIdx, 0, item)
            );
            return { ...state, all: withItem };
        }

        // We only want to remove the singular symbol from a watchlist without expecting the updated list to come back from the api.
        case Actions.Watchlists.DeleteSymbols.Loading:
        case Actions.Watchlists.DeleteSymbols.Failure:
        case Actions.Watchlists.DeleteSymbols.Success:
            return optimisticDeleteSymbolFromWatchlist(action);

        case Actions.Watchlists.AddSymbols.Success:
            if (action?.data?.securities.find((item) => item.symbol === action.subject.symbol)) updateWatchlist(action);
            return addSymbolToWatchlist(action);

        // Optimistically add and remove symbols from watchlists; we only update the state on success, checkboxes and such are managed by components
        case Actions.Watchlists.AddSymbols.Loading:
        case Actions.Watchlists.AddSymbols.Failure:
        case Actions.Watchlists.Edit.Loading:
        case Actions.Watchlists.Edit.Success:
        case Actions.Watchlists.Edit.Failure:
            return updateWatchlist(action);
    }

    return state;
};
