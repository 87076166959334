import { Edit } from '@mui/icons-material';
import { Skeleton, Typography } from '@mui/material';
import { GetSelectedAccountForOrderRequest } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { CircularLogoPresentation } from 'components/CircularLogo/CircularLogo';
import { TabSelectPresentation } from 'components/TabSelect/TabSelect';
import useOptionsOpenClose from 'hooks/useOptionsOpenClose';
import { T } from 'phoenix/assets/lang/T';
import { OptionsOpenClose, OrderTypes, TradeActions } from 'phoenix/constants/Trade';
import { useQuantityHeld } from 'phoenix/hooks/UseQuantityHeld';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { ApiData } from 'phoenix/models';
import { ApiOrderType, ApiTradeAction } from 'phoenix/models/ApiTradeRequest';
import { AssetClass } from 'phoenix/models/AssetClasses/AssetClass';
import { EquityOptionsAssetClass } from 'phoenix/models/AssetClasses/EquityOptionsAssetClass';
import { FuturesAssetClass } from 'phoenix/models/AssetClasses/FuturesAssetClass';
import { FuturesOptionsAssetClass } from 'phoenix/models/AssetClasses/FuturesOptionsAssetClass';
import { useAssetClass, useAssetClassColors } from 'phoenix/models/AssetClasses/useAssetClass';
import { QualifiedSecurityId } from 'phoenix/models/QualifiedSecurityId';
import { MyInfo, OptionsOpenClose as OptionsOpenCloseType, OptionSymbol, OrderType, TradeAction } from 'phoenix/redux/models';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { SecurityMetadata } from 'phoenix/redux/models/Securities/SecurityMetadata';
import { SecurityLogo } from 'phoenix/redux/models/SecurityLogo/SecurityLogo';
import { IsOffshoreMutualFundByMetadata } from 'phoenix/util';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import React, { ReactElement, useMemo, useState } from 'react';
import { GetDeviceThemeVariant } from 'theming/GetDeviceThemeVariant';
import { AnchoredMenu, StyledIcon } from '../..';
import { Flex } from '../../Flex';
import { GetTradeTicketQuote } from '../Shared/helpers';
import { DisplayOrderType, TradeTicketViewModel } from '../Store/TradeTicketViewModel';
import { getDefaultPrice, useTradeTicketViewModel } from '../Store/useTradeTicketViewModel';
import './index.scss';

const DisplayorderTypes: Record<string, DisplayOrderType> = {
    ...OrderTypes,
    loc: 'loc',
    moc: 'moc'
};

interface TradeInputHeaderProps {
    action?: string;
    baseSymbol?: string;
    canSellIfNotHeld?: boolean;
    customActions?: { label: string; value: string }[];
    ignoreWindowSize?: boolean;
    onActionChange?: (value: ApiTradeAction) => void;
    openClose?: OptionsOpenCloseType;
    orderType?: OrderType;
    orderTypeDisabled?: boolean;
    permittedOrderTypes?: OrderType[];
    subtitle?: string;
    subtitleLoading?: boolean;
    symbol?: string;
    tabStyles?: React.CSSProperties;
}

export const TradeInputHeader = React.memo((props: TradeInputHeaderProps) => {
    const { customActions } = props;
    const {
        displayOrderType = '',
        initialQuantity,
        isGtx,
        leg2Symbol,
        limitPrice,
        modifyOrderType,
        modifyingOrder,
        orderType,
        quantity,
        quantityQualifier,
        setViewModel = () => undefined,
        stopPrice,
        symbol = '',
        timeInForce,
        tradeAction
    } = useTradeTicketViewModel<TradeTicketViewModel>();
    const orderTypeDisabled = props.orderTypeDisabled || (modifyingOrder && !modifyOrderType);
    const assetClass: AssetClass = useAssetClass(symbol);
    const baseSymbol = assetClass.derivative === 'option' ? new OptionSymbol(symbol).underlyingSymbol : symbol;
    const tradeability = assetClass.tradeability;
    const text = useText((s) => s);
    const userInfo = useSnexStore((s) => s.user?.myInfo.data) || ({} as MyInfo);
    const { onCloseEnabled } = userInfo;
    const quote = GetTradeTicketQuote(symbol);
    const selectedAccountNumber = GetSelectedAccountForOrderRequest(assetClass);
    const secId = QualifiedSecurityId.Parse(symbol);
    const effectiveQuantity = quantity || initialQuantity || 0;
    const held = useQuantityHeld(secId, selectedAccountNumber);
    const isShort = !held || effectiveQuantity > held;
    const { openClose } = useOptionsOpenClose();
    const meta = useSnexStore((s) => s.securities.bySymbol[symbol]?.metadata?.data) || ({} as SecurityMetadata);
    const isOffshore = IsOffshoreMutualFundByMetadata(meta);

    const effectiveSymbol = useMemo(() => {
        if (assetClass.type === FuturesOptionsAssetClass.type) {
            return new FuturesSymbol(new OptionSymbol(symbol).underlyingSymbol).baseContract;
        }
        if (assetClass.type === FuturesAssetClass.type) {
            return new FuturesSymbol(symbol).baseContract;
        }
        if (assetClass.type === EquityOptionsAssetClass.type) {
            return new OptionSymbol(symbol).underlyingSymbol;
        }

        return symbol;
    }, [assetClass.type, symbol]);

    const logo = useSnexStore((s) => s.logos.bySymbol[effectiveSymbol]);
    // Allow the parent to override order types - these can change conditionally
    const rawOrderTypes = props?.permittedOrderTypes || tradeability.permittedOrderTypes;
    const marketTime = useSnexStore((s) => s.market?.status?.data);
    const tenMinutesFromNow = new Date(new Date().getTime() + 600000);
    const onCloseTimeValid = ['MAIN_MARKET', 'PRE_MARKET'].includes(marketTime?.segment) && new Date(marketTime?.todaysSchedule?.postMarketStart) > tenMinutesFromNow;

    // Set default prices on order type OR trade action change, if the user has not entered/manipulated any values
    const getNewDefaultPrices = (field: 'tradeAction' | 'orderType', value: ApiTradeAction | DisplayOrderType) => {
        const effectiveOrderType = field === 'orderType' ? value : orderType;
        const effectiveTradeAction = field === 'tradeAction' ? value : tradeAction;
        const result: Pick<TradeTicketViewModel, 'initialLimitPrice' | 'initialStopPrice'> = {};

        if (!limitPrice && !leg2Symbol) {
            const converted: ApiOrderType = effectiveOrderType === 'loc' ? 'limit' : (effectiveOrderType as ApiOrderType);
            const defaultPrice = getDefaultPrice({ assetClass, orderType: converted, quote, tradeAction: effectiveTradeAction as ApiTradeAction });

            result.initialLimitPrice = defaultPrice;
        }

        if (!stopPrice) {
            const converted: ApiOrderType = effectiveOrderType === 'loc' ? 'limit' : (effectiveOrderType as ApiOrderType);
            const defaultPrice = getDefaultPrice({ assetClass, orderType: converted, quote, tradeAction: effectiveTradeAction as ApiTradeAction });

            result.initialStopPrice = defaultPrice;
        }

        return result;
    };

    const onOrderTypeUpdate = (value: DisplayOrderType) => {
        const newState: Pick<TradeTicketViewModel, 'displayOrderType' | 'isGtx' | 'orderType' | 'orderType' | 'initialLimitPrice' | 'initialStopPrice' | 'timeInForce'> =
            {
                ...getNewDefaultPrices('orderType', value),
                displayOrderType: value,
                orderType: value as ApiOrderType
            };

        // If the order type switches to stop, stoplimit, or market and the ticket is currently set to gtx, turn off gtx.
        // If the order type is changing in a way that removes a field, and the user has entered values in that field, set them to the initial value
        // Important to keep this after the above changes to override any default price settings
        if (['stop', 'stoplimit', 'market'].includes(value) && isGtx) newState.isGtx = false;
        if (!value.includes('stop') && !!stopPrice) {
            newState.initialStopPrice = stopPrice;
        }

        if (!value.includes('limit') && !!limitPrice) {
            newState.initialLimitPrice = limitPrice;
        }

        if (value === 'market') {
            newState.timeInForce = 'Day';
        }

        setViewModel(newState);
    };

    const onActionUpdate = (value: ApiTradeAction) => {
        const newState: Pick<TradeTicketViewModel, 'tradeAction' | 'timeInForce' | 'quantityQualifier' | 'initialQuantity' | 'quantity'> = {
            ...getNewDefaultPrices('tradeAction', value),
            tradeAction: value as TradeAction
        };

        // If switching to an equity short sell when user has selected any TIF but "Day", reset and they will have to manually select "Day"
        if (value === 'Sell' && assetClass.type === 'equity' && isShort && timeInForce !== 'Day') {
            newState.timeInForce = undefined;
        }

        // Offshore mutual fund "Buy" can only be sent with a quantityQualifier of EvenDollar

        if (isOffshore && value === 'Buy') {
            newState.quantityQualifier = 'EvenDollar';

            // If switching from selling shares, reset the quantity
            if (quantityQualifier === 'Shares') {
                newState.initialQuantity = undefined;
                newState.quantity = undefined;
            }
        }

        if (props.onActionChange) props.onActionChange(value);

        setViewModel(newState);
    };

    const displayOrderTypes = [
        ...rawOrderTypes.map((x) => ({
            eventTag: `Order Type changed to ${x}`,
            label: text.tradeTicket.input.orderType[x],
            onClick: () => onOrderTypeUpdate(x),
            value: x
        })),
        ...(tradeability.canTradeOnClose && onCloseEnabled
            ? [
                  {
                      disabled: !onCloseTimeValid,
                      label: text.tradeTicket.input.orderType.loc,
                      eventTag: 'Order Type changed to loc',
                      onClick: () => setViewModel({ displayOrderType: DisplayorderTypes.loc, orderType: OrderTypes.limit, timeInForce: 'Day' }),
                      value: DisplayorderTypes.loc
                  },
                  {
                      disabled: !onCloseTimeValid,
                      label: text.tradeTicket.input.orderType.moc,
                      eventTag: 'Order Type changed to moc',
                      onClick: () => setViewModel({ displayOrderType: DisplayorderTypes.moc, orderType: OrderTypes.market, timeInForce: 'Day' }),
                      value: DisplayorderTypes.moc
                  }
              ]
            : [])
    ];

    const actionTypes = useMemo(() => {
        return (
            customActions ||
            [
                { label: T((s) => s.tradeTicket.input.action.buy), value: TradeActions.Buy },
                { label: T((s) => s.tradeTicket.input.action.sell), value: TradeActions.Sell }
            ].filter((x) => !!x)
        );
    }, [customActions]);

    const { primaryColor = '' } = useAssetClassColors(assetClass, GetDeviceThemeVariant());

    return (
        <TradeInputHeaderPresentation
            action={tradeAction}
            color={primaryColor}
            ignoreWindowSize={modifyingOrder}
            onClose={['loc', 'moc'].includes(displayOrderType)}
            openClose={openClose}
            showLogo={modifyingOrder}
            subtitleLoading={openClose === OptionsOpenClose.Loading}
            {...{
                ...props,
                onActionUpdate,
                orderType,
                orderActionDisabled: modifyingOrder,
                orderTypeDisabled,
                actionTypes,
                baseSymbol,
                logo,
                displayOrderTypes,
                symbol,
                text
            }}
        />
    );
});

type TradeInputHeaderPresentationProps = {
    action?: string;
    actionTypes?: { label: string; value: string }[];
    baseSymbol?: string;
    color: string;
    ignoreWindowSize?: boolean;
    logo: ApiData<SecurityLogo>;
    onClose: boolean;
    onFieldUpdate?: (field: 'tradeAction' | 'orderType', value: ApiTradeAction | DisplayOrderType) => void;
    onActionUpdate: (value: ApiTradeAction) => void;
    openClose?: OptionsOpenCloseType;
    orderType?: string;
    orderActionDisabled?: boolean;
    orderTypeDisabled?: boolean;
    displayOrderTypes: {
        disabled?: boolean;
        eventTag: string;
        label: string;
        value: string;
    }[];
    showLogo?: boolean;
    subtitle?: string;
    subtitleLoading?: boolean;
    symbol: string;
    tabStyles?: React.CSSProperties;
};

export const TradeInputHeaderPresentation = (props: TradeInputHeaderPresentationProps): ReactElement => {
    const {
        action,
        actionTypes = [],
        baseSymbol = '',
        displayOrderTypes = [],
        ignoreWindowSize,
        logo,
        onClose,
        onActionUpdate,
        orderType = '',
        orderActionDisabled,
        orderTypeDisabled,
        showLogo,
        subtitle,
        subtitleLoading,
        symbol,
        tabStyles
    } = props;
    const secId = QualifiedSecurityId.Parse(symbol);
    const [menuEl, setMenuEl] = useState<HTMLElement | null>(null);

    const orderTypeLabel = displayOrderTypes?.find((x) => {
        if (onClose) {
            const orderTypeMap = {
                limit: 'loc',
                market: 'moc'
            };
            return x.value === orderTypeMap[props?.orderType as 'market' | 'limit'];
        }
        return x.value === orderType;
    })?.label;

    return (
        <div className={`trade-header${ignoreWindowSize ? ' ignore-window-size' : ''}`}>
            <Flex row align='center'>
                {showLogo && (
                    <div style={{ marginRight: 10 }}>
                        <CircularLogoPresentation loaded={true} logo={logo} size={25} symbol={symbol} />
                    </div>
                )}
                <Typography className='security-id' variant='h5'>
                    {QualifiedId.RemovePrefix(baseSymbol) || QualifiedId.RemovePrefix(secId.id || '')}
                </Typography>
            </Flex>
            {subtitle ? (
                <Typography className='subtitle' variant='h5'>
                    {subtitle}
                </Typography>
            ) : null}
            {subtitleLoading ? <Skeleton animation='wave' style={{ width: 100 }} /> : null}

            {actionTypes.length ? (
                <TabSelectPresentation
                    defaultSelected={action}
                    disabled={orderActionDisabled}
                    tabStyles={{ ...tabStyles }}
                    values={orderActionDisabled ? actionTypes.filter((a) => a.value === action) : actionTypes}
                    onSelect={onActionUpdate}
                />
            ) : null}

            {displayOrderTypes?.length < 1 ? <div style={{ width: 40 }} /> : null}
            {displayOrderTypes?.length >= 1 && (
                <div id='tour-04-order-type'>
                    <Flex row align='center' justify='flex-end' style={{ background: 'transparent', cursor: 'pointer', width: 100 }}>
                        <Flex
                            align='center'
                            className='order-type-label'
                            onClick={
                                orderTypeDisabled
                                    ? undefined
                                    : (e) => {
                                          setMenuEl(e.target);
                                      }
                            }
                        >
                            <Typography variant='h5' style={{ fontSize: 16 }}>
                                {orderTypeLabel}
                            </Typography>
                            {!orderTypeDisabled && <StyledIcon IconComponent={Edit} />}
                        </Flex>
                        <AnchoredMenu anchorEl={menuEl} handleClose={() => setMenuEl(null)} items={displayOrderTypes} />
                    </Flex>
                </div>
            )}
        </div>
    );
};
