// @ts-strict-ignore
import { Button, Typography, Skeleton, Tooltip, TooltipProps, styled, tooltipClasses, colors } from '@mui/material';
import { Brightness2, Brightness4, Brightness5, BugReport, Chat, Class, Description, Email, ExitToApp, MailOutline, Person, Receipt, School, Visibility, WbSunny } from '@mui/icons-material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Flex, UserAvatar } from '../';
import { ResolveEnvironment } from 'phoenix/constants';
import { useSupportByVariant } from 'phoenix/constants/Support';
import { useOperationSubmitting } from 'phoenix/hooks/UseOperationSubmitting';
import { GetAggregateSummaryAction } from 'phoenix/redux/actions';
import { StreamingDebugger } from 'components/StreamingDebugger';
import { FormatNumber, GetClosedSimulatedHours, GetHolidaySimulatedHours, GetMarketSimulatedHours, GetPostmarketSimulatedHours, GetPremarketSimulatedHours, MarketTimeSegment } from 'phoenix/util';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { AnchoredMenu, AnchoredMenuSeparator } from '../AnchoredMenu';
import { AnimatedModal } from '../Modals/AnimatedModal';
import './Header.scss';
import { useColors } from 'hooks/UseColors';
import { GoToLogout } from 'util/GoToLogin';
import { Routes } from 'util/Routes';
import { useProgress } from 'hooks/UseProgress';
import { MyProgress } from 'phoenix/redux/models/Users/MyProgress';
import { TelemetryProvider } from 'providers/TelemetryContext';
import { TelemetryCategories } from 'constants/Telemetry/TelemetryCategories';
import { useOpenChatWindow } from 'hooks/UseChat';
import { IsWhitelabel } from 'phoenix/constants/Whitelabels';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { useAccountValuation } from 'phoenix/hooks/UseAccountValuation';
import { Link } from 'react-router-dom';
import { BuyingPowerStore_Load, useBuyingPowerStore } from 'phoenix/stores/BuyingPowerStore';
import { MarketHoursBadge } from 'components/MarketHoursBadge';
import { MarketTimeSegments } from 'phoenix/constants/MarketTimeSegments';
import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import _ from 'lodash';

const adminRoles = new Set(['admin', 'qa']);
const adminEnvs = new Set(['local', 'development', 'test']);
const devEnvs = new Set(['local', 'development']);

export const HeaderUserAvatar = TelemetryProvider(() => {
    const colors = useColors();
    const [progress, setProgress] = useProgress();
    const env = useMemo(() => ResolveEnvironment(), []);
    const isAdmin = useSnexStore((s) => adminEnvs.has(env) || adminRoles.has(s.user.myInfo?.data?.role));
    const loggedIn = useSnexStore((s) => s.login.loggedIn);
    const loginLoading = loggedIn.pristine || loggedIn.loading;
    const myInfo = useSnexStore((s) => s.user?.myInfo);
    const myInfoLoading = myInfo.pristine || myInfo.loading;
    const text = useText((s) => s.header);
    const unreadCount = useSnexStore((s) => s.messages.unreadCount?.data);
    const [streamingDebuggerOpen, setStreamingDebuggerOpen] = useState(false);
    const [nAsyncOperations] = useOperationSubmitting();
    const [profileAnchorEl, setProfileAnchorEl] = useState(null);

    const handleClick = useCallback((e: Event) => setProfileAnchorEl(e.target), []);

    const handleReactivateToursClick = useCallback(() => {
        setProgress(MyProgress.resetTours(progress));
    }, [text]);

    const { email } = useSupportByVariant();
    const handleSupportMailClick = useCallback(() => {
        window.location.href = email.mailto;
    }, []);

    useEffect(() => {
        if (loggedIn.error) GoToLogout();
    }, [env, loggedIn.error]);

    const openChatWindow = useOpenChatWindow();

    if (devEnvs.has(env) && ((loggedIn.error && !loginLoading) || (!myInfo.data && !myInfoLoading))) {
        return (
            <Link to={Routes.profile('debug')}>
                <Button>Log In</Button>
            </Link>
        );
    }

    const isWhitelabel = IsWhitelabel();
    const messagesColor = '#398ae6';

    const menuItems1 = [
        { label: text.profile, route: Routes.profile('personal-info'), icon: <Person />, eventTag: 'Profile Screen' },
        { label: text.documents, route: Routes.documents(), icon: <Description />, eventTag: 'Documents Screen' },
        {
            label: (
                <Flex row>
                    {text.messages}
                    {unreadCount > 0 && (
                        <div
                            style={{
                                backgroundColor: messagesColor,
                                marginLeft: '5px',
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%'
                            }}
                        />
                    )}
                </Flex>
            ),
            route: Routes.messages(),
            icon: <MailOutline />,
            eventTag: 'Messages'
        },
        { label: text.transactions, route: Routes.transactions(), icon: <Receipt />, eventTag: 'Transactions Screen' },
        { label: text.projectedIncome, route: Routes.projectedIncome(), icon: <Visibility />, eventTag: 'Projected Income Screen' }
    ];

    const menuItems2 = isWhitelabel
        ? [AnchoredMenuSeparator]
        : [
              { label: text.disclosures, route: Routes.disclosures(), icon: <Class />, eventTag: 'Disclosures Screen' },
              AnchoredMenuSeparator,
              { label: text.chatWithUs, onClick: openChatWindow, icon: <Chat />, textStyle: { color: colors.primaryItemColor }, eventTag: 'Support Chat' },
              { label: text.emailUs, onClick: handleSupportMailClick, icon: <Email />, textStyle: { color: colors.primaryItemColor }, eventTag: 'Email Support' }
          ];

    const menuItems3 = [
        { label: text.resetTours, onClick: handleReactivateToursClick, icon: <School />, textStyle: { color: colors.primaryItemColor }, eventTag: 'Reset Tours' },
        AnchoredMenuSeparator,
        { label: text.logOut, onClick: () => GoToLogout(), icon: <ExitToApp />, eventTag: 'Logout' },
        isAdmin && { label: 'Streaming Debug', onClick: () => setStreamingDebuggerOpen(true), icon: <BugReport /> },
        isAdmin && { label: 'Support', route: Routes.support('funding', 'limits'), icon: <SupportAgentIcon />, eventTag: 'Support Screen' }
    ];

    const items = [].concat(menuItems1).concat(menuItems2).concat(menuItems3);

    return (
        <Flex column style={{ marginLeft: 35}}>
            <Flex center row className='stonex-header-avatar' id='tour-05-profile' tabIndex={0} >
                <MarketHoursIcon size={42} />
                <UserAvatar size={48} spinning={!!nAsyncOperations} onClick={handleClick}/>
            </Flex>
            <AnimatedModal open={streamingDebuggerOpen} onClose={() => setStreamingDebuggerOpen(false)}>
                <StreamingDebugger />
            </AnimatedModal>
            <AnchoredMenu showBackdropOverlay anchorEl={profileAnchorEl} handleClose={() => setProfileAnchorEl(null)} headerSection={<MenuHeader />} items={items} />
        </Flex>
    );
}, TelemetryCategories.headerProfileMenu);

const MenuHeader = React.memo(() => {
    const text = useText((s) => s.header);

    const dispatch = useDispatch();
    const summary = useSnexStore((s) => s.accountSummary.aggregate);
    const buyingPower = useBuyingPowerStore();
    const user = useSnexStore((s) => s.user.myInfo?.data);
    const { value } = useAccountValuation();

    useEffect(() => {
        dispatch(GetAggregateSummaryAction());
        BuyingPowerStore_Load();
    }, []);

    const loading = useMemo(() => summary.pristine, [summary.pristine]);

    return (
        <Flex column style={{ padding: '10px 20px', minWidth: 260 }}>
            <Flex>
                <Typography variant='h6'>{user?.name}</Typography>
            </Flex>
            <Flex row style={{ padding: '8px 0' }}>
                <MenuHeaderDataCell label={text.buyingPower} loading={loading} value={buyingPower?.summary?.buyingPower} />
                <MenuHeaderDataCell label={text.portfolioValue} loading={loading} value={value} />
            </Flex>
            <Flex row style={{ padding: '8px 0' }}>
                <MarketHoursBadge />
            </Flex>
        </Flex>
    );
});

const MenuHeaderDataCell = React.memo((props: { value: number; label: string; loading?: boolean }) => (
    <Flex column style={{ flex: 1, boxSizing: 'border-box' }}>
        {!props.loading ? <Typography variant='h6'>{FormatNumber.toMoney(props.value)}</Typography> : <Skeleton animation='wave' style={{ width: 30 }} />}
        <Typography variant='caption'>{props.label}</Typography>
    </Flex>
));


const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => {
    const colors = useColors()
    return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: colors.cardBackgroundColor,
      padding: 0,
      margin: '-12px 0 0',
    },
  }});

const MarketHoursIcon = ({ size }) => {
    const [marketTimeSegment] = useMarketTimeSegmentV2();
    const [_marketTimeSegment, setMarketTimeSegment] = useState<MarketTimeSegment>()

    const colors = useColors()
    
    const flavor = useMemo(() => {
        // prettier-ignore
        switch (_marketTimeSegment) {
            case MarketTimeSegments.open: return 'green'
            case MarketTimeSegments.premarket: return 'orange'
            case MarketTimeSegments.postmarket: case 'closed': case 'loading': default: return 'blue'
        }
    }, [_marketTimeSegment]);

    const Icon = useMemo(() => {
        // prettier-ignore
        switch (_marketTimeSegment) {
            case MarketTimeSegments.closed: return Brightness2
            case MarketTimeSegments.open: return WbSunny
            case MarketTimeSegments.postmarket: return Brightness4
            case MarketTimeSegments.premarket: return Brightness5
            default: return Brightness5
        }
    }, [_marketTimeSegment]);

    const useBadgeColors = () => {
        switch (flavor) {
            case 'blue':
                return {
                    reg: colors.primaryItemColor,
                    light: colors.primaryItemBackdropColor
                }
            case 'green':
                return { reg: colors.green, light: colors.greenLight }
            case 'orange':
                return { reg: colors.orange, light: colors.orangeLight }
            // case 'red':
            //     return { reg: colors.red, light: colors.redLight }
            default:
                return { reg: colors.green, light: colors.greenLight }
        }
    }

    const {reg, light} = useBadgeColors()
    const iconSize = size * .55
    const iconOutX = (size - iconSize) - 6
    const iconInX = size + 6
    const [iconX, setIconX] = useState<number>(iconInX)

    const sleep = (ms) => { return new Promise(resolve => setTimeout(resolve, ms));  }

    const slideOut = (seg: MarketTimeSegment) => { sleep(500).then(() => {setMarketTimeSegment(seg); setIconX(iconOutX)}) }
    const slideInAndOut = (seg: MarketTimeSegment) =>  {
            setIconX(iconInX)
            sleep(1250).then(() => {
                setMarketTimeSegment(seg);
                setIconX(iconOutX)
            })
    }
    const handleSlide = (seg: MarketTimeSegment) =>  {iconX === iconOutX ? slideInAndOut(seg) : slideOut(seg)}
    
    useEffect(() => { handleSlide(marketTimeSegment) }, [marketTimeSegment])

    return (
        <div style={{backgroundColor: colors.cardBackgroundColor, borderRadius: size / 2, transition: '2s transform ease', transform: `translate(${iconX}px, 0)`}}>
            <HtmlTooltip
            placement='bottom-end'
            PopperProps={{style:{zIndex:9999999, padding: 0, backgroundColor: 'transparent', borderRadius: 4, wordWrap: 'normal'}}}
            title={ <div style={{ padding: 0, margin: 0}}><MarketHoursBadge /></div> }>
                <div style={{
                    height: size,
                    width: size,
                    borderRadius: size / 2,
                    padding: 2,
                    boxSizing: 'border-box',
                    backgroundColor: light,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: reg,
                }}>
                    <Icon style={{width: iconSize, height: iconSize}} />
                </div>
            </HtmlTooltip>
        </div>

    );
};

