// @ts-strict-ignore
import { Flex, ToastEmitter } from "components"
import { useText } from "phoenix/hooks/UseText"
import React, { useEffect, useMemo, useState } from "react"
import { OnboardingInputRow, OnboardingTextField, OnboardingToast } from "../../../Base/OnboardingControls"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import * as Sentry from '@sentry/react'
import { useRegistrationStore } from "../../Store"
import { getPersonalErrors } from "../Personal/Validation"
import { getVerifyEmailErrors } from "./Validation"
import { SnexButton } from "components/SnexButton"
import { colors, Typography } from "@mui/material";
import { useColors } from "hooks/UseColors";
import { EquitiesOnboardingActionsV2 } from "phoenix/onboarding/equities/actions/EquitiesOnboardingActions";
import { GetSystemMessageDeliveryControlsAction } from "phoenix/redux/actions";
import { useAppDispatch } from "AppRoot";
import { AddToast, useToasts } from "react-toast-notifications";
import { T } from "phoenix/assets/lang/T";
import { ResolveFrontend, Urls } from "phoenix/constants";
import { Okta } from "phoenix/util/Okta";



export const HandleVerifyAndLoginClick = async (addToast: AddToast) => {
    const {data, verificationCode, clearData, setValue} = useRegistrationStore.getState()
    setValue('verifying', true)
    const { firstName, lastName, email, password, phone, areaCode, countryCode } = data
    const verifyRes = await EquitiesOnboardingActionsV2.verifySignupCodeAndComplete({ email, password, activationCode: verificationCode, firstName, lastName, citizenshipCountry: 'US',
        phoneNumber: areaCode + phone, countryCode: countryCode.toString() });
    if (!verifyRes.success) {
        const err = {...verifyRes, 
            firstName,
            lastName,
            email,
            phoneNumber: areaCode + phone,
            countryCode: countryCode || 1,
            citizenshipCountry: 'US',
        }
        Sentry.captureException(err)
        addToast(<OnboardingToast message={'Verification failed.'} isError={false}/>, { appearance: 'error' })
        setValue('codeIsVerified', false)
    } else {
        setValue('codeIsVerified', true)
        // use cookies
        const oktaResponse = await Okta._getSessionToken(data.email, data.password)
        const sessionToken = oktaResponse.data?.sessionToken
        const redirectUrl = ResolveFrontend()
        const loginUrl = Urls.authentication.okta.webLogin(sessionToken, redirectUrl)
        clearData()
        window.location.href = loginUrl 
        
    }
    setValue('verifying', false)
}

export const Registration_VerifyEmail_Form = () => {
    const {data, verificationCode, codeIsVerified, setValue} = useRegistrationStore()
    const text = useText(t => t.webRegistration)
    const errors = useMemo(() => getVerifyEmailErrors(), [data, verificationCode, codeIsVerified])
    const [resending, setResending] = useState<boolean>(false)
    const { addToast } = useToasts();
    
    useEffect(() => {
        setValue('codeIsVerified', null)
    }, [verificationCode])

   
    
    const handleResendClick = async () => {
        setResending(true)
        try
        {
            const result = await EquitiesOnboardingActionsV2.resendVerificationCode(data.email)
            if(result?.success) {
                addToast(<OnboardingToast title={'Success'} message={`${text.resentVerificationCode} to ${data.email}`} isError={false}/>, { appearance: 'info' })
            } else {
                addToast(<OnboardingToast message={result?.error} isError={false}/>, { appearance: 'error' })
            }
        }
        catch(e)
        {
            addToast(<OnboardingToast message={e} isError={false}/>, { appearance: 'error' })
        }
        setResending(false)
    }

    return(
    <Flex column fullWidth center style={{minWidth: '450px'}}>
        <OnboardingInputRow noPadding align='flex-end'>  
                <OnboardingTextField name='verificationCode' label={text.verificationCode} value={verificationCode} 
                handleChange={setValue} errors={errors?.filter(e=> e.key === 'verificationCode')} 
                button={codeIsVerified? null : 
                    <SnexButton flavor={resending ? 'loading': undefined} onClick={handleResendClick} style={{padding: '10px 15px', margin: 0}}>{text.resendCode}</SnexButton> }/>
                    
        </OnboardingInputRow>
    </Flex>
    )
}