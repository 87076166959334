// @ts-strict-ignore
import { ArrowForwardIosRounded } from '@mui/icons-material';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { TelemetryCategories } from 'constants/Telemetry/TelemetryCategories';
import { useTelemetry } from 'hooks/UseTelemetry';
import { OrderStatusInput } from 'phoenix/constants';
import { useText } from 'phoenix/hooks/UseText';
import { useAssetClass, useAssetClassColors } from 'phoenix/models/AssetClasses/useAssetClass';
import { ConvertTradeableSecurityTypeToApiTradeableSecurityType, GetAllOpenOrdersAction, SearchOrdersV3Action } from 'phoenix/redux/actions/OrdersActions';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { GetVariant } from 'phoenix/util/Variant';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetDeviceThemeVariant } from 'theming/GetDeviceThemeVariant';
import { Routes } from 'util/Routes';
import { Flex, SectionHeader } from '..';
import './index.css';
import { OrderList } from './OrderList';

interface OpenOrdersProps {
    symbol?: string;
    accountNumber?: string;
    label?: string;
    status?: OrderStatusInput;
    securityKnown?: boolean;
    unexpandedOrders?: number;
    noHistoryLink?: boolean;
}

//should probably move the status-based inclusion logic to to the reducer
const openStatuses = [
    'Open',
    'Working', // futures
    'NotProcessed', // mutual funds,
    'Pending'
];

export const OpenOrders = React.memo((props: OpenOrdersProps) => {
    const LogEvent = useTelemetry();
    const dispatch = useDispatch();
    const orders = useSelector((state: GlobalState) => {
        if (props.symbol) return state.orders.searchResults;
        return state.orders.openOrders;
    });
    const ordersRouteSymbol = props.symbol ? encodeURIComponent(props.symbol) : null;
    const symbolAssetClass = useAssetClass(props?.symbol);
    const symbolSecurityType = symbolAssetClass ? symbolAssetClass?.type : null;

    //const linkColor = useAssetClassColors(symbolAssetClass, GetDeviceThemeVariant()).primaryColor;
    const text = useText((t) => t);

    useEffect(() => {
        if (props.symbol)
            dispatch(
                SearchOrdersV3Action({
                    symbol: props.symbol,
                    take: 100,
                    status: props.status,
                    tradeableSecurityType: ConvertTradeableSecurityTypeToApiTradeableSecurityType(symbolSecurityType)
                })
            );
        else dispatch(GetAllOpenOrdersAction({}));
    }, [props.symbol, props.status, dispatch, symbolAssetClass, symbolSecurityType]);

    const filteredOrders = useMemo(
        () =>
            orders?.data?.filter((o) => {
                if (props.symbol) {
                    if (props.status === 'All') return props.symbol === o.symbol;
                    else return props.symbol === o.symbol && openStatuses.includes(o?.orderStatus);
                }
                if (props.status === 'All') return true;
                if (props.accountNumber && props.accountNumber !== 'all' && o.accountNumber !== props.accountNumber) return false;
                return openStatuses.includes(o?.orderStatus);
            }),
        [orders?.data, props?.accountNumber, props.status, props.symbol]
    );

    const hideHeader = !(orders?.loading || !filteredOrders?.length);
    const { canViewTradeHistory } = GetVariant();

    return (
        <Flex column className='open-orders'>
            {hideHeader && (
                <SectionHeader
                    actionLabel={canViewTradeHistory && !props.noHistoryLink ? text.orders.orderHistory : null}
                    assetFamily={symbolAssetClass.family}
                    label={props.label ? props.label : text.orders.openOrders}
                    route={canViewTradeHistory && !props.noHistoryLink ? Routes.orders(ordersRouteSymbol, null, props.accountNumber) : null}
                    onAction={
                        canViewTradeHistory && !props.noHistoryLink ? () => LogEvent('Trade History button click', undefined, false, TelemetryCategories.history) : null
                    }
                />
            )}
            <ErrorBoundary>
                <OrderList
                    allowExpand
                    expandedSize={50}
                    loading={orders?.loading || !orders?.data}
                    orders={filteredOrders}
                    securityKnown={props.securityKnown}
                    unexpandedSize={props.unexpandedOrders || 3}
                />
            </ErrorBoundary>
        </Flex>
    );
});
