// @ts-strict-ignore
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tour, { ReactourStep } from 'reactour';
import { TourBadge } from './TourBadge';
import { GetMyUserInfoAction } from 'phoenix/redux/actions';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { GetShowTours } from 'phoenix/util';
import style from './style.module.scss';
import { useRelay } from 'phoenix/hooks/UseRelay';
import { Relays } from 'constants/Relays'
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { ProgressNameKey } from 'phoenix/constants/ProgressNames';
import { Typography } from '@mui/material';
import { useColors } from 'hooks/UseColors';
import { useProgress } from 'hooks/UseProgress';
import { useText } from 'phoenix/hooks/UseText';


interface SnexTourProps {
    progressName: string,
    reduxSelectors?: string,
    steps: ReactourStep[]
}

export const SnexTour = React.memo((props: SnexTourProps) => {
    return (
        <SnexTours tours={[{...props}]} />
    )
});

export const SnexTours = React.memo(({tours}:{tours: SnexTourProps[]}) : JSX.Element => {
    const colors = useColors();
    const dispatch = useDispatch();
    const [curProgress, setCurProgress] = useProgress();
    const [show, setShow] = useState<boolean | null>(null);
    const info = useSelector((s: GlobalState) => s.user.myInfo);
    const progress = useSnexStore(s => s.user.updateProgress)
    const [welcomeModalVisible, _] = useRelay(Relays.WelcomeModalVisible);

    const text = useText(s => s.tours);

    useEffect(() => {
        dispatch(GetMyUserInfoAction());
    }, []);
   
    const handleBeforeClose = (progressKeyNamesToSet: string[]) => {
        document.body.classList.remove('scroll-freeze');

        /* set all of the keys to true since we would have completed all the tours */
        const keys = progressKeyNamesToSet.map(p => ({[ProgressNameKey(p)]: true}))
        const mergedKeys = keys.reduce((prev, curr) => {
            Object.assign(prev, curr)
            return prev
        }, {})
       setCurProgress({ ...curProgress, ...mergedKeys})
    }

    const handleAfterOpen = () => {
        document.body.classList.add('scroll-freeze');
    };

    const progressNames = useMemo(() => tours.flatMap(p => p.progressName), [tours])
    const visibleSteps = useMemo(() => tours.flatMap(p => p.steps.filter(s => {
       const el = s?.selector ? document.getElementById(s.selector.replace('#', '')) : null
       const isVisible = !!el && el?.style.visibility !== 'hidden'
       const hasToured = GetShowTours() ? false : curProgress?.[ProgressNameKey(p.progressName)] || false;
       return isVisible && !hasToured
    }) || []) , [tours, curProgress])

    const moreThanOneStep = visibleSteps.length > 1

    useEffect(() => {
        if (!tours.some(t => t.progressName)) return;
        if (!show && !!info.data && welcomeModalVisible === false && !info.pristine && !info.loading && !progress.loading) {
            const hasBeenWelcomed = curProgress?.hasBeenWelcomed || false;
            const hasToured = GetShowTours() ? false : tours.every(t => curProgress?.[ProgressNameKey(t.progressName)]) || false;
            if (hasBeenWelcomed) setShow(!hasToured);
        }
        if (welcomeModalVisible) setShow(false)
    }, [info, welcomeModalVisible, progress, JSON.stringify(curProgress), tours]);

    if(!visibleSteps || visibleSteps.length < 1) return null;

    return (
        <div id='tour-parent'>
        <Tour
            disableInteraction
            accentColor={colors.primaryItemColor}
            badgeContent={(curr, tot) => <TourBadge curr={curr} tot={tot} preposition={text.stepsBadgePreposition}/>}
            className={style.tooltip}
            highlightedMaskClassName={style.tourMask}
            isOpen={show === true}
            lastStepNextButton={<Typography variant='body2'>{text.lastStepButtonText}</Typography>}
            maskSpace={15}
            rounded={7}
            scrollDuration={500}
            startAt={0}
            steps={visibleSteps}
            onAfterOpen={handleAfterOpen}
            onBeforeClose={() => handleBeforeClose(progressNames)}
            onRequestClose={() => setShow(false)} 
            showNumber={moreThanOneStep}
            showNavigation={moreThanOneStep}
            showNavigationNumber={moreThanOneStep}
            showButtons={moreThanOneStep}
            /> 
    </div>
    );
})