// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".account-summary-header{padding:15px 0}.account-summary-header .account-summary-header-text{font-size:20px;font-weight:bold;line-height:normal}.account-summary-row{font-weight:500;padding:14px 0px;border-bottom:solid 1px var(--dividerColor);width:100%}.account-summary-row .account-summary-row-text{font-size:15px;line-height:normal;padding-left:8px}.account-summary-row .account-summary-row-figure{font-size:15px;font-weight:400;padding-right:8px}.account-summary-row-margin{font-weight:500;padding:14px 0px;width:100%}.account-summary-row-margin .account-summary-row-text{font-size:15px;line-height:normal;padding-left:8px}.account-summary-row-margin .account-summary-row-figure{font-size:15px;font-weight:400;padding-right:8px}.maintenance-row .account-summary-row-text,.nyse-row .account-summary-row-text{color:var(--orange)}.maintenance-row .account-summary-row-figure,.nyse-row .account-summary-row-figure{color:var(--orange)}.fed-row .account-summary-row-text{color:var(--red)}.fed-row .account-summary-row-figure{color:var(--red)}.account-summary-total-row{font-weight:500;padding:14px 0px;width:100%;background-color:var(--cellHoverBackgroundColor);border-radius:10.5px}.account-summary-total-row .account-summary-row-text{font-size:15px;line-height:normal;padding-left:8px}.account-summary-total-row .account-summary-row-figure{font-size:15px;font-weight:400;padding-right:8px}.account-summary-total-row .account-summary-row-text{color:var(--primaryItemColor)}.listIconWrapper{border-radius:5px;transition:.1s;cursor:pointer;padding-left:12px}.green-btn,.green-btn:hover{background-color:var(--positiveValueColor);font-weight:500;border-radius:4px;min-height:25;color:var(--white);font-size:13px;white-space:normal}.deposit-btn{padding-top:10px}.main-wrapper{border-top:solid 1px var(--dividerColor)}", ""]);
// Exports
exports.locals = {
	"mainBackgroundColor": "#fff",
	"cardBackgroundColor": "#fff",
	"cardSecondaryBackgroundColor": "#fff",
	"blurredBackgroundColor": "rgba(255,255,255,.9176470588)",
	"fadedTextColor": "#596269",
	"blurredAdornmentColor": "#a9a9a9",
	"focusedAdornmentColor": "#000",
	"primaryItemColor": "#346094",
	"primaryItemBackdropColor": "rgba(50,103,168,.24)",
	"primaryItemLightColor": "rgba(52,95,148,.1921568627)",
	"dividerColor": "#f5f5f5",
	"generalTextColor": "#000",
	"areaChartNegativeColor": "#f7e0e3",
	"areaChartPositiveColor": "#e0f6e6",
	"secondaryButtonBackgroundColor": "#f7fafc",
	"secondaryButtonBackgroundHoverColor": "#d2e3ee",
	"colorOverlay": "rgba(255,255,255,.7)",
	"greyBackgroundColor": "#f8f8f8",
	"inputBorderColor": "rgba(0,0,0,.15)",
	"inputBorderActiveColor": "rgba(213,213,213,.438)",
	"disabledButtonText": "#fff",
	"scrollbarColor": "#e9e9e9",
	"segmentedControlBackgroundColor": "#f0f7ff",
	"segmentedControlActiveColor": "#336094",
	"positiveValueColor": "#02b334",
	"positiveValueLighterColor": "rgba(2,179,52,.16)",
	"negativeValueColor": "#b2292e",
	"negativeValueLighterColor": "rgba(178,41,46,.16)",
	"futuresColor": "#28bbd6",
	"fplGreen": "\"#13B53F\"",
	"futuresBackdropColor": "rgba(40,188,214,.2196078431)",
	"stickyCard": "#ffc",
	"selectListItemColor": "rgba(51,96,148,.1)",
	"selectListSelectedItemColor": "#346094"
};
module.exports = exports;
