// @ts-strict-ignore
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CardActionArea, Grid, Skeleton, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ExpandButton, Flex, SectionHeader } from '..';
import { GetSectorPerformanceAction, StartPollingSectorPerformanceAction, StopPollingSectorPerformanceAction } from 'phoenix/redux/actions/SecuritiesActions';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { SectorPerformance } from 'phoenix/redux/models/Securities/SectorPerformance';
import { ChangeColor, FormatNumber } from 'phoenix/util';
import { Card } from '../Card';
import { CulledCollapse } from '../CulledCollapse/CulledCollapse';
import { T } from 'phoenix/assets/lang/T';
import { useAppWindowSize, WindowSizes } from 'hooks/UseWindowSize';
import { LogEvent } from 'util/Tele';
import { Routes } from 'util/Routes';
import { GetSectorDisplayName, GetSectorForRoute } from 'screens/SectorScreen/SectorScreen';
import { useText } from 'phoenix/hooks/UseText';
import { Link } from 'react-router-dom';

export const SectorCardSection = () => {
    const dispatch = useDispatch();
    const [appWindowSize] = useAppWindowSize();
    const sectors = useSelector((s: GlobalState) => s.securities.sectors.performance);
    const [expanded, setExpanded] = useState(false);

    const rowCount = useMemo(() => {
        switch (appWindowSize) {
            case WindowSizes.tablet:
                return 2;
            case WindowSizes.regular:
            case WindowSizes.small:
                return 3;
            case WindowSizes.large:
            default:
                return 4;
        }
    }, [appWindowSize]);

    useEffect(() => {
        dispatch(GetSectorPerformanceAction());
        dispatch(StartPollingSectorPerformanceAction());
        return () => {
            dispatch(StopPollingSectorPerformanceAction());
        };
    }, []);

    return (
        <Flex column id={'sectors-section'}>
            <SectionHeader label={T((s) => s.portfolioScreen.sectors.sectors)} infoIconMessagePath={(s) => s.topMoversAndSectors} />
            <Grid container spacing={2} style={{ marginBottom: 10 }}>
                {!sectors.data ? (
                    <>
                        <SectorCard loading />
                        <SectorCard loading />
                        <SectorCard loading />
                        <SectorCard loading />
                    </>
                ) : (
                    sectors.data.slice(0, rowCount).map((c, idx) => <SectorCard key={c.name} sector={c} />)
                )}
            </Grid>
            <CulledCollapse in={expanded}>
                <Grid container spacing={3}>
                    {sectors.data.slice(rowCount).map((c) => (
                        <SectorCard key={c.name} sector={c} />
                    ))}
                </Grid>
            </CulledCollapse>
            <ExpandButton expanded={expanded} onClick={() => setExpanded(!expanded)} />
        </Flex>
    );
};

const SectorCard = React.memo((p: { sector?: SectorPerformance; rank?: number; loading?: boolean; hidden?: boolean }) => {
    const [appWindowSize, setAppWindowSize] = useAppWindowSize();

    const gridSize = useMemo(() => {
        switch (appWindowSize) {
            case WindowSizes.large:
                return 3;
            case WindowSizes.tablet:
                return 6;
            case WindowSizes.regular:
            case WindowSizes.small:
            default:
                return 4;
        }
    }, [appWindowSize]);

    const Loading = useCallback((p: { width?: number }) => {
        return <Skeleton animation='wave' style={{ width: p.width || 100 }} />;
    }, []);

    const handleClick = (name) => {
        LogEvent(`${name} sector click`, { name, route: Routes.sectors(name) });
    };

    return (
        <Grid item xs={gridSize}>
            <Card>
                <Link to={Routes.sectors(GetSectorForRoute(p.sector?.name))}>
                    <CardActionArea style={{ padding: 20 }} disableRipple onClick={() => handleClick(p.sector?.name)}>
                        <Flex column justify='space-between' style={{ height: 115 }}>
                            <Flex column>
                                <div style={{ height: 10, marginBottom: 10 }}>
                                    {p.sector?.lastUpdated && (
                                        <Typography style={{ opacity: 0.5 }} variant='caption'>
                                            {T((s) => s.portfolioScreen.sectors.asOf(new Date(p.sector.lastUpdated)))}
                                        </Typography>
                                    )}
                                </div>
                                {!p.loading ? (
                                    <Typography variant={'h4'}>
                                        {GetSectorDisplayName(
                                            p.sector?.name,
                                            useText((s) => s.sectorScreen)
                                        )}
                                    </Typography>
                                ) : (
                                    <Loading width={200} />
                                )}
                            </Flex>
                            <Typography style={{ color: ChangeColor(p.sector?.performance) }} variant='h4'>
                                {FormatNumber.toPercent(p.sector?.performance * 100)}
                            </Typography>
                        </Flex>
                    </CardActionArea>
                </Link>
            </Card>
        </Grid>
    );
});
