import { ReactourStep } from 'reactour';
import { TourGuide } from 'components/SnexTour/TourGuide';
import React from 'react';
import { GetVariant } from 'phoenix/util/Variant';

export const PreFundedPortfolioTour = (): ReactourStep[] => [
    {
        selector: '#tour-01-chart', // PortfolioChartSection.tsx
        content: () => <TourGuide key={1} text={ s => s.tours.portfolioScreen.chart } />
    },
    {
        selector: '#tour-02-watchlist', // PortfolioScreen.tsx
        content: () => <TourGuide key={2} text={ s => s.tours.portfolioScreen.watchlist } />
    },
    {
        selector: '#tour-03-deposit-funds', // PortfolioChartSection.tsx
        content: () => <TourGuide key={3} text={ s => s.tours.portfolioScreen.depositFunds } />
    },
    {
        selector: '#tour-04-search', // MainSearchBox.tsx
        content: () => <TourGuide key={4} text={ s => s.tours.portfolioScreen.search } />
    },
    {
        selector: '#tour-05-profile', // Header.tsx
        content: () => <TourGuide key={5} text={ s => s.tours.portfolioScreen.profile } />
    }
];

export const PostFundedPortfolioTour = (): ReactourStep[] => [
    {
        selector: '#tour-01-chart', // PortfolioChartSection.tsx
        content: () => <TourGuide key={6} text={ s => s.tours.portfolioScreen.chart } />
    },
    {
        selector: '#tour-02-watchlist', // PortfolioScreen.tsx
        content: () => <TourGuide key={7} text={ s => s.tours.portfolioScreen.watchlist } />
    },
    {
        selector: '#tour-04-search', // MainSearchBox.tsx
        content: () => <TourGuide key={8} text={ s => s.tours.portfolioScreen.search } />
    },
    {
        selector: '#tour-05-profile', // Header.tsx
        content: () => <TourGuide key={9} text={ s => s.tours.portfolioScreen.profile } />
    },
];

export const StockScreenTour = (): ReactourStep[] => {
    const { showAdvancedChart, showTradeTicket, showOptions } = GetVariant();
    return [
        {
            selector: '#tour-03-add-to-watchlist', // SecurityChartSection.tsx
            content: () => <TourGuide key={3} text={ s => s.tours.stockScreen.addToWatchlist } /> 
        },
        {
            selector: '#tour-01-candle-chart-button', // SecurityChartSection.tsx
            content: () => <TourGuide key={1} text={ s => s.tours.stockScreen.candlestick }></TourGuide>
        },
        ...(showAdvancedChart ? [ {
            selector: '#tour-02-advanced-chart-button', // SecurityChartSection.tsx
            content: () => <TourGuide key={2} text={ s => s.tours.stockScreen.advancedChart } /> 
        } ] : []),
        ...(showTradeTicket ? [ {
            selector: '#tour-04-order-type', // StockScreen.tsx
            content: () => <TourGuide key={4} text={ s => s.tours.stockScreen.orderType } /> 
        } ] : []),
        ...(showOptions ? [ {
            selector: '#tour-05-options-trading', // StockScreen.tsx
            content: () => <TourGuide key={5} text={ s => s.tours.stockScreen.optionsTrading } /> 
        } ] : [])
    ]};

export const PremiumStockScreenTour = (): ReactourStep[] => {
    const { showAdvancedChart, showTradeTicket, showOptions } = GetVariant();
    return [
        {
            selector: '#tour-02-advanced-chart-button', // SecurityChartSection.tsx
            content: () => <TourGuide key={1} text={ s => s.tours.premiumStockScreen.advancedChart } /> 
        },
        {
            selector: '#tour-p01-alerts-icon', // SecurityChartSection.tsx
            content: () => <TourGuide key={2} text={ s => s.tours.premiumStockScreen.alerts } />
        },
        ...(showAdvancedChart ? [ {
            selector: '#tour-p02-deep-analysis', // SecurityChartSection.tsx
            content: () => <TourGuide key={3} text={ s => s.tours.premiumStockScreen.deepAnalysis } /> 
        } ] : []),
        ...(showTradeTicket ? [ {
            selector: '#tour-p03-news', // StockScreen.tsx
            content: () => <TourGuide key={4} text={ s => s.tours.premiumStockScreen.streamingNews } /> 
        } ] : [])
    ]};

export const TicketBarTour = (): ReactourStep[] => {
    return [
        {
            selector: '#tour-06-tradingViewMarquee', // TradingViewMarquee.tsx
            content: () => <TourGuide key={1} text={ s => s.tours.tickerBar.tradingViewMarquee } /> 
        },
    ]
}