// @ts-strict-ignore
import { Button, Typography } from '@mui/material';
import { GetAssetClassFromAccountNumber } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { AccountChartsForMarketTimeManager } from 'components/Charting/MarketTimeChartManagers';
import { getUnixTime } from 'date-fns';
import { useProgress } from 'hooks/UseProgress';
import { useAppWindowSize, useAppWindowSizeVariable } from 'hooks/UseWindowSize';
import { ResolveEnvironment } from 'phoenix/constants';
import { ChartRange, ChartRanges } from 'phoenix/constants/ChartRange';
import { LiveDataNamespaces } from 'phoenix/constants/LiveDataNamespaces';
import { useAccountValuation } from 'phoenix/hooks/UseAccountValuation';
import { useText } from 'phoenix/hooks/UseText';
import { AssetClass } from 'phoenix/models/AssetClasses/AssetClass';
import { useAccountAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import { ReduxLiveUnsubscribeNamespace } from 'phoenix/redux/actions';
import { GetAccountChartAction, GetAggregateAccountChartAction, GetDummyAggregateAccountChartAction } from 'phoenix/redux/actions/AccountChartActions';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { ChangeColor, GetCurrentRelevantTimeFrame, GetUseLimitedAccess } from 'phoenix/util';
import { GetChartFrequency } from 'phoenix/util/ChartHelpers';
import { GetVariant } from 'phoenix/util/Variant';
import { XS } from 'phoenix/xstream/XS';
import { useXstreamDispatch } from 'phoenix/xstream/XstreamProvider';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Routes } from 'util/Routes';
import { DetermineChange } from 'util/Utils';
import { Flex } from '..';
import { SkeletonChart } from '../Charting/Chart/SkeletonChart';
import { RangeSelector } from '../Charting/RangeSelectors';
import { AccountValues } from './AccountValues/AccountValues';
import { AccountValuesViewModel } from './AccountValues/AccountValuesViewModel';
import './PortfolioChart.scss';
import { FuturesAssetClass } from 'phoenix/models/AssetClasses/FuturesAssetClass';
import MultiSeriesChart from 'components/Charting/Chart/MultiSeriesChart';
import { SnexChartPoint, SnexChartTimezone, SnexChartTimezoneType } from 'phoenix/constants/ChartTypes';
import { getMultiSeriesConfig } from 'components/Charting/Chart/helpers';
import { CrosshairUpdateObject } from 'components/Charting/SecurityChartWrapper';

interface PortfolioChartSectionProps {
    accountNumber?: string;
}

const getExcludedRangesForAssetClass = (assetClass: AssetClass) => {
    if (!assetClass) return new Set(['24h', '1w', '6m', 'All'] as ChartRange[]);
    const assetChartRanges = assetClass.chartRanges().map((range) => range.value);

    // TODO - may need to add separate range config on AssetClass for Account Valuations vs. Securities
    const toExclude = (range: ChartRange) => {
        if (assetClass !== FuturesAssetClass) return !assetChartRanges.includes(range);

        return (!assetChartRanges.includes(range) && range !== ChartRanges.twentyFourHours) || range === ChartRanges.oneDay;
    };

    return new Set(Object.values(ChartRanges).filter((range) => toExclude(range)));
};

export const PortfolioChartSection = React.memo((props: PortfolioChartSectionProps) => {
    const { accountNumber } = props;
    const xdispatch = useXstreamDispatch();
    const dispatch = useDispatch();
    const variant = useMemo(() => GetVariant(), []);
    const [progress] = useProgress();
    const [streamingData, setStreamingData] = useState([]);
    const assetClass = useAccountAssetClass(accountNumber);
    const defaultRange = assetClass === FuturesAssetClass ? '24h' : variant.isClient ? '1m' : assetClass.accountChartRanges?.[0] || '1d';

    const hasTraded = progress ? progress.hasTraded : true;
    const [range, setRange] = useState<ChartRange>(defaultRange);
    // const [isLoading, setIsLoading] = useState(true);
    const [appWindowSize] = useAppWindowSize();
    const userInfo = useSelector((s: GlobalState) => s.user.myInfo.data);
    const displayFundOverlay = userInfo && !userInfo?.hasFunded;

    const { change, chart, summary } = useAccountValuation(accountNumber, range, { withoutMarketTimeRules: true });
    // const [isInitialLoad, setIsInitialLoad] = useState(true); // TODO Figure out if we need to actually pass back a loading state from useAccountValuations
    const isInitialLoad = !chart || !summary; // TODO Might need to pass loading state for chart, empty doesn't necessarily mean loading

    const chartAsSeriesData = useMemo(() => chart?.map((p) => ({ ...p, time: getUnixTime(new Date(p.timestamp)) })), [chart]);

    const latestValue = summary?.totalAccountValueCurrent;

    const rangeForLastCalDay = useMemo(() => range, [range]);
    const chartDimensions = useAppWindowSizeVariable({
        tablet: { width: 560, height: 320 },
        md: { width: 670, height: 350 },
        sm: { width: 670, height: 350 },
        def: { width: 1000, height: 350 }
    });

    useEffect(() => {
        if (accountNumber) dispatch(GetAccountChartAction(accountNumber, defaultRange));
        if (accountNumber) xdispatch(XS.AccountValuations.start(accountNumber, LiveDataNamespaces.AccountChart));
        else xdispatch(XS.PortfolioValuation.start(LiveDataNamespaces.PortfolioChart));
        return () => {
            ReduxLiveUnsubscribeNamespace(LiveDataNamespaces.PortfolioChart);
            ReduxLiveUnsubscribeNamespace(LiveDataNamespaces.AccountChart);
            XS.stopNs(LiveDataNamespaces.AccountChart);
            XS.stopNs(LiveDataNamespaces.PortfolioChart);
        };
    }, []);

    useEffect(() => {
        // Since Mondays and the weekend in general won't have any chart data, we want to let the API know to get the previous calendar day as opposed to the 1D chart (bc it's empty).
        if (userInfo === null) return;
        if (!displayFundOverlay) {
            if (accountNumber) dispatch(GetAccountChartAction(accountNumber, rangeForLastCalDay));
            else dispatch(GetAggregateAccountChartAction(rangeForLastCalDay));
        } else dispatch(GetDummyAggregateAccountChartAction());
    }, [range, accountNumber, userInfo]);

    useEffect(() => {
        if (latestValue && GetCurrentRelevantTimeFrame() === 'today') setStreamingData([...streamingData, { time: new Date().getTime() / 1000, value: latestValue }]);
    }, [latestValue]);

    const onCrosshairUpdate = useCallback((update: CrosshairUpdateObject, isScrubbing?: boolean) => {
        if (isScrubbing) {
            const changeValues = { value: update?.value, change: update?.chartValChange, changePercent: update?.chartPercChange };
            AccountValuesViewModel.setValuesOverride(true);
            AccountValuesViewModel.setValues(changeValues);
        } else {
            AccountValuesViewModel.setValuesOverride(false);
        }
    }, []);

    const chartChange = { valueChange: change };

    const segmentScale = useMemo(() => {
        switch (range) {
            case '24h':
            case '1d':
            case '5d':
                return 'day';
            case '1m':
                return 'week';
            case '3m':
            case '1y':
                return 'month';
            case '5y':
            default:
                return 'year';
        }
    }, [range]);

    const changeForColorOverride = useMemo(
        () => (range === '1d' ? DetermineChange(latestValue, latestValue - change)?.valueChange : chartChange.valueChange),
        [range, change, chartChange.valueChange, latestValue]
    );

    const chartFrequency = useMemo(() => (chart && range === '1d' ? GetChartFrequency(chartAsSeriesData) : null), [chart, chartAsSeriesData, range]);

    const excludedRanges: Set<ChartRange> | undefined = useMemo(() => {
        const excludeRange = getExcludedRangesForAssetClass(assetClass);
        return variant?.isClient ? excludeRange.add('1d').add('5d') : excludeRange;
    }, [assetClass, variant?.isClient]);

    const multiSeries = useMemo(
        () =>
            getMultiSeriesConfig({
                data: chartAsSeriesData as SnexChartPoint[],
                segmentScale,
                seriesType: 'line',
                showVolume: false,
                timezone: (assetClass.family === 'futures' ? SnexChartTimezone.cdt : SnexChartTimezone.edt) as SnexChartTimezoneType
            }),
        [assetClass.family, chartAsSeriesData, segmentScale]
    );


    return (
        <div key={`${appWindowSize}`} style={{ minHeight: 475, marginTop: hasTraded ? 0 : 50 }}>
            {displayFundOverlay ? <NoFundsDisplay /> : null}
            <div id='tour-01-chart' style={{ opacity: displayFundOverlay ? 0.4 : 1, marginBottom: 25 }}>
                <AccountValues accountNumber={accountNumber} range={range} />
                <div style={{ position: 'relative', minHeight: chartDimensions.height, overflow: 'hidden' }}>
                    {isInitialLoad && (
                        <div style={{ position: 'absolute' }}>
                            <SkeletonChart containerId={`portfolio-${appWindowSize}-skeleton`} height={chartDimensions.height} width={chartDimensions.width} />
                        </div>
                    )}
                    <div style={{ visibility: isInitialLoad ? 'hidden' : 'visible' }}>
                        <MultiSeriesChart
                            canScale={false}
                            canToggleSeries={false}
                            height={chartDimensions.height}
                            isLoading={isInitialLoad}
                            logicalRangeOverride={chartFrequency ? 420 / chartFrequency : null}
                            multiSeries={multiSeries}
                            onCrosshairUpdate={onCrosshairUpdate}
                            range={range}
                            width={chartDimensions.width}
                        />
                    </div>
                </div>
                <RangeSelector
                    excludeRanges={excludedRanges}
                    disabled={displayFundOverlay}
                    selectedRange={range}
                    setRange={setRange}
                    valueChange={changeForColorOverride}
                />
            </div>
            <AccountChartsForMarketTimeManager accountNumber={accountNumber} range={rangeForLastCalDay} />
        </div>
    );
});

const NoFundsDisplay = React.memo(() => {
    const text = useText((s) => s.portfolioScreen);
    const isBeta = GetUseLimitedAccess() || ResolveEnvironment() === 'beta';
    return (
        <Flex row align='center' justify='center' style={{ position: 'absolute', top: 150, width: 1000, zIndex: 50 }}>
            <Flex column align='center' justify='center'>
                <Typography color='primary' style={{ marginBottom: 20 }} variant='h3'>
                    {text.readyToStart(isBeta)}
                </Typography>
                <Link to={Routes.banking()}>
                    <Button id='tour-03-deposit-funds' style={{ backgroundColor: ChangeColor(1), color: 'white', fontSize: 18, padding: '10px 30px' }}>
                        {text.depositFunds}
                    </Button>
                </Link>
            </Flex>
        </Flex>
    );
});
