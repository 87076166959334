// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1CozdRRfxzlC2l6XcvIb7W{border-radius:10px}._3ym_2_qmi0mfifib58BxBY{background:var(--cardBackgroundColor);border-radius:10px}", ""]);
// Exports
exports.locals = {
	"mainBackgroundColor": "#fff",
	"cardBackgroundColor": "#fff",
	"cardSecondaryBackgroundColor": "#fff",
	"blurredBackgroundColor": "rgba(255,255,255,.9176470588)",
	"fadedTextColor": "#596269",
	"blurredAdornmentColor": "#a9a9a9",
	"focusedAdornmentColor": "#000",
	"primaryItemColor": "#346094",
	"primaryItemBackdropColor": "rgba(50,103,168,.24)",
	"primaryItemLightColor": "rgba(52,95,148,.1921568627)",
	"dividerColor": "#f5f5f5",
	"generalTextColor": "#000",
	"areaChartNegativeColor": "#f7e0e3",
	"areaChartPositiveColor": "#e0f6e6",
	"secondaryButtonBackgroundColor": "#f7fafc",
	"secondaryButtonBackgroundHoverColor": "#d2e3ee",
	"colorOverlay": "rgba(255,255,255,.7)",
	"greyBackgroundColor": "#f8f8f8",
	"inputBorderColor": "rgba(0,0,0,.15)",
	"inputBorderActiveColor": "rgba(213,213,213,.438)",
	"disabledButtonText": "#fff",
	"scrollbarColor": "#e9e9e9",
	"segmentedControlBackgroundColor": "#f0f7ff",
	"segmentedControlActiveColor": "#336094",
	"positiveValueColor": "#02b334",
	"positiveValueLighterColor": "rgba(2,179,52,.16)",
	"negativeValueColor": "#b2292e",
	"negativeValueLighterColor": "rgba(178,41,46,.16)",
	"futuresColor": "#28bbd6",
	"fplGreen": "\"#13B53F\"",
	"futuresBackdropColor": "rgba(40,188,214,.2196078431)",
	"stickyCard": "#ffc",
	"selectListItemColor": "rgba(51,96,148,.1)",
	"selectListSelectedItemColor": "#346094",
	"tourMask": "_1CozdRRfxzlC2l6XcvIb7W",
	"tooltip": "_3ym_2_qmi0mfifib58BxBY"
};
module.exports = exports;
